import { Descope } from '@descope/react-sdk'
import { useQueryClient } from '@tanstack/react-query'

import { listUsers } from '@/gen/customer/management/v1/service-CustomerManagementService_connectquery'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useToast } from '@/components/ui/use-toast'

type InvitePageProps = {
  onSuccess?: () => void
}

export const InvitePage = ({ onSuccess }: InvitePageProps) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { queryKey: listUsersQueryKey } = listUsers.useQuery()

  const reportErrors = useReportErrorsCallback()
  return (
    <div className='mx-auto max-w-lg'>
      <Descope
        flowId='invite'
        theme='light'
        onSuccess={() => {
          toast({
            status: 'success',
            title: 'User has been successfully invited.',
          })
          queryClient.invalidateQueries(listUsersQueryKey)
          onSuccess && onSuccess()
        }}
        onError={(err) => reportErrors(new Error(`Descope error ${err}`))}
      />
    </div>
  )
}
