import { Fragment, ReactNode } from 'react'
import { VictoryPie } from 'victory'

import { purpleScale } from '@/lib/color'
import { calculatePercentage } from '@/lib/math-utils'

interface PieChartProps {
  data: { x: ReactNode; y: number }[]
}

export const PieChart = ({ data }: PieChartProps) => {
  const total = data.reduce((acc, { y }) => acc + y, 0)

  const coloredData = data.map((item, index) => ({
    ...item,
    color: purpleScale((index + 1) / data.length),
  }))

  return (
    <div className='flex w-full items-center gap-6'>
      <div className='w-72'>
        <VictoryPie
          style={{
            labels: { display: 'none' },
            data: {
              fill: ({ datum }) => datum.color,
            },
          }}
          cornerRadius={30}
          innerRadius={125}
          padAngle={2}
          data={coloredData}
        />
      </div>
      <div className='grid w-full grid-cols-[min-content,min-content,auto] items-center gap-x-3 gap-y-2'>
        {coloredData.map(({ x, y, color }, index) => (
          <Fragment key={index}>
            <div className='size-3 rounded-sm' style={{ backgroundColor: color }} /> <div>{x}</div>
            <div className='ml-auto w-8'>{calculatePercentage(y, total).toFixed(0)}%</div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}
