import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'

import { Company } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

export const isUrgentCompany = ({
  status: companyStatus,
  risk: companyRisk,
  findingsByLevel,
}: PlainMessage<Company>) => {
  if (companyStatus !== CompanyStatus.UNSANCTIONED && companyStatus !== CompanyStatus.ASSESSED) {
    return false
  }

  const findingLevels = _.filter(findingsByLevel, ({ count }) => count > 0).map(
    ({ riskLevel }) => riskLevel,
  )

  const hasUrgentFindings = findingLevels.some((riskLevel) =>
    [RiskLevel.CRITICAL, RiskLevel.HIGH].includes(riskLevel),
  )

  if (!hasUrgentFindings) {
    return false
  }

  if (companyStatus === CompanyStatus.UNSANCTIONED) {
    if (![RiskLevel.CRITICAL, RiskLevel.HIGH].includes(companyRisk)) {
      return false
    }
  }

  return true
}
