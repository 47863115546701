import { Modal } from 'antd'
import { CheckCircle2Icon } from 'lucide-react'
import { useState } from 'react'

import {
  InviteStep,
  InviteStepProps,
} from '@/pages/questionnaire-management/new-questionnaire-modal/step-4-invite'
import { InputRecipient } from '@/pages/questionnaire/input-recipient'

import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'

export type InviteRecipientModalProps = {
  mutateFunc: (
    recipientEmails: string[],
  ) => Promise<{ links: { recipient: string; magicLink: string }[] }>
}

export const useInviteRecipientModal = ({ mutateFunc }: InviteRecipientModalProps) => {
  const [open, setOpen] = useState(false)
  const [recipients, setRecipients] = useState<string[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [magicLinks, setMagicLinks] = useState<Pick<InviteStepProps, 'invitees'>>()

  const reset = () => {
    setRecipients([])
    setSubmitted(false)
    setMagicLinks(undefined)
    return true
  }

  const invite = async () => {
    const res = await mutateFunc(recipients)
    setMagicLinks({
      invitees: res.links.map((item) => ({
        label: item.recipient,
        magicLink: item.magicLink,
      })),
    })
    setSubmitted(true)
  }

  const renderModal = () => {
    return (
      <Modal
        title='Invite Questionnaire Recipient(s)'
        open={open}
        footer={
          <div>
            {!submitted && (
              <Button onClick={() => invite()} disabled={recipients.length === 0}>
                Send Invite via Email
              </Button>
            )}
            {submitted && <Button onClick={() => reset() && setOpen(false)}>Close</Button>}
          </div>
        }
        onCancel={() => reset() && setOpen(false)}
      >
        {!submitted && (
          <>
            <Label className='text-bold mb-2'>Recipient email addresses</Label>
            <InputRecipient onRecipientChange={setRecipients} />
          </>
        )}
        {submitted && (
          <InviteStep
            invitees={magicLinks?.invitees || []}
            title={
              <div className='flex flex-col gap-2 text-base'>
                <div className='flex items-center gap-1'>
                  <CheckCircle2Icon size={16} className='text-green-500' />
                  <span className='font-semibold'>Invite emails sent successfully!</span>
                </div>
                <span>Optional: You may also send these invite links to your invitees</span>
              </div>
            }
          />
        )}
      </Modal>
    )
  }

  return { renderModal, openModal: () => setOpen(true) }
}
