import { Steps as AntdSteps, Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { ReactElement, useRef, useState } from 'react'

import { useCreateCompany } from '@/api/company.hook'
import { AutocompleteItem } from '@/gen/companycatalog/v1/company_catalog_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { useReportErrorsCallback } from '@/lib/error-reporting'

import { UploadFilesRef } from '@/pages/settings-page/artifacts-section/upload-files'

import { UploadArtifactStep } from '@/components/add-new-third-party/artifact-upload-step'
import { AddNewThirdPartyContext } from '@/components/add-new-third-party/context'
import { ProfileStep } from '@/components/add-new-third-party/profile-step'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

export type StepProps = {
  setIsNextEnabled: (isNextEnabled: boolean) => void
}

type Step = {
  title: string
  component: ReactElement
}

export const useAddThirdPartyModal = () => {
  const track = useTrackCallback('third-party.add')
  const reportErrors = useReportErrorsCallback()
  const { toast } = useToast()
  const uploadRef = useRef<UploadFilesRef>(null)
  const { mutateAsync: createCompanyAsync, isLoading } = useCreateCompany()
  const [isManual, setIsManual] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<AutocompleteItem | null>(null)
  const [selectedStatus, setSelectedStatus] = useState(CompanyStatus.ASSESSMENT_REQUIRED)
  const [isOpen, setIsOpen] = useState(false)
  const [isNextEnabled, setIsNextEnabled] = useState(false)

  const showModal = () => setIsOpen(true)

  const [currentStep, setCurrentStep] = useState(0)

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const steps: Step[] = [
    { title: 'Profile', component: <ProfileStep setIsNextEnabled={setIsNextEnabled} /> },
    {
      title: 'Artifact Upload',
      component: <UploadArtifactStep setIsNextEnabled={setIsNextEnabled} />,
    },
  ]

  const closeModal = (isSuccess = false) => {
    track({ step: steps[currentStep].title, isManual, isSuccess, ...selectedCompany })
    setIsManual(false)
    setCurrentStep(0)
    setSelectedCompany(null)
    setIsOpen(false)
    setSelectedStatus(CompanyStatus.ASSESSMENT_REQUIRED)
  }
  const isLastStep = currentStep === steps.length - 1
  const isFirstStep = currentStep === 0

  const onFinish = async () => {
    try {
      if (isManual && !selectedCompany?.name) {
        throw new Error('Name is required')
      }
      if (!isManual && !selectedCompany?.companyIdentification) {
        throw new Error('No company selected')
      }
      console.log('selectedCompany', selectedCompany, 'isManual', isManual)
      const { id: companyId } = await createCompanyAsync({
        isManual,
        predicate: selectedCompany?.companyIdentification,
        status: selectedStatus,
        name: selectedCompany?.name,
        website: selectedCompany?.domain,
      })

      console.log('added company successfully', companyId)
      toast({
        status: 'success',
        title: 'Third-Party added successfully!',
      })
      await uploadRef.current?.handleUpload(companyId)
      closeModal(true)
    } catch (e) {
      reportErrors(e as Error)

      // fallback attempt to create a company that is not linked to the pdl id
      // useful when PDL is not available or in development environments
      try {
        if (!isManual) {
          const { id: companyId } = await createCompanyAsync({
            isManual: true,
            status: selectedStatus,
            name: selectedCompany?.name,
            website: selectedCompany?.domain,
          })
          await uploadRef.current?.handleUpload(companyId)
          closeModal(true)
        }
      } catch (e) {
        reportErrors(e as Error)
        toast({
          status: 'error',
          title: 'Failed to add Third-Party',
        })
      }
    }
  }

  const next = async () => {
    if (isNextEnabled) {
      if (!isLastStep) {
        setCurrentStep(currentStep + 1)
      } else {
        await onFinish()
      }
    }
  }

  const renderModal = () => (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      width={670}
      styles={{
        content: { padding: 0 }, // since we have colored header, we don't need padding
      }}
      open={isOpen}
      onCancel={() => closeModal()}
      footer={
        <div className='flex items-end justify-between px-15 py-6'>
          <Button variant={'ghost'} onClick={() => closeModal()}>
            Cancel
          </Button>
          <div className='flex gap-2'>
            {!isFirstStep && (
              <Button variant={'outline'} onClick={prev}>
                Back
              </Button>
            )}
            <Button
              disabled={!isNextEnabled || isLoading || uploadRef.current?.isUploading}
              onClick={next}
            >
              {(isLoading || uploadRef.current?.isUploading) && (
                <Loader2Icon className='mr-2 size-4 animate-spin' />
              )}
              {isLastStep ? 'Finish' : 'Next'}
            </Button>
          </div>
        </div>
      }
    >
      <AddNewThirdPartyContext.Provider
        value={{
          selectedStatus,
          setSelectedStatus,
          uploadRef,
          setSelectedCompany,
          selectedCompany,
          setIsManual,
          isManual,
        }}
      >
        <div>
          <div className='w-full rounded-t bg-gray-50 px-20 pt-10'>
            <h1 className='text-3xl font-bold'>New Third-Party</h1>
            <AntdSteps className='w-2/3 py-8' size='small' current={currentStep} items={steps} />
          </div>
          <div className='px-20'>{steps[currentStep].component}</div>
        </div>
      </AddNewThirdPartyContext.Provider>
    </Modal>
  )

  return { showModal, renderModal }
}
