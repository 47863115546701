import { Tag } from 'antd'
import _ from 'lodash'
import React, { useMemo } from 'react'

import {
  InherentRiskCategory,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'

import {
  InherentRiskCategoryEnumDescription,
  InherentRiskCategoryEnumTitle,
  riskLevelLabel,
} from '@/const/label'

import { riskLevelToBgColor, riskLevelToColor } from '@/lib/color'

import { IrqCheckbox } from './irq-checkbox'

export type IrqCarouselPageProps = {
  inherentRiskCategories: InherentRiskCategory[]
  irqStatus: Set<string>
  suggestions: Map<string, InherentRiskSource>
  thirdPartyId: string
  transactionId: string
}

export const IrqCarouselPage = ({
  inherentRiskCategories,
  irqStatus,
  suggestions,
  thirdPartyId,
  transactionId,
}: IrqCarouselPageProps) => {
  const bySeverity: { [severity: string]: InherentRiskItem[] }[] = useMemo(() => {
    return _.map(inherentRiskCategories, (category: InherentRiskCategory) =>
      _.groupBy(category.items, (item) => riskLevelLabel[item.severity]),
    )
  }, [inherentRiskCategories])
  return (
    <div className='flex flex-col'>
      <div className='flex flex-col self-center'>
        {inherentRiskCategories.map((category: InherentRiskCategory, idx: number) => {
          return (
            <React.Fragment key={category.categoryEnum}>
              <h3 className='w-full text-xl font-bold text-gray-700'>
                {InherentRiskCategoryEnumTitle[category.categoryEnum]}
              </h3>
              <div className='mb-4 w-min'>
                <h4 className='mb-2 text-wrap break-words text-lg text-gray-700'>
                  {InherentRiskCategoryEnumDescription[category.categoryEnum]}
                </h4>
                {_.entries(bySeverity[idx]).map(([severity, items]) => {
                  const riskColor = riskLevelToColor[items[0].severity]
                  const riskBgColor = riskLevelToBgColor[items[0].severity]

                  return (
                    <div key={`irqgroup-${category.categoryEnum}-${severity}`}>
                      <Tag
                        className='mt-2'
                        style={{ color: riskColor }}
                        bordered={false}
                        color={riskBgColor}
                      >
                        {severity}
                      </Tag>
                      {items.map((item) => {
                        return (
                          item.id && (
                            <IrqCheckbox
                              transactionId={transactionId}
                              thirdPartyId={thirdPartyId}
                              key={item.id}
                              riskCategoryId={item.id}
                              displayName={item.displayName}
                              value={irqStatus.has(item.id)}
                              categoryEnum={category.categoryEnum}
                              suggestion={
                                suggestions.get(item.id) || InherentRiskSource.UNSPECIFIED
                              }
                            />
                          )
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
