import { Modal } from 'antd'
import { Loader2Icon, MoreHorizontalIcon } from 'lucide-react'
import { useEffect } from 'react'

import { useListUsers } from '@/api/list-users.hook'
import { useRemoveUserFromTenant } from '@/api/remove-user-from-tenant.hook'
import { User } from '@/gen/customer/management/v1/service_pb'

import { UserStatusBadge } from '@/components/badge/user-status'
import { Dropdown } from '@/components/dropdown/dropdown'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { useToast } from '@/components/ui/use-toast'

const columns: ColumnType<User>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    search: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Access Management',
    dataIndex: 'status',
    render: (status) => <UserStatusBadge status={status} />,
  },
  {
    title: '',
    key: 'action',
    render: (user) => <UserActionDropdown {...user} />,
  },
]

export const UserTable = () => {
  const { data, isLoading } = useListUsers()

  return <Table loading={isLoading} stickyFilterBar columns={columns} dataSource={data} />
}

const UserActionDropdown = ({ loginIds, name }: User) => {
  const [modal, contextHolder] = Modal.useModal()

  const { toast } = useToast()
  const { mutate, isError, isLoading, isSuccess } = useRemoveUserFromTenant()

  useEffect(() => {
    if (isError) {
      toast({
        status: 'error',
        title: 'Error Removing User',
      })
    }
    if (isSuccess) {
      toast({
        status: 'success',
        title: 'User access has been successfully revoked.',
      })
    }
  }, [isError, isSuccess, toast])

  return isLoading ? (
    <Loader2Icon className='mr-2 size-4 animate-spin text-gray-700' />
  ) : (
    <div className='flex'>
      <Dropdown
        triggerAppearance={'headless'}
        triggerContent={<MoreHorizontalIcon className='text-gray-400' strokeWidth={1} />}
        menuItems={[
          {
            content: 'Revoke Access',
            onClick: async () => {
              modal.confirm({
                title: `Are you sure you want to revoke ${name || 'this user'}'s access to Lema?`,
                okButtonProps: { className: 'bg-gray-700 text-white' },
                okText: 'Yes',
                onOk: () => mutate({ loginId: loginIds[0] }),
              })
            },
          },
        ]}
      />
      {contextHolder}
    </div>
  )
}
