import { ArrowRightIcon, CircleCheckIcon, ClipboardIcon, SettingsIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import {
  CompanyActivityLogItem,
  CompanyInherentRiskItemAdded,
  CompanyRiskChange,
  CompanyStatusChange,
  InherentRiskCategoryEnum,
} from '@/gen/inventory/v1/company_service_pb'
import {
  FindingAdded,
  FindingSeverityChange,
  FindingStatusChange,
} from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { companyStatusLabel, riskLevelLabel } from '@/const/label'
import { findingStatusLabel } from '@/const/label'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import {
  ActivityRowLayout,
  BaseActivityRowLayoutProps,
} from '@/pages/company-drawer/activity-log-drawer/activity-row-layout'
import { InherentRiskItemCell } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-item-cell'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'
import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { CompanyRiskIcon } from '@/components/icons/company-risk'

import CriticalRisk from '@/assets/tier-critical.svg'

type ActivityRowProps = BaseActivityRowLayoutProps & {
  activityLogItem: CompanyActivityLogItem
  companyName: string
}

export const ActivityRow = ({
  activityLogItem: { activityLogItem },
  companyName,
  ...props
}: ActivityRowProps) => {
  const navigate = useNavigate()
  switch (activityLogItem.case) {
    case 'findingAdded':
      return <FindingAddedActivity findingAddedItem={activityLogItem.value} {...props} />
    case 'findingSeverityChange':
      return (
        <FindingSeverityChangeActivity
          findingSeverityChangeItem={activityLogItem.value}
          {...props}
        />
      )
    case 'findingStatusChange':
      return (
        <FindingStatusChangeActivity findingStatusChangeItem={activityLogItem.value} {...props} />
      )
    case 'inherentRiskItemAdded':
      return (
        <InherentRiskItemAddedActivity
          inherentRiskItemAdded={activityLogItem.value}
          companyName={companyName}
          {...props}
        />
      )
    case 'riskChange':
      return <RiskChangeActivity riskChangeItem={activityLogItem.value} {...props} />
    case 'statusChange':
      return <StatusChangeActivity statusChangeItem={activityLogItem.value} {...props} />
    case 'controlSettingsChange':
      return (
        <ActivityRowLayout
          icon={<SettingsIcon className='size-4 text-gray-300' strokeWidth={2.5} />}
          title={`Control ${activityLogItem.value.isEnabled ? 'r' : 'd'}e-activated: `}
          details={activityLogItem.value.title}
          onClick={() => navigate(`settings?${UrlParam.SETTING_SECTION}=controls`)}
          {...props}
        />
      )
    case 'irqUpdate':
      return (
        <ActivityRowLayout
          icon={<CriticalRisk className='size-4 text-gray-300' />}
          title='IRQ updated'
          {...props}
        />
      )
    case 'companyCreated':
      return (
        <ActivityRowLayout
          icon={null}
          title={`${companyName} added to your inventory! ✨`}
          {...props}
        />
      )
    default:
      return null
  }
}

type CompanyRiskChangeActivityProps = BaseActivityRowLayoutProps & {
  riskChangeItem: CompanyRiskChange
}

const RiskChangeActivity = ({
  riskChangeItem: { newInherentRisk, prevInherentRisk },
  ...props
}: CompanyRiskChangeActivityProps) => {
  const { removeParam } = useUrlParams()

  return (
    <ActivityRowLayout
      icon={<CriticalRisk className='size-4 text-gray-300' />}
      title='Inherent risk changed '
      details={`to ${riskLevelLabel[newInherentRisk]} from ${riskLevelLabel[prevInherentRisk]}`}
      onClick={() => removeParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID)}
      tooltip={
        <div className='flex flex-col items-center gap-2'>
          Inherent risk changed
          <div className='flex flex-wrap items-center gap-2'>
            <CompanyRiskIcon riskLevel={prevInherentRisk} />
            <ArrowRightIcon size={14} />
            <CompanyRiskIcon riskLevel={newInherentRisk} />
          </div>
        </div>
      }
      {...props}
    />
  )
}

type StatusChangeActivityProps = BaseActivityRowLayoutProps & {
  statusChangeItem: CompanyStatusChange
}

const StatusChangeActivity = ({
  statusChangeItem: { newStatus, prevStatus },
  ...props
}: StatusChangeActivityProps) => {
  const { removeParam } = useUrlParams()

  return (
    <ActivityRowLayout
      icon={<CircleCheckIcon className='size-4 text-gray-300' strokeWidth={2.5} />}
      title={newStatus === CompanyStatus.ASSESSED ? 'Assessment completed  🎉' : 'Status changed'}
      details={
        newStatus === CompanyStatus.ASSESSED
          ? ` Status is now '${companyStatusLabel[newStatus]}'`
          : ` to ${companyStatusLabel[newStatus]} from ${companyStatusLabel[prevStatus]}`
      }
      onClick={() => removeParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID)}
      tooltip={
        <div className='flex flex-col items-center gap-2'>
          Status changed
          <div className='flex flex-wrap items-center gap-2'>
            <CompanyVerificationBadge status={prevStatus} />
            <ArrowRightIcon size={14} />
            <CompanyVerificationBadge status={newStatus} />
          </div>
        </div>
      }
      {...props}
    />
  )
}

type FindingStatusChangeActivityProps = BaseActivityRowLayoutProps & {
  findingStatusChangeItem: FindingStatusChange
}

const FindingStatusChangeActivity = ({
  findingStatusChangeItem: { findingId, newStatus, prevStatus, title },
  ...props
}: FindingStatusChangeActivityProps) => {
  const { replaceParams } = useUrlParams()

  return (
    <ActivityRowLayout
      icon={<ClipboardIcon className='size-4 text-gray-300' strokeWidth={2.5} />}
      title={`Finding status changed to ${findingStatusLabel[newStatus]}: `}
      details={title}
      tooltip={
        <div className='flex flex-col items-center gap-2'>
          Status changed
          <div className='flex flex-wrap items-center gap-2'>
            <FindingStatusBadge status={prevStatus} />
            <ArrowRightIcon size={14} />
            <FindingStatusBadge status={newStatus} />
          </div>
        </div>
      }
      onClick={() =>
        replaceParams({
          remove: [UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID],
          update: [[UrlParam.FINDING_ID, findingId]],
        })
      }
      {...props}
    />
  )
}

type FindingSeverityChangeActivityProps = BaseActivityRowLayoutProps & {
  findingSeverityChangeItem: FindingSeverityChange
}

const FindingSeverityChangeActivity = ({
  findingSeverityChangeItem: { id, newSeverity, prevSeverity, title },
  ...props
}: FindingSeverityChangeActivityProps) => {
  const { replaceParams } = useUrlParams()

  return (
    <ActivityRowLayout
      icon={<ClipboardIcon className='size-4 text-gray-300' strokeWidth={2.5} />}
      title={`Finding severity changed to ${riskLevelLabel[newSeverity]}: `}
      details={title}
      tooltip={
        <div className='flex flex-col items-center gap-2'>
          Status changed
          <div className='flex flex-wrap items-center gap-2'>
            <FindingSeverityBadge level={prevSeverity} />
            <ArrowRightIcon size={14} />
            <FindingSeverityBadge level={newSeverity} />
          </div>
        </div>
      }
      onClick={() =>
        replaceParams({
          remove: [UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID],
          update: [[UrlParam.FINDING_ID, id]],
        })
      }
      {...props}
    />
  )
}

type FindingAddedActivityProps = BaseActivityRowLayoutProps & {
  findingAddedItem: FindingAdded
}

const FindingAddedActivity = ({
  findingAddedItem: { title, riskLevel, id },
  ...props
}: FindingAddedActivityProps) => {
  const { replaceParams } = useUrlParams()

  return (
    <ActivityRowLayout
      tooltip={
        <div className='flex flex-col items-center gap-2'>
          <div>Finding Added</div>
          <FindingSeverityBadge level={riskLevel} />
        </div>
      }
      icon={<ClipboardIcon className='size-4 text-gray-300' strokeWidth={2.5} />}
      title={`${riskLevelLabel[riskLevel]} finding added: `}
      details={title}
      onClick={() =>
        replaceParams({
          remove: [UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID],
          update: [[UrlParam.FINDING_ID, id]],
        })
      }
      {...props}
    />
  )
}

type InherentRiskItemAddedActivityProps = BaseActivityRowLayoutProps & {
  inherentRiskItemAdded: CompanyInherentRiskItemAdded
  companyName: string
}

const InherentRiskItemAddedActivity = ({
  inherentRiskItemAdded: { categoryEnum, newInherentRiskItem },
  companyName,
  ...props
}: InherentRiskItemAddedActivityProps) => {
  const { removeParam } = useUrlParams()

  return (
    <ActivityRowLayout
      icon={<CriticalRisk className='size-4 text-gray-300' />}
      onClick={() => removeParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID)}
      title={`${companyName} ${categoryToPhrase[categoryEnum]}`}
      details={newInherentRiskItem?.displayName}
      tooltip={
        <InherentRiskItemCell categoryEnum={categoryEnum} inherentRiskItem={newInherentRiskItem} />
      }
      {...props}
    />
  )
}

const categoryToPhrase: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.ATTACK_SURFACE]: 'has the attack surface: ',
  [InherentRiskCategoryEnum.SPEND]: 'spend updated: ',
  [InherentRiskCategoryEnum.LOB]: 'usage updated: ',
  [InherentRiskCategoryEnum.DATA]: 'is processing/storing ',
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.INTEG_METHOD]: 'is using the integration method ',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]:
    'may have the following impact on operations: ',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]: 'may have the following impact on business: ',
}
