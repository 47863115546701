import { VictoryBar, VictoryStack } from 'victory'

import { useCompaniesWithMostFindings } from '@/api/statistics.hook'
import { CompanySummary } from '@/gen/inventory/v1/company_summary_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelToColor } from '@/lib/color'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { WidgetActiveStatusesInfo } from '@/pages/dashboard/widget-active-statuses-info'

import { HeadlessTable, TableCell } from '@/components/charts/headless-table'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { RiskLevelIcon } from '@/components/icons/risk-severity'
import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

export const ThirdPartiesWithTheHighestRisk = () => {
  const { data, isLoading } = useCompaniesWithMostFindings()
  const { updateParam } = useUrlParams()

  if (isLoading || !data) {
    return <Loading />
  }

  const max =
    Math.max(
      ...data.companies.map(({ values }) => values.reduce((acc, { total }) => acc + total, 0)),
    ) || 0

  return (
    <Card className='p-5'>
      <CardHeader>
        <CardTitle>
          <TextWithIcon
            iconPosition='end'
            text={'Third-Parties with the Highest Risk'}
            icon={<WidgetActiveStatusesInfo />}
          />
        </CardTitle>
      </CardHeader>
      <CardContent>
        {data.companies.length > 0 ? (
          <HeadlessTable
            header={['THIRD-PARTY', 'TOTAL OPEN AND ONGOING FINDINGS']}
            rows={data.companies.map(({ company, values }) => {
              return {
                onClick: () => company && updateParam(UrlParam.THIRD_PARTY_ID, company.companyId),
                cells: getRowCells({
                  company,
                  byDimension: values.map(({ severity, total }) => [severity, total]),
                  maxDomainChart: max,
                }),
              }
            })}
          />
        ) : (
          <div className='mt-7 h-36 text-center leading-5'>
            <h3 className='text-5xl'>🎉</h3>
            <h4 className='font-semibold'>No Third Parties with High or Critical Findings</h4>
            <span>{"You're all Clear!"}</span>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const getRowCells = (
  CompanyFindingsStackedBarProps: CompanyFindingsStackedBarProps,
): TableCell[] => {
  return [
    {
      element: (
        <TextWithIcon
          className='py-4'
          icon={
            <CompanyRiskIcon
              riskLevel={CompanyFindingsStackedBarProps.company?.risk || RiskLevel.UNSPECIFIED}
            />
          }
          text={CompanyFindingsStackedBarProps.company?.profileName}
        />
      ),
    },
    {
      element: (
        <div className='flex max-w-xl items-center gap-8'>
          <div className='flex gap-6'>
            {CompanyFindingsStackedBarProps.byDimension.map(([level, value], index) => {
              return (
                <TextWithIcon
                  className='w-10'
                  key={index}
                  text={value}
                  icon={<RiskLevelIcon level={level} />}
                />
              )
            })}
          </div>
          <div>
            <CompanyFindingsStackedBar {...CompanyFindingsStackedBarProps} />
          </div>
        </div>
      ),
    },
  ]
}

type CompanyFindingsStackedBarProps = {
  company?: CompanySummary
  maxDomainChart: number
  byDimension: [RiskLevel, number][]
}

const CompanyFindingsStackedBar = ({
  maxDomainChart,
  byDimension,
}: CompanyFindingsStackedBarProps) => {
  const filteredByDimension = byDimension.filter(([_, value]) => value !== 0)
  return (
    <VictoryStack padding={0} maxDomain={{ y: maxDomainChart }} horizontal height={30}>
      {filteredByDimension.map(([level, value], index) => {
        const isFirst = index === 0
        const isLast = index === filteredByDimension.length - 1

        return (
          <VictoryBar
            horizontal
            alignment='start'
            cornerRadius={{
              bottomRight: isFirst ? 4 : 0,
              bottomLeft: isFirst ? 4 : 0,
              topLeft: isLast ? 4 : 0,
              topRight: isLast ? 4 : 0,
            }}
            key={level}
            style={{ data: { fill: riskLevelToColor[level] } }}
            barWidth={30}
            data={[{ y: value }]}
          />
        )
      })}
    </VictoryStack>
  )
}
