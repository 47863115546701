import { PartialMessage } from '@bufbuild/protobuf'
import { Input } from 'antd'
import type { RcFile, UploadFile } from 'antd/es/upload'
import { UploadFileStatus } from 'antd/es/upload/interface'
import pluralize from 'pluralize'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { useUploadArtifact } from '@/api/upload-document.hook'
import { CategoryMetadata as ProtoCategoryMetadata } from '@/gen/artifacts/store/v1/store_service_pb'
import { DocumentSource } from '@/gen/inventory/v1/artifact_service_pb'

import { artifactSubcategoryLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { isFilePasswordProtected } from '@/lib/file-utils'
import { rcFileToUint8Array } from '@/lib/rc-file-to-unit-8-array'

import { useToast } from '@/components/ui/use-toast'
import { Upload } from '@/components/upload/upload'

export interface CategoryMetadata extends PartialMessage<ProtoCategoryMetadata> {
  subcategory: keyof typeof artifactSubcategoryLabel
}

export type DocumentMetadata = {
  company: {
    id: string
    name: string
  }
  categoryMetadata?: CategoryMetadata
}

interface FileWithStatus extends RcFile {
  isEncrypted?: boolean
  status?: UploadFileStatus
  error?: unknown
}

interface UploadFileWithStatus extends UploadFile<FileWithStatus> {
  isEncrypted?: boolean
}

const megabyte = 1000 * 1000
const maxSize = 50 * megabyte
const defaultAllowedFileTypes = [
  // main use-cases
  'application/pdf',
  'application/vnd.ms-excel',
  'text/plain',
  'text/csv',
  // other document types that a user might want to upload
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'text/html',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.presentation',
]
type UploadFilesProps = {
  hideTitle?: boolean
  metadata?: DocumentMetadata
  setIsUploadEnabled?: (isUploadEnabled: boolean) => void
  allowedFileTypes?: string[]
}

export type UploadFilesRef = {
  handleUpload: (companyId?: string) => Promise<void>
  isUploading: boolean
}

export const UploadFiles = forwardRef<UploadFilesRef, UploadFilesProps>(
  (
    {
      metadata: { company, categoryMetadata } = {},
      setIsUploadEnabled,
      hideTitle,
      allowedFileTypes = defaultAllowedFileTypes,
    },
    ref,
  ) => {
    const [fileList, setFileList] = useState<FileWithStatus[]>([])
    const [filePasswords, setFilePasswords] = useState<Record<string, string>>({})
    const { mutateAsync } = useUploadArtifact()
    const { toast } = useToast()
    const trackUpload = useTrackCallback('artifact.upload')
    const [isUploading, setIsUploading] = useState(false)

    useEffect(
      () => setIsUploadEnabled?.(isUploading || fileList.length > 0),
      [fileList, setIsUploadEnabled, isUploading],
    )
    useImperativeHandle(ref, () => ({
      isUploading,
      handleUpload: async (uploadCompanyId?: string) => {
        const acceptedFiles = fileList.filter((f) => allowedFileTypes.includes(f.type))
        if (acceptedFiles.length === 0) return
        const companyId = uploadCompanyId || (company && company.id)

        let source = DocumentSource.MANUAL_UPLOAD
        if (companyId) {
          if (categoryMetadata) {
            source = DocumentSource.MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY
          } else {
            source = DocumentSource.MANUAL_UPLOAD_WITH_COMPANY
          }
        }

        setIsUploading(true)
        const uploadResults = await Promise.allSettled(
          acceptedFiles.map(async (rawFile) => {
            const { name: fileName, isEncrypted, uid } = rawFile
            const fileContent = await rcFileToUint8Array(rawFile)
            return mutateAsync({
              fileContent,
              fileName,
              source,
              companyId,
              categoryMetadata: categoryMetadata,
              isEncrypted,
              password: filePasswords[uid],
            })
          }),
        )

        const successfulUploads = uploadResults.filter((result) => result.status === 'fulfilled')
        const failedUploads = uploadResults.filter((result) => result.status === 'rejected')

        if (successfulUploads.length > 0) {
          const successFileWordPlural = pluralize('File', successfulUploads.length)
          const successIsAre = pluralize('is', successfulUploads.length)
          toast({
            status: 'success',
            title: `${successfulUploads.length} Files Uploaded Successfully!`,
            description: `The ${successfulUploads.length} ${successFileWordPlural} ${successIsAre} being analyzed. You will be notified when analysis is finished.`,
          })
          trackUpload({
            company: company?.name,
            companyId: company?.id,
            fileCount: successfulUploads.length,
            source,
            files: acceptedFiles
              .filter((_, index) => uploadResults[index].status === 'fulfilled')
              .map((f) => f.name),
          })
        }

        const rejectedFiles = acceptedFiles
          .map((file, index) => {
            const stat = uploadResults[index]
            if (stat.status !== 'rejected') {
              file.status = 'done'
            } else {
              file.status = 'error'
              file.error = stat.reason
            }
            return file
          })
          .filter((file) => file.status === 'error')

        setFileList((fileList) => [...fileList.filter((f) => f.status === 'error')])
        setIsUploading(false)
        setFilePasswords((filePasswords) => {
          return rejectedFiles.reduce(
            (acc, file) => ({
              ...acc,
              [file.uid]: filePasswords[file.uid],
            }),
            {},
          )
        })

        if (failedUploads.length > 0) {
          const failFileWordPlural = pluralize('File', failedUploads.length)
          toast({
            status: 'error',
            title: `Error Uploading ${failFileWordPlural}`,
            description: `There was an error uploading ${failedUploads.length} ${failFileWordPlural.toLocaleLowerCase()}. Please try again or contact support.`,
          })

          throw new Error('Failed to upload files')
        }
      },
    }))

    const handleBeforeUpload = async (file: FileWithStatus): Promise<boolean> => {
      if (file.size >= maxSize) {
        toast({
          status: 'error',
          title: `File ${file.name} is over 50MB`,
        })
        return false
      }

      if (!allowedFileTypes.includes(file.type)) {
        file.error = {
          message: `Type of file (${file.type}) is not supported`,
        }
        file.status = 'error'
      }

      file.isEncrypted = await isFilePasswordProtected(file)
      setFileList((fileList) => [...fileList, file])
      return false
    }

    const handleItemRender = (originNode: React.ReactNode, file: UploadFileWithStatus) => {
      return (
        <div className='flex flex-row items-end justify-between'>
          <div className='w-full min-w-0 text-ellipsis'>{originNode}</div>
          {file.isEncrypted && (
            <Input.Password
              className='ml-2 h-6 w-2/5 align-middle'
              placeholder='Password'
              value={filePasswords[file.uid] || ''}
              onChange={(e) => {
                setFilePasswords((filePasswords) => ({
                  ...filePasswords,
                  [file.uid]: e.target.value,
                }))
              }}
            />
          )}
        </div>
      )
    }

    const handleRemove = (file: UploadFileWithStatus) => {
      setFileList((fileList) => {
        const index = fileList.findIndex((f) => f.uid === file.uid)
        const newFileList = fileList.slice()
        newFileList.splice(index, 1)
        return newFileList
      })
      setFilePasswords((filePasswords) => {
        const { [file.uid]: _, ...newFilePasswords } = filePasswords
        return newFilePasswords
      })
    }

    return (
      <div>
        {!hideTitle && (
          <h3 className='mb-4 font-semibold'>
            {company
              ? `Upload ${categoryMetadata?.subcategory ? artifactSubcategoryLabel[categoryMetadata?.subcategory] : 'Files'} for ${company.name}`
              : 'Upload Files for Multiple Third-Parties (auto-detected)'}
          </h3>
        )}
        <Upload
          multiple
          fileList={fileList}
          itemRender={handleItemRender}
          beforeUpload={handleBeforeUpload}
          onRemove={handleRemove}
        />
      </div>
    )
  },
)
UploadFiles.displayName = 'UploadFiles'
