import { QuestionStatusString, useMutation } from '@/lib/liveblocks.config'

export const useUpdateQuestionStatus = (questionId: string) => {
  const updateQuestionStatus = useMutation(({ storage }, newStatus: QuestionStatusString) => {
    const questions = storage.get('questions')
    const questionIndex = questions.findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex)

    if (!question) return

    storage.get('questions').set(questionIndex, {
      ...question,
      status: newStatus,
    })
  }, [])

  return updateQuestionStatus
}
