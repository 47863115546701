// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file wiz/v1/wiz_service.proto (package wiz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCredentialsRequest, AddCredentialsResponse } from "./wiz_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "wiz.v1.WizCollectorService";

/**
 * @generated from service wiz.v1.WizCollectorService
 */
export const WizCollectorService = {
  typeName: "wiz.v1.WizCollectorService",
  methods: {
    /**
     * @generated from rpc wiz.v1.WizCollectorService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: WizCollectorService,});

/**
 * @generated from rpc wiz.v1.WizCollectorService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
