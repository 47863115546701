import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  listUsers,
  removeUserFromTenant,
} from '@/gen/customer/management/v1/service-CustomerManagementService_connectquery'

export const useRemoveUserFromTenant = () => {
  const queryClient = useQueryClient()
  const { queryKey: listUsersQueryKey } = listUsers.useQuery()

  return useMutation(removeUserFromTenant.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listUsersQueryKey)
    },
  })
}
