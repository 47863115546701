import { PartialMessage } from '@bufbuild/protobuf'

import { useGetFinding } from '@/api/get-risk-factor.hook'
import { Control } from '@/gen/inventory/v1/control_pb'

import { useReportErrorsCallback } from '@/lib/error-reporting'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { FindingNotFound } from '@/pages/error-pages/finding-not-found'

import { Evidence } from '@/components/evidence'
import { Loading } from '@/components/loading'
import { Markdown } from '@/components/markdown'
import { Separator } from '@/components/ui/separator'

type FindingOverviewProps = {
  findingId: string
}

export const FindingOverview = ({ findingId }: FindingOverviewProps) => {
  const tryReportErrors = useReportErrorsCallback()
  const { data: findingResult, error, isLoading, isError } = useGetFinding(findingId)
  tryReportErrors(error)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <FindingNotFound />
  }

  if (!findingResult?.finding || !findingResult.company) {
    return <FindingNotFound />
  }

  const { documentation, factMarkdown, additionalFactMarkdown, additionalFactCount } = findingResult

  return (
    <div className='pb-13'>
      <div className='flex gap-7 text-gray-700'>
        <div className='w-1/2 py-3.75 pr-10'>
          {findingResult.control && (
            <div>
              <h1 className='font-semibold leading-10'>Control</h1>
              <ControlCard
                companyId={findingResult.company.companyId}
                control={findingResult.control}
              />
              <br />
            </div>
          )}
          <div>
            <h1 className='font-semibold leading-10'>Description</h1>
            <Markdown>{documentation?.description}</Markdown>
          </div>
          <br />
          <div>
            <h1 className='font-semibold leading-10'>What is the Risk?</h1>
            <Markdown>{documentation?.risk}</Markdown>
          </div>
        </div>
        <div className='w-1/2 bg-secondary px-9 py-3.75'>
          <h1 className='font-semibold leading-10'>Suggested Actions</h1>
          <ol className='list-decimal space-y-4 px-5'>
            {findingResult.documentation?.actions.map((action, index) => (
              <li key={index}>{action}</li>
            ))}
          </ol>
        </div>
      </div>
      <br />
      {factMarkdown && (
        <>
          <Separator className='my-12' />
          <Evidence
            factMarkdown={factMarkdown}
            additionalFactMarkdown={additionalFactMarkdown}
            additionalFactCount={additionalFactCount}
          />
        </>
      )}
    </div>
  )
}

const ControlCard = ({
  control,
  companyId,
}: {
  control: PartialMessage<Control>
  companyId: string
}) => {
  const { replaceParams } = useUrlParams()
  return (
    <div
      className='group cursor-pointer rounded border border-purple-100 p-4 duration-200 ease-in-out hover:bg-gray-100'
      onClick={() =>
        control.id &&
        replaceParams({
          remove: [UrlParam.FINDING_ID],
          update: [
            [UrlParam.CONTROL_ID, control.id],
            [UrlParam.THIRD_PARTY_CONTROL_ID, companyId],
          ],
        })
      }
    >
      <h3 className='text-md font-light'>{control?.title}</h3>
      <span className='text-purple-400 group-hover:text-purple-500'>{control?.description}</span>
    </div>
  )
}
