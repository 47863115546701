// country code regex
const CC_REGEX = /^[a-z]{2}$/i

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

export const countryCodeEmoji = (cc: string) => {
  if (!CC_REGEX.test(cc)) {
    return null
  }

  const codePoints = [...cc.toUpperCase()].map((c) => c.codePointAt(0)! + OFFSET)
  return String.fromCodePoint(...codePoints)
}
