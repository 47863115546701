import React, { useContext } from 'react'

import { cn } from '@/lib/style-helpers'

import { CheckboxFilter } from '@/components/table/filter/checkbox-filter'
import { FilterProviderContext } from '@/components/table/filter/context'
import { FilterDropdown } from '@/components/table/filter/dropdown'
import { valueRender } from '@/components/table/filter/filter.hook'
import { SearchFilter } from '@/components/table/filter/search'
import { ShowFilterTagsToggler } from '@/components/table/filter/show-filter-tags-toggler'
import { FilterTags } from '@/components/table/filter/tags'

export interface Filter {
  label: string | React.ReactNode
  key: React.Key
  options: string[]
  optionRender?: valueRender
  defaultSelected?: string[]
  radioState?: boolean
}

export type FilteredState = Record<React.Key, string[] | null>

type FilterBarProps = {
  filters: Filter[]
  showCheckboxFilter?: boolean
  checkboxFilterLabel?: string
  showSearch: boolean
  narrow?: true
}

export const FilterBar = ({
  filters,
  checkboxFilterLabel,
  showCheckboxFilter,
  showSearch,
  narrow,
}: FilterBarProps) => {
  const { clearFilter, toggleSelected, filteredState } = useContext(FilterProviderContext)

  return (
    <div className='rounded-sm border border-solid border-gray-200 bg-white'>
      <div
        className={cn(
          'no-scrollbar my-1 flex items-center justify-between overflow-scroll px-8',
          narrow ? 'h-11' : 'h-14',
        )}
      >
        <div className='flex gap-8'>
          {showSearch && <SearchFilter />}
          {filters.map(({ key, label, options, radioState, optionRender }) => (
            <FilterDropdown
              optionRender={optionRender}
              key={key}
              label={label}
              options={options}
              selectedOptions={filteredState[key]?.value || []}
              onClick={(value) => {
                if (radioState) {
                  clearFilter(key)
                }
                toggleSelected(key, value, optionRender)
              }}
            />
          ))}
          {showCheckboxFilter && <CheckboxFilter label={checkboxFilterLabel} />}
        </div>
        {filters.length > 0 && <ShowFilterTagsToggler />}
      </div>
      <FilterTags />
    </div>
  )
}
