import { ReactNode } from 'react'

import { cn } from '@/lib/style-helpers'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

export type TableCell = { element: React.ReactNode; width?: 'auto' | 'min'; colSpan?: number }

type Row = {
  cells: TableCell[]
  onClick?: () => void
}

type HeadlessTableProps = {
  disableHover?: boolean
  header?: ReactNode[]
  rows: Row[]
}

/*
 * HeadlessTable is a table without styling.
 * it's used to display data in a table format without the table styling
 */
export const HeadlessTable = ({ header, rows, disableHover }: HeadlessTableProps) => {
  return (
    <Table>
      {header && (
        <TableHeader>
          <TableRow className='border-none'>
            {header.map((title, index) => (
              <TableHead className={'whitespace-nowrap px-1.5 text-gray-400'} key={index}>
                {title}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            onClick={row.onClick}
            className={cn('border-none', {
              'hover:bg-transparent': disableHover,
              'cursor-pointer': row.onClick,
            })}
            key={index}
          >
            {row.cells.map((cell, index) => (
              <TableCell
                colSpan={cell.colSpan}
                className={cn('px-1.5 py-0', cell.width === 'auto' ? 'w-full' : 'w-fit')}
                key={index}
              >
                {cell.element}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
