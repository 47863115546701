import { RcFile } from 'antd/es/upload'

export const isFilePasswordProtected = async (file: RcFile) => {
  if (file.type !== 'application/pdf' && !file.name.endsWith('.pdf')) return false

  const content = await file.text()
  return (
    content.includes('Encrypt') &&
    content.substring(content.lastIndexOf('<<'), content.lastIndexOf('>>')).includes('/Encrypt')
  )
}
