import { cn } from '@/lib/style-helpers'

export const DashedContainer = ({
  children,
  className = '',
  active = true,
}: {
  children: React.ReactNode
  className?: string
  active?: boolean
}) => (
  <div
    className={cn(
      className,
      'mb-4 flex w-full flex-col rounded border border-dashed px-4 pb-4 pt-2',
    )}
  >
    <div className={cn('transition', !active && 'opacity-40')}>{children}</div>
  </div>
)
