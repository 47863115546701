import { Collapse } from 'antd'

import { Markdown } from '@/components/markdown'

type EvidenceProps = {
  factMarkdown: string
  defaultOpen?: boolean
} & (
  | {
      additionalFactMarkdown?: never
      additionalFactCount?: never
    }
  | {
      additionalFactMarkdown: string
      additionalFactCount: number
    }
)

export const Evidence = ({
  factMarkdown,
  additionalFactMarkdown,
  additionalFactCount,
  defaultOpen = false,
}: EvidenceProps) => {
  if (!factMarkdown) return null

  return (
    <>
      <div>
        <h3 className='text-lg font-semibold leading-8'>Evidence</h3>
      </div>

      <Markdown>{factMarkdown}</Markdown>

      {additionalFactMarkdown && (
        <Collapse
          defaultActiveKey={defaultOpen ? '1' : []}
          data-dd-action-name='additional-facts'
          bordered={false}
          ghost
          className='-mx-4'
          items={[
            {
              key: '1',
              label: `Additional Information (${additionalFactCount})`,
              children: <Markdown>{additionalFactMarkdown}</Markdown>,
            },
          ]}
        />
      )}
    </>
  )
}
