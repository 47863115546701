import { Modal } from 'antd'
import { PlusIcon } from 'lucide-react'
import { useState } from 'react'

import { InvitePage } from '@/pages/settings-page/user-section/invite-page'
import { UserTable } from '@/pages/settings-page/user-section/user-table'

import { Button } from '@/components/ui/button'

export const UserSection = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className='mb-5 flex justify-between'>
        <h2 className='text-3xl font-bold'>Users And Access</h2>
        <Button onClick={() => setIsOpen(true)} className='flex items-center gap-2 px-4 py-2'>
          <PlusIcon size={14} />
          <span>Add Users</span>
        </Button>
      </div>
      <UserTable />
      <Modal footer={null} open={isOpen} onCancel={() => setIsOpen(false)}>
        <div>
          <h3 className='mb-4 font-semibold'>Add Users</h3>
          <InvitePage onSuccess={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
  )
}
