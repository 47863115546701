import { UserResponse } from '@descope/core-js-sdk'
import { useSession, useUser } from '@descope/react-sdk'
import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'

export const useOrgId = () => {
  const { user, isUserLoading } = useUser()
  const { isSessionLoading, sessionToken } = useSession()
  const orgId = useOrgIdFromToken(user, sessionToken)
  return { orgId, isUserLoading, isSessionLoading }
}

export const useOrgIdFromToken = (user: UserResponse, token: string) => {
  return useMemo(() => getOrgId(user, token), [user, token])
}

const getOrgId = (user: UserResponse, token: string) => {
  if (token && token.length > 0) {
    const { dct } = jwtDecode<{ dct?: string }>(token)
    if (dct) {
      return dct
    }
  }

  if (!user || !user.userTenants || !user.userTenants.length) {
    return null
  }

  // fall back option, attempt to always select the same tenant
  const tenants = user.userTenants.sort((a, b) => a.tenantId.localeCompare(b.tenantId))
  return tenants[0].tenantId
}
