import { Handle, NodeProps } from '@xyflow/react'
import { Button, Card, Input, InputNumber, Popover, Select, Spin } from 'antd'
import { cva } from 'class-variance-authority'
import React, { memo } from 'react'

import { Log, NodeLog, NodeStatus } from '@/gen/artifacts/analyzer/v1/analyzer_service_pb'

import { Tooltip } from '@/components/ui/tooltip'

import { OneConnectionHandle } from './one-connection-handle'
import type {
  HandleProps,
  InputProps,
  LogLevel,
  NodeState,
  NumberProps,
  SelectProps,
  SelectValue,
  TagsProps,
} from './types'

import '@xyflow/react/dist/style.css'

const logLineColor = cva([], {
  variants: {
    logLevel: {
      ERROR: 'text-red-400',
      WARN: 'text-yellow-400',
      INFO: 'text-blue-400',
      DEBUG: 'text-gray-400',
    },
  },
  defaultVariants: {
    logLevel: 'DEBUG',
  },
})

const nodeVariants = cva(
  'border border-gray-300 bg-white rounded-xl cursor-grab active:cursor-grabbing max-w-80 shadow-md shadow-gray-200',
  {
    variants: {
      selected: {
        true: 'border-gray-500 border',
        false: '',
      },
      isHit: {
        true: 'border-gray-700 border-2',
        false: '',
      },
      state: {
        success: '',
        warning: '',
        error: '',
      },
      isLoading: {
        true: 'animate-pulse',
        false: '',
      },
    },
    compoundVariants: [
      {
        isHit: true,
        state: 'success',
        isLoading: false,
        className: 'border-green-500',
      },
      {
        isHit: true,
        state: 'warning',
        isLoading: false,
        className: 'border-yellow-500',
      },
      {
        isHit: true,
        state: 'error',
        isLoading: false,
        className: 'border-red-500',
      },
    ],
    defaultVariants: {
      selected: false,
      isHit: false,
      state: null,
      isLoading: false,
    },
  },
)
const inputElementVariants = cva('nodrag', {
  variants: {
    type: {
      text: 'w-full',
      select: 'w-full',
      tags: 'w-full',
      number: 'w-[49%]',
    },
  },
})
const getInputElement = (input: InputProps | SelectProps | TagsProps | NumberProps) => {
  if (!input) {
    return null
  }
  const className = inputElementVariants({ type: input.type })
  switch (input.type) {
    case 'text':
      return (
        <Input
          key={input.id}
          name={input.name}
          className={className}
          type={input.type}
          onChange={input.onChange as (event: React.ChangeEvent<HTMLInputElement>) => void}
          defaultValue={input.value as string}
          placeholder={input.placeholder}
        />
      )
    case 'select':
      input = input as SelectProps
      return (
        <Select<SelectValue>
          showSearch
          key={input.id}
          className={className}
          onChange={input.onChange as (value: SelectValue) => void}
          placeholder={input.placeholder}
          options={input.options}
          mode={input.mode === 'custom' ? 'tags' : input.mode}
          maxCount={input.mode === 'multiple' ? undefined : 1}
          maxTagCount={10}
          size='small'
          defaultValue={input.value}
          popupMatchSelectWidth={false}
          filterOption={(input, option) =>
            (typeof option?.label === 'string' ? option.label : '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      )
    case 'tags':
      return (
        <Select
          key={input.id}
          className={className}
          mode={(input as TagsProps).mode || 'tags'}
          onChange={input.onChange as (value: string | string[]) => void}
          tokenSeparators={[',']}
          filterOption={false}
          size='small'
          notFoundContent={null}
          suffixIcon={null}
          options={input.options}
          placeholder={input.placeholder}
          defaultValue={input.value as string | string[] | null}
          popupMatchSelectWidth={false}
        />
      )
    case 'number':
      return (
        <InputNumber
          key={input.id}
          className={className}
          onChange={input.onChange as (value: number | null) => void}
          placeholder={input.placeholder}
          value={input.value as number}
          min={(input as NumberProps).min}
          max={(input as NumberProps).max}
          status={input.value ? '' : 'error'}
        />
      )
    default:
      return null
  }
}

export type BaseNodeProps = Partial<NodeProps> & {
  title: string
  id: string
  dataInputs?: (InputProps | SelectProps | TagsProps | NumberProps)[]
  inputHandles?: HandleProps[]
  outputHandles?: HandleProps[]
  data?: {
    nodeLogs: Log[]
    status: NodeStatus
    isHit: boolean
    isLoading: boolean
    state: NodeState
  }
}

const formatLogs = (logs: Log[]) => {
  const nodeLogs = logs.map((log) => log.log.value as NodeLog)
  return nodeLogs.map((log, index) => {
    const logLevel = log.level as LogLevel
    const args = Object.entries(log.args)
    return (
      <Tooltip
        key={index}
        title='args'
        trigger={
          <p
            key={index}
            className={`flex max-w-[55vw] justify-start whitespace-break-spaces text-wrap ${logLineColor({ logLevel })}`}
          >
            <span className='inline-block w-14 text-start'>[{logLevel}]</span>
            <span className='ml-2 text-start'>{log.log}</span>
          </p>
        }
      >
        <p className='mb-1 text-lg underline'>Log Arguments</p>
        {args.length === 0
          ? 'No args were provided for this log'
          : args.map(([key, value]) => (
              <p
                className='max-w-[60vw] whitespace-break-spaces text-wrap'
                key={key}
              >{`${key}: ${value}`}</p>
            ))}
      </Tooltip>
    )
  })
}

export const BaseNode: React.FC<BaseNodeProps> = memo(
  ({ title, dataInputs, inputHandles, outputHandles, selected, data }) => {
    const nodeLogs = data?.nodeLogs || []

    return (
      <Card
        size='small'
        title={title}
        className={nodeVariants({
          selected: selected ?? false,
          isHit: data?.isHit ?? false,
          state: data?.state ?? null,
          isLoading: data?.isLoading ?? false,
        })}
      >
        {inputHandles?.map((handle) => (
          <Handle
            id={handle.id}
            className='h-5 w-3 rounded-sm bg-gray-500'
            key={handle.id}
            position={handle.position}
            type={handle.type}
            isConnectable={handle.isConnectable}
          />
        ))}
        {dataInputs ? (
          <div className='flex flex-row flex-wrap justify-between gap-1'>
            {dataInputs.map((input) => getInputElement(input))}
          </div>
        ) : (
          <div className='mb-0 h-5 w-full text-center text-gray-300'>
            This node type has no inputs
          </div>
        )}
        <div className='mt-3 flex flex-col justify-around gap-3'>
          {outputHandles?.map((handle) => (
            <div key={handle.id} className='relative left-3'>
              <OneConnectionHandle
                id={handle.id}
                className={`h-5 w-3 rounded-sm border-2 bg-gray-500 border-${handle.borderColor ?? 'gray'}-300`}
                key={handle.id}
                position={handle.position}
                type={handle.type}
              />
              {handle.label && (
                <span className='relative right-4 flex justify-end text-[0.5rem] text-gray-400'>
                  {handle.label}
                </span>
              )}
            </div>
          ))}
        </div>
        {data?.isHit && nodeLogs && (
          <>
            <Popover overlayClassName='' content={formatLogs(nodeLogs)} trigger='click'>
              <Button>View Logs</Button>
            </Popover>
            {data?.isLoading && <Spin className='absolute right-2 top-2' />}
          </>
        )}
      </Card>
    )
  },
)

BaseNode.displayName = 'BaseNode'
