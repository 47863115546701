import { SlackAuthButton } from '@knocklabs/react'
import { AlertTriangleIcon, HashIcon, LockIcon } from 'lucide-react'
import { useState } from 'react'

import {
  useSetTenantSlackChannel,
  useTenantAuthTest,
  useTenantSlackChannels,
} from '@/api/notifications-knock.hook'
import { useListSlackChannels } from '@/api/notifications-service.hook'

import { ChannelSelect } from '@/pages/settings-page/notification-section/channel-select'

import { useToast } from '@/components/ui/use-toast'

import '@knocklabs/react/dist/index.css'

type OrgNotificationSettingsProps = {
  orgId: string
  knockChannelIdSlack: string
  slackClientId: string
}

export const OrgNotificationSettings = ({
  orgId,
  knockChannelIdSlack,
  slackClientId,
}: OrgNotificationSettingsProps) => {
  const { toast } = useToast()
  const { mutate } = useSetTenantSlackChannel()
  const { data: authTest, isError, isLoading } = useTenantAuthTest(orgId, knockChannelIdSlack)
  const isSlackConnected = !isLoading && !isError && authTest?.connection?.ok

  const availableChannels = useListSlackChannels({ enabled: isSlackConnected })
  const { data: connectedChannelIds } = useTenantSlackChannels(orgId, knockChannelIdSlack)
  const [connectedChannel, setConnectedChannel] = useState<string | undefined>(
    connectedChannelIds?.[0],
  )

  const channels = availableChannels.data.sort((a, b) => a.name.localeCompare(b.name))
  const onSelected = (channel: string) => {
    mutate({
      tenantId: orgId,
      knockChannelId: knockChannelIdSlack,
      slackChannelId: channel,
    })
    setConnectedChannel(channel)
    toast({
      status: 'success',
      title: 'Slack channel updated successfully',
    })
  }

  return (
    <div className='rounded border border-gray-200 p-4'>
      <div className='mb-4 mt-1 flex items-center justify-between gap-4'>
        <img src='/icons/slack.svg' alt='Slack' width={28} height={28} />
        <span className='mr-auto'>Receive notifications to a public channel from our SlackBot</span>
        <SlackAuthButton
          slackClientId={slackClientId}
          redirectUrl={`${window.origin}/settings?notifications`}
          onAuthenticationComplete={(result: string) => {
            console.log('result', result)
            if (result === 'authComplete') {
              toast({
                status: 'success',
                title: 'Slack connected successfully',
              })
            }
          }}
        />
      </div>
      <>
        <div className='mb-2 font-semibold'>Select a Channel</div>
        <ChannelSelect
          channels={channels.map((c) => ({
            id: c.id,
            value: c.name,
            label: (
              <span className='flex items-center gap-1'>
                {c.isPrivate ? <LockIcon size={12} /> : <HashIcon size={12} />} {c.name}
              </span>
            ),
          }))}
          connectedChannelId={connectedChannel}
          onSelected={onSelected}
          disabled={isSlackConnected !== true}
        />
      </>
      <div className='my-8 border-l-2 border-purple-400 px-2'>
        <h3 className='flex items-center gap-1 text-lg font-semibold'>
          <AlertTriangleIcon size={16} />
          Notice
        </h3>
        <p>
          After connecting slack, invite the @Lema app to your desired channel by typing{' '}
          <code className='rounded bg-gray-100 p-1 font-mono text-sm font-semibold'>
            /invite @Lema
          </code>{' '}
          in that channel. This will make the channel appear in the channel selection dropdown.
        </p>
      </div>
    </div>
  )
}
