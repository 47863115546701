import { useMutation, useQuery } from '@tanstack/react-query'

import {
  getRoom,
  getUploadLink,
  inviteRecipient,
} from '@/gen/questionnaire/v1/questionnaire_service-QuestionnaireService_connectquery'

export const useGetRoom = (magicLink?: string) => {
  const queryState = useQuery(
    getRoom.useQuery({
      magicLink,
    }),
  )
  return {
    ...queryState,
    roomId: queryState.data?.roomId,
    questionnaire: queryState.data?.questionnaire,
    currentUserName: queryState.data?.currentUserName,
  }
}

export const useGetUploadLink = () => {
  // We use mutationFn to call it only when we need the upload link, which is usually just before uploading a file.
  return useMutation(getUploadLink.useMutation().mutationFn)
}

export const useInviteRecipient = () => {
  return useMutation(inviteRecipient.useMutation().mutationFn)
}
