// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/finding_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCommentRequest, AddCommentResponse, GetFindingRequest, GetFindingResponse, GetFindingTimelineRequest, GetFindingTimelineResponse, ListFindingsRequest, ListFindingsResponse, UpdateFindingRequest, UpdateFindingResponse } from "./finding_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.FindingInventoryService";

/**
 * @generated from service inventory.v1.FindingInventoryService
 */
export const FindingInventoryService = {
  typeName: "inventory.v1.FindingInventoryService",
  methods: {
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.AddComment
     */
    addComment: {
      name: "AddComment",
      I: AddCommentRequest,
      O: AddCommentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.GetFindingTimeline
     */
    getFindingTimeline: {
      name: "GetFindingTimeline",
      I: GetFindingTimelineRequest,
      O: GetFindingTimelineResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.ListFindings
     */
    listFindings: {
      name: "ListFindings",
      I: ListFindingsRequest,
      O: ListFindingsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.GetFinding
     */
    getFinding: {
      name: "GetFinding",
      I: GetFindingRequest,
      O: GetFindingResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.FindingInventoryService.UpdateFinding
     */
    updateFinding: {
      name: "UpdateFinding",
      I: UpdateFindingRequest,
      O: UpdateFindingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: FindingInventoryService,});

/**
 * @generated from rpc inventory.v1.FindingInventoryService.AddComment
 */
export const addComment: UnaryFunctionsWithHooks<AddCommentRequest, AddCommentResponse> = {   ...$queryService.addComment,  ...createUnaryHooks($queryService.addComment)};

/**
 * @generated from rpc inventory.v1.FindingInventoryService.GetFindingTimeline
 */
export const getFindingTimeline: UnaryFunctionsWithHooks<GetFindingTimelineRequest, GetFindingTimelineResponse> = {   ...$queryService.getFindingTimeline,  ...createUnaryHooks($queryService.getFindingTimeline)};

/**
 * @generated from rpc inventory.v1.FindingInventoryService.ListFindings
 */
export const listFindings: UnaryFunctionsWithHooks<ListFindingsRequest, ListFindingsResponse> = {   ...$queryService.listFindings,  ...createUnaryHooks($queryService.listFindings)};

/**
 * @generated from rpc inventory.v1.FindingInventoryService.GetFinding
 */
export const getFinding: UnaryFunctionsWithHooks<GetFindingRequest, GetFindingResponse> = {   ...$queryService.getFinding,  ...createUnaryHooks($queryService.getFinding)};

/**
 * @generated from rpc inventory.v1.FindingInventoryService.UpdateFinding
 */
export const updateFinding: UnaryFunctionsWithHooks<UpdateFindingRequest, UpdateFindingResponse> = {   ...$queryService.updateFinding,  ...createUnaryHooks($queryService.updateFinding)};
