import { useMutation, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'

import {
  getCompany,
  listCompanies,
} from '@/gen/inventory/v1/company_service-CompanyInventoryService_connectquery'
import {
  getControl,
  listControls,
} from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'
import {
  getFinding,
  getFindingTimeline,
  listFindings,
  updateFinding,
} from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'
import { listCompaniesWithMostFindings } from '@/gen/inventory/v1/statistics_service-StatisticsService_connectquery'

import { findingStatusLabel, riskLevelLabel } from '@/const/label'

import { useToast } from '@/components/ui/use-toast'

export const useUpdateFinding = (findingId: string) => {
  const queryClient = useQueryClient()
  const { showUpdateFindingToast } = useUpdateFindingToast()
  const { queryKey: listCompaniesQueryKey } = listCompanies.useQuery()
  const { queryKey: getCompanyQueryKey } = getCompany.useQuery()
  const { queryKey: listFindingsQueryKey } = listFindings.useQuery()
  const { queryKey: getFindingQueryKey } = getFinding.useQuery({ id: findingId })
  const { queryKey: getFindingTimelineKey } = getFindingTimeline.useQuery({ findingId })
  const { queryKey: getControlKey } = getControl.useQuery()
  const { queryKey: listControlsKey } = listControls.useQuery()
  const { queryKey: listCompaniesWithMostFindingsKey } = listCompaniesWithMostFindings.useQuery()

  return useMutation(updateFinding.useMutation().mutationFn, {
    onSuccess: (_, { level, status }) => {
      queryClient.invalidateQueries(listCompaniesQueryKey)
      queryClient.invalidateQueries(getCompanyQueryKey)
      queryClient.invalidateQueries(listFindingsQueryKey)
      queryClient.invalidateQueries(getFindingQueryKey)
      queryClient.invalidateQueries(getFindingTimelineKey)
      queryClient.invalidateQueries(getControlKey)
      queryClient.invalidateQueries(listControlsKey)
      queryClient.invalidateQueries(listCompaniesWithMostFindingsKey)

      if (level) showUpdateFindingToast('severity', riskLevelLabel[level])
      if (status) showUpdateFindingToast('work status', findingStatusLabel[status])
    },
  })
}

const useUpdateFindingToast = () => {
  const { toast } = useToast()

  const showUpdateFindingToast = (entity: 'work status' | 'severity', newValue: string) => {
    if (newValue) {
      toast({
        title: `${_.capitalize(entity)} Successfully Changed`,
        description: `This finding's ${entity} has been set to '${newValue}'`,
        status: 'success',
      })
    }
  }
  return { showUpdateFindingToast }
}
