import { Descope } from '@descope/react-sdk'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useToast } from '@/components/ui/use-toast'

type SwitchOrgPageProps = {
  onSuccess?: () => void
}

export const SwitchOrgPage = ({ onSuccess }: SwitchOrgPageProps) => {
  const { toast } = useToast()
  const reportErrors = useReportErrorsCallback()
  return (
    <div className='mx-auto max-w-lg'>
      <Descope
        flowId='switch-org'
        theme='light'
        onSuccess={() => {
          toast({
            status: 'success',
            title: 'Switched to tenant successfully.',
          })
          onSuccess && onSuccess()
        }}
        onError={(err) => reportErrors(new Error(`Descope error ${err}`))}
      />
    </div>
  )
}

type ImpersonateSupportAdminPageProps = {
  onSuccess?: () => void
}

export const ImpersonateSupportAdminPage = ({ onSuccess }: ImpersonateSupportAdminPageProps) => {
  const { toast } = useToast()
  const reportErrors = useReportErrorsCallback()
  return (
    <div className='mx-auto max-w-lg'>
      <Descope
        flowId='support-admin'
        theme='light'
        form={{
          impersonateId: 'support-user@lema.ai',
        }}
        onSuccess={() => {
          toast({
            status: 'success',
            title: 'Impersonated support admin successfully.',
          })
          onSuccess && onSuccess()
        }}
        onError={() => {
          reportErrors(new Error('Failed to impersonate support admin.'))
          toast({
            status: 'error',
            title: 'Failed to impersonate support admin.',
          })
        }}
      />
    </div>
  )
}
