import { ActivityIcon } from 'lucide-react'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { OpenAndOngoingFindingsOverTimeChart } from '@/pages/dashboard/charts/open-and-ongoing-findings-over-time'
import { ThirdPartiesPerInherentRisk } from '@/pages/dashboard/charts/third-parties-per-inherent-risk'
import { ThirdPartiesWithTheHighestRisk } from '@/pages/dashboard/charts/third-parties-with-the-highest-risk'
import { TimeCompanyInAssessmentRequired } from '@/pages/dashboard/charts/time-company-in-assessment-required'
import { UrgentFindingsChart } from '@/pages/dashboard/charts/urgent-findings'

import { TextWithIcon } from '@/components/text-with-icon'

export const DashboardPage = () => {
  const { isEnabled: isFindingOverTimeCharEnabled } = useFeatureFlagEnabled(
    'dashboard-findings-over-time-chart',
  )

  return (
    <div className='grid w-full grid-cols-1 gap-12 px-40 pb-64'>
      <TextWithIcon
        className='mt-15 w-full text-2xl font-bold text-gray-700'
        text='Overall Risk'
        icon={<ActivityIcon className='w-5' />}
      />
      <UrgentFindingsChart key='urgent-findings-chart' />
      {isFindingOverTimeCharEnabled && (
        <OpenAndOngoingFindingsOverTimeChart key={'open-and-ongoing-findings-over-time-chart'} />
      )}
      <ThirdPartiesWithTheHighestRisk key='implication-criticality-table' />
      <div className='flex w-full gap-12' key={'third-parties'}>
        <TimeCompanyInAssessmentRequired />
        <ThirdPartiesPerInherentRisk />
      </div>
    </div>
  )
}
