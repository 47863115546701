import { useDescope, useUser } from '@descope/react-sdk'
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import { Modal } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ImpersonateSupportAdminPage,
  SwitchOrgPage,
} from '@/pages/settings-page/user-section/switch-org-page'

import { Dropdown } from '@/components/dropdown/dropdown'
import { Avatar } from '@/components/ui/avatar'

export const UserProfileButton = () => {
  const navigate = useNavigate()
  const { logout } = useDescope()
  const { user } = useUser()

  const [switchOrgOpen, setSwitchOrgOpen] = useState(false)
  const [supportAdminOpen, setSupportAdminOpen] = useState(false)

  if (!user) return null

  const userInitials = user?.name
    ?.split(' ')
    .map((n) => n[0])
    .join('')

  return (
    <div>
      <Modal footer={null} open={switchOrgOpen} onCancel={() => setSwitchOrgOpen(false)}>
        <div>
          <h3 className='mb-4 font-semibold'>Switch Organization</h3>
          <SwitchOrgPage
            onSuccess={() => {
              setSwitchOrgOpen(false)
              setTimeout(() => navigate(0), 500)
            }}
          />
        </div>
      </Modal>
      <Modal footer={null} open={supportAdminOpen} onCancel={() => setSupportAdminOpen(false)}>
        <div>
          <h3 className='mb-4 font-semibold'>Support Admin</h3>
          <ImpersonateSupportAdminPage
            onSuccess={() => {
              setSupportAdminOpen(false)
              setTimeout(() => navigate(0), 500)
            }}
          />
        </div>
      </Modal>
      <Dropdown
        menuItems={[
          {
            content: 'Switch Organization',
            hide: (user.userTenants?.length || 0) <= 1,
            onClick: () => {
              setSwitchOrgOpen(true)
            },
          },
          {
            content: 'Support Admin',
            hide: user.roleNames?.includes('Support') === false,
            onClick: () => {
              setSupportAdminOpen(true)
            },
          },
          {
            content: 'Log Out',
            onClick: async () => {
              await logout()
              navigate('/login')
            },
          },
        ]}
        triggerAppearance={'headless'}
        triggerContent={
          <Avatar className='size-8 bg-gray-200'>
            <AvatarImage src={user.picture} alt={user.name} />
            <AvatarFallback className='mx-auto flex items-center justify-center text-sm font-medium'>
              {userInitials}
            </AvatarFallback>
          </Avatar>
        }
      />
    </div>
  )
}
