import { PlainMessage } from '@bufbuild/protobuf'
import type { CheckboxOptionType, RadioChangeEvent } from 'antd'
import { Radio } from 'antd'
import { NewspaperIcon, PlusIcon, SquarePenIcon } from 'lucide-react'

import { InherentRiskGroup } from '@/gen/inventory/v1/company_service_pb'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { InherentRiskProfileTable } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-profile-table'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

import { InherentRiskPanelSource } from './inherent-risk-panel'

type InherentRiskPanelProps = {
  inherentRiskGroups: PlainMessage<InherentRiskGroup>[]
  selectedSource: InherentRiskPanelSource
  setSelectedSource: (value: InherentRiskPanelSource) => void
  irqCount: number
  companyId: string
}

export const InherentRiskProfile = ({
  inherentRiskGroups,
  selectedSource,
  setSelectedSource,
  irqCount,
  companyId,
}: InherentRiskPanelProps) => {
  const { updateParam } = useUrlParams()

  const sourceOptions: CheckboxOptionType<InherentRiskPanelSource>[] = [
    { label: 'IRQ', value: 'irq' },
    { label: 'Lema', value: 'lema' },
  ]

  const irqFilled = irqCount > 0

  const handleSourceChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedSource(value)
  }

  const openIrqDrawer = () => updateParam(UrlParam.IRQ, companyId)

  return (
    <div className='mx-4 grow'>
      <div className='flex items-center px-4'>
        <div className='mt-1'>
          <span className='mr-4 text-sm font-semibold uppercase text-gray-400'>
            Inherent risk source
          </span>
          <Radio.Group
            value={selectedSource}
            options={sourceOptions}
            onChange={handleSourceChange}
            optionType='button'
            buttonStyle='solid'
          />
        </div>
        <Button
          className='ml-auto h-fit px-2 py-1 text-sm text-gray-700 shadow-none'
          variant='outline'
          size='sm'
          onClick={openIrqDrawer}
        >
          <TextWithIcon
            className='my-0'
            text={irqFilled ? 'Show IRQ' : 'Fill IRQ'}
            icon={<NewspaperIcon size='14' />}
          />
        </Button>
      </div>
      <div className='px-4'>
        <Separator className='my-4' />
      </div>
      <div className='h-full px-2.5'>
        {selectedSource === 'irq' && !irqFilled ? (
          <div className='flex h-full flex-col items-center justify-center'>
            <SquarePenIcon className='size-10 text-gray-300' />
            <span className='my-2 flex shrink-0 text-lg text-gray-700'>
              No Inherent Risk Questionnaire found
            </span>
            <Button onClick={openIrqDrawer} className='flex py-5 text-lg'>
              <TextWithIcon
                className='my-0'
                text='Fill an IRQ'
                icon={<PlusIcon className='w-4' />}
              />
            </Button>
          </div>
        ) : (
          <InherentRiskProfileTable inherentRiskGroups={inherentRiskGroups} />
        )}
      </div>
    </div>
  )
}
