import { useContext } from 'react'

import { QuestionType } from '@/lib/liveblocks.config'

import { SubCategoryRequiredContext } from '@/pages/questionnaire/sub-category-required-provider'

// useGetQuestionRequired determines if a participant must answer a specific question.
// It is useful when leading questions can make an entire subcategory of questions optional or required.
export const useGetQuestionRequired = (question: QuestionType) => {
  const { subCategoriesRequired } = useContext(SubCategoryRequiredContext)

  if (!subCategoriesRequired || question.isLeading) {
    return 'required'
  }

  return subCategoriesRequired[question.subCategory]
}
