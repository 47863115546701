import { CheckCircle2Icon, XCircleIcon } from 'lucide-react'

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/components/ui/toast'
import { ToastStatus, useToast } from '@/components/ui/use-toast'

const icons: Record<ToastStatus, React.ReactNode> = {
  success: <CheckCircle2Icon className='text-green-500' size={24} />,
  error: <XCircleIcon className='text-red-500' size={24} />,
}

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, description, action, title, status, ...props }) {
        return (
          <Toast key={id} className='rounded' {...props}>
            <div className='flex w-full flex-col gap-3'>
              <div className='flex items-center justify-between'>
                <div className='flex items-start gap-3 text-base font-semibold'>
                  {icons[status]}
                  {title && <ToastTitle>{title}</ToastTitle>}
                </div>
                <ToastClose />
              </div>
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
