// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/company_status.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.CompanyStatus
 */
export enum CompanyStatus {
  /**
   * @generated from enum value: COMPANY_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPANY_STATUS_ASSESSED = 1;
   */
  ASSESSED = 1,

  /**
   * @generated from enum value: COMPANY_STATUS_ASSESSMENT_REQUIRED = 2;
   */
  ASSESSMENT_REQUIRED = 2,

  /**
   * @generated from enum value: COMPANY_STATUS_IN_ASSESSMENT = 3;
   */
  IN_ASSESSMENT = 3,

  /**
   * @generated from enum value: COMPANY_STATUS_RE_ASSESSMENT_REQUIRED = 4;
   */
  RE_ASSESSMENT_REQUIRED = 4,

  /**
   * @generated from enum value: COMPANY_STATUS_OFFBOARDED = 5;
   */
  OFFBOARDED = 5,

  /**
   * @generated from enum value: COMPANY_STATUS_ARCHIVED = 6;
   */
  ARCHIVED = 6,

  /**
   * @generated from enum value: COMPANY_STATUS_REJECTED = 7;
   */
  REJECTED = 7,

  /**
   * @generated from enum value: COMPANY_STATUS_UNSANCTIONED = 8;
   */
  UNSANCTIONED = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(CompanyStatus)
proto3.util.setEnumType(CompanyStatus, "inventory.v1.CompanyStatus", [
  { no: 0, name: "COMPANY_STATUS_UNSPECIFIED" },
  { no: 1, name: "COMPANY_STATUS_ASSESSED" },
  { no: 2, name: "COMPANY_STATUS_ASSESSMENT_REQUIRED" },
  { no: 3, name: "COMPANY_STATUS_IN_ASSESSMENT" },
  { no: 4, name: "COMPANY_STATUS_RE_ASSESSMENT_REQUIRED" },
  { no: 5, name: "COMPANY_STATUS_OFFBOARDED" },
  { no: 6, name: "COMPANY_STATUS_ARCHIVED" },
  { no: 7, name: "COMPANY_STATUS_REJECTED" },
  { no: 8, name: "COMPANY_STATUS_UNSANCTIONED" },
]);

