import { Result } from 'antd'
import { useParams } from 'react-router-dom'

import { useIntegration } from '@/api/get-integrations.hook'

import { useReportErrorsCallback } from '@/lib/error-reporting'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { NetskopeIntegration } from '@/pages/settings-page/integration-section/integration/netskope'

import { Loading } from '@/components/loading'

import { GoogleDriveIntegration } from './integration/google-drive'
import { GwsIntegration } from './integration/gws'
import { LogicmanagerIntegration } from './integration/logicmanager'
import { MicrosoftIntegration } from './integration/microsoft'
import { OktaIntegration } from './integration/okta'
import { VendrIntegration } from './integration/vendr'
import { WebhookIntegration } from './integration/webhook'
import { WizIntegration } from './integration/wiz'
import { ZiphqIntegration } from './integration/ziphq'

export const IntegrationSetupPage = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const { data, isLoading, isError, error } = useIntegration({
    integrationId: integrationId || '',
    enabled: integrationId !== undefined,
  })
  const { isEnabled: logicmanagerEnabled } = useFeatureFlagEnabled('logicmanager-integration')
  const { isEnabled: wizEnabled } = useFeatureFlagEnabled('wiz-integration')

  const tryReportErr = useReportErrorsCallback()
  if (isLoading) {
    return <Loading />
  }

  if (isError || !data || !integrationId) {
    tryReportErr(error)
    return <Result status='404' subTitle='Sorry, this Integration you visited does not exist.' />
  }

  if (isLoading) {
    return <Loading />
  }

  switch (integrationId) {
    case 'google-workspace':
      return <GwsIntegration integration={data} />
    case 'google-drive':
      return <GoogleDriveIntegration integration={data} />
    case 'microsoft-entra-id':
      return <MicrosoftIntegration integration={data} />
    case 'ziphq':
      return <ZiphqIntegration integration={data} />
    case 'logicmanager':
      return logicmanagerEnabled && <LogicmanagerIntegration integration={data} />
    case 'wiz':
      return wizEnabled && <WizIntegration integration={data} />
    case 'okta':
      return <OktaIntegration integration={data} />
    case 'netskope':
      return <NetskopeIntegration integration={data} />
    case 'webhook':
      return <WebhookIntegration integration={data} />
    case 'vendr':
      return <VendrIntegration integration={data} />
    default:
      return <Result status='404' subTitle='Sorry, this Integration you visited does not exist.' />
  }
}
