import { cva } from 'class-variance-authority'

import { Sentiment } from '@/components/variance-percentage/types'

export const badgeSentimentVariant = cva(
  'text-md flex w-fit items-center gap-1 whitespace-nowrap border-none font-normal',
  {
    variants: {
      sentiment: {
        [Sentiment.NEGATIVE]: 'rounded bg-red-50 px-1 font-medium text-red-500',
        [Sentiment.POSITIVE]: 'rounded bg-green-50 px-1 font-medium text-green-500',
        [Sentiment.NEUTRAL]: 'pl-1 pr-0 text-gray-700',
      },
    },
  },
)
