import _ from 'lodash'

import { useListUserSourcedArtifact } from '@/api/artifact.hook'
import { useGetCompany } from '@/api/company.hook'
import { Artifact } from '@/gen/inventory/v1/artifact_service_pb'

import { documentSourceLabel, documentStatusLabel } from '@/const/label'
import { documentStatusPriority } from '@/const/priority'

import { formatDate } from '@/lib/date'
import { useUrlParams } from '@/lib/url-param.hook'

import { ArtifactHyperlink } from '@/components/artifact-hyperlink'
import { ArtifactExpirationDate } from '@/components/badge/artifact-expiration-date'
import { DocumentStatusBadge } from '@/components/badge/file-analyze-status'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextOverflow } from '@/components/text-overflow'
import { TextWithIcon } from '@/components/text-with-icon'

export const ArtifactsTable = () => {
  const { data, isLoading } = useListUserSourcedArtifact()
  const { urlParams } = useUrlParams()
  const columns: ColumnType<Artifact>[] = [
    {
      title: 'Third-Party',
      dataIndex: 'companyId',
      width: '17%',
      render: (companyId: Artifact['companyId']) => <ThirdPartyColumn companyId={companyId} />,
      filter: true,
      sort: false,
      accessor: ({ companyId }) => ({
        filterValue: companyId ? [companyId] : [],
      }),
      defaultSelectedFilter: urlParams.thirdParty ? [urlParams.thirdParty] : [],
      optionRender: (companyId) => <ThirdPartyColumn companyId={companyId} />,
    },
    {
      title: 'Artifact',
      width: '9%',
      filter: true,
      accessor: ({ artifactSubcategory }) => ({
        filterValue: artifactSubcategory,
        sortValue: artifactSubcategory,
      }),
      render: ({ artifactSubcategory, expirationDate }: Artifact) => {
        if (!artifactSubcategory) return '–'

        return (
          <TextWithIcon
            text={artifactSubcategory}
            icon={
              expirationDate && <ArtifactExpirationDate expirationDate={expirationDate.toDate()} />
            }
            iconPosition='end'
          />
        )
      },
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: '13%',
      render: (source: Artifact['source']) => documentSourceLabel[source],
    },
    {
      title: 'Link',
      width: '30%',
      render: ({ name, id }) => (
        <TextOverflow hideTooltip>
          <ArtifactHyperlink artifactId={id}>{name}</ArtifactHyperlink>
        </TextOverflow>
      ),
      search: true,
      accessor: ({ name }) => ({
        searchValue: name,
      }),
    },
    {
      title: 'Status',
      width: '13%',
      render: ({ status, error, dateUpdated }: Artifact) => (
        <DocumentStatusBadge error={error} status={status} lastUpdated={dateUpdated} />
      ),
      filter: true,
      accessor: ({ status, error }) => {
        const maxStatus = _.max(Object.values(documentStatusPriority)) || 0
        return {
          filterValue: error ? 'Failed' : documentStatusLabel[status],
          sortValue: error ? maxStatus + 1 : documentStatusPriority[status],
        }
      },
    },
    {
      title: 'Date Added',
      dataIndex: 'dateAdded',
      width: '13%',
      render: (dateAdded: Artifact['dateAdded']) =>
        dateAdded && <span className='text-gray-400'>{formatDate(dateAdded.toDate())}</span>,
      accessor: ({ dateAdded }) => ({
        sortValue: dateAdded && dateAdded.toDate(),
      }),
    },
  ]
  return (
    <Table
      pagination={{}}
      stickyFilterBar
      columns={columns}
      dataSource={data}
      loading={isLoading}
    />
  )
}

const ThirdPartyColumn = ({ companyId }: { companyId?: string }) => {
  const { data } = useGetCompany(companyId, !!companyId)
  return data?.company?.profile?.name || '-'
}
