import { EyeIcon } from 'lucide-react'

import { useGetRoom, useInviteRecipient } from '@/api/questionnaire.hook'

import { Header } from '@/pages/questionnaire/header'
import { ListQuestion } from '@/pages/questionnaire/list-question'
import { QuestionnaireProvider } from '@/pages/questionnaire/questionnaire-provider'
import { useInviteRecipientModal } from '@/pages/questionnaire/use-invite-recipient-modal.hook'

import { Loading } from '@/components/loading'
import { Alert } from '@/components/ui/alert'

const currentMagicLink = window.location.pathname

export const RecipientQuestionnaire = () => {
  const { roomId, currentUserName, questionnaire, isLoading } = useGetRoom(currentMagicLink)
  const { mutateAsync } = useInviteRecipient()
  const { renderModal, openModal } = useInviteRecipientModal({
    mutateFunc: async (recipientEmails: string[]) =>
      await mutateAsync({
        recipientEmails,
        magicLink: currentMagicLink,
      }),
  })

  if (isLoading || !questionnaire) return <Loading />

  return (
    <QuestionnaireProvider roomId={roomId}>
      <div className='bg-gray-50 px-16 pt-8'>
        <Alert variant={'info'}>
          <div className='flex flex-row items-center gap-1'>
            <EyeIcon size={16} />
            <span className='grow'>
              Welcome, <span className='font-semibold'>{currentUserName}</span> to {'"'}
              {questionnaire.name}
              {'"'}, If this is not you, please click on the link to the right.
            </span>
            <a className='inline cursor-pointer hover:underline' onClick={() => openModal()}>
              Not Me
            </a>
          </div>
        </Alert>
      </div>
      <Header
        title={questionnaire.name}
        description={questionnaire.description}
        dueDate={questionnaire.dueDate?.toDate() || new Date()}
        activeStatusFilters={['needsReview', 'unanswered', 'rejected']}
        recipients={questionnaire.recipients}
        onRequestInviteRecipient={() => openModal()}
        questionnaireStatus={questionnaire.status}
      />
      <ListQuestion participantRole='recipient' />
      {renderModal()}
    </QuestionnaireProvider>
  )
}
