import _ from 'lodash'
import { useEffect } from 'react'

import { useGetPreferences } from '@/api/notifications-knock.hook'
import { useUserSlackAuthTest } from '@/api/notifications-slack.hook'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { cn } from '@/lib/style-helpers'

import { KnockPreferenceSwitch } from '@/pages/settings-page/notification-section/knock-preference-switch'
import { workflows } from '@/pages/settings-page/notification-section/workflow.const'

import { useToast } from '@/components/ui/use-toast'

export type WorkflowPreferences = {
  knockChannelIdSlack: string
}

export const WorkflowPreferences = ({ knockChannelIdSlack }: WorkflowPreferences) => {
  const {
    isLoading: isSlackLoading,
    data: slackInfo,
    isError: authTestError,
  } = useUserSlackAuthTest(knockChannelIdSlack)
  const {
    data: preferences,
    isLoading: isPreferencesLoading,
    error: preferencesError,
    isError: isPreferencesError,
  } = useGetPreferences()

  const { isEnabled: emailPrefEnabled, isLoading: isEmailPrefLoading } = useFeatureFlagEnabled(
    'notifications-email-preferences',
  )
  const isLoading = isSlackLoading || isPreferencesLoading || isEmailPrefLoading
  const isSlackConnected = !authTestError && slackInfo?.connected
  const workflowsByCategory = _.groupBy(workflows, 'category')

  const { toast } = useToast()
  useEffect(() => {
    if (isPreferencesError) {
      toast({
        status: 'error',
        title: 'Error fetching notification preferences',
      })
      console.error('Error fetching notification preferences', preferencesError)
    }
  }, [isPreferencesError, preferencesError, toast])

  return (
    <div
      className={cn(
        'mt-4 grid gap-x-2 gap-y-4 rounded border p-4',
        emailPrefEnabled ? 'grid-cols-[auto,4rem,4rem]' : 'grid-cols-[auto,4rem]',
      )}
    >
      <div className='col-start-2 text-sm font-bold uppercase'>Slack</div>
      {emailPrefEnabled && <div className='col-start-3 text-sm font-bold uppercase'>Email</div>}
      {Object.keys(workflowsByCategory).flatMap((category) => [
        <div
          key={`category-${category}`}
          className={cn(
            'col-span-3 pt-4 text-sm font-bold uppercase tracking-wider text-gray-400',
            emailPrefEnabled ? 'col-span-3' : 'col-span-2',
          )}
        >
          {category}
        </div>,
        ...workflowsByCategory[category].flatMap((workflow) => [
          <div key={`${workflow.id}-label`}>{workflow.label}</div>,
          <KnockPreferenceSwitch
            disabled={!isSlackConnected || isLoading}
            key={`${workflow.id}-slack-switch`}
            workflowId={workflow.id}
            channelType='chat'
            preferences={preferences}
          />,
          emailPrefEnabled && (
            <KnockPreferenceSwitch
              disabled={isLoading}
              key={`${workflow.id}-email-switch`}
              workflowId={workflow.id}
              channelType='email'
              preferences={preferences}
            />
          ),
        ]),
      ])}
    </div>
  )
}
