import { CalendarIcon } from 'lucide-react'
import { useState } from 'react'

import { formatDate } from '@/lib/date'
import { cn } from '@/lib/style-helpers'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

export type DatePickerProps = {
  initialDate?: Date
  onDateChange: (date: Date) => void
  className?: string
  buttonClassName?: string
  disabled?: boolean
}

export const DatePicker = ({
  initialDate,
  onDateChange: onDateChange,
  className,
  buttonClassName,
  disabled = false,
}: DatePickerProps) => {
  const [date, setDate] = useState<Date | undefined>(initialDate)
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={'outline'}
          className={cn('w-[280px] justify-start text-left font-normal', buttonClassName)}
        >
          <CalendarIcon className='mr-2 size-4' />
          {date ? formatDate(date) : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('w-auto p-0', className)}>
        <Calendar
          mode='single'
          selected={date}
          onSelect={(newDate) => {
            if (!newDate) return
            setDate(newDate)
            onDateChange && onDateChange(newDate)
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
