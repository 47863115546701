// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/control_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Control } from "./control_pb.js";
import { CompanySummary } from "./company_summary_pb.js";
import { Implication } from "./implication_pb.js";

/**
 * @generated from enum inventory.v1.InherentRiskSetting
 */
export enum InherentRiskSetting {
  /**
   * @generated from enum value: INHERENT_RISK_SETTING_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_LOW_AND_ABOVE = 1;
   */
  LOW_AND_ABOVE = 1,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_MEDIUM_AND_ABOVE = 2;
   */
  MEDIUM_AND_ABOVE = 2,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_HIGH_AND_ABOVE = 3;
   */
  HIGH_AND_ABOVE = 3,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_CRITICAL_AND_ABOVE = 4;
   */
  CRITICAL_AND_ABOVE = 4,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_DISABLED = 5;
   */
  DISABLED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(InherentRiskSetting)
proto3.util.setEnumType(InherentRiskSetting, "inventory.v1.InherentRiskSetting", [
  { no: 0, name: "INHERENT_RISK_SETTING_UNSPECIFIED" },
  { no: 1, name: "INHERENT_RISK_SETTING_LOW_AND_ABOVE" },
  { no: 2, name: "INHERENT_RISK_SETTING_MEDIUM_AND_ABOVE" },
  { no: 3, name: "INHERENT_RISK_SETTING_HIGH_AND_ABOVE" },
  { no: 4, name: "INHERENT_RISK_SETTING_CRITICAL_AND_ABOVE" },
  { no: 5, name: "INHERENT_RISK_SETTING_DISABLED" },
]);

/**
 * @generated from message inventory.v1.UpdateControlSettingsResponse
 */
export class UpdateControlSettingsResponse extends Message<UpdateControlSettingsResponse> {
  constructor(data?: PartialMessage<UpdateControlSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSettingsResponse | PlainMessage<UpdateControlSettingsResponse> | undefined, b: UpdateControlSettingsResponse | PlainMessage<UpdateControlSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateControlSettingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlSettingsRequest
 */
export class UpdateControlSettingsRequest extends Message<UpdateControlSettingsRequest> {
  /**
   * @generated from field: repeated inventory.v1.UpdateControlSetting control_settings = 1;
   */
  controlSettings: UpdateControlSetting[] = [];

  constructor(data?: PartialMessage<UpdateControlSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_settings", kind: "message", T: UpdateControlSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSettingsRequest | PlainMessage<UpdateControlSettingsRequest> | undefined, b: UpdateControlSettingsRequest | PlainMessage<UpdateControlSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateControlSettingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlSetting
 */
export class UpdateControlSetting extends Message<UpdateControlSetting> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  /**
   * @generated from field: inventory.v1.InherentRiskSetting inherent_risk_setting = 2;
   */
  inherentRiskSetting = InherentRiskSetting.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateControlSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "inherent_risk_setting", kind: "enum", T: proto3.getEnumType(InherentRiskSetting) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSetting | PlainMessage<UpdateControlSetting> | undefined, b: UpdateControlSetting | PlainMessage<UpdateControlSetting> | undefined): boolean {
    return proto3.util.equals(UpdateControlSetting, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlSettingsRequest
 */
export class ListControlSettingsRequest extends Message<ListControlSettingsRequest> {
  constructor(data?: PartialMessage<ListControlSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlSettingsRequest | PlainMessage<ListControlSettingsRequest> | undefined, b: ListControlSettingsRequest | PlainMessage<ListControlSettingsRequest> | undefined): boolean {
    return proto3.util.equals(ListControlSettingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlSettingsResponse
 */
export class ListControlSettingsResponse extends Message<ListControlSettingsResponse> {
  /**
   * @generated from field: repeated inventory.v1.ControlSetting control_settings = 1;
   */
  controlSettings: ControlSetting[] = [];

  constructor(data?: PartialMessage<ListControlSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_settings", kind: "message", T: ControlSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlSettingsResponse | PlainMessage<ListControlSettingsResponse> | undefined, b: ListControlSettingsResponse | PlainMessage<ListControlSettingsResponse> | undefined): boolean {
    return proto3.util.equals(ListControlSettingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlSetting
 */
export class ControlSetting extends Message<ControlSetting> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: string control_title = 3;
   */
  controlTitle = "";

  /**
   * @generated from field: inventory.v1.InherentRiskSetting inherent_risk_setting = 4;
   */
  inherentRiskSetting = InherentRiskSetting.UNSPECIFIED;

  constructor(data?: PartialMessage<ControlSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "control_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "inherent_risk_setting", kind: "enum", T: proto3.getEnumType(InherentRiskSetting) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlSetting {
    return new ControlSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlSetting {
    return new ControlSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlSetting {
    return new ControlSetting().fromJsonString(jsonString, options);
  }

  static equals(a: ControlSetting | PlainMessage<ControlSetting> | undefined, b: ControlSetting | PlainMessage<ControlSetting> | undefined): boolean {
    return proto3.util.equals(ControlSetting, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlsRequest
 */
export class ListControlsRequest extends Message<ListControlsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListControlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlsRequest | PlainMessage<ListControlsRequest> | undefined, b: ListControlsRequest | PlainMessage<ListControlsRequest> | undefined): boolean {
    return proto3.util.equals(ListControlsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlsResponse
 */
export class ListControlsResponse extends Message<ListControlsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Control controls = 1;
   */
  controls: Control[] = [];

  constructor(data?: PartialMessage<ListControlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "controls", kind: "message", T: Control, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlsResponse | PlainMessage<ListControlsResponse> | undefined, b: ListControlsResponse | PlainMessage<ListControlsResponse> | undefined): boolean {
    return proto3.util.equals(ListControlsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlRequest
 */
export class GetControlRequest extends Message<GetControlRequest> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  /**
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetControlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlRequest {
    return new GetControlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlRequest {
    return new GetControlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlRequest {
    return new GetControlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlRequest | PlainMessage<GetControlRequest> | undefined, b: GetControlRequest | PlainMessage<GetControlRequest> | undefined): boolean {
    return proto3.util.equals(GetControlRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlResponse
 */
export class GetControlResponse extends Message<GetControlResponse> {
  /**
   * @generated from field: inventory.v1.Control control = 1;
   */
  control?: Control;

  /**
   * @generated from field: optional string finding_id = 3;
   */
  findingId?: string;

  /**
   * @generated from field: string fact_markdown = 4;
   */
  factMarkdown = "";

  /**
   * @generated from field: inventory.v1.CompanySummary company = 6;
   */
  company?: CompanySummary;

  /**
   * @generated from field: repeated inventory.v1.Implication implications = 7;
   */
  implications: Implication[] = [];

  /**
   * @generated from field: string additional_fact_markdown = 8;
   */
  additionalFactMarkdown = "";

  /**
   * @generated from field: int32 additional_fact_count = 9;
   */
  additionalFactCount = 0;

  constructor(data?: PartialMessage<GetControlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control", kind: "message", T: Control },
    { no: 3, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "company", kind: "message", T: CompanySummary },
    { no: 7, name: "implications", kind: "enum", T: proto3.getEnumType(Implication), repeated: true },
    { no: 8, name: "additional_fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "additional_fact_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlResponse {
    return new GetControlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlResponse {
    return new GetControlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlResponse {
    return new GetControlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlResponse | PlainMessage<GetControlResponse> | undefined, b: GetControlResponse | PlainMessage<GetControlResponse> | undefined): boolean {
    return proto3.util.equals(GetControlResponse, a, b);
  }
}

