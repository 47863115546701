import { VariantProps, cva } from 'class-variance-authority'

import { CompanySummary } from '@/gen/inventory/v1/company_summary_pb'
import { Finding } from '@/gen/inventory/v1/finding_service_pb'

import { formatDate } from '@/lib/date'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { ExportFinding } from '@/pages/finding-details/export-finding'

import { CompanySummaryBadge } from '@/components/badge/company-summary'
import { SelectFindingSeverityBadge } from '@/components/badge/finding-severity'
import { SelectFindingStatusBadge } from '@/components/badge/finding-status'
import { ImplicationIndicatorList } from '@/components/indicator/implication'
import { ProfileTeaser } from '@/components/profile-teaser'
import { StatisticsRow } from '@/components/stats-row/stats-row'

import CriticalIcon from '@/assets/critical.svg'

const findingDetailsHeaderTitleVariant = cva('mb-8  grow text-2xl font-bold text-gray-700', {
  variants: {
    titleVariant: {
      default: 'line-clamp-1',
      showAll: '',
    },
  },
  defaultVariants: {
    titleVariant: 'default',
  },
})

export type FindingDetailsHeaderProps = Pick<
  Finding,
  'title' | 'riskLevel' | 'status' | 'implications' | 'assignee' | 'detectionTime' | 'id'
> & {
  company: CompanySummary
  disableActions?: boolean
} & VariantProps<typeof findingDetailsHeaderTitleVariant>

export const FindingDetailsHeader = ({
  title,
  riskLevel,
  status,
  implications,
  assignee,
  detectionTime,
  id,
  company,
  disableActions = false,
  titleVariant,
}: FindingDetailsHeaderProps) => {
  const { isEnabled: assigneeEnabled } = useFeatureFlagEnabled('finding-assignee')
  const { isEnabled: suggestedWorkStatusEnabled } = useFeatureFlagEnabled(
    'finding-suggested-work-status',
  )
  const { isEnabled: suggestedSeverityEnabled } = useFeatureFlagEnabled(
    'finding-suggested-severity',
  )

  return (
    <div className='bg-gray-50 px-8 py-1'>
      <div className='mb-6 mt-4 flex items-center justify-between'>
        <CompanySummaryBadge disableAction={disableActions} companySummary={company} />
        {!disableActions && <ExportFinding findingId={id} />}
      </div>
      <h2 className={findingDetailsHeaderTitleVariant({ titleVariant })}>{title}</h2>
      <div className='mb-10 text-md'>
        <StatisticsRow
          items={[
            {
              title: 'severity',
              icon: <CriticalIcon />,
              children: (
                <SelectFindingSeverityBadge
                  disableAction={disableActions}
                  severity={riskLevel}
                  findingId={id}
                  suggestedEnabled={suggestedSeverityEnabled}
                  extraTrackingProps={{
                    from: 'third-party-drawer',
                    company: company?.profileName,
                    finding: title,
                  }}
                />
              ),
            },
            {
              title: 'work status',
              children: (
                <SelectFindingStatusBadge
                  disableAction={disableActions}
                  findingId={id}
                  status={status}
                  suggestedWorkStatusEnabled={suggestedWorkStatusEnabled}
                  extraTrackingProps={{
                    from: 'third-party-drawer',
                    company: company?.profileName,
                    finding: title,
                  }}
                />
              ),
            },
            {
              title: 'implications',
              children: <ImplicationIndicatorList activeImplications={implications} />,
            },
            {
              title: 'detection time',
              children: <>{detectionTime && formatDate(detectionTime.toDate(), 'dateTime')}</>,
            },
            {
              hide: !assigneeEnabled,
              title: 'assignee',
              children: <ProfileTeaser username={assignee} />,
            },
          ]}
        />
      </div>
    </div>
  )
}
