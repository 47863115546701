import { Implication } from '@/gen/inventory/v1/implication_pb'

import { implicationDescription } from '@/const/description'
import { implicationLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'

import { ImplicationIcon } from '@/components/icons/implication'
import { Tooltip } from '@/components/ui/tooltip'

type implicationVariant = 'active' | 'faded'

export type ImplicationIndicatorProps = {
  implication: Implication
  variant?: implicationVariant
}

export const ImplicationIndicator = ({ implication, variant }: ImplicationIndicatorProps) => {
  return (
    <Tooltip
      trigger={
        <div
          className={cn('rounded-sm p-1 hover:bg-gray-200', variant === 'faded' && 'text-gray-300')}
        >
          <ImplicationIcon implication={implication} />
        </div>
      }
    >
      <div className='max-w-xs px-3 py-1 text-center'>
        <div className='mb-2 font-semibold capitalize'>{implicationLabel[implication]}</div>
        <span>{implicationDescription[implication]}</span>
      </div>
    </Tooltip>
  )
}

const allImplications = [
  Implication.BREACH_IMPACT,
  Implication.BREACH_LIKELIHOOD,
  Implication.BUSINESS_CONTINUITY,
  Implication.COMPLIANCE,
]

export type ImplicationIndicatorListProps = {
  activeImplications: Implication[]
}

export const ImplicationIndicatorList = ({ activeImplications }: ImplicationIndicatorListProps) => (
  <div className='flex'>
    {Object.values(allImplications).map((implication, i) => (
      <ImplicationIndicator
        key={i}
        implication={implication}
        variant={activeImplications.includes(implication) ? 'active' : 'faded'}
      />
    ))}
  </div>
)
