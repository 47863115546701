import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { riskLevelToColor } from '@/lib/color'

import { Sentiment } from '@/components/variance-percentage/types'
import { VariancePercentage } from '@/components/variance-percentage/variance-percentage'

type EntityCountAndGrowthProps = {
  severity: RiskLevel
  count: number
  entityName: 'findings' | 'third-parties'
  growthPercentage: number
  growthSentiment: Sentiment
}

export const EntityCountAndGrowth = ({
  severity,
  count,
  entityName,
  growthPercentage,
  growthSentiment,
}: EntityCountAndGrowthProps) => {
  return (
    <div key={severity} className='flex w-full justify-between gap-2'>
      <div className='flex gap-3'>
        <div
          style={{
            backgroundColor: riskLevelToColor[severity],
          }}
          className='h-14 w-2 min-w-2 rounded-sm'
        />
        <div>
          <h4 className='text-3xl font-bold'>{count}</h4>
          <span className='text-xs text-gray-400'>
            {riskLevelLabel[severity]} {entityName === 'findings' && 'severity'} {entityName}
          </span>
        </div>
      </div>
      <VariancePercentage
        className='h-full self-start'
        value={growthPercentage}
        variant='badge'
        growthSentiment={growthSentiment}
      />
    </div>
  )
}
