import { AutoComplete, Form, Input, Spin } from 'antd'
import { ArrowLeft, Globe } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'

import { useAutocompleteCompany } from '@/api/autocomplete-company.hook'
import { AutocompleteItem } from '@/gen/companycatalog/v1/company_catalog_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { companyStatusLabel } from '@/const/label'

import { useDelayedCallback } from '@/lib/delayed-callback.hook'

import { RadioItem } from '@/components/radio-item'
import { Badge } from '@/components/ui/badge'
import { RadioGroup } from '@/components/ui/radio-group'

import { CompanyProfileInfo } from './company-profile-info'
import { AddNewThirdPartyContext } from './context'
import { StepProps } from './use-add-third-party-modal.hook'

const stringToCompanyStatus: Record<string, CompanyStatus> = Object.fromEntries(
  Object.entries(companyStatusLabel).map(([key, value]) => [value, parseInt(key) as CompanyStatus]),
)

const THIRD_PARTY_FINDER_TITLE = 'Third-Party Finder'
const THIRD_PARTY_FINDER_PLACEHOLDER = 'Third-Party Name or Website'
const THIRD_PARTY_NAME_TITLE = 'Third-Party Name'
const THIRD_PARTY_NAME_PLACEHOLDER = 'Third-Party Name'
const THIRD_PARTY_WEBSITE_TITLE = 'Third-Party Website'
const THIRD_PARTY_WEBSITE_PLACEHOLDER = 'website.com'

type ProfileStepProps = StepProps

export const ProfileStep = ({ setIsNextEnabled }: ProfileStepProps) => {
  const {
    setSelectedCompany,
    selectedStatus,
    setSelectedStatus,
    selectedCompany,
    isManual,
    setIsManual,
  } = useContext(AddNewThirdPartyContext)
  const [form] = Form.useForm()
  const [inputValue, setInputValue] = useState(selectedCompany?.name)
  const [companyNameQuery, setCompanyNameQuery] = useState<string>('')
  const [sendNameInput, isHanging] = useDelayedCallback(setCompanyNameQuery)
  const { data, isInitialLoading } = useAutocompleteCompany(companyNameQuery)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true, dirty: true })
      .then(() => setIsNextEnabled(!!selectedCompany?.domain))
      .catch(() => setIsNextEnabled(false))
  }, [selectedCompany?.domain, selectedCompany, setIsNextEnabled, form, isManual])

  return (
    <div>
      <h2 className='my-5 text-xl font-light'>Profile Information</h2>
      <div className='flex'>
        <h3 className='font-semibold leading-10'>
          {isManual ? THIRD_PARTY_NAME_TITLE : THIRD_PARTY_FINDER_TITLE}
          <span className='text-red-500'>*</span>
        </h3>
        {!isManual ? (
          <div
            onClick={() => {
              setSelectedCompany((c) => ({ ...c, name: inputValue }) as AutocompleteItem)
              setIsManual(true)
            }}
            className='ml-auto mr-0 inline-flex cursor-pointer gap-1 justify-self-end text-xs leading-10 text-gray-400'
            data-dd-action-name='third-party.add.manual'
          >
            <span>{"Can't find your third-party?"}</span>
            <div className='flex items-center gap-1 text-purple-600'>Click here</div>
            <span>{'to add it manually'}</span>
          </div>
        ) : (
          <div
            onClick={() => {
              setSelectedCompany(null)
              setIsManual(false)
            }}
            className='ml-auto mr-0 inline-flex cursor-pointer gap-1 justify-self-end text-xs leading-10 text-gray-400'
            data-dd-action-name='third-party.add.finder'
          >
            <div className='flex items-center gap-1 text-purple-600'>
              <ArrowLeft size={12} />
              Back
            </div>
            <span>{'to third-party finder'}</span>
          </div>
        )}
      </div>
      <Form
        name='add-company'
        autoComplete='off'
        form={form}
        validateTrigger={['onChange', 'onBlur']}
      >
        <Form.Item
          valuePropName='name'
          name='name'
          rules={[isManual ? { required: true, message: 'Third-party name required' } : {}]}
        >
          <AutoComplete
            className='w-full'
            size='large'
            placeholder={isManual ? THIRD_PARTY_NAME_PLACEHOLDER : THIRD_PARTY_FINDER_PLACEHOLDER}
            value={inputValue}
            suffixIcon={(isHanging || isInitialLoading) && <Spin size='small' />}
            onSearch={(input) => sendNameInput(input)}
            onChange={(value) => {
              value = value.replace(/^https?:\/\//, '')
              setInputValue(value)
              if (isManual) {
                setSelectedCompany((company) => ({ ...company, name: value }) as AutocompleteItem)
              } else {
                setSelectedCompany(null)
              }
            }}
            onSelect={(_, { company }) => {
              setIsManual(false)
              setSelectedCompany(company)
              setInputValue(company.name)
            }}
            options={data?.companies
              .filter((company) => company.domain)
              .sort((a, b) => b.similarityScore - a.similarityScore)
              .sort((a, b) => (a.isAdded === b.isAdded ? 0 : a.isAdded ? -1 : 1))
              .map((company) => ({
                company,
                value: getAutocompleteItemId(company),
                label: company.name,
              }))}
            optionRender={({ data }) => {
              return <AutoCompleteOption {...data} />
            }}
          />
        </Form.Item>
        {isManual ? (
          <Form.Item
            name='website'
            normalize={(value) => 'https://' + value.replace(/^https?:\/\//, '')}
            rules={[
              { required: true, message: 'Third-party website required' },
              {
                type: 'url',
                message: 'Third-party website must be a valid URL',
              },
            ]}
          >
            <div>
              <h3 className='font-semibold leading-10'>
                {THIRD_PARTY_WEBSITE_TITLE}
                <span className='text-red-500'>*</span>
              </h3>
              <Input
                value={selectedCompany?.domain}
                onChange={(e) => {
                  setSelectedCompany({
                    name: selectedCompany?.name || inputValue,
                    domain: e.target.value.replace(/^https?:\/\//, '').trim(),
                  } as AutocompleteItem)
                }}
                placeholder={THIRD_PARTY_WEBSITE_PLACEHOLDER}
                size='large'
                addonBefore='https://'
              />
            </div>
          </Form.Item>
        ) : (
          <CompanyProfileInfo companyInfo={selectedCompany} />
        )}
      </Form>
      <div className='my-5 font-semibold'>Assessment Status</div>
      <RadioGroup
        onValueChange={(value) => setSelectedStatus(stringToCompanyStatus[value])}
        value={companyStatusLabel[selectedStatus]}
        className='flex w-fit flex-col gap-5.5'
      >
        <RadioItem
          isSelect={selectedStatus === CompanyStatus.ASSESSED}
          id={companyStatusLabel[CompanyStatus.ASSESSED]}
          value={companyStatusLabel[CompanyStatus.ASSESSED]}
          description='I have already assessed this third-party'
        />

        <RadioItem
          isSelect={selectedStatus === CompanyStatus.ASSESSMENT_REQUIRED}
          id={companyStatusLabel[CompanyStatus.ASSESSMENT_REQUIRED]}
          value={companyStatusLabel[CompanyStatus.ASSESSMENT_REQUIRED]}
          description='This third-party requires assessment'
        />
      </RadioGroup>
    </div>
  )
}

const getAutocompleteItemId = (company: AutocompleteItem): string => {
  switch (company.companyIdentification.case) {
    case 'id':
      return company.companyIdentification.value
    case 'pdlId':
      return `pdl-${company.companyIdentification.value}`
    default:
      return ''
  }
}

const AutoCompleteOption = ({ company }: { company: AutocompleteItem }) => {
  const { domain, name, isAdded } = company as AutocompleteItem
  const id = getAutocompleteItemId(company)
  return (
    <div className='flex items-center gap-3' key={id}>
      <object
        className='size-6'
        type='image/x-icon'
        data={`https://www.google.com/s2/favicons?domain=${domain}&sz=64`}
      >
        <Globe className='text-gray-400' size={24} />
      </object>
      <div>
        <div className='flex items-center gap-2'>
          <span className='font-bold'>{name}</span>
          {isAdded && <AddedBadge />}
        </div>
        <div className='text-xs'>{domain}</div>
      </div>
    </div>
  )
}

const AddedBadge = () => (
  <Badge
    variant='outline'
    className='h-3.5 rounded border-green-50 bg-green-50 p-1 text-xs font-medium text-green-500'
  >
    Added
  </Badge>
)
