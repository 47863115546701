import { CopyIcon } from 'lucide-react'

import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

export type InviteStepProps = {
  title: React.ReactNode | string
  invitees: {
    label: string
    magicLink: string
  }[]
}

export const InviteStep = ({ title, invitees }: InviteStepProps) => {
  return (
    <div className='mb-6 pt-4 text-base'>
      <h2 className='mb-6 text-lg'>{title}</h2>
      <div className='flex flex-col gap-2'>
        {invitees.map((invitee) => (
          <InviteLink key={invitee.label} invitee={invitee} />
        ))}
      </div>
    </div>
  )
}

const getFullMagicLink = (magicLink: string) => `https://${document.location.host}${magicLink}`

const InviteLink = ({ invitee }: { invitee: { label: string; magicLink: string } }) => {
  const { toast } = useToast()
  return (
    <div className='flex flex-col gap-2'>
      <span key={`${invitee.label}-name`} className='max-w-48 font-semibold'>
        {invitee.label}
      </span>
      <Tooltip
        key={`${invitee.label}-link`}
        trigger={
          <div
            className='flex w-full items-center gap-2'
            onClick={() => {
              navigator.clipboard.writeText(getFullMagicLink(invitee.magicLink))
              toast({
                title: 'Copied to clipboard',
                status: 'success',
              })
            }}
          >
            <span className='block w-full shrink truncate text-nowrap'>
              {getFullMagicLink(invitee.magicLink)}
            </span>
            <CopyLabel />
          </div>
        }
      >
        Copy to clipboard
      </Tooltip>
    </div>
  )
}

const CopyLabel = () => {
  return (
    <span className='flex items-center gap-1 text-nowrap text-purple-400 hover:text-purple-500'>
      <CopyIcon size={14} /> Copy Link
    </span>
  )
}
