import { Input } from 'antd'

import { type FreeTextQuestionType, isAssessor, useMutation } from '@/lib/liveblocks.config'

import { useGetQuestionRequired } from '@/pages/questionnaire/question/use-get-question-required.hook'

import { QuestionContainer, QuestionContainerProps } from './container'

interface FreeTextQuestionProps extends QuestionContainerProps {
  question: FreeTextQuestionType
}

export const FreeTextQuestion = (props: FreeTextQuestionProps) => {
  const { question, participantRole } = props

  const requiredStatus = useGetQuestionRequired(question)

  const setQuestionAnswer = useSetQuestionAnswer(question.id)
  return (
    <QuestionContainer {...props}>
      <Input
        disabled={requiredStatus !== 'required'}
        readOnly={isAssessor(participantRole)}
        placeholder='Enter answer here...'
        value={question.answer}
        onChange={(e) => setQuestionAnswer(e.target.value)}
        className='rounded border p-2'
      />
    </QuestionContainer>
  )
}

const useSetQuestionAnswer = (questionId: string) => {
  const setQuestionAnswer = useMutation(({ storage }, answer: string) => {
    const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex) as FreeTextQuestionType
    storage.get('questions').set(questionIndex, {
      ...question,
      answer,
      status: answer.length > 0 ? 'needsReview' : 'unanswered',
    })
  }, [])

  return setQuestionAnswer
}
