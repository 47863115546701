import BaseMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { remarkMark } from 'remark-mark-highlight'

import { cn } from '@/lib/style-helpers'

import { Hyperlink } from '@/components/hyperlink'

type MarkdownHyperlinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

export const MarkdownHyperlink = (props: MarkdownHyperlinkProps) => {
  if (
    props.children &&
    typeof props.children === 'string' &&
    props.children.startsWith('^^') &&
    props.children.endsWith('^^')
  ) {
    const newProps = { ...props, children: props.children.slice(2, -2) }
    return (
      <p>
        <Hyperlink {...newProps} color={'blue'} />
      </p>
    )
  }
  return <Hyperlink {...props} color={'blue'} />
}

type MarkdownProps = React.ComponentProps<typeof BaseMarkdown>

export const Markdown = ({ children, className }: MarkdownProps) => {
  return (
    <BaseMarkdown
      components={{
        a: MarkdownHyperlink,
      }}
      remarkPlugins={[remarkGfm, remarkMark as () => undefined]} // this is remark's fault
      className={cn(
        'prose-h1:m-0 prose-h1:text-base prose-h1:font-semibold prose-h1:leading-10 prose-p:text-base prose-p:text-gray-700 prose-a:break-all prose-pre:max-w-full prose-ul:list-disc prose-ul:pl-5 prose-table:w-full prose-table:border prose-table:text-left prose-th:whitespace-nowrap prose-th:px-6 prose-th:py-4 prose-th:text-left prose-th:text-sm prose-th:font-light prose-th:text-gray-400 prose-td:px-6 prose-td:py-4 prose-td:text-gray-700',
        className,
      )}
    >
      {children}
    </BaseMarkdown>
  )
}
