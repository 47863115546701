import { RcFile } from 'antd/es/upload'

export const rcFileToUint8Array = (file: RcFile): Promise<Uint8Array> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const result = event.target?.result
      if (result instanceof ArrayBuffer) {
        const uint8Array = new Uint8Array(result)
        resolve(uint8Array)
      } else {
        reject(new Error('Failed to read RcFile as ArrayBuffer.'))
      }
    }

    reader.onerror = (event) => {
      reject(new Error(`File reading error: ${event.target?.error?.message}`))
    }

    reader.readAsArrayBuffer(file)
  })
