import { ChevronDown } from 'lucide-react'
import { useCallback } from 'react'

import { cn } from '@/lib/style-helpers'

import { Filter } from '@/components/table/filter/bar'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

const MIN_ITEMS_TO_SHOW_SEARCH = 8

type FilterDropdownProps = {
  selectedOptions?: string[]
  onClick?: (value: string) => void
} & Pick<Filter, 'optionRender' | 'options' | 'label'>

export const FilterDropdown = ({
  label,
  options,
  selectedOptions = [],
  onClick = () => {},
  optionRender,
}: FilterDropdownProps) => {
  const isSelected = useCallback(
    (option: string) => selectedOptions.includes(option),
    [selectedOptions],
  )

  const totalSelected = selectedOptions.length
  const isActive = totalSelected > 0

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={options.length === 0}
          variant='ghost'
          className={cn(
            'flex items-center gap-1 whitespace-nowrap rounded-sm text-base font-light text-gray-400',
            {
              'font-normal text-gray-700': isActive,
            },
          )}
        >
          {label}
          {isActive && `(${totalSelected})`}
          <ChevronDown className='w-3.5' />
        </Button>
      </PopoverTrigger>
      <PopoverContent align='start' className='z-max w-52 p-0'>
        <Command className='p-0'>
          {options.length > MIN_ITEMS_TO_SHOW_SEARCH && (
            <>
              <CommandInput placeholder='Search...' />
              <CommandEmpty>Not found.</CommandEmpty>
            </>
          )}
          <CommandList>
            <CommandGroup className='max-h-112 overflow-y-auto p-0'>
              {options.map((option) => (
                <CommandItem
                  className='cursor-pointer'
                  onMouseUp={() => onClick(option)}
                  key={option}
                >
                  <Checkbox
                    checked={isSelected(option)}
                    value={option}
                    className={cn('mr-2 h-4 w-4')}
                  />
                  {optionRender ? optionRender(option) : option}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
