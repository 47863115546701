// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file customer/management/v1/service.proto (package customer.management.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetUserByIdRequest, GetUserByIdResponse, ListUsersRequest, ListUsersResponse, RemoveUserFromTenantRequest, RemoveUserFromTenantResponse } from "./service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "customer.management.v1.CustomerManagementService";

/**
 * @generated from service customer.management.v1.CustomerManagementService
 */
export const CustomerManagementService = {
  typeName: "customer.management.v1.CustomerManagementService",
  methods: {
    /**
     * @generated from rpc customer.management.v1.CustomerManagementService.ListUsers
     */
    listUsers: {
      name: "ListUsers",
      I: ListUsersRequest,
      O: ListUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer.management.v1.CustomerManagementService.RemoveUserFromTenant
     */
    removeUserFromTenant: {
      name: "RemoveUserFromTenant",
      I: RemoveUserFromTenantRequest,
      O: RemoveUserFromTenantResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer.management.v1.CustomerManagementService.GetUserById
     */
    getUserById: {
      name: "GetUserById",
      I: GetUserByIdRequest,
      O: GetUserByIdResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: CustomerManagementService,});

/**
 * @generated from rpc customer.management.v1.CustomerManagementService.ListUsers
 */
export const listUsers: UnaryFunctionsWithHooks<ListUsersRequest, ListUsersResponse> = {   ...$queryService.listUsers,  ...createUnaryHooks($queryService.listUsers)};

/**
 * @generated from rpc customer.management.v1.CustomerManagementService.RemoveUserFromTenant
 */
export const removeUserFromTenant: UnaryFunctionsWithHooks<RemoveUserFromTenantRequest, RemoveUserFromTenantResponse> = {   ...$queryService.removeUserFromTenant,  ...createUnaryHooks($queryService.removeUserFromTenant)};

/**
 * @generated from rpc customer.management.v1.CustomerManagementService.GetUserById
 */
export const getUserById: UnaryFunctionsWithHooks<GetUserByIdRequest, GetUserByIdResponse> = {   ...$queryService.getUserById,  ...createUnaryHooks($queryService.getUserById)};
