import { TransportProvider } from '@connectrpc/connect-query'
import { datadogRum } from '@datadog/browser-rum'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { colors } from '@/const/colors'

import { sessionTokenInterceptor } from '@/lib/auth/session-token-interceptor'
import { getConnectTransport } from '@/lib/connect-transport'
import { datadogOptions } from '@/lib/datadog.config'

import { Toaster } from '@/components/ui/toaster'

import { App } from './App'

import './index.css'

const posthogKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY
const posthogHost = import.meta.env.VITE_POSTHOG_HOST
const buildMode = import.meta.env.MODE
if (!['development', 'preview', 'staging', 'production', 'production_us'].includes(buildMode)) {
  console.warn(`Unknown build mode: ${buildMode}`)
}

const queryClient = new QueryClient()

datadogRum.init(datadogOptions(buildMode))

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <React.StrictMode>
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              handleActiveColor: colors.gray[700],
              handleColor: colors.gray[700],
              handleSize: 8,
              handleSizeHover: 10,
              trackBg: colors.gray[700],
              dotActiveBorderColor: colors.gray[700],
            },
            Carousel: {
              dotWidth: 22,
              dotActiveWidth: 44,
              dotHeight: 4,
            },
            Steps: {
              controlItemBgActive: colors.purple[100],
              colorPrimary: colors.purple[600],
            },
          },
          token: {
            colorPrimary: colors.gray[700],
            colorText: colors.gray[700],
            colorBorder: colors.gray[200],
            fontFamily: 'Manrope',
            borderRadius: 4,
          },
        }}
      >
        <PostHogProvider apiKey={posthogKey} options={{ api_host: posthogHost }}>
          <TransportProvider
            transport={getConnectTransport({
              interceptors:
                buildMode === 'production' || buildMode === 'production_us'
                  ? []
                  : [sessionTokenInterceptor],
            })}
          >
            <QueryClientProvider client={queryClient}>
              <App />
              <Toaster />
              {buildMode == 'development' && <ReactQueryDevtools />}
            </QueryClientProvider>
          </TransportProvider>
        </PostHogProvider>
      </ConfigProvider>
    </React.StrictMode>
  </BrowserRouter>,
)
