import { proto3 } from '@bufbuild/protobuf'
import { Position, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'

import { DocumentSource } from '@/gen/inventory/v1/artifact_service_pb'

import { BaseNode, BaseNodeProps } from './base-node'
import { SelectValue } from './types'

type SourceNodeProps = BaseNodeProps & {
  data: {
    selectedSources: DocumentSource[]
  }
  id: string
}

export const SourceNode: React.FC<SourceNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Source Node'
      inputHandles={[
        {
          id: `source-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
      dataInputs={[
        {
          id: `source-${id}-selectedSources`,
          name: 'selectedSources',
          type: 'select',
          options: proto3.getEnumType(DocumentSource).values.map((value) => ({
            label: value.name,
            value: value.no,
          })),
          onChange: (value: SelectValue) => updateNodeData(id, { selectedSources: value }),
          value: data.selectedSources,
          placeholder: 'Select sources',
          mode: 'multiple',
        },
      ]}
      outputHandles={[
        {
          id: `source-${id}-matched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Matched',
          borderColor: 'green',
        },
        {
          id: `source-${id}-unmatched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Unmatched',
          borderColor: 'red',
        },
      ]}
    />
  )
})

SourceNode.displayName = 'SourceNode'
