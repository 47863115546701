import { PlainMessage } from '@bufbuild/protobuf'
import { DollarSignIcon, NewspaperIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { useGetCompanyCISOProfile, useUpdateCompany } from '@/api/company.hook'
import {
  FindingCountStat,
  PermissionCategory,
  CompanyProfile as ProtoCompanyProfile,
  Solution,
} from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { INACTIVE_COMPANY_STATUSES } from '@/const/company-statuses'
import { companyStatusLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { CompanyProfile } from '@/pages/company-drawer/company-profile'

import { SelectVerification } from '@/components/badge/third-party-status'
import { SelectCompanyRisk } from '@/components/icons/company-risk'
import { FindingStatusSymbol } from '@/components/icons/finding-status'
import { PermissionSymbol } from '@/components/icons/permission'
import { thirdPartyStatusToIcon } from '@/components/icons/third-party-status'
import { PermissionIndicatorList } from '@/components/indicator/permission'
import { LastChanged } from '@/components/last-changed'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { TextWithIcon } from '@/components/text-with-icon'
import { Alert } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

import { SolutionSelect } from './solution-select'

import ClockEditIcon from '@/assets/clock-edit.svg'

export interface ThirdPartyHeaderDetailsProps {
  companyId: string
  companyProfile: PlainMessage<ProtoCompanyProfile>
  description: string
  status: {
    status: CompanyStatus
    lastChanged?: Date
  }
  permissions: PermissionCategory[]
  spend: string
  solutions: PlainMessage<Solution>[]
  findingCount: FindingCountStat[]
  companyRisk: RiskLevel
  setSelectedSolution: (solution?: string) => void
  selectedSolution?: string
}

export const ThirdPartyDetailsHeader = ({
  companyProfile,
  findingCount,
  spend,
  status,
  permissions,
  solutions,
  companyRisk,
  companyId,
  selectedSolution,
  setSelectedSolution,
}: ThirdPartyHeaderDetailsProps) => {
  const { data: cisoProfile } = useGetCompanyCISOProfile(companyId)
  const { updateParam } = useUrlParams()

  const [showProfile, setShowProfile] = useState<boolean | undefined>(undefined)
  const { isEnabled: spendEnabled } = useFeatureFlagEnabled('spend')
  const { isEnabled: showCompanyProfileByDefault } = useFeatureFlagEnabled(
    'tpcompany-profile-open-by-default',
  )

  const isArchived = INACTIVE_COMPANY_STATUSES.indexOf(status.status) !== -1

  const { mutate: updateCompany } = useUpdateCompany(companyId)

  const trackShowProfile = useTrackCallback('third-party.profile.view')
  const trackStatusUpdate = useTrackCallback('third-party.status.update')

  useEffect(() => {
    // on first load state is undefined, use the feature flag instead as the default
    // take into account it takes a few cycles until the feature flag is loaded,
    // this is why we dont just use it as the default value
    if (showProfile === undefined) {
      setShowProfile(showCompanyProfileByDefault)
    }
  }, [showCompanyProfileByDefault, setShowProfile, showProfile])

  const selectSolutionEnabled = solutions.length > 1

  return (
    <>
      <div className='px-8'>
        <div className='mb-1 flex items-center'>
          <TextWithIcon
            className='gap-2'
            icon={
              <SelectCompanyRisk
                companyName={companyProfile.name}
                thirdPartyId={companyId}
                riskLevel={companyRisk}
              />
            }
            text={<h2 className='text-3xl font-semibold text-gray-700'>{companyProfile.name}</h2>}
          />
          <div className='flex w-full justify-between'>
            <div className='ml-2.5 flex items-center pt-2'>
              <SelectVerification
                onValueChange={(newStatus, comment) => {
                  trackStatusUpdate({
                    id: companyId,
                    status: companyStatusLabel[newStatus],
                    previousStatus: companyStatusLabel[status.status],
                    comment,
                    company: companyProfile.name,
                  })
                  updateCompany({
                    status: newStatus,
                    id: companyId,
                    comment: comment || undefined,
                  })
                }}
                verification={status.status}
              />
              {status.lastChanged && <LastChanged lastChanged={status.lastChanged} />}
            </div>
            <Button
              variant='ghost'
              onClick={() => updateParam(UrlParam.THIRD_PARTY_ACTIVITY_LOG_ID, companyId)}
            >
              <ClockEditIcon className='size-5' />
            </Button>
          </div>
        </div>
        {isArchived && (
          <Alert className='my-3' variant={'default'}>
            <TextWithIcon
              icon={thirdPartyStatusToIcon[status.status]}
              text={`Third-Party has been ${companyStatusLabel[status.status]}.`}
            />
          </Alert>
        )}
      </div>
      <div className='mb-4 mt-1 px-8'>
        <span className='mr-2'>{companyProfile.longDescription}</span>
        <Button
          className='ml-auto h-fit bg-transparent px-2 py-1 text-sm text-gray-700 shadow-none'
          variant='outline'
          size='sm'
          onClick={() => {
            if (showProfile != true) {
              trackShowProfile({ companyName: companyProfile.name })
            }
            setShowProfile(!showProfile)
          }}
        >
          <NewspaperIcon size={10} className='mr-1 inline' />
          {showProfile ? 'Hide Profile' : 'Show Profile'}
        </Button>
      </div>
      <div className={cn('mb-4 py-4', showProfile ? '' : 'hidden')}>
        <Separator />
        <div className='px-8 pb-4'>
          <CompanyProfile info={companyProfile} ciso={cisoProfile} />
        </div>
        <Separator />
      </div>
      <div className='flex max-w-5xl items-center gap-x-10 px-8 2xl:gap-x-20'>
        {selectSolutionEnabled && (
          <SolutionSelect
            setSelectedSolution={setSelectedSolution}
            selectedSolution={selectedSolution}
            solutions={solutions}
          />
        )}
        <StatisticsRow
          items={[
            {
              title: 'Solution',
              hide: selectSolutionEnabled,
              children: (
                <div className='max-w-60 truncate'>
                  {solutions.length ? solutions[0].name : companyProfile.name}
                </div>
              ),
            },
            {
              title: 'Findings',
              icon: <FindingStatusSymbol />,
              children: <FindingDistribution findingCountByLevel={findingCount} />,
            },
            {
              hide: !spendEnabled,
              title: 'Spend',
              icon: <DollarSignIcon className='w-2.5' />,
              children: <span className='text-lg'>{spend}</span>,
            },
            {
              title: 'Permissions',
              icon: <PermissionSymbol />,
              children: <PermissionIndicatorList permissions={permissions} />,
            },
          ]}
        />
      </div>
    </>
  )
}
