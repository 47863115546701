import { Input } from 'antd'
import { cva } from 'class-variance-authority'
import { ArrowLeftIcon, SearchIcon } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useIntegrations } from '@/api/get-integrations.hook'
import { Integration, IntegrationStatus } from '@/gen/inventory/v1/integration_service_pb'

import { categoryLabel } from '@/const/label'
import { integrationStatusPriority } from '@/const/priority'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { Badge } from '@/components/ui/badge'

export const IntegrationsMarketplace = () => {
  const [filterText, setFilterText] = useState('')
  const { isEnabled: logicmanagerEnabled } = useFeatureFlagEnabled('logicmanager-integration')
  const { isEnabled: wizEnabled } = useFeatureFlagEnabled('wiz-integration')
  const { data } = useIntegrations()
  return (
    <div className='mt-5 flex justify-center px-8'>
      <div className='w-full max-w-4xl'>
        <Link
          to={'/settings?settingSection=integrations'}
          className='mb-2 flex cursor-pointer items-center gap-1 text-purple-600'
        >
          <ArrowLeftIcon size={14} /> <span> Back to Integrations</span>
        </Link>
        <h2 className='mb-6 text-3xl font-bold'>Integrations Marketplace</h2>

        <Input
          onChange={(e) => setFilterText(e.target.value.toLocaleLowerCase())}
          placeholder='Search...'
          className='mb-4 rounded border-gray-200 py-2.5'
          prefix={<SearchIcon className='h-4 text-gray-400' />}
        />

        <div className='flex w-full flex-wrap gap-4'>
          {data
            .filter(({ name }) => name.toLocaleLowerCase().includes(filterText))
            .filter(
              ({ name }) =>
                !name.toLocaleLowerCase().includes('logicmanager') || logicmanagerEnabled,
            )
            .filter(({ name }) => !name.toLocaleLowerCase().includes('wiz') || wizEnabled)
            .sort(
              (a, b) =>
                integrationStatusPriority[a.status || IntegrationStatus.UNSPECIFIED] -
                integrationStatusPriority[b.status || IntegrationStatus.UNSPECIFIED],
            )
            .map((props) => (
              <IntegrationItem key={props.id} {...props} />
            ))}
        </div>
      </div>
    </div>
  )
}

const integrationItemVariants = cva('flex h-24 w-72 flex-col rounded border', {
  variants: {
    enabled: {
      true: 'cursor-pointer transition duration-300 ease-in-out hover:bg-gray-50',
      false: 'cursor-not-allowed opacity-50',
    },
  },
})

type IntegrationItemProps = Pick<Integration, 'name' | 'category' | 'iconUrl' | 'status' | 'id'>
export const IntegrationItem = ({ id, name, category, iconUrl, status }: IntegrationItemProps) => {
  return (
    <Link
      to={status === IntegrationStatus.UNAVAILABLE ? '' : `/integrations/${id}`}
      className={integrationItemVariants({ enabled: status !== IntegrationStatus.UNAVAILABLE })}
    >
      <StatusBadge status={status || IntegrationStatus.UNSPECIFIED} />
      <div className='flex items-center gap-3 rounded px-6'>
        <img className='size-9' alt={name} src={iconUrl} />
        <div className='flex flex-col items-start gap-1'>
          <h3 className='font-semibold'>{name}</h3>
          <Badge className='rounded' variant={'outline'}>
            {categoryLabel[category]}
          </Badge>
        </div>
      </div>
    </Link>
  )
}

const statusLabel: Record<IntegrationStatus, string> = {
  [IntegrationStatus.AVAILABLE]: '',
  [IntegrationStatus.UNAVAILABLE]: 'Coming Soon',
  [IntegrationStatus.ADDED]: 'Added',
  [IntegrationStatus.UNSPECIFIED]: '',
}

const statusVariants = cva('h-6 w-24 self-end rounded-bl rounded-tr text-center', {
  variants: {
    status: {
      [IntegrationStatus.ADDED]: 'bg-green-100 text-green-500',
      [IntegrationStatus.UNAVAILABLE]: 'bg-gray-100 text-gray-500',
      [IntegrationStatus.AVAILABLE]: '',
      [IntegrationStatus.UNSPECIFIED]: '',
    },
  },
})

const StatusBadge = ({ status }: { status: IntegrationStatus }) => (
  <div className={statusVariants({ status })}>{statusLabel[status]}</div>
)
