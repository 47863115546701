import { ReactNode } from 'react'

export interface StatisticItem {
  title: string
  icon?: ReactNode
  children: ReactNode
  hide?: boolean
}

export interface StatisticsRowProps {
  items: StatisticItem[]
}

export const StatisticsRow = ({ items }: StatisticsRowProps) => {
  return (
    <div className='grid w-full grid-flow-col grid-rows-2 items-center gap-x-4 2xl:gap-x-8'>
      {items
        .filter(({ hide }) => !hide)
        .map(({ title, icon, children }: StatisticItem, index) => {
          return [
            <div key={`header-${index}`} className='flex items-center gap-1'>
              {icon}
              <h3 className='text-xs font-bold uppercase tracking-wide'>{title}</h3>
            </div>,
            <div key={`value-${index}`}>{children}</div>,
          ]
        })
        .flat()}
    </div>
  )
}
