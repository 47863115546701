import _ from 'lodash'
import { CheckIcon, DownloadIcon, XIcon } from 'lucide-react'
import { useMemo, useState } from 'react'

import { useGetSoc2Activities } from '@/api/get-soc2-assessment.hook'
import {
  SOC2Activity,
  SOC2ActivityImplementor,
  SOC2Scope,
} from '@/gen/inventory/v1/artifact_service_pb'

import { soc2ScopeLabel } from '@/const/label'

import { SOC2_SCOPES } from '@/lib/proto-types'

import { ActivityTable } from '@/pages/company-drawer/artifact/activity-table'
import { EmptySoc2Activities } from '@/pages/company-drawer/artifact/empty-soc2-activities'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

interface Soc2SectionProps {
  companyId: string
  companyName: string
}

export const Soc2Section = ({ companyId, companyName }: Soc2SectionProps) => {
  const [showExceptionsOnly, setShowExceptionsOnly] = useState(false)
  const { data: activities } = useGetSoc2Activities(companyId)

  const coveredScopes = useMemo(
    () => _.uniq(activities.flatMap(({ controls }) => controls.map((c) => c.scope))),
    [activities],
  )

  const userComplementaryActivities = useMemo(
    () =>
      activities.filter(
        ({ implementor }) => implementor === SOC2ActivityImplementor.SOC2_ACTIVITY_IMPLEMENTOR_USER,
      ),
    [activities],
  )

  const displayedActivities = useMemo(
    () =>
      activities.filter(
        ({ implementor, result }) =>
          implementor !== SOC2ActivityImplementor.SOC2_ACTIVITY_IMPLEMENTOR_USER &&
          (!showExceptionsOnly || result),
      ) as SOC2Activity[],
    [activities, showExceptionsOnly],
  )

  if (activities.length === 0) {
    return <EmptySoc2Activities companyName={companyName} companyId={companyId} />
  }

  return (
    <div>
      <div className='flex justify-between'>
        <h3 className='mb-3 text-xl font-light'>Scopes</h3>
        <Button variant='secondary' className='h-6 border border-gray-200'>
          <TextWithIcon
            className='text-xs text-gray-400'
            text='Download SOC2 Type II Report'
            icon={<DownloadIcon size={11} />}
          />
        </Button>
      </div>
      <div className='flex flex-col gap-2'>
        {SOC2_SCOPES.map(({ no }) => (
          <ScopeStatus key={no} scope={no} coveredScopes={coveredScopes} />
        ))}
      </div>

      {userComplementaryActivities.length > 0 && (
        <>
          <h3 className='mb-3 mt-11 text-xl font-light'>Complementary User Controls</h3>
          <ul className='ml-5 max-w-screen-sm list-outside list-disc'>
            {userComplementaryActivities.map((activity, i) => (
              <ComplementaryUserControlListItem
                key={i}
                controlIds={activity.controls.map((c) => c.id)}
                details={activity.details}
              />
            ))}
          </ul>
        </>
      )}
      <h3 className='mb-6 mt-11 text-xl font-light'>
        Controls & Management Responses to Exceptions
      </h3>
      <ActivityTable
        setShowExceptionsOnly={setShowExceptionsOnly}
        showExceptionsOnly={showExceptionsOnly}
        dataSource={displayedActivities}
      />
    </div>
  )
}

type ScopeStatusProps = {
  coveredScopes: (SOC2Scope | undefined)[]
  scope: SOC2Scope
}

const ScopeStatus = ({ scope, coveredScopes }: ScopeStatusProps) => {
  return (
    <div className='flex items-center gap-2'>
      <span>
        {coveredScopes.includes(scope) ? (
          <CheckIcon className='text-green-500' size={16} />
        ) : (
          <XIcon className='text-gray-400' size={16} />
        )}
      </span>
      <span>{soc2ScopeLabel[scope]}</span>
    </div>
  )
}

type ComplementaryUserControlListItemProps = {
  controlIds: string[]
  details: string
}

const ComplementaryUserControlListItem = ({
  controlIds,
  details,
}: ComplementaryUserControlListItemProps) => (
  <li className='my-2'>
    <span className='font-semibold'>{controlIds.join(', ')}</span>: <span>{details}</span>
  </li>
)
