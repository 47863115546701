import { PlusIcon } from 'lucide-react'

import { useUploadDocumentsModal } from '@/pages/settings-page/artifacts-section/use-upload-files-modal'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button, ButtonProps } from '@/components/ui/button'

type CompanyArtifactUploadButtonProps = {
  companyId: string
  companyName: string
} & ButtonProps

export const CompanyArtifactUploadButton = ({
  companyId,
  companyName,
  ...remainingProps
}: CompanyArtifactUploadButtonProps) => {
  const { showModal, renderModal } = useUploadDocumentsModal({
    metadata: { company: { id: companyId, name: companyName } },
  })
  return (
    <>
      <Button className='w-fit self-center' onClick={showModal} {...remainingProps}>
        <TextWithIcon
          icon={<PlusIcon strokeWidth={1.5} className='size-3.5' />}
          text={'Upload Artifacts'}
        />
      </Button>
      {renderModal()}
    </>
  )
}
