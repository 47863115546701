import { Timestamp } from '@bufbuild/protobuf'
import { Steps as AntdSteps, Modal } from 'antd'
import { ReactElement, useState } from 'react'

import { useCreateQuestionnaire } from '@/api/create-questionnaire.hook'

import { useTrackCallback } from '@/lib/analytics/events'
import { useReportErrorsCallback } from '@/lib/error-reporting'

import { NewQuestionnaireContext } from '@/pages/questionnaire-management/new-questionnaire-modal/context'
import { Footer } from '@/pages/questionnaire-management/new-questionnaire-modal/footer'
import { RecipientsStep } from '@/pages/questionnaire-management/new-questionnaire-modal/step-1-recipients'
import { TemplateSelectionStep } from '@/pages/questionnaire-management/new-questionnaire-modal/step-2-templates'
import { SectionsStep } from '@/pages/questionnaire-management/new-questionnaire-modal/step-3-sections'
import { InviteStep } from '@/pages/questionnaire-management/new-questionnaire-modal/step-4-invite'

import { useToast } from '@/components/ui/use-toast'

export type StepProps = {
  setIsNextEnabled: (isNextEnabled: boolean) => void
}

type Step = {
  title: string
  component: ReactElement<StepProps>
}

const twoWeeks = 1000 * 60 * 60 * 24 * 14

export const useNewQuestionnaireModal = () => {
  const track = useTrackCallback('questionnaire.new')
  const reportErrors = useReportErrorsCallback()
  const { toast } = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [isNextEnabled, setIsNextEnabled] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const { mutateAsync, isLoading } = useCreateQuestionnaire()
  const [isSubmitted, setIsSubmitted] = useState(false)

  // state to be used across all steps and accessed by context
  const [companyId, setCompanyId] = useState<string>('')
  const [dueDate, setDueDate] = useState<Date>(new Date(new Date().getTime() + twoWeeks))
  const [selectedTemplate, setSelectedTemplate] = useState<string>('')
  const [selectedSections, setSelectedSections] = useState<string[]>([])
  const [recipients, setRecipients] = useState<string[]>([])

  // used in the last step
  const [magicLinks, setMagicLinks] = useState<{ label: string; magicLink: string }[]>([])

  const showModal = () => setIsOpen(true)

  const steps: Step[] = [
    {
      title: 'Recipients',
      component: <RecipientsStep setIsNextEnabled={setIsNextEnabled} />,
    },
    {
      title: 'Template',
      component: <TemplateSelectionStep setIsNextEnabled={setIsNextEnabled} />,
    },
    {
      title: 'Sections & Collaborators',
      component: <SectionsStep />,
    },
  ]

  const closeModal = () => {
    setIsSubmitted(false)
    setCompanyId('')
    setDueDate(new Date(new Date().getTime() + twoWeeks))
    setSelectedTemplate('')
    setSelectedSections([])
    setRecipients([])
    setCurrentStep(0)
    setIsOpen(false)
  }

  const onSubmit = async () => {
    const analyticsProps = {
      step: steps[currentStep].title,
      selectedSections: selectedSections,
      recipients: recipients,
      dueDate: dueDate,
      companyId: companyId,
    }
    // first submit will create the questionnaire
    // then the user sees the magic links
    // second submit will close the modal
    if (isSubmitted) {
      closeModal()
      track(analyticsProps)
      return // To avoid creating another questionnaire
    }

    try {
      const res = await mutateAsync({
        companyId: companyId,
        templateId: selectedTemplate,
        dueDate: Timestamp.fromDate(dueDate),
        selectedSections: selectedSections,
        recipients: recipients,
      })
      setMagicLinks(
        res.links.map(({ magicLink, recipient }) => ({ label: recipient, magicLink: magicLink })),
      )
      track({ isSuccess: true, questionnaireId: res.questionnaireId, ...analyticsProps })
      toast({
        status: 'success',
        title: 'New questionnaire created successfully!',
      })
    } catch (e) {
      reportErrors(e as Error)
      toast({
        status: 'error',
        title: 'Failed to create new questionnaire',
      })
      track({ isSuccess: false, ...analyticsProps })
    }
    setIsSubmitted(true)
  }

  const renderModal = () => (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      width={670}
      styles={{
        content: { padding: 0 }, // since we have colored header, we don't need padding
      }}
      open={isOpen}
      onCancel={() => closeModal()}
      footer={
        <Footer
          step={currentStep}
          stepCount={steps.length}
          setStep={setCurrentStep}
          isNextEnabled={isNextEnabled}
          isPrevEnabled={!isSubmitted}
          isLoading={isLoading}
          onCancel={closeModal}
          onFinish={onSubmit}
        />
      }
    >
      <NewQuestionnaireContext.Provider
        value={{
          selectedTemplateId: selectedTemplate,
          setSelectedTemplateId: setSelectedTemplate,
          dueDate,
          setDueDate,
          selectedThirdPartyId: companyId,
          setSelectedThirdPartyId: setCompanyId,
          selectedSections,
          setSelectedSections,
          recipients,
          setRecipients,
        }}
      >
        <div>
          <div className='w-full rounded-t bg-gray-50 px-20 pt-10'>
            <h1 className='text-3xl font-bold'>New Questionnaire</h1>
            {!isSubmitted && (
              <AntdSteps className='py-8' size='small' current={currentStep} items={steps} />
            )}
          </div>
          <div className='px-20'>
            {!isSubmitted && steps[currentStep].component}
            {isSubmitted && <InviteStep invitees={magicLinks} title='Invite Recipients' />}
          </div>
        </div>
      </NewQuestionnaireContext.Provider>
    </Modal>
  )

  return { showModal, renderModal }
}
