import { TransportProvider } from '@connectrpc/connect-query'
import { AuthProvider, useDescope, useSession, useUser } from '@descope/react-sdk'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { useTrackUser } from '@/lib/analytics/track-user.hook'
import { useOrgIdFromToken } from '@/lib/auth/orgid.hook'
import { getConnectTransport } from '@/lib/connect-transport'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { DashboardPage } from '@/pages/dashboard/dashboard-page'
import { ControlDrawer } from '@/pages/drawers/control-drawer'
import { FindingDrawer } from '@/pages/drawers/finding-drawer'
import { IrqDrawer } from '@/pages/drawers/irq-drawer/irq-drawer'
import { ThirdPartyActivityLogDrawer } from '@/pages/drawers/third-party-activity-log-drawer'
import { ThirdPartyDrawer } from '@/pages/drawers/third-party-drawer'
import { FindingInventory } from '@/pages/finding-inventory/finding-inventory'
import { LoginPage } from '@/pages/login-page'
import { QuestionnaireManagementPage } from '@/pages/questionnaire-management/questionnaire-management-page'
import { QuestionnaireTemplate } from '@/pages/questionnaire-template/questionnaire-template'
import { AssessorQuestionnaire } from '@/pages/questionnaire/assessor-questionnaire'
import { RecipientQuestionnaire } from '@/pages/questionnaire/recipient-questionnaire'
import { RecipeEditorPage } from '@/pages/recipe-editor/recipe-editor-page'
import { IntegrationSetupPage } from '@/pages/settings-page/integration-section/integration-setup'
import { IntegrationsMarketplace } from '@/pages/settings-page/integration-section/integrations-marketplace'
import { SettingsPage } from '@/pages/settings-page/settings-page'
import { ThirdPartyInventoryPage } from '@/pages/third-party-inventory/inventory-page'

import { Tab } from '@/components/navigation-bar/link-tabs'
import { NavigationBar } from '@/components/navigation-bar/navigation-bar'

const descopeProjectID = import.meta.env.VITE_DESCOPE_PROJECTID
const descopeBaseUrl = import.meta.env.VITE_DESCOPE_BASE_URL
const googleClientId = import.meta.env.VITE_GOOGLE_DRIVE_WEB_CLIENT_ID
const env = import.meta.env.VITE_DD_ENV

export const App = () => {
  const { isEnabled: findingInventoryEnabled } = useFeatureFlagEnabled('finding-inventory')
  const { isEnabled: questionnaireModuleEnabled } = useFeatureFlagEnabled('questionnaire-module')
  const { isEnabled: dashboardEnabled } = useFeatureFlagEnabled('dashboard')
  const lemaAppTabs: Tab[] = [
    {
      link: '/dashboard',
      title: 'Dashboard',
      component: <DashboardPage />,
      isVisible: dashboardEnabled,
    },
    {
      link: '/inventory',
      title: 'Inventory',
      component: <ThirdPartyInventoryPage />,
    },
    {
      link: '/findings',
      title: 'Findings',
      component: <FindingInventory />,
      isDisabled: !findingInventoryEnabled,
    },
    {
      link: '/questionnaires',
      title: 'Questionnaires',
      component: <QuestionnaireManagementPage />,
      isVisible: questionnaireModuleEnabled,
    },
  ]

  return (
    <div className='min-w-fit'>
      <Routes>
        <Route
          key={'lema-app'}
          element={
            <LemaAppAuthProviders>
              <LemaAppAuthTrackProvider>
                <>
                  <SupportUserBanner />
                  <Outlet />
                </>
              </LemaAppAuthTrackProvider>
            </LemaAppAuthProviders>
          }
        >
          <Route
            key={'login'}
            path='/login'
            element={<LoginPage authenticatedPath='/inventory' />}
          />
          <Route
            key={'lema-app-authenticated'}
            element={
              <>
                <RedirectToLogin loginPath='/login' />
                <NavigationBar tabs={lemaAppTabs} />
                <Outlet />
                <FindingDrawer />
                <ControlDrawer />
                <ThirdPartyDrawer />
                <ThirdPartyActivityLogDrawer />
                <IrqDrawer />
              </>
            }
          >
            {lemaAppTabs.map((tab) => (
              <Route key={tab.link} path={tab.link} element={tab.component} />
            ))}
            <Route key={'settings'} path={'/settings'} element={<SettingsPage />} />
            {env !== 'production' && (
              <Route key={'recipe'} path={'/recipe'} element={<RecipeEditorPage />} />
            )}
            <Route
              key={'integrations-marketplace'}
              path='/integrations/marketplace'
              element={<IntegrationsMarketplace />}
            />
            <Route
              key={'integrations'}
              path='integrations/:integrationId'
              element={<IntegrationSetupPage />}
            />
            <Route
              key={'template'}
              path='questionnaires/templates/:templateId'
              element={<QuestionnaireTemplate />}
            />
            <Route
              key={'questionnaires-room'}
              path='questionnaires/:questionnaireId'
              element={<AssessorQuestionnaire />}
            />
          </Route>
        </Route>
        <Route
          key={'questionnaire'}
          path='/q/*'
          element={
            <TransportProvider transport={getConnectTransport({})}>
              <RecipientQuestionnaire />
            </TransportProvider>
          }
        />

        {/* Catch-all route - redirect all unmatched routes to /inventory */}
        <Route path='*' element={<Navigate to='/inventory' replace />} />
      </Routes>
    </div>
  )
}

const RedirectToLogin = ({ loginPath }: { loginPath: string }) => {
  const location = useLocation()
  const { isAuthenticated, isSessionLoading } = useSession()
  if (location.pathname === loginPath) {
    return null
  }
  if (!isAuthenticated && !isSessionLoading) {
    return (
      <Navigate
        to={loginPath}
        state={{
          from: { pathname: location.pathname, search: location.search, hash: location.hash },
        }}
      />
    )
  }

  return null
}

const LemaAppAuthProviders = ({ children }: { children: React.ReactElement }) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <AuthProvider projectId={descopeProjectID} baseUrl={descopeBaseUrl}>
        {children}
      </AuthProvider>
    </GoogleOAuthProvider>
  )
}

const LemaAppAuthTrackProvider = ({ children }: { children: React.ReactElement }) => {
  const { user } = useUser()
  const { sessionToken, isAuthenticated } = useSession()
  const { selectTenant } = useDescope()

  useEffect(() => {
    const selectCurrentTenant = function () {
      if (!sessionToken || !isAuthenticated) {
        return
      }

      const { dct } = jwtDecode<{ dct?: string }>(sessionToken)
      if (!dct && user && user.userTenants && user.userTenants.length > 0) {
        const tenants = user.userTenants.sort((a, b) => a.tenantId.localeCompare(b.tenantId))
        selectTenant(tenants[0].tenantId)
      }
    }

    try {
      // wrapped in a function to make sure uesEffect never returns an object (will try to use it as a cleanup function)
      selectCurrentTenant()
    } catch (e) {
      console.warn('Error selecting tenant', e)
    }
  }, [isAuthenticated, selectTenant, sessionToken, user])

  const orgId = useOrgIdFromToken(user, sessionToken)
  useTrackUser(user, orgId || '')

  return children
}

const SupportUserBanner = () => {
  const { user } = useUser()

  if (user && user.roleNames?.includes('Support User')) {
    return (
      <div className='fixed bottom-0 z-max flex h-12 w-full items-center justify-center border-t-2 border-red-500 bg-red-50 text-center font-bold text-red-500'>
        Support Portal
      </div>
    )
  }
  return null
}
