import pluralize from 'pluralize'
import { useContext, useEffect } from 'react'

import { useGetQuestionnaireTemplate } from '@/api/questionnaire-template.hook'

import { cn } from '@/lib/style-helpers'

import { NewQuestionnaireContext } from '@/pages/questionnaire-management/new-questionnaire-modal/context'
import { StepProps } from '@/pages/questionnaire-management/new-questionnaire-modal/use-new-questionnaire-modal.hook'

import { Loading } from '@/components/loading'
import { Checkbox } from '@/components/ui/checkbox'

export type SectionsStepProps = StepProps

export const SectionsStep = () => {
  const { selectedTemplateId, selectedSections, setSelectedSections } =
    useContext(NewQuestionnaireContext)
  const { data: template, isLoading } = useGetQuestionnaireTemplate({
    templateId: selectedTemplateId!,
  })

  useEffect(() => {
    if (isLoading || selectedSections.length !== 0) {
      return
    }
    setSelectedSections(template?.sections.map((section) => section.name) || [])
  }, [isLoading, selectedSections.length, setSelectedSections, template?.sections])

  const onCheckedChange = (checked: boolean, sectionName: string) => {
    if (checked && !selectedSections.includes(sectionName)) {
      setSelectedSections([...selectedSections, sectionName])
    }
    if (!checked) {
      setSelectedSections(selectedSections.filter((s) => s !== sectionName))
    }
  }

  const isChecked = (sectionName: string) => selectedSections.includes(sectionName)

  if (isLoading) return <Loading />

  return (
    <div className='mt-4 flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <h3 className='text-lg font-semibold'>
          Configure the Questionnaire & Assign Internal Collaborators
        </h3>
        <p>
          Tailor the questionnaire to suit this third-party relationship & assign each section to an
          internal owner.
        </p>
      </div>

      <div className='flex flex-col gap-2'>
        {template?.sections.map((section) => (
          <div
            key={section.name}
            className={cn('flex items-center gap-2', !isChecked(section.name) && 'text-gray-400')}
          >
            <Checkbox
              key={section.name}
              defaultChecked
              className={cn(!isChecked(section.name) && 'border-gray-400')}
              onCheckedChange={(checked) => {
                onCheckedChange(checked === true, section.name)
              }}
            />
            <div className='flex w-full flex-row'>
              <span className='grow'>{section.name}</span>
              <span>{pluralize('Question', section.questionCount, true)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
