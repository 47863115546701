// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/integration_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.IntegrationStatus
 */
export enum IntegrationStatus {
  /**
   * @generated from enum value: INTEGRATION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INTEGRATION_STATUS_AVAILABLE = 1;
   */
  AVAILABLE = 1,

  /**
   * Usually means "comming soon"
   *
   * @generated from enum value: INTEGRATION_STATUS_UNAVAILABLE = 2;
   */
  UNAVAILABLE = 2,

  /**
   * @generated from enum value: INTEGRATION_STATUS_ADDED = 3;
   */
  ADDED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IntegrationStatus)
proto3.util.setEnumType(IntegrationStatus, "inventory.v1.IntegrationStatus", [
  { no: 0, name: "INTEGRATION_STATUS_UNSPECIFIED" },
  { no: 1, name: "INTEGRATION_STATUS_AVAILABLE" },
  { no: 2, name: "INTEGRATION_STATUS_UNAVAILABLE" },
  { no: 3, name: "INTEGRATION_STATUS_ADDED" },
]);

/**
 * @generated from enum inventory.v1.IntegrationCategory
 */
export enum IntegrationCategory {
  /**
   * @generated from enum value: INTEGRATION_CATEGORY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_IDP = 1;
   */
  IDP = 1,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_PROCUREMENT = 2;
   */
  PROCUREMENT = 2,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_GRC = 3;
   */
  GRC = 3,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_CLOUD_INFRASTRUCTURE = 4;
   */
  CLOUD_INFRASTRUCTURE = 4,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_PAYMENT = 5;
   */
  PAYMENT = 5,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_SASE = 6;
   */
  SASE = 6,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_FILE_SHARING = 7;
   */
  FILE_SHARING = 7,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_SECURITY_STACK = 8;
   */
  SECURITY_STACK = 8,

  /**
   * @generated from enum value: INTEGRATION_CATEGORY_API_GATEWAY = 9;
   */
  API_GATEWAY = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(IntegrationCategory)
proto3.util.setEnumType(IntegrationCategory, "inventory.v1.IntegrationCategory", [
  { no: 0, name: "INTEGRATION_CATEGORY_UNSPECIFIED" },
  { no: 1, name: "INTEGRATION_CATEGORY_IDP" },
  { no: 2, name: "INTEGRATION_CATEGORY_PROCUREMENT" },
  { no: 3, name: "INTEGRATION_CATEGORY_GRC" },
  { no: 4, name: "INTEGRATION_CATEGORY_CLOUD_INFRASTRUCTURE" },
  { no: 5, name: "INTEGRATION_CATEGORY_PAYMENT" },
  { no: 6, name: "INTEGRATION_CATEGORY_SASE" },
  { no: 7, name: "INTEGRATION_CATEGORY_FILE_SHARING" },
  { no: 8, name: "INTEGRATION_CATEGORY_SECURITY_STACK" },
  { no: 9, name: "INTEGRATION_CATEGORY_API_GATEWAY" },
]);

/**
 * @generated from message inventory.v1.ListAllIntegrationsRequest
 */
export class ListAllIntegrationsRequest extends Message<ListAllIntegrationsRequest> {
  constructor(data?: PartialMessage<ListAllIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAllIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllIntegrationsRequest {
    return new ListAllIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllIntegrationsRequest {
    return new ListAllIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllIntegrationsRequest {
    return new ListAllIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllIntegrationsRequest | PlainMessage<ListAllIntegrationsRequest> | undefined, b: ListAllIntegrationsRequest | PlainMessage<ListAllIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(ListAllIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAllIntegrationsResponse
 */
export class ListAllIntegrationsResponse extends Message<ListAllIntegrationsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Integration integrations = 1;
   */
  integrations: Integration[] = [];

  constructor(data?: PartialMessage<ListAllIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAllIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrations", kind: "message", T: Integration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllIntegrationsResponse {
    return new ListAllIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllIntegrationsResponse {
    return new ListAllIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllIntegrationsResponse {
    return new ListAllIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllIntegrationsResponse | PlainMessage<ListAllIntegrationsResponse> | undefined, b: ListAllIntegrationsResponse | PlainMessage<ListAllIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(ListAllIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetIntegrationRequest
 */
export class GetIntegrationRequest extends Message<GetIntegrationRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = "";

  /**
   * @generated from field: string integration_id = 2;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIntegrationRequest {
    return new GetIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIntegrationRequest {
    return new GetIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIntegrationRequest {
    return new GetIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIntegrationRequest | PlainMessage<GetIntegrationRequest> | undefined, b: GetIntegrationRequest | PlainMessage<GetIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetIntegrationRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetIntegrationResponse
 */
export class GetIntegrationResponse extends Message<GetIntegrationResponse> {
  /**
   * @generated from field: inventory.v1.Integration integration = 1;
   */
  integration?: Integration;

  constructor(data?: PartialMessage<GetIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration", kind: "message", T: Integration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIntegrationResponse {
    return new GetIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIntegrationResponse {
    return new GetIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIntegrationResponse {
    return new GetIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetIntegrationResponse | PlainMessage<GetIntegrationResponse> | undefined, b: GetIntegrationResponse | PlainMessage<GetIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetIntegrationResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Integration
 */
export class Integration extends Message<Integration> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: inventory.v1.IntegrationCategory category = 4;
   */
  category = IntegrationCategory.UNSPECIFIED;

  /**
   * @generated from field: optional inventory.v1.IntegrationStatus status = 5;
   */
  status?: IntegrationStatus;

  /**
   * @generated from field: optional google.protobuf.Timestamp date_added = 6;
   */
  dateAdded?: Timestamp;

  /**
   * @generated from field: string description = 7;
   */
  description = "";

  /**
   * @generated from field: string documentation_url = 8;
   */
  documentationUrl = "";

  /**
   * @generated from field: repeated string data_collected = 9;
   */
  dataCollected: string[] = [];

  /**
   * @generated from field: bool is_unqiue = 10;
   */
  isUnqiue = false;

  constructor(data?: PartialMessage<Integration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Integration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category", kind: "enum", T: proto3.getEnumType(IntegrationCategory) },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(IntegrationStatus), opt: true },
    { no: 6, name: "date_added", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "documentation_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "data_collected", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "is_unqiue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Integration {
    return new Integration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Integration {
    return new Integration().fromJsonString(jsonString, options);
  }

  static equals(a: Integration | PlainMessage<Integration> | undefined, b: Integration | PlainMessage<Integration> | undefined): boolean {
    return proto3.util.equals(Integration, a, b);
  }
}

