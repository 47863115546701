// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/statistics_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { RiskLevel } from "./risk_pb.js";
import { CompanySummary } from "./company_summary_pb.js";

/**
 * @generated from message inventory.v1.DaysCompaniesInAssessmentRequiredRequest
 */
export class DaysCompaniesInAssessmentRequiredRequest extends Message<DaysCompaniesInAssessmentRequiredRequest> {
  constructor(data?: PartialMessage<DaysCompaniesInAssessmentRequiredRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DaysCompaniesInAssessmentRequiredRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DaysCompaniesInAssessmentRequiredRequest {
    return new DaysCompaniesInAssessmentRequiredRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DaysCompaniesInAssessmentRequiredRequest {
    return new DaysCompaniesInAssessmentRequiredRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DaysCompaniesInAssessmentRequiredRequest {
    return new DaysCompaniesInAssessmentRequiredRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DaysCompaniesInAssessmentRequiredRequest | PlainMessage<DaysCompaniesInAssessmentRequiredRequest> | undefined, b: DaysCompaniesInAssessmentRequiredRequest | PlainMessage<DaysCompaniesInAssessmentRequiredRequest> | undefined): boolean {
    return proto3.util.equals(DaysCompaniesInAssessmentRequiredRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.DaysCompaniesInAssessmentRequiredResponse
 */
export class DaysCompaniesInAssessmentRequiredResponse extends Message<DaysCompaniesInAssessmentRequiredResponse> {
  /**
   * @generated from field: repeated int32 days = 1;
   */
  days: number[] = [];

  constructor(data?: PartialMessage<DaysCompaniesInAssessmentRequiredResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DaysCompaniesInAssessmentRequiredResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "days", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DaysCompaniesInAssessmentRequiredResponse {
    return new DaysCompaniesInAssessmentRequiredResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DaysCompaniesInAssessmentRequiredResponse {
    return new DaysCompaniesInAssessmentRequiredResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DaysCompaniesInAssessmentRequiredResponse {
    return new DaysCompaniesInAssessmentRequiredResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DaysCompaniesInAssessmentRequiredResponse | PlainMessage<DaysCompaniesInAssessmentRequiredResponse> | undefined, b: DaysCompaniesInAssessmentRequiredResponse | PlainMessage<DaysCompaniesInAssessmentRequiredResponse> | undefined): boolean {
    return proto3.util.equals(DaysCompaniesInAssessmentRequiredResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCountCompaniesBySeverityRequest
 */
export class GetCountCompaniesBySeverityRequest extends Message<GetCountCompaniesBySeverityRequest> {
  /**
   * @generated from field: google.protobuf.Timestamp date = 1;
   */
  date?: Timestamp;

  constructor(data?: PartialMessage<GetCountCompaniesBySeverityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCountCompaniesBySeverityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCountCompaniesBySeverityRequest {
    return new GetCountCompaniesBySeverityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCountCompaniesBySeverityRequest {
    return new GetCountCompaniesBySeverityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCountCompaniesBySeverityRequest {
    return new GetCountCompaniesBySeverityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCountCompaniesBySeverityRequest | PlainMessage<GetCountCompaniesBySeverityRequest> | undefined, b: GetCountCompaniesBySeverityRequest | PlainMessage<GetCountCompaniesBySeverityRequest> | undefined): boolean {
    return proto3.util.equals(GetCountCompaniesBySeverityRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCountCompaniesBySeverityResponse
 */
export class GetCountCompaniesBySeverityResponse extends Message<GetCountCompaniesBySeverityResponse> {
  /**
   * @generated from field: repeated inventory.v1.CountBySeverity values = 1;
   */
  values: CountBySeverity[] = [];

  constructor(data?: PartialMessage<GetCountCompaniesBySeverityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCountCompaniesBySeverityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: CountBySeverity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCountCompaniesBySeverityResponse {
    return new GetCountCompaniesBySeverityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCountCompaniesBySeverityResponse {
    return new GetCountCompaniesBySeverityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCountCompaniesBySeverityResponse {
    return new GetCountCompaniesBySeverityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCountCompaniesBySeverityResponse | PlainMessage<GetCountCompaniesBySeverityResponse> | undefined, b: GetCountCompaniesBySeverityResponse | PlainMessage<GetCountCompaniesBySeverityResponse> | undefined): boolean {
    return proto3.util.equals(GetCountCompaniesBySeverityResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingsCountBySeverityTimeseriesRequest
 */
export class GetFindingsCountBySeverityTimeseriesRequest extends Message<GetFindingsCountBySeverityTimeseriesRequest> {
  constructor(data?: PartialMessage<GetFindingsCountBySeverityTimeseriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingsCountBySeverityTimeseriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingsCountBySeverityTimeseriesRequest {
    return new GetFindingsCountBySeverityTimeseriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingsCountBySeverityTimeseriesRequest {
    return new GetFindingsCountBySeverityTimeseriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingsCountBySeverityTimeseriesRequest {
    return new GetFindingsCountBySeverityTimeseriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingsCountBySeverityTimeseriesRequest | PlainMessage<GetFindingsCountBySeverityTimeseriesRequest> | undefined, b: GetFindingsCountBySeverityTimeseriesRequest | PlainMessage<GetFindingsCountBySeverityTimeseriesRequest> | undefined): boolean {
    return proto3.util.equals(GetFindingsCountBySeverityTimeseriesRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingsCountBySeverityTimeseriesResponse
 */
export class GetFindingsCountBySeverityTimeseriesResponse extends Message<GetFindingsCountBySeverityTimeseriesResponse> {
  /**
   * @generated from field: repeated inventory.v1.Timeseries timeseries = 1;
   */
  timeseries: Timeseries[] = [];

  constructor(data?: PartialMessage<GetFindingsCountBySeverityTimeseriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingsCountBySeverityTimeseriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timeseries", kind: "message", T: Timeseries, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingsCountBySeverityTimeseriesResponse {
    return new GetFindingsCountBySeverityTimeseriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingsCountBySeverityTimeseriesResponse {
    return new GetFindingsCountBySeverityTimeseriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingsCountBySeverityTimeseriesResponse {
    return new GetFindingsCountBySeverityTimeseriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingsCountBySeverityTimeseriesResponse | PlainMessage<GetFindingsCountBySeverityTimeseriesResponse> | undefined, b: GetFindingsCountBySeverityTimeseriesResponse | PlainMessage<GetFindingsCountBySeverityTimeseriesResponse> | undefined): boolean {
    return proto3.util.equals(GetFindingsCountBySeverityTimeseriesResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Timeseries
 */
export class Timeseries extends Message<Timeseries> {
  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: repeated inventory.v1.CountBySeverity values = 2;
   */
  values: CountBySeverity[] = [];

  constructor(data?: PartialMessage<Timeseries>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Timeseries";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "message", T: Timestamp },
    { no: 2, name: "values", kind: "message", T: CountBySeverity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Timeseries {
    return new Timeseries().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJsonString(jsonString, options);
  }

  static equals(a: Timeseries | PlainMessage<Timeseries> | undefined, b: Timeseries | PlainMessage<Timeseries> | undefined): boolean {
    return proto3.util.equals(Timeseries, a, b);
  }
}

/**
 * @generated from message inventory.v1.CountBySeverity
 */
export class CountBySeverity extends Message<CountBySeverity> {
  /**
   * @generated from field: inventory.v1.RiskLevel severity = 1;
   */
  severity = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: double total = 2;
   */
  total = 0;

  constructor(data?: PartialMessage<CountBySeverity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CountBySeverity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "severity", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 2, name: "total", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountBySeverity {
    return new CountBySeverity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountBySeverity {
    return new CountBySeverity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountBySeverity {
    return new CountBySeverity().fromJsonString(jsonString, options);
  }

  static equals(a: CountBySeverity | PlainMessage<CountBySeverity> | undefined, b: CountBySeverity | PlainMessage<CountBySeverity> | undefined): boolean {
    return proto3.util.equals(CountBySeverity, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompaniesWithMostFindingsRequest
 */
export class ListCompaniesWithMostFindingsRequest extends Message<ListCompaniesWithMostFindingsRequest> {
  /**
   * @generated from field: optional int32 limit = 1;
   */
  limit?: number;

  constructor(data?: PartialMessage<ListCompaniesWithMostFindingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompaniesWithMostFindingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompaniesWithMostFindingsRequest {
    return new ListCompaniesWithMostFindingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompaniesWithMostFindingsRequest {
    return new ListCompaniesWithMostFindingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompaniesWithMostFindingsRequest {
    return new ListCompaniesWithMostFindingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompaniesWithMostFindingsRequest | PlainMessage<ListCompaniesWithMostFindingsRequest> | undefined, b: ListCompaniesWithMostFindingsRequest | PlainMessage<ListCompaniesWithMostFindingsRequest> | undefined): boolean {
    return proto3.util.equals(ListCompaniesWithMostFindingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompaniesWithMostFindingsResponse
 */
export class ListCompaniesWithMostFindingsResponse extends Message<ListCompaniesWithMostFindingsResponse> {
  /**
   * @generated from field: repeated inventory.v1.CompanyWithFindings companies = 1;
   */
  companies: CompanyWithFindings[] = [];

  constructor(data?: PartialMessage<ListCompaniesWithMostFindingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompaniesWithMostFindingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "companies", kind: "message", T: CompanyWithFindings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompaniesWithMostFindingsResponse {
    return new ListCompaniesWithMostFindingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompaniesWithMostFindingsResponse {
    return new ListCompaniesWithMostFindingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompaniesWithMostFindingsResponse {
    return new ListCompaniesWithMostFindingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompaniesWithMostFindingsResponse | PlainMessage<ListCompaniesWithMostFindingsResponse> | undefined, b: ListCompaniesWithMostFindingsResponse | PlainMessage<ListCompaniesWithMostFindingsResponse> | undefined): boolean {
    return proto3.util.equals(ListCompaniesWithMostFindingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyWithFindings
 */
export class CompanyWithFindings extends Message<CompanyWithFindings> {
  /**
   * @generated from field: inventory.v1.CompanySummary company = 1;
   */
  company?: CompanySummary;

  /**
   * @generated from field: repeated inventory.v1.CountBySeverity values = 2;
   */
  values: CountBySeverity[] = [];

  constructor(data?: PartialMessage<CompanyWithFindings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyWithFindings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company", kind: "message", T: CompanySummary },
    { no: 2, name: "values", kind: "message", T: CountBySeverity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyWithFindings {
    return new CompanyWithFindings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyWithFindings {
    return new CompanyWithFindings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyWithFindings {
    return new CompanyWithFindings().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyWithFindings | PlainMessage<CompanyWithFindings> | undefined, b: CompanyWithFindings | PlainMessage<CompanyWithFindings> | undefined): boolean {
    return proto3.util.equals(CompanyWithFindings, a, b);
  }
}

