import * as ProgressPrimitive from '@radix-ui/react-progress'
import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/style-helpers'

import { Sentiment } from '@/components/variance-percentage/types'

const ProgressVariants = cva('size-full flex-1 rounded-full bg-gray-700 transition-all', {
  variants: {
    sentiment: {
      [Sentiment.NEGATIVE]: 'bg-red-200',
      [Sentiment.POSITIVE]: 'bg-green-200',
      [Sentiment.NEUTRAL]: 'bg-yellow-200',
    },
  },
})

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> &
    VariantProps<typeof ProgressVariants>
>(({ className, value, sentiment, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn('relative h-4 w-full overflow-hidden rounded-full bg-secondary', className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={ProgressVariants({ sentiment })}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
