import { useState } from 'react'

export type valueRender = (value: string) => React.ReactNode

export type FilteredState = Record<React.Key, { value: string[] | null; valueRender?: valueRender }>

export type FilterContext = {
  showFilterTags: boolean
  setShowFilterTags: (showFilterTags: boolean) => void
  filteredState: FilteredState
  setSelected: (
    filterKey: React.Key,
    value: string,
    selected: boolean,
    valueRender?: valueRender,
  ) => void
  toggleSelected: (filterKey: React.Key, value: string, valueRender?: valueRender) => void
  isSelected: (filterKey: React.Key, value: string) => boolean
  clearFilter: (filterKey: React.Key) => void
  clearFilters: () => void
}

export const useFilter = (): FilterContext => {
  const [showFilterTags, setShowFilterTags] = useState<boolean>(false)
  const [filteredState, setFilters] = useState<FilteredState>({})

  // Define functions to update filters
  const addFilter = (filterKey: React.Key, value: string, valueRender?: valueRender) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: {
        value: [...(prevFilters[filterKey]?.value || []), value],
        valueRender,
      },
    }))

  const removeFilter = (filterKey: React.Key, value: string) => {
    setFilters((prevFilters) => {
      const updatedValues =
        (prevFilters[filterKey] || []).value?.filter((item) => item !== value) || []
      const updatedFilters = { ...prevFilters }
      if (updatedValues?.length === 0) {
        delete updatedFilters[filterKey]
      } else {
        updatedFilters[filterKey].value = updatedValues
      }
      if (Object.keys(updatedFilters).length === 0) {
        setShowFilterTags(false)
      }
      return updatedFilters
    })
  }

  const setSelected = (
    filterKey: React.Key,
    value: string,
    selected: boolean,
    valueRender?: valueRender,
  ) => {
    if (selected) {
      addFilter(filterKey, value, valueRender)
    } else {
      removeFilter(filterKey, value)
    }
  }

  const isSelected = (filterKey: React.Key, value: string) => {
    return !!(filteredState[filterKey] || []).value?.includes(value)
  }

  const toggleSelected = (filterKey: React.Key, value: string, valueRender?: valueRender) => {
    setSelected(filterKey, value, !isSelected(filterKey, value), valueRender)
  }

  const clearFilter = (filterKey: React.Key) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, [filterKey]: { value: null } }
    })
  }

  const clearFilters = () => {
    setFilters({})
    setShowFilterTags(false)
  }

  return {
    filteredState,
    showFilterTags,
    setSelected,
    toggleSelected,
    isSelected,
    clearFilter,
    clearFilters,
    setShowFilterTags,
  }
}
