// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file questionnaire/v1/assessor_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AssessorGetParticipantsRequest, AssessorGetParticipantsResponse, AssessorInviteRecipientRequest, AssessorInviteRecipientResponse, FinalizeQuestionnaireRequest, FinalizeQuestionnaireResponse, GetQuestionnaireRequest, GetQuestionnaireResponse, GetTokenRequest, GetTokenResponse, ListQuestionnaireRequest, ListQuestionnaireResponse } from "./assessor_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "questionnaire.v1.AssessorService";

/**
 * @generated from service questionnaire.v1.AssessorService
 */
export const AssessorService = {
  typeName: "questionnaire.v1.AssessorService",
  methods: {
    /**
     * @generated from rpc questionnaire.v1.AssessorService.GetToken
     */
    getToken: {
      name: "GetToken",
      I: GetTokenRequest,
      O: GetTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.AssessorService.GetQuestionnaire
     */
    getQuestionnaire: {
      name: "GetQuestionnaire",
      I: GetQuestionnaireRequest,
      O: GetQuestionnaireResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.AssessorService.ListQuestionnaire
     */
    listQuestionnaire: {
      name: "ListQuestionnaire",
      I: ListQuestionnaireRequest,
      O: ListQuestionnaireResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.AssessorService.AssessorInviteRecipient
     */
    assessorInviteRecipient: {
      name: "AssessorInviteRecipient",
      I: AssessorInviteRecipientRequest,
      O: AssessorInviteRecipientResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.AssessorService.AssessorGetParticipants
     */
    assessorGetParticipants: {
      name: "AssessorGetParticipants",
      I: AssessorGetParticipantsRequest,
      O: AssessorGetParticipantsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.AssessorService.FinalizeQuestionnaire
     */
    finalizeQuestionnaire: {
      name: "FinalizeQuestionnaire",
      I: FinalizeQuestionnaireRequest,
      O: FinalizeQuestionnaireResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: AssessorService,});

/**
 * @generated from rpc questionnaire.v1.AssessorService.GetToken
 */
export const getToken: UnaryFunctionsWithHooks<GetTokenRequest, GetTokenResponse> = {   ...$queryService.getToken,  ...createUnaryHooks($queryService.getToken)};

/**
 * @generated from rpc questionnaire.v1.AssessorService.GetQuestionnaire
 */
export const getQuestionnaire: UnaryFunctionsWithHooks<GetQuestionnaireRequest, GetQuestionnaireResponse> = {   ...$queryService.getQuestionnaire,  ...createUnaryHooks($queryService.getQuestionnaire)};

/**
 * @generated from rpc questionnaire.v1.AssessorService.ListQuestionnaire
 */
export const listQuestionnaire: UnaryFunctionsWithHooks<ListQuestionnaireRequest, ListQuestionnaireResponse> = {   ...$queryService.listQuestionnaire,  ...createUnaryHooks($queryService.listQuestionnaire)};

/**
 * @generated from rpc questionnaire.v1.AssessorService.AssessorInviteRecipient
 */
export const assessorInviteRecipient: UnaryFunctionsWithHooks<AssessorInviteRecipientRequest, AssessorInviteRecipientResponse> = {   ...$queryService.assessorInviteRecipient,  ...createUnaryHooks($queryService.assessorInviteRecipient)};

/**
 * @generated from rpc questionnaire.v1.AssessorService.AssessorGetParticipants
 */
export const assessorGetParticipants: UnaryFunctionsWithHooks<AssessorGetParticipantsRequest, AssessorGetParticipantsResponse> = {   ...$queryService.assessorGetParticipants,  ...createUnaryHooks($queryService.assessorGetParticipants)};

/**
 * @generated from rpc questionnaire.v1.AssessorService.FinalizeQuestionnaire
 */
export const finalizeQuestionnaire: UnaryFunctionsWithHooks<FinalizeQuestionnaireRequest, FinalizeQuestionnaireResponse> = {   ...$queryService.finalizeQuestionnaire,  ...createUnaryHooks($queryService.finalizeQuestionnaire)};
