import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useSetNetskopeCredentials } from '@/api/credentials/netskope.hook'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

type NetskopeIntegrationProps = BaseIntegrationProps

type FormValues = { apiKey: string; tenantHost: string }

export const NetskopeIntegration = ({ integration }: NetskopeIntegrationProps) => {
  const {
    mutate: setNetskopeCredentials,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSetNetskopeCredentials()
  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Read access to Netskope Application Events']}
        />
        <DashedContainer>
          <Form
            onFinish={({ apiKey, tenantHost }: FormValues) => {
              setNetskopeCredentials({
                apiKey,
                tenantHost,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Credentials</h2>
            <Form.Item<FormValues>
              name='tenantHost'
              rules={[{ required: true, message: 'Tenant URL is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Tenant Host'
                type='text'
              />
            </Form.Item>
            <Form.Item<FormValues>
              name='apiKey'
              rules={[{ required: true, message: 'API Key is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='API Key'
                type='password'
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
