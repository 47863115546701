import pluralize from 'pluralize'

import { Anchor } from '@/components/anchor/anchor'

type Category = {
  category: string
  questionCount: number
}

type QuestionnaireAnchorProps = {
  categories: Category[]
}

export const QuestionnaireAnchor = ({ categories }: QuestionnaireAnchorProps) => {
  return (
    <div className='ml-14 w-44 overflow-x-hidden'>
      <Anchor
        items={categories.map(({ category, questionCount }) => ({
          id: category,
          title: (
            <div>
              <div className='w-36 overflow-hidden text-ellipsis text-sm'>{category}</div>
              <div className='text-xs'>{pluralize('Question', questionCount, true)}</div>
            </div>
          ),
        }))}
      />
    </div>
  )
}
