import { Position } from '@xyflow/react'
import React, { memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'

type PentestNodeProps = BaseNodeProps & {
  id: string
}

export const PentestNode: React.FC<PentestNodeProps> = memo((props) => {
  const { id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Pentest Node'
      inputHandles={[
        {
          id: `pentest-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
    />
  )
})

PentestNode.displayName = 'PentestNode'
