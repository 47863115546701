type ExternalDocumentationCalloutProps = {
  //
  title: string
  children?: React.ReactNode
}

/** ExternalDocumentationCallout is used for having a noticeable section in an integration form that can be used to inform the user of extra data  */
export const FormCallout = ({ title, children }: ExternalDocumentationCalloutProps) => {
  return (
    <div className='my-5 w-full rounded border border-dashed border-purple-200 bg-purple-200/10 p-4'>
      <h2 className='mb-5 text-xl font-light'>{title}</h2>
      {children}
    </div>
  )
}
