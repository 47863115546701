import { DocumentStatus } from '@/gen/inventory/v1/artifact_service_pb'
import { DataTypeSource } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { InherentRiskSetting } from '@/gen/inventory/v1/control_service_pb'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'
import { IntegrationStatus } from '@/gen/inventory/v1/integration_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

export const integrationStatusPriority: Record<IntegrationStatus, number> = {
  [IntegrationStatus.UNSPECIFIED]: 0,
  [IntegrationStatus.AVAILABLE]: 1,
  [IntegrationStatus.ADDED]: 2,
  [IntegrationStatus.UNAVAILABLE]: 3,
}

export const riskLevelPriority: Record<RiskLevel, number> = {
  [RiskLevel.UNSPECIFIED]: 0,
  [RiskLevel.CRITICAL]: 1,
  [RiskLevel.HIGH]: 2,
  [RiskLevel.MEDIUM]: 3,
  [RiskLevel.LOW]: 4,
}

export const companyStatusPriority: Record<CompanyStatus, number> = {
  [CompanyStatus.UNSPECIFIED]: 0,
  [CompanyStatus.IN_ASSESSMENT]: 1,
  [CompanyStatus.ASSESSMENT_REQUIRED]: 2,
  [CompanyStatus.RE_ASSESSMENT_REQUIRED]: 3,
  [CompanyStatus.ASSESSED]: 4,
  [CompanyStatus.UNSANCTIONED]: 5,
  [CompanyStatus.OFFBOARDED]: 6,
  [CompanyStatus.REJECTED]: 7,
  [CompanyStatus.ARCHIVED]: 8,
}

export const dataTypeSourcePriority: Record<DataTypeSource, number> = {
  [DataTypeSource.UNSPECIFIED]: 0,
  [DataTypeSource.UNDOCUMENTED_ACCESS]: 1,
  [DataTypeSource.DOCUMENTED_AND_ACCESSED]: 2,
  [DataTypeSource.DOCUMENTED_ONLY]: 3,
}

export const findingStatusPriority: Record<FindingStatus, number> = {
  [FindingStatus.UNSPECIFIED]: 0,
  [FindingStatus.OPEN]: 1,
  [FindingStatus.ONGOING]: 2,
  [FindingStatus.RESOLVED]: 3,
  [FindingStatus.ACCEPTED]: 4,
}

export const InherentRiskSettingPriority: Record<InherentRiskSetting, number> = {
  [InherentRiskSetting.UNSPECIFIED]: 0,
  [InherentRiskSetting.DISABLED]: 1,
  [InherentRiskSetting.CRITICAL_AND_ABOVE]: 2,
  [InherentRiskSetting.HIGH_AND_ABOVE]: 3,
  [InherentRiskSetting.MEDIUM_AND_ABOVE]: 4,
  [InherentRiskSetting.LOW_AND_ABOVE]: 5,
}

export const documentStatusPriority: Record<DocumentStatus, number> = {
  [DocumentStatus.UNSPECIFIED]: 0,
  [DocumentStatus.COMPLETE]: 1,
  [DocumentStatus.ANALYZING]: 2,
  [DocumentStatus.CLASSIFYING]: 3,
  [DocumentStatus.PENDING]: 4,
}

const getSortByPriority =
  <T extends number>(priority: Record<T, number>) =>
  (a: T, b: T) => {
    const priorityA = priority[a]
    const priorityB = priority[b]
    if (priorityA === priorityB) return 0
    if (priorityA === null) return -1
    if (priorityB === null) return 1
    return priorityA - priorityB
  }

export const sortDataTypeSource = getSortByPriority(dataTypeSourcePriority)
export const sortCompanyStatus = getSortByPriority(companyStatusPriority)
export const sortRiskLevel = getSortByPriority(riskLevelPriority)
