import { ThreadData } from '@liveblocks/client'
import { Composer as LiveblocksComposer, Thread } from '@liveblocks/react-comments'
import { CheckIcon, MessageCircleIcon, XIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { ReactNode, useMemo, useRef, useState } from 'react'

import {
  ParticipantRole,
  ThreadMetadata,
  useEditThreadMetadata,
  useThreads,
} from '@/lib/liveblocks.config'
import { useOutsideClick } from '@/lib/outside-click.hook'
import { cn } from '@/lib/style-helpers'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'

type QuestionThreadProps = {
  questionId: string
  readOnly?: boolean
  participantRole: ParticipantRole
}

export const QuestionThread = ({ questionId, participantRole, readOnly }: QuestionThreadProps) => {
  const commentRef = useRef(null)
  useOutsideClick(commentRef, () => setIsOpen(false), true)
  const [isOpen, setIsOpen] = useState(false)
  const { thread } = useGetThread(questionId)

  let hintMessage: ReactNode
  if (thread && thread.metadata.lastRoleToReply === participantRole) {
    const roleNeedToReply =
      thread.metadata.lastRoleToReply === 'assessor' ? 'third-party' : 'assessor'
    hintMessage = (
      <>
        <b>The ball is in the {roleNeedToReply}’s court!</b> Notify the {roleNeedToReply} to remind
        them to take action on this questionnaire.
      </>
    )
  }

  return (
    <div ref={commentRef} className='absolute flex -translate-y-1/2 items-center gap-2'>
      <div
        className={cn(
          'flex h-fit w-fit cursor-pointer items-center gap-1 rounded border bg-gray-200 bg-opacity-20 p-2 text-sm text-gray-400 duration-200 ease-in-out hover:bg-opacity-80',
          thread && {
            'border-yellow-200 bg-yellow-100 text-yellow-500':
              thread?.metadata.lastRoleToReply !== participantRole,
            'border-green-200 bg-green-100 text-green-500': thread.metadata.resolved,
          },
        )}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <MessageCircleIcon strokeWidth={1.5} size={12} /> {thread?.comments.length}
      </div>
      {isOpen && (
        <div className='mb-8 mr-8 flex min-w-96 flex-col gap-2 bg-white'>
          <div className='rounded shadow'>
            <div className='flex h-14 items-center justify-between border-b px-4 text-sm'>
              <span className='whitespace-nowrap'>
                {pluralize('Comment', thread?.comments.length || 0, true)}
              </span>
              <div className='flex items-center gap-4'>
                {thread && <ThreadResolveButton thread={thread} disabled={readOnly} />}
                <XIcon
                  className='cursor-pointer rounded p-0.5 text-gray-400 duration-200 ease-in-out hover:bg-gray-200'
                  size={18}
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </div>
            {thread && (
              <Thread
                className='max-h-56 overflow-y-auto'
                thread={thread}
                showReactions={false}
                showComposer={false}
                showResolveAction={false}
                showActions={!readOnly}
              />
            )}
          </div>
          <Composer
            participantRole={participantRole}
            questionId={questionId}
            threadId={thread?.id}
            disabled={readOnly}
            hint={hintMessage}
          />
        </div>
      )}
    </div>
  )
}

type ComposerProps = {
  questionId: string
  threadId: string | undefined
  participantRole: ParticipantRole
  hint?: ReactNode
  disabled?: boolean
}

const Composer = ({ questionId, threadId, hint, participantRole, disabled }: ComposerProps) => {
  const editThreadMetadata = useEditThreadMetadata()
  return (
    <div className='flex flex-col gap-3 rounded border'>
      {hint && <p className='px-4 pt-4 text-sm text-gray-400'>{hint}</p>}
      {threadId && (
        <LiveblocksComposer
          threadId={threadId}
          placeholder='Write a comment...'
          disabled={disabled}
          onSubmit={() =>
            editThreadMetadata({
              threadId: threadId,
              metadata: {
                lastRoleToReply: participantRole,
              },
            })
          }
        />
      )}
      {!threadId && (
        <LiveblocksComposer
          placeholder='Write a comment...'
          disabled={disabled}
          metadata={{
            resolved: false,
            createdAt: Date.now(),
            questionId: questionId,
            lastRoleToReply: participantRole,
          }}
        />
      )}
    </div>
  )
}

type ThreadType = ThreadData<ThreadMetadata>
const ThreadResolveButton = ({ thread, disabled }: { thread: ThreadType; disabled?: boolean }) => {
  const editThreadMetadata = useEditThreadMetadata()
  const message = useMemo(() => {
    if (!disabled) {
      return `Click to ${thread.metadata.resolved ? 'unresolve' : 'resolve'} this thread`
    }
    return `Cannot modify chat after the questionnaire is submitted`
  }, [thread.metadata.resolved, disabled])

  return (
    <Tooltip
      trigger={
        <Button
          disabled={disabled}
          onClick={() =>
            editThreadMetadata({
              threadId: thread.id,
              metadata: {
                ...thread.metadata,
                resolved: !thread.metadata.resolved,
              },
            })
          }
          variant={'ghost'}
        >
          {thread.metadata.resolved ? (
            <TextWithIcon
              text='Unresolve thread'
              icon={<XIcon strokeWidth={3} size={13} />}
              className='text-sm font-semibold text-yellow-500'
            />
          ) : (
            <TextWithIcon
              text='Resolve thread'
              icon={<CheckIcon strokeWidth={3} size={13} />}
              className='text-sm font-semibold text-green-500'
            />
          )}
        </Button>
      }
    >
      {message}
    </Tooltip>
  )
}

const useGetThread = (questionId: string) => {
  const { threads, isLoading } = useThreads()

  return useMemo(() => {
    if (isLoading) return { thread: null, isLoading }
    const thread = threads.find((thread) => thread.metadata.questionId === questionId)
    return { thread, isLoading }
  }, [threads, isLoading, questionId])
}
