import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  assessorInviteRecipient,
  finalizeQuestionnaire,
  getQuestionnaire,
  listQuestionnaire,
} from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'

export const useGetQuestionnaire = ({ questionnaireId }: { questionnaireId: string }) => {
  const queryState = useQuery(getQuestionnaire.useQuery({ id: questionnaireId }))
  return {
    ...queryState,
    questionnaire: queryState.data?.questionnaire,
  }
}

export const useListQuestionnaire = () => {
  const queryState = useQuery(listQuestionnaire.useQuery())

  return {
    ...queryState,
    questionnaires: queryState.data?.questionnaires || [],
  }
}

export const useAssessorInviteRecipient = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  return useMutation(assessorInviteRecipient.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
    },
  })
}

export const useMutateFinalizeQuestionnaire = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  return useMutation(finalizeQuestionnaire.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
    },
  })
}
