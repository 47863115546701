export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
    950: '#090E19',
  },
  red: {
    50: '#FEEAEC',
    100: '#FDC0C7',
    200: '#FC96A2',
    300: '#FB808F',
    400: '#F9566A',
    500: '#F82C45',
    600: '#DF283E',
    700: '#C62337',
    800: '#951A29',
    900: '#7C1623',
    950: '#63121C',
  },
  orange: {
    50: '#FFF2E6',
    100: '#FFDEBF',
    200: '#FFCA99',
    300: '#FFAF66',
    400: '#FF9533',
    500: '#FF7A00',
    600: '#CC6200',
    700: '#994900',
    800: '#663100',
    900: '#4C2500',
    950: '#331800',
  },
  yellow: {
    50: '#FFFCF0',
    100: '#FFEECC',
    200: '#FFDC99',
    300: '#FFCB66',
    400: '#FFB933',
    500: '#FFA800',
    600: '#CC8600',
    700: '#996500',
    800: '#664300',
    900: '#332200',
    950: '#191100',
  },
  green: {
    50: '#F0F9EC',
    100: '#E1F3D9',
    200: '#C4E7B3',
    300: '#A6DB8C',
    400: '#89CF66',
    500: '#6BC340',
    600: '#569C33',
    700: '#407526',
    800: '#2B4E1A',
    900: '#15270D',
    950: '#0B1306',
  },
  purple: {
    50: '#FBFAFF',
    100: '#EDE4FF',
    200: '#DAC8FE',
    300: '#CDB5FD',
    400: '#B590FC',
    500: '#9C6BFB',
    600: '#8346FA',
    700: '#6938C8',
    800: '#4F2A96',
    900: '#341C64',
    950: '#1A0E32',
  },
  blue: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#BAE6FD',
    300: '#7DD3FC',
    400: '#38BDF8',
    500: '#0EA5E9',
    600: '#0284C7',
    700: '#006CD8',
    800: '#075985',
    900: '#0C4A6E',
    950: '#062B3F',
  },
  transparent: 'transparent',
}
