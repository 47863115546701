import { Loader2Icon } from 'lucide-react'

import { Button } from '@/components/ui/button'

export type FooterProps = {
  step: number
  stepCount: number
  setStep: (step: number) => void
  isNextEnabled: boolean
  isPrevEnabled: boolean
  isLoading: boolean

  onCancel: () => void
  onFinish: () => Promise<void>
}

export const Footer = ({
  step,
  stepCount,
  setStep,
  isNextEnabled,
  isPrevEnabled,
  onCancel,
  onFinish,
  isLoading,
}: FooterProps) => {
  const isLastStep = step === stepCount - 1
  const isFirstStep = step === 0
  const prev = () => {
    if (!isPrevEnabled) return
    setStep(step - 1)
  }
  const next = async () => {
    if (isNextEnabled) {
      if (!isLastStep) {
        setStep(step + 1)
      } else {
        await onFinish()
      }
    }
  }
  return (
    <div className='flex items-end justify-between px-15 py-6'>
      <Button variant={'ghost'} onClick={onCancel}>
        Cancel
      </Button>
      <div className='flex gap-2'>
        {!isFirstStep && (
          <Button variant={'outline'} onClick={prev} disabled={!isPrevEnabled}>
            Back
          </Button>
        )}
        <Button disabled={!isNextEnabled || isLoading} onClick={next}>
          {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
          {isLastStep ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  )
}
