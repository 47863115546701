import { Position } from '@xyflow/react'
import React, { memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'

type NoOpNodeProps = BaseNodeProps & {
  id: string
}

export const NoOpNode: React.FC<NoOpNodeProps> = memo((props) => {
  const { id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='No Op Node'
      inputHandles={[
        {
          id: `no-op-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
    />
  )
})

NoOpNode.displayName = 'NoOpNode'
