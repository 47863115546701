import { VariantProps, cva } from 'class-variance-authority'

import { PermissionCategory } from '@/gen/inventory/v1/company_service_pb'

import { permissionDescription } from '@/const/description'
import { getPermissionLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'

import { PermissionIcon } from '@/components/icons/permission'
import { Tooltip } from '@/components/ui/tooltip'

const permissionVariants = cva('rounded-sm p-1 hover:bg-gray-200', {
  variants: {
    variant: {
      active: 'text-gray-500',
      faded: 'text-gray-200',
    },
  },
  defaultVariants: {
    variant: 'active',
  },
})

type PermissionIndicatorProps = {
  permission: PermissionCategory
} & VariantProps<typeof permissionVariants>

export const PermissionIndicator = ({ permission, variant }: PermissionIndicatorProps) => {
  const description = permissionDescription[permission]
  return (
    <Tooltip
      trigger={
        <div className={permissionVariants({ variant })}>
          <PermissionIcon permission={permission} />
        </div>
      }
    >
      <div className='max-w-xs px-3 py-1 text-center'>
        <div className={cn('font-semibold capitalize', { 'mb-2': description })}>
          {getPermissionLabel(permission)}
        </div>
        {description && <div className='text-wrap'>{description}</div>}
      </div>
    </Tooltip>
  )
}

type PermissionIndicatorListProps = {
  permissions: PermissionCategory[]
  /** toggle whether to display all permission indicators and highlight the active ones as specified in `permissions` or to just show the active ones while hiding the others  */
  showAllIndicators?: boolean
}

const allPermissionCategories = [
  PermissionCategory.DATA_ACCESS,
  PermissionCategory.IDENTITY_AND_ACCESS_MANAGEMENT,
  PermissionCategory.CONFIGURATION_PERMISSIONS,
  PermissionCategory.OPERATIONAL_ENVIRONMENT,
  PermissionCategory.DATA_MODIFICATION,
  PermissionCategory.EMPLOYEE_DATA_ACCESS,
  PermissionCategory.EMPLOYEE_DATA_MODIFICATION,
  PermissionCategory.CLOUD_ENVIRONMENT,
]

export const PermissionIndicatorList = ({
  permissions,
  showAllIndicators,
}: PermissionIndicatorListProps) => {
  const sortedUniquePermissions = [...new Set(permissions)]
    .filter((permission) => permission !== PermissionCategory.UNSPECIFIED)
    .sort((a, b) => allPermissionCategories.indexOf(a) - allPermissionCategories.indexOf(b))
  const categories = showAllIndicators ? allPermissionCategories : sortedUniquePermissions

  return (
    <div className='flex'>
      {categories.map((permission) => {
        const isActive = permissions.includes(permission)
        return (
          <PermissionIndicator
            key={permission}
            permission={permission}
            variant={isActive ? 'active' : 'faded'}
          />
        )
      })}
    </div>
  )
}
