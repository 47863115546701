import { DataTypeInfo, DataTypeSource } from '@/gen/inventory/v1/company_service_pb'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

export const useDatatypeClickCallback = () => {
  const { updateParam } = useUrlParams()
  return {
    action: (_event: React.MouseEvent, { source, findingId }: DataTypeInfo) => {
      if (findingId && source == DataTypeSource.UNDOCUMENTED_ACCESS) {
        updateParam(UrlParam.FINDING_ID, findingId.toString())
      }
    },
  }
}
