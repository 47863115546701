import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useSetWizCredentials } from '@/api/credentials/wiz.hook'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

type WizIntegrationProps = BaseIntegrationProps

type FormValues = { region: string; clientId: string; clientSecret: string }

export const WizIntegration = ({ integration }: WizIntegrationProps) => {
  const { mutate: setWizCredentials, isLoading, isSuccess, isError, error } = useSetWizCredentials()
  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Access to create Service Account in Wiz portal']}
        />
        <DashedContainer>
          <Form
            onFinish={({
              region: region,
              clientId: clientId,
              clientSecret: clientSecret,
            }: FormValues) => {
              setWizCredentials({
                region: region,
                clientId: clientId,
                clientSecret: clientSecret,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Credentials</h2>
            <Form.Item<FormValues>
              name='region'
              rules={[{ required: true, message: 'Tenant Data Center is required' }]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Tenant Data Center' />
            </Form.Item>
            <Form.Item<FormValues>
              name='clientId'
              rules={[{ required: true, message: 'Client ID is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Client ID'
                type='password'
              />
            </Form.Item>
            <Form.Item<FormValues>
              name='clientSecret'
              rules={[{ required: true, message: 'Client Secret is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Client Secret'
                type='password'
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
