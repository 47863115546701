import { Input } from 'antd'
import { PlusIcon, XIcon } from 'lucide-react'
import { useState } from 'react'

import { isEmailValid } from '@/lib/string-validators'
import { cn } from '@/lib/style-helpers'

import { Badge } from '@/components/ui/badge'
import { useToast } from '@/components/ui/use-toast'

export type InputRecipientProps = {
  onRecipientChange: (recipients: string[]) => void
  placeholder?: string
}

export const InputRecipient = ({
  onRecipientChange,
  placeholder = 'example@gmail.com',
}: InputRecipientProps) => {
  const [recipientInput, setRecipientInput] = useState('')
  const [recipients, setRecipients] = useState<string[]>([])
  const { toast } = useToast()

  const handleAddRecipient = () => {
    let recipient = recipientInput.trim()
    if (recipient.endsWith(',')) {
      recipient = recipient.slice(0, -1)
    }

    if (!isEmailValid(recipient)) {
      toast({
        status: 'error',
        title: 'Invalid email address',
      })
      return
    }

    if (recipients.includes(recipient)) {
      toast({
        status: 'error',
        title: 'Email address already added',
      })
      return
    }

    const newRecipients = [...recipients, recipient]
    setRecipients(newRecipients)
    onRecipientChange(newRecipients)
    setRecipientInput('')
  }

  const removeRecipient = (recipient: string) => {
    const r = recipients.filter((r) => r !== recipient)
    setRecipients(r)
    onRecipientChange(r)
  }

  return (
    <>
      <Input
        suffix={
          <SuffixButton
            onClick={handleAddRecipient}
            enabled={isEmailValid(recipientInput.trim())}
          />
        }
        className='h-10 w-full'
        placeholder={placeholder}
        value={recipientInput}
        onChange={(e) => setRecipientInput(e.currentTarget.value)}
        onKeyUp={(e) =>
          (e.key === ',' || e.key === ' ' || e.key === 'Enter') && handleAddRecipient()
        }
      />
      <div className='flex flex-wrap pt-2'>
        {recipients.map((recipient) => (
          <Badge className='mb-2 mr-2 flex w-fit gap-1' key={recipient} variant='outline'>
            {recipient}
            <XIcon
              size={16}
              className='cursor-pointer rounded p-0.5 text-gray-700 duration-200 ease-in-out hover:bg-gray-200'
              onClick={() => {
                removeRecipient(recipient)
              }}
            />
          </Badge>
        ))}
      </div>
    </>
  )
}

const SuffixButton = ({ onClick, enabled }: { onClick: () => void; enabled: boolean }) => {
  return (
    <span
      onClick={enabled ? onClick : undefined}
      className={cn('flex cursor-pointer select-none items-center gap-1 p-1 hover:bg-gray-100', {
        'cursor-not-allowed': !enabled,
        'opacity-50': !enabled,
      })}
    >
      <PlusIcon size={16} className='cursor-pointer' />
      Add Recipient
    </span>
  )
}
