// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file companycatalog/v1/company_catalog.proto (package companycatalog.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AutocompleteCompanyRequest, AutocompleteCompanyResponse, CreateUnverifiedOrgCompanyRequest, CreateUnverifiedOrgCompanyResponse, LookupCompanyRequest, LookupCompanyResponse, LookupOrgCompanyRequest, LookupOrgCompanyResponse, LookupSolutionRequest, LookupSolutionResponse, UpsertOrgCompanyRequest, UpsertOrgCompanyResponse } from "./company_catalog_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service companycatalog.v1.CompanyCatalogService
 */
export const CompanyCatalogService = {
  typeName: "companycatalog.v1.CompanyCatalogService",
  methods: {
    /**
     * @generated from rpc companycatalog.v1.CompanyCatalogService.LookupCompany
     */
    lookupCompany: {
      name: "LookupCompany",
      I: LookupCompanyRequest,
      O: LookupCompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc companycatalog.v1.CompanyCatalogService.LookupSolution
     */
    lookupSolution: {
      name: "LookupSolution",
      I: LookupSolutionRequest,
      O: LookupSolutionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service companycatalog.v1.CompanyManagementService
 */
export const CompanyManagementService = {
  typeName: "companycatalog.v1.CompanyManagementService",
  methods: {
    /**
     * LookupOrgCompany is a extension of LookupCompany for that returns also org_company_id when the org_company exists
     * for the given org.
     *
     * @generated from rpc companycatalog.v1.CompanyManagementService.LookupOrgCompany
     */
    lookupOrgCompany: {
      name: "LookupOrgCompany",
      I: LookupOrgCompanyRequest,
      O: LookupOrgCompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateUnverifiedOrgCompany is used to create a new company when LookupCompany is called and the company is not found
     * but we still want to create a third-party for the customer.
     * Expected to be used by the webui (Add third-party button) and procurement integrations.
     *
     * @generated from rpc companycatalog.v1.CompanyManagementService.CreateUnverifiedOrgCompany
     */
    createUnverifiedOrgCompany: {
      name: "CreateUnverifiedOrgCompany",
      I: CreateUnverifiedOrgCompanyRequest,
      O: CreateUnverifiedOrgCompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpsertOrgCompany will create a org_company linked to the given company_id
     * It is responsible for maintaining the external_ids field (if two different consumers add the same company, it will be added once with both external ids)
     *
     * @generated from rpc companycatalog.v1.CompanyManagementService.UpsertOrgCompany
     */
    upsertOrgCompany: {
      name: "UpsertOrgCompany",
      I: UpsertOrgCompanyRequest,
      O: UpsertOrgCompanyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AutocompleteCompany is a UI helper function to facilitate the creation of new companies
     * Needs to be in CompanyManagementService because it uses customer speicifc data to show what companies are already added
     *
     * @generated from rpc companycatalog.v1.CompanyManagementService.AutocompleteCompany
     */
    autocompleteCompany: {
      name: "AutocompleteCompany",
      I: AutocompleteCompanyRequest,
      O: AutocompleteCompanyResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

