import { createContext } from 'react'

import { AutocompleteItem } from '@/gen/companycatalog/v1/company_catalog_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { UploadFilesRef } from '@/pages/settings-page/artifacts-section/upload-files'

type AddNewThirdPartyContext = {
  selectedCompany: AutocompleteItem | null
  setSelectedCompany: React.Dispatch<React.SetStateAction<AutocompleteItem | null>>
  selectedStatus: CompanyStatus
  setSelectedStatus: React.Dispatch<CompanyStatus>
  isManual: boolean
  setIsManual: (isEnable: boolean) => void
  uploadRef: React.RefObject<UploadFilesRef>
}

export const AddNewThirdPartyContext = createContext<AddNewThirdPartyContext>({
  selectedCompany: null,
  setSelectedCompany: () => {},
  selectedStatus: CompanyStatus.ASSESSMENT_REQUIRED,
  setSelectedStatus: () => {},
  isManual: false,
  setIsManual: () => {},
  uploadRef: { current: null },
})
