import { ChevronDown } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

import { cn } from '@/lib/style-helpers'

import { Tooltip } from '@/components/ui/tooltip'

type TextOverflowProps = React.PropsWithChildren &
  Pick<React.HTMLProps<HTMLDivElement>, 'className'> & {
    hideToggle?: boolean
    hideTooltip?: boolean
  }

export const TextOverflow = ({
  children,
  className,
  hideToggle,
  hideTooltip,
}: TextOverflowProps) => {
  const [isExtended, setIsExtended] = useState(true)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [initialWidth, setInitialWidth] = useState<string | number>('auto')
  const contentRef = useRef<HTMLDivElement>(null)

  const toggleEnabled = isOverflowing && !hideToggle

  useEffect(() => {
    if (contentRef.current && contentRef.current.clientWidth !== 0) {
      const oneLineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight)
      // If there is more than one line, we need to show the toggler
      setIsOverflowing(isOverflowing || contentRef.current.clientHeight > oneLineHeight)
      setInitialWidth(contentRef.current.clientWidth)
      setIsExtended(false)
    }
  }, [contentRef.current?.clientWidth, isOverflowing])

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (toggleEnabled) {
      setIsExtended(!isExtended)
      event.stopPropagation()
    }
  }

  return (
    <Tooltip
      tooltipDisabled={!toggleEnabled || hideTooltip}
      trigger={
        <div
          ref={contentRef}
          style={{ width: initialWidth }}
          onClick={handleClick}
          className={cn(className, 'flex')}
        >
          <div
            style={{ width: initialWidth }}
            className={cn(
              isExtended ? 'whitespace-break-spaces break-all' : 'line-clamp-1 text-ellipsis',
              'text-left',
              { 'cursor-pointer': toggleEnabled },
            )}
          >
            {children}
          </div>
          {toggleEnabled && (
            <ChevronDown
              className={cn('mt-1.5 cursor-pointer transition-all duration-300', {
                'rotate-180': isExtended,
              })}
              size={14}
              strokeWidth={1}
            />
          )}
        </div>
      }
    >
      {isExtended ? 'Click to collapse' : 'Click to expand'}
    </Tooltip>
  )
}
