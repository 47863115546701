import { Input } from 'antd'
import { SearchIcon, XIcon } from 'lucide-react'
import { useContext } from 'react'

import { FilterProviderContext } from '@/components/table/filter/context'

export const SearchFilter = () => {
  const { setSearchState, searchState } = useContext(FilterProviderContext)

  return (
    <Input
      onChange={(event) => setSearchState(event.target.value)}
      value={searchState}
      placeholder='Search...'
      className='h-10 w-72 min-w-max rounded'
      suffix={
        searchState.length > 0 ? (
          <XIcon className='h-4 cursor-pointer' onClick={() => setSearchState('')} />
        ) : (
          <SearchIcon className='h-4 text-gray-400' />
        )
      }
    />
  )
}
