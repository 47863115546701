import { Input, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'

import { UrlParam } from '@/lib/url-param.hook'

import { useToast } from '@/components/ui/use-toast'

type WebhookURLModalProps = {
  url: string
}

export const WebhookURLModal = ({ url }: WebhookURLModalProps) => {
  const { toast } = useToast()
  const navigate = useNavigate()

  const handleClose = () => {
    navigator.clipboard.writeText(url)
    toast({
      status: 'success',
      title: 'Webhook URL Copied',
    })
    navigate(`/settings?${UrlParam.SETTING_SECTION}=integrations`)
  }
  return (
    <Modal
      title='Webhook URL'
      open={true}
      onOk={handleClose}
      closeIcon={false}
      okText='Copy & Close'
      cancelButtonProps={{ style: { display: 'none' }, disabled: true }}
    >
      <div>Webhook URL</div>
      <Input className='w-full' value={url} readOnly onClick={(e) => e.currentTarget.select()} />
    </Modal>
  )
}
