import { CheckedState } from '@radix-ui/react-checkbox'
import { Spin, Tag } from 'antd'
import { useCallback, useEffect } from 'react'

import { useSetIRQItem } from '@/api/company.hook'
import {
  IRQChangeType,
  InherentRiskCategoryEnum,
  InherentRiskSource,
  SetIRQItemRequest,
} from '@/gen/inventory/v1/company_service_pb'

import { inherentRiskSourceLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'

import { Checkbox } from '@/components/ui/checkbox'
import { useToast } from '@/components/ui/use-toast'

export type IrqCheckboxProps = {
  riskCategoryId: string
  displayName: string
  categoryEnum: InherentRiskCategoryEnum
  value: boolean
  suggestion: InherentRiskSource
  thirdPartyId: string
  transactionId: string
}

export const IrqCheckbox = ({
  riskCategoryId,
  displayName,
  value,
  suggestion,
  thirdPartyId,
  categoryEnum,
  transactionId,
}: IrqCheckboxProps) => {
  const {
    mutateAsync,
    error: updateIrqError,
    isLoading: updateIrqLoading,
  } = useSetIRQItem(thirdPartyId)

  const { toast } = useToast()

  const updateIRQ = useCallback(
    (request: Partial<SetIRQItemRequest>) => {
      mutateAsync({ ...request, transactionId, companyId: thirdPartyId })
    },
    [mutateAsync, thirdPartyId, transactionId],
  )

  useEffect(() => {
    if (updateIrqError) {
      toast({
        status: 'error',
        title: 'Error updating the IRQ',
      })
    }
  }, [updateIrqError, toast])

  const handleCheckboxChange = (checked: CheckedState) => {
    const changeType = checked
      ? IRQChangeType.IRQ_CHANGE_TYPE_ADD
      : IRQChangeType.IRQ_CHANGE_TYPE_REMOVE

    updateIRQ({ riskCategoryId, changeType, category: categoryEnum, companyId: thirdPartyId })
  }

  const borderColor = value ? 'border-gray-700' : 'border-gray-200'

  return (
    <div className='relative'>
      <div className='flex self-center py-1'>
        <div
          className={cn(
            'flex h-12 w-64 cursor-pointer select-none items-center justify-between self-center rounded-sm border border-solid px-4 py-1 hover:bg-gray-50',
            borderColor,
          )}
          onClick={() => handleCheckboxChange(!value)}
        >
          <div>
            <span>{displayName}</span>
          </div>
          {updateIrqLoading ? (
            <Spin />
          ) : (
            <Checkbox
              checked={value}
              value={riskCategoryId}
              onCheckedChange={handleCheckboxChange}
              className={cn(borderColor)}
            />
          )}
        </div>
        {suggestion !== InherentRiskSource.UNSPECIFIED && (
          <Tag
            className='absolute left-full ml-4 flex items-center justify-between self-center text-gray-400'
            bordered={false}
          >
            {inherentRiskSourceLabel[suggestion]}
          </Tag>
        )}
      </div>
    </div>
  )
}
