import { useContext } from 'react'

import { FilterProviderContext } from '@/components/table/filter/context'
import { Checkbox } from '@/components/ui/checkbox'

type CheckboxFilterProps = {
  label?: string
}

export const CheckboxFilter = ({ label }: CheckboxFilterProps) => {
  const { setCheckboxFilterState, checkboxFilterState } = useContext(FilterProviderContext)

  return (
    <div
      onMouseUp={() => setCheckboxFilterState && setCheckboxFilterState(!checkboxFilterState)}
      className='flex cursor-pointer items-center gap-2.5 self-center'
    >
      <Checkbox checked={checkboxFilterState} />
      <span className='font-light text-gray-400'>{label}</span>
    </div>
  )
}
