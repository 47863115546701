// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file netskope/v1/netskope_service.proto (package netskope.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCredentialsRequest, AddCredentialsResponse } from "./netskope_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "netskope.v1.NetskopeCollectorService";

/**
 * @generated from service netskope.v1.NetskopeCollectorService
 */
export const NetskopeCollectorService = {
  typeName: "netskope.v1.NetskopeCollectorService",
  methods: {
    /**
     * @generated from rpc netskope.v1.NetskopeCollectorService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: NetskopeCollectorService,});

/**
 * @generated from rpc netskope.v1.NetskopeCollectorService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
