import { RefObject, useEffect } from 'react'

export const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  ignoreClickOutOfRoot: boolean = false,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // In case ignoreClickOutOfRoot is true and click is outside of the root element we are not calling the callback
      const root = document.getElementById('root')
      if (ignoreClickOutOfRoot && root && !root.contains(event.target as Node)) return

      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback, ignoreClickOutOfRoot])
}
