import { CheckIcon, XCircleIcon } from 'lucide-react'

import { SOC2Activity } from '@/gen/inventory/v1/artifact_service_pb'

import { cn } from '@/lib/style-helpers'

import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

const columns: ColumnType<SOC2Activity>[] = [
  {
    title: 'Control',
    render: (_, { result, controls }) => (
      <div className='flex flex-col'>
        <div className='flex items-center gap-1.5 text-wrap'>
          <span
            className={cn({
              'text-red-400': !!result,
            })}
          >
            {controls
              .map((control) => control.id)
              .sort()
              .join(', ')}
          </span>
        </div>
      </div>
    ),
    search: true,
    accessor: ({ controls, details, test, result, managementResponse }) => ({
      searchValue: [
        controls.map((control) => control.id).join(', '),
        details,
        test,
        result,
        managementResponse,
      ].join(' '),
    }),
  },
  {
    title: 'Activity',
    dataIndex: 'details',
    render: (text: string) => <div className='text-wrap'>{text}</div>,
  },
  {
    title: 'Applied Test',
    dataIndex: 'test',
    render: (text: string) => <div className='text-wrap'>{text}</div>,
  },
  {
    title: 'Result',
    dataIndex: 'result',
    render: (result: SOC2Activity['result']) => (
      <div className='text-wrap'>
        <span>
          {result && <XCircleIcon size={14} className='mr-1 inline text-red-500' />}
          {result ?? (
            <TextWithIcon
              className='text-green-400'
              icon={<CheckIcon size={14} />}
              text='No exceptions noted'
            />
          )}
        </span>
      </div>
    ),
  },
]

interface ActivityTableProps {
  dataSource: SOC2Activity[]
  showExceptionsOnly: boolean
  setShowExceptionsOnly: (value: boolean) => void
}

export const ActivityTable = ({
  dataSource,
  showExceptionsOnly,
  setShowExceptionsOnly,
}: ActivityTableProps) => {
  return (
    <Table
      key={dataSource.length} // `dataSource.length` as key triggers re-render on data change, fixing render expandable issues.
      pagination={{}}
      checkboxFilterTitle='Show Exceptions & Responses Only'
      checkboxFilterState={showExceptionsOnly}
      setCheckboxFilterState={setShowExceptionsOnly}
      cellAlignTextTop
      expandable={{
        showExpandColumn: false,
        defaultExpandAllRows: true,
        rowExpandable: (record: SOC2Activity) => !!record.result, // Only create new row if there's an exception
        expandedRowRender: (record: SOC2Activity) => (
          <div className='flex gap-5'>
            <strong>Management Response</strong>
            <span className='text-wrap'> {record.managementResponse}</span>
          </div>
        ),
      }}
      narrowFilterBar
      columns={columns}
      dataSource={dataSource}
    />
  )
}
