// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/finding_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CompanySummary } from "./company_summary_pb.js";
import { Control } from "./control_pb.js";
import { RiskLevel } from "./risk_pb.js";
import { Implication } from "./implication_pb.js";

/**
 * @generated from enum inventory.v1.FindingStatus
 */
export enum FindingStatus {
  /**
   * @generated from enum value: FINDING_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FINDING_STATUS_OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: FINDING_STATUS_ONGOING = 2;
   */
  ONGOING = 2,

  /**
   * @generated from enum value: FINDING_STATUS_ACCEPTED = 3;
   */
  ACCEPTED = 3,

  /**
   * @generated from enum value: FINDING_STATUS_RESOLVED = 4;
   */
  RESOLVED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(FindingStatus)
proto3.util.setEnumType(FindingStatus, "inventory.v1.FindingStatus", [
  { no: 0, name: "FINDING_STATUS_UNSPECIFIED" },
  { no: 1, name: "FINDING_STATUS_OPEN" },
  { no: 2, name: "FINDING_STATUS_ONGOING" },
  { no: 3, name: "FINDING_STATUS_ACCEPTED" },
  { no: 4, name: "FINDING_STATUS_RESOLVED" },
]);

/**
 * @generated from message inventory.v1.AddCommentRequest
 */
export class AddCommentRequest extends Message<AddCommentRequest> {
  /**
   * @generated from field: string finding_id = 1;
   */
  findingId = "";

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  constructor(data?: PartialMessage<AddCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddCommentRequest | PlainMessage<AddCommentRequest> | undefined, b: AddCommentRequest | PlainMessage<AddCommentRequest> | undefined): boolean {
    return proto3.util.equals(AddCommentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.AddCommentResponse
 */
export class AddCommentResponse extends Message<AddCommentResponse> {
  constructor(data?: PartialMessage<AddCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddCommentResponse | PlainMessage<AddCommentResponse> | undefined, b: AddCommentResponse | PlainMessage<AddCommentResponse> | undefined): boolean {
    return proto3.util.equals(AddCommentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingTimelineRequest
 */
export class GetFindingTimelineRequest extends Message<GetFindingTimelineRequest> {
  /**
   * @generated from field: string finding_id = 1;
   */
  findingId = "";

  constructor(data?: PartialMessage<GetFindingTimelineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingTimelineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingTimelineRequest {
    return new GetFindingTimelineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingTimelineRequest {
    return new GetFindingTimelineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingTimelineRequest {
    return new GetFindingTimelineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingTimelineRequest | PlainMessage<GetFindingTimelineRequest> | undefined, b: GetFindingTimelineRequest | PlainMessage<GetFindingTimelineRequest> | undefined): boolean {
    return proto3.util.equals(GetFindingTimelineRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingTimelineResponse
 */
export class GetFindingTimelineResponse extends Message<GetFindingTimelineResponse> {
  /**
   * @generated from field: repeated inventory.v1.FindingTimelineItem items = 1;
   */
  items: FindingTimelineItem[] = [];

  constructor(data?: PartialMessage<GetFindingTimelineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingTimelineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: FindingTimelineItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingTimelineResponse {
    return new GetFindingTimelineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingTimelineResponse {
    return new GetFindingTimelineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingTimelineResponse {
    return new GetFindingTimelineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingTimelineResponse | PlainMessage<GetFindingTimelineResponse> | undefined, b: GetFindingTimelineResponse | PlainMessage<GetFindingTimelineResponse> | undefined): boolean {
    return proto3.util.equals(GetFindingTimelineResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListFindingsRequest
 */
export class ListFindingsRequest extends Message<ListFindingsRequest> {
  /**
   * @generated from field: optional string company_id = 1;
   */
  companyId?: string;

  /**
   * @generated from field: optional string solution_id = 2;
   */
  solutionId?: string;

  /**
   * if true, include company summary data in response
   *
   * @generated from field: bool with_company = 3;
   */
  withCompany = false;

  /**
   * @generated from field: bool urgent_only = 4;
   */
  urgentOnly = false;

  constructor(data?: PartialMessage<ListFindingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListFindingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "with_company", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "urgent_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFindingsRequest {
    return new ListFindingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFindingsRequest {
    return new ListFindingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFindingsRequest {
    return new ListFindingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFindingsRequest | PlainMessage<ListFindingsRequest> | undefined, b: ListFindingsRequest | PlainMessage<ListFindingsRequest> | undefined): boolean {
    return proto3.util.equals(ListFindingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListFindingsResponse
 */
export class ListFindingsResponse extends Message<ListFindingsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Finding findings = 1;
   */
  findings: Finding[] = [];

  /**
   * if with_company is true, map from each company_id in findings to company summary
   *
   * @generated from field: map<string, inventory.v1.CompanySummary> company_summary = 2;
   */
  companySummary: { [key: string]: CompanySummary } = {};

  constructor(data?: PartialMessage<ListFindingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListFindingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "findings", kind: "message", T: Finding, repeated: true },
    { no: 2, name: "company_summary", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CompanySummary} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFindingsResponse {
    return new ListFindingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFindingsResponse {
    return new ListFindingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFindingsResponse {
    return new ListFindingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFindingsResponse | PlainMessage<ListFindingsResponse> | undefined, b: ListFindingsResponse | PlainMessage<ListFindingsResponse> | undefined): boolean {
    return proto3.util.equals(ListFindingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingRequest
 */
export class GetFindingRequest extends Message<GetFindingRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional bool exclude_internal_links = 2;
   */
  excludeInternalLinks?: boolean;

  constructor(data?: PartialMessage<GetFindingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "exclude_internal_links", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingRequest {
    return new GetFindingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingRequest {
    return new GetFindingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingRequest {
    return new GetFindingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingRequest | PlainMessage<GetFindingRequest> | undefined, b: GetFindingRequest | PlainMessage<GetFindingRequest> | undefined): boolean {
    return proto3.util.equals(GetFindingRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetFindingResponse
 */
export class GetFindingResponse extends Message<GetFindingResponse> {
  /**
   * @generated from field: inventory.v1.Finding finding = 1;
   */
  finding?: Finding;

  /**
   * @generated from field: inventory.v1.FindingDocumentation documentation = 2;
   */
  documentation?: FindingDocumentation;

  /**
   * @generated from field: inventory.v1.CompanySummary company = 4;
   */
  company?: CompanySummary;

  /**
   * @generated from field: optional inventory.v1.Control control = 5;
   */
  control?: Control;

  /**
   * @generated from field: string fact_markdown = 6;
   */
  factMarkdown = "";

  /**
   * @generated from field: string additional_fact_markdown = 7;
   */
  additionalFactMarkdown = "";

  /**
   * @generated from field: int32 additional_fact_count = 8;
   */
  additionalFactCount = 0;

  constructor(data?: PartialMessage<GetFindingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetFindingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "finding", kind: "message", T: Finding },
    { no: 2, name: "documentation", kind: "message", T: FindingDocumentation },
    { no: 4, name: "company", kind: "message", T: CompanySummary },
    { no: 5, name: "control", kind: "message", T: Control, opt: true },
    { no: 6, name: "fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "additional_fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "additional_fact_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFindingResponse {
    return new GetFindingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFindingResponse {
    return new GetFindingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFindingResponse {
    return new GetFindingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFindingResponse | PlainMessage<GetFindingResponse> | undefined, b: GetFindingResponse | PlainMessage<GetFindingResponse> | undefined): boolean {
    return proto3.util.equals(GetFindingResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateFindingRequest
 */
export class UpdateFindingRequest extends Message<UpdateFindingRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional inventory.v1.FindingStatus status = 2;
   */
  status?: FindingStatus;

  /**
   * @generated from field: optional inventory.v1.RiskLevel level = 3;
   */
  level?: RiskLevel;

  /**
   * @generated from field: optional string comment = 4;
   */
  comment?: string;

  constructor(data?: PartialMessage<UpdateFindingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateFindingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(FindingStatus), opt: true },
    { no: 3, name: "level", kind: "enum", T: proto3.getEnumType(RiskLevel), opt: true },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFindingRequest {
    return new UpdateFindingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFindingRequest {
    return new UpdateFindingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFindingRequest {
    return new UpdateFindingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFindingRequest | PlainMessage<UpdateFindingRequest> | undefined, b: UpdateFindingRequest | PlainMessage<UpdateFindingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFindingRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateFindingResponse
 */
export class UpdateFindingResponse extends Message<UpdateFindingResponse> {
  constructor(data?: PartialMessage<UpdateFindingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateFindingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFindingResponse {
    return new UpdateFindingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFindingResponse {
    return new UpdateFindingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFindingResponse {
    return new UpdateFindingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFindingResponse | PlainMessage<UpdateFindingResponse> | undefined, b: UpdateFindingResponse | PlainMessage<UpdateFindingResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFindingResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Finding
 */
export class Finding extends Message<Finding> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * @generated from field: repeated string solution_ids = 3;
   */
  solutionIds: string[] = [];

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 5;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.FindingStatus status = 6;
   */
  status = FindingStatus.UNSPECIFIED;

  /**
   * @generated from field: repeated inventory.v1.Implication implications = 7;
   */
  implications: Implication[] = [];

  /**
   * assignee email
   *
   * @generated from field: string assignee = 8;
   */
  assignee = "";

  /**
   * @generated from field: google.protobuf.Timestamp detection_time = 10;
   */
  detectionTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 11;
   */
  updateTime?: Timestamp;

  /**
   * not supported yet, only for demo
   *
   * @generated from field: int32 comments = 12;
   */
  comments = 0;

  /**
   * should finding be visible to the customer,
   * backend doesn't usually show non-visible findings
   * but this allows sending all findings when a privileged user is viewing them
   *
   * @generated from field: bool visible = 13;
   */
  visible = false;

  constructor(data?: PartialMessage<Finding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Finding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "solution_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(FindingStatus) },
    { no: 7, name: "implications", kind: "enum", T: proto3.getEnumType(Implication), repeated: true },
    { no: 8, name: "assignee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "detection_time", kind: "message", T: Timestamp },
    { no: 11, name: "update_time", kind: "message", T: Timestamp },
    { no: 12, name: "comments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Finding {
    return new Finding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Finding {
    return new Finding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Finding {
    return new Finding().fromJsonString(jsonString, options);
  }

  static equals(a: Finding | PlainMessage<Finding> | undefined, b: Finding | PlainMessage<Finding> | undefined): boolean {
    return proto3.util.equals(Finding, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingDocumentation
 */
export class FindingDocumentation extends Message<FindingDocumentation> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * explains what might happen if the risk factor is not mitigated
   *
   * @generated from field: string risk = 3;
   */
  risk = "";

  /**
   * possible actions to mitigate the risk
   *
   * @generated from field: repeated string actions = 4;
   */
  actions: string[] = [];

  constructor(data?: PartialMessage<FindingDocumentation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingDocumentation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "risk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingDocumentation {
    return new FindingDocumentation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingDocumentation {
    return new FindingDocumentation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingDocumentation {
    return new FindingDocumentation().fromJsonString(jsonString, options);
  }

  static equals(a: FindingDocumentation | PlainMessage<FindingDocumentation> | undefined, b: FindingDocumentation | PlainMessage<FindingDocumentation> | undefined): boolean {
    return proto3.util.equals(FindingDocumentation, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingTimelineItem
 */
export class FindingTimelineItem extends Message<FindingTimelineItem> {
  /**
   * @generated from oneof inventory.v1.FindingTimelineItem.timeline_item
   */
  timelineItem: {
    /**
     * @generated from field: inventory.v1.FindingChange change = 1;
     */
    value: FindingChange;
    case: "change";
  } | {
    /**
     * @generated from field: inventory.v1.Comment comment = 2;
     */
    value: Comment;
    case: "comment";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FindingTimelineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingTimelineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "change", kind: "message", T: FindingChange, oneof: "timeline_item" },
    { no: 2, name: "comment", kind: "message", T: Comment, oneof: "timeline_item" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingTimelineItem {
    return new FindingTimelineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingTimelineItem {
    return new FindingTimelineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingTimelineItem {
    return new FindingTimelineItem().fromJsonString(jsonString, options);
  }

  static equals(a: FindingTimelineItem | PlainMessage<FindingTimelineItem> | undefined, b: FindingTimelineItem | PlainMessage<FindingTimelineItem> | undefined): boolean {
    return proto3.util.equals(FindingTimelineItem, a, b);
  }
}

/**
 * @generated from message inventory.v1.Comment
 */
export class Comment extends Message<Comment> {
  /**
   * @generated from field: google.protobuf.Timestamp time = 1;
   */
  time?: Timestamp;

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  constructor(data?: PartialMessage<Comment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Comment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Comment {
    return new Comment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Comment {
    return new Comment().fromJsonString(jsonString, options);
  }

  static equals(a: Comment | PlainMessage<Comment> | undefined, b: Comment | PlainMessage<Comment> | undefined): boolean {
    return proto3.util.equals(Comment, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingChange
 */
export class FindingChange extends Message<FindingChange> {
  /**
   * @generated from field: google.protobuf.Timestamp time = 1;
   */
  time?: Timestamp;

  /**
   * @generated from field: inventory.v1.FindingStatus prev_status = 2;
   */
  prevStatus = FindingStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.FindingStatus new_status = 3;
   */
  newStatus = FindingStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel prev_risk_level = 4;
   */
  prevRiskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel new_risk_level = 5;
   */
  newRiskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: optional inventory.v1.Comment comment = 6;
   */
  comment?: Comment;

  /**
   * @generated from field: string user_id = 7;
   */
  userId = "";

  constructor(data?: PartialMessage<FindingChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "prev_status", kind: "enum", T: proto3.getEnumType(FindingStatus) },
    { no: 3, name: "new_status", kind: "enum", T: proto3.getEnumType(FindingStatus) },
    { no: 4, name: "prev_risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 5, name: "new_risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 6, name: "comment", kind: "message", T: Comment, opt: true },
    { no: 7, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingChange {
    return new FindingChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingChange {
    return new FindingChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingChange {
    return new FindingChange().fromJsonString(jsonString, options);
  }

  static equals(a: FindingChange | PlainMessage<FindingChange> | undefined, b: FindingChange | PlainMessage<FindingChange> | undefined): boolean {
    return proto3.util.equals(FindingChange, a, b);
  }
}

