import { type RadioQuestionType, isAssessor, useMutation } from '@/lib/liveblocks.config'

import { Explanation } from '@/pages/questionnaire/question/explanation'
import { OptionLabel } from '@/pages/questionnaire/question/option-label'
import { useGetQuestionRequired } from '@/pages/questionnaire/question/use-get-question-required.hook'

import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

import { QuestionContainer, QuestionContainerProps } from './container'

interface RadioQuestionProps extends QuestionContainerProps {
  question: RadioQuestionType
}

export const RadioQuestion = (props: RadioQuestionProps) => {
  const { question, participantRole } = props

  const requiredStatus = useGetQuestionRequired(question)

  const toggleOption = useToggleOption(question.id)
  return (
    <QuestionContainer {...props}>
      <RadioGroup
        disabled={requiredStatus !== 'required' || isAssessor(participantRole)}
        className='flex w-fit flex-col gap-2'
        onValueChange={(optionId) => toggleOption(optionId)}
      >
        {question.options.map((option) => (
          <div key={option.id} className='flex w-fit items-center gap-2.5'>
            <RadioGroupItem
              className='shrink-0 border-gray-600 text-gray-700'
              value={option.id}
              checked={option.isSelect}
            />
            <OptionLabel option={option} participantRole={participantRole} />
          </div>
        ))}
      </RadioGroup>
      {question.options.some(({ isFlag, isSelect }) => isFlag && isSelect) && (
        <Explanation question={question} participantRole={participantRole} />
      )}
    </QuestionContainer>
  )
}

const useToggleOption = (questionId: string) => {
  const toggleOption = useMutation(({ storage }, optionId: string) => {
    const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex) as RadioQuestionType
    const options = question.options.map((option) =>
      option.id === optionId ? { ...option, isSelect: true } : { ...option, isSelect: false },
    )
    storage.get('questions').set(questionIndex, {
      ...question,
      options,
      status: options.some(({ isSelect }) => isSelect) ? 'needsReview' : 'unanswered',
    })
  }, [])

  return toggleOption
}
