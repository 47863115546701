import React from 'react'

import { cn } from '@/lib/style-helpers'

type iconPosition = 'start' | 'end'

export type TextWithIconPops = {
  text: string | React.ReactNode
  icon?: React.ReactNode
  iconPosition?: iconPosition
} & React.HTMLAttributes<HTMLDivElement>

export const TextWithIcon = ({
  text,
  icon,
  iconPosition = 'start',
  ...props
}: TextWithIconPops) => {
  return (
    <div
      {...props}
      className={cn(
        'flex w-fit items-center gap-1 whitespace-nowrap',
        { 'flex-row-reverse': iconPosition === 'end' },
        props.className,
      )}
    >
      {icon}
      {text}
    </div>
  )
}
