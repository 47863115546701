// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/statistics_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DaysCompaniesInAssessmentRequiredRequest, DaysCompaniesInAssessmentRequiredResponse, GetCountCompaniesBySeverityRequest, GetCountCompaniesBySeverityResponse, GetFindingsCountBySeverityTimeseriesRequest, GetFindingsCountBySeverityTimeseriesResponse, ListCompaniesWithMostFindingsRequest, ListCompaniesWithMostFindingsResponse } from "./statistics_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.StatisticsService";

/**
 * @generated from service inventory.v1.StatisticsService
 */
export const StatisticsService = {
  typeName: "inventory.v1.StatisticsService",
  methods: {
    /**
     * @generated from rpc inventory.v1.StatisticsService.GetFindingsCountBySeverityTimeseries
     */
    getFindingsCountBySeverityTimeseries: {
      name: "GetFindingsCountBySeverityTimeseries",
      I: GetFindingsCountBySeverityTimeseriesRequest,
      O: GetFindingsCountBySeverityTimeseriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.StatisticsService.ListCompaniesWithMostFindings
     */
    listCompaniesWithMostFindings: {
      name: "ListCompaniesWithMostFindings",
      I: ListCompaniesWithMostFindingsRequest,
      O: ListCompaniesWithMostFindingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.StatisticsService.GetCountCompaniesBySeverity
     */
    getCountCompaniesBySeverity: {
      name: "GetCountCompaniesBySeverity",
      I: GetCountCompaniesBySeverityRequest,
      O: GetCountCompaniesBySeverityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.StatisticsService.DaysCompaniesInAssessmentRequired
     */
    daysCompaniesInAssessmentRequired: {
      name: "DaysCompaniesInAssessmentRequired",
      I: DaysCompaniesInAssessmentRequiredRequest,
      O: DaysCompaniesInAssessmentRequiredResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: StatisticsService,});

/**
 * @generated from rpc inventory.v1.StatisticsService.GetFindingsCountBySeverityTimeseries
 */
export const getFindingsCountBySeverityTimeseries: UnaryFunctionsWithHooks<GetFindingsCountBySeverityTimeseriesRequest, GetFindingsCountBySeverityTimeseriesResponse> = {   ...$queryService.getFindingsCountBySeverityTimeseries,  ...createUnaryHooks($queryService.getFindingsCountBySeverityTimeseries)};

/**
 * @generated from rpc inventory.v1.StatisticsService.ListCompaniesWithMostFindings
 */
export const listCompaniesWithMostFindings: UnaryFunctionsWithHooks<ListCompaniesWithMostFindingsRequest, ListCompaniesWithMostFindingsResponse> = {   ...$queryService.listCompaniesWithMostFindings,  ...createUnaryHooks($queryService.listCompaniesWithMostFindings)};

/**
 * @generated from rpc inventory.v1.StatisticsService.GetCountCompaniesBySeverity
 */
export const getCountCompaniesBySeverity: UnaryFunctionsWithHooks<GetCountCompaniesBySeverityRequest, GetCountCompaniesBySeverityResponse> = {   ...$queryService.getCountCompaniesBySeverity,  ...createUnaryHooks($queryService.getCountCompaniesBySeverity)};

/**
 * @generated from rpc inventory.v1.StatisticsService.DaysCompaniesInAssessmentRequired
 */
export const daysCompaniesInAssessmentRequired: UnaryFunctionsWithHooks<DaysCompaniesInAssessmentRequiredRequest, DaysCompaniesInAssessmentRequiredResponse> = {   ...$queryService.daysCompaniesInAssessmentRequired,  ...createUnaryHooks($queryService.daysCompaniesInAssessmentRequired)};
