import { Spin } from 'antd'

import { cn } from '@/lib/style-helpers'

import { Label } from '@/components/ui/label'
import { RadioGroupItem } from '@/components/ui/radio-group'

type RadioItemProps = {
  disabled?: boolean
  isSelect?: boolean
  value: string
  id: string
  loading?: boolean
  description?: string
}

export const RadioItem = ({
  disabled = false,
  value,
  id,
  description,
  isSelect = false,
  loading = false,
}: RadioItemProps) => (
  <div
    className={cn('flex items-start gap-x-2', {
      'items-center': !description,
    })}
  >
    <RadioGroupItem disabled={disabled} value={id} id={id} />
    <div>
      <Label
        className='flex items-center gap-x-2 font-semibold uppercase text-purple-600'
        htmlFor={id}
      >
        <Spin size='small' spinning={loading} /> {value}
      </Label>
      {description && (
        <span
          className={cn('text-sm leading-7', {
            'text-gray-400': !isSelect,
          })}
        >
          {description}
        </span>
      )}
    </div>
  </div>
)
