// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/control.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.ControlStatus
 */
export enum ControlStatus {
  /**
   * @generated from enum value: CONTROL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CONTROL_STATUS_VALIDATED = 1;
   */
  VALIDATED = 1,

  /**
   * @generated from enum value: CONTROL_STATUS_FINDING = 2;
   */
  FINDING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ControlStatus)
proto3.util.setEnumType(ControlStatus, "inventory.v1.ControlStatus", [
  { no: 0, name: "CONTROL_STATUS_UNSPECIFIED" },
  { no: 1, name: "CONTROL_STATUS_VALIDATED" },
  { no: 2, name: "CONTROL_STATUS_FINDING" },
]);

/**
 * @generated from message inventory.v1.Control
 */
export class Control extends Message<Control> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: inventory.v1.ControlStatus status = 5;
   */
  status = ControlStatus.UNSPECIFIED;

  /**
   * @generated from field: bool is_enabled = 6;
   */
  isEnabled = false;

  constructor(data?: PartialMessage<Control>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Control";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(ControlStatus) },
    { no: 6, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Control {
    return new Control().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Control {
    return new Control().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Control {
    return new Control().fromJsonString(jsonString, options);
  }

  static equals(a: Control | PlainMessage<Control> | undefined, b: Control | PlainMessage<Control> | undefined): boolean {
    return proto3.util.equals(Control, a, b);
  }
}

