import { PlainMessage } from '@bufbuild/protobuf'
import { BarChartIcon, ClipboardIcon, HomeIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'

import { useListFindings } from '@/api/list-findings.hook'
import { CompanySummary } from '@/gen/inventory/v1/company_summary_pb'
import { Finding, FindingStatus } from '@/gen/inventory/v1/finding_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { findingStatusLabel, riskLevelLabel } from '@/const/label'
import { findingStatusPriority, riskLevelPriority } from '@/const/priority'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { FindingNotFound } from '@/pages/error-pages/finding-not-found'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { SelectFindingStatusBadge } from '@/components/badge/finding-status'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { ImplicationIndicatorList } from '@/components/indicator/implication'
import { InventoryHeader } from '@/components/inventory-header'
import { ProfileTeaser } from '@/components/profile-teaser'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

type FindingRow = PlainMessage<Finding> & {
  company?: PlainMessage<CompanySummary>
}

export const FindingInventory = () => {
  const { isEnabled: suggestedWorkStatusEnabled } = useFeatureFlagEnabled(
    'finding-suggested-work-status',
  )
  const { isEnabled: assigneeEnabled } = useFeatureFlagEnabled('finding-assignee')
  const { updateParam } = useUrlParams()

  const { data, isLoading, isError } = useListFindings({ withCompany: true })

  const findings = useMemo(() => {
    return (
      data?.findings.map((finding) => ({
        company: data?.companySummary[finding.companyId] || null,
        ...finding,
      })) || ([] as FindingRow[])
    )
  }, [data])

  const onClickFinding = useCallback(
    (row: FindingRow) => {
      return {
        onClick: () => {
          updateParam(UrlParam.FINDING_ID, row.id)
        },
      }
    },
    [updateParam],
  )

  if (isError) {
    return <FindingNotFound />
  }
  const columns: ColumnType<FindingRow>[] = [
    {
      hidden: true, // We don't want to show this column, but we need it for filtering
      title: 'Third-Party Risk',
      filter: true,
      accessor: ({ company }) => ({
        filterValue: riskLevelLabel[company?.risk || RiskLevel.UNSPECIFIED],
      }),
    },
    {
      title: <TextWithIcon text='Third-Party' icon={<HomeIcon className='size-3' />} />,
      key: 'thirdParty',
      filter: true,
      width: '24%',
      accessor: ({ company }) => ({
        filterValue: company?.profileName,
        // We need to sort by risk level first, then by company name
        sortValue:
          riskLevelPriority[company?.risk || RiskLevel.UNSPECIFIED].toString() +
          company?.profileName,
      }),
      render: ({ company }: FindingRow) => {
        return (
          <div className='flex w-full flex-row gap-4 truncate align-top'>
            <CompanyRiskIcon showTooltip riskLevel={company?.risk || RiskLevel.UNSPECIFIED} />
            <div className='w-full'>
              <span>{company?.profileName}</span>
              <div className='text-xs'>
                <span className='block max-w-md truncate text-gray-400'>
                  {company?.profileShortDescription}
                </span>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: <TextWithIcon text='Finding' icon={<ClipboardIcon className='size-3' />} />,
      width: '30%',
      dataIndex: 'title',
      render: (title: string) => <div className='truncate'>{title}</div>,
      search: true,
      accessor: ({ title, company }) => ({
        searchValue: `${title} ${company?.profileName || ''}`,
      }),
    },
    {
      title: <TextWithIcon text='Severity' icon={<BarChartIcon className='size-3' />} />,
      width: '10%',
      defaultSortOrder: 'ascend',
      dataIndex: 'riskLevel',
      filter: true,
      render: (riskLevel: RiskLevel) => <FindingSeverityBadge level={riskLevel} />,
      accessor: ({ riskLevel }: FindingRow) => ({
        filterValue: riskLevelLabel[riskLevel] || undefined,
        sortValue: riskLevelPriority[riskLevel],
      }),
    },
    {
      title: 'Implication',
      width: '13%',
      render: ({ implications }: FindingRow) => (
        <ImplicationIndicatorList activeImplications={implications} />
      ),
    },
    {
      hidden: !assigneeEnabled,
      title: 'Assignee',
      width: '9%',
      ellipsis: true,
      render: (row: Finding) => <ProfileTeaser username={row.assignee} />,
    },
    {
      title: 'Status',
      width: '15%',
      key: 'status',
      filter: true,
      accessor: ({ status }: FindingRow) => ({
        filterValue: findingStatusLabel[status],
        sortValue: findingStatusPriority[status],
      }),
      defaultSelectedFilter: [
        findingStatusLabel[FindingStatus.OPEN],
        findingStatusLabel[FindingStatus.ONGOING],
      ],
      render: ({ status, id, company, title }: FindingRow) => (
        <SelectFindingStatusBadge
          findingId={id}
          status={status}
          suggestedWorkStatusEnabled={suggestedWorkStatusEnabled}
          extraTrackingProps={{ from: 'inventory', company: company?.profileName, finding: title }}
        />
      ),
    },
  ]

  return (
    <>
      <div className='bg-gray-50'>
        <InventoryHeader title='Findings' icon={<ClipboardIcon className='h-5.5 w-5.5' />} />
      </div>
      <div className='mx-8 mt-10 min-h-screen pb-10'>
        <Table
          dataSource={findings}
          tableLayout='fixed'
          loading={isLoading}
          columns={columns}
          stickyFilterBar
          pagination={{}}
          onRow={onClickFinding}
        />
      </div>
    </>
  )
}
