import { Code, ConnectError } from '@connectrpc/connect'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { useRenameSection } from '@/api/questionnaire-template.hook'
import { TemplateQuestion } from '@/gen/questionnaire/v1/template_service_pb'

import { SubCategory } from '@/pages/questionnaire-template/sub-category'

import { EditableText } from '@/components/editable-text'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'

type CategoryProps = {
  category: string
  questions: TemplateQuestion[]
}

export const Category = ({ category, questions }: CategoryProps) => {
  const { templateId } = useParams<{ templateId: string }>()
  const { mutateAsync } = useRenameSection()
  const { toast } = useToast()

  const subSectionToQuestions = _.groupBy(questions, 'subSection')

  return (
    <>
      <div className='flex w-full flex-col gap-6' id={category}>
        <EditableText
          onSave={async (input) => {
            if (!input) {
              toast({ status: 'error', title: "Section name can't be left empty" })
              return
            }
            try {
              await mutateAsync({
                currentName: category,
                newName: input,
                templateId,
              })
            } catch (err) {
              if (err instanceof ConnectError) {
                const message =
                  err.code === Code.AlreadyExists ? err.rawMessage : "Couldn't rename section"
                toast({
                  status: 'error',
                  title: message,
                })
              }
            }
          }}
          text={category}
          className='min-h-11 w-4/5 max-w-3xl text-2xl font-bold'
        />
        {_.sortBy(Object.values(subSectionToQuestions), (questions) =>
          _.min(questions.map(({ id }) => id)),
        ).map((questions, index) => (
          <SubCategory key={index} questionList={questions} />
        ))}
      </div>
      <div className='my-10 mr-11 last:invisible'>
        <Separator />
      </div>
    </>
  )
}
