import { RcFile } from 'antd/es/upload'
import axios from 'axios'
import { InfoIcon, PaperclipIcon } from 'lucide-react'

import { useGetUploadLink } from '@/api/questionnaire.hook'

import { type FileQuestionType, isAssessor, useMutation } from '@/lib/liveblocks.config'
import { calculatePercentage } from '@/lib/math-utils'

import { TextWithIcon } from '@/components/text-with-icon'
import { Upload } from '@/components/upload/upload'

import { QuestionContainer, QuestionContainerProps } from './container'

interface FileQuestionProps extends QuestionContainerProps {
  question: FileQuestionType
}

export const FileQuestion = (props: FileQuestionProps) => {
  const { participantRole, question } = props

  return (
    <QuestionContainer {...props}>
      {participantRole === 'recipient' && <QuestionnaireUploadFile {...props} />}
      {isAssessor(participantRole) && (
        <div>
          {question.artifactId && (
            <TextWithIcon icon={<PaperclipIcon size={14} />} text={question.artifactName} />
          )}
          {!question.artifactId && (
            <TextWithIcon icon={<InfoIcon size={14} />} text='No file uploaded' />
          )}
        </div>
      )}
    </QuestionContainer>
  )
}

type QuestionnaireUploadFileProps = Pick<FileQuestionProps, 'participantRole' | 'question'>

const QuestionnaireUploadFile = ({ participantRole, question }: QuestionnaireUploadFileProps) => {
  const setQuestionArtifactId = useSetQuestionArtifactId(question.id)
  const { mutateAsync: getUploadLink } = useGetUploadLink()
  return (
    <Upload
      disabled={isAssessor(participantRole)}
      customRequest={async ({ onProgress, onSuccess, onError, file }) => {
        const { artifactId, uploadLink } = await getUploadLink({
          magicLink: document.location.pathname,
        })
        const response = await axios({
          method: 'PUT',
          url: uploadLink,
          data: file,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress: ({ total, loaded }) => {
            if (total && total > 0 && onProgress) {
              onProgress({ percent: calculatePercentage(loaded, total) })
            }
          },
        })

        if (response.status === 200) {
          setQuestionArtifactId(artifactId, (file as RcFile).name || '')
          onSuccess && onSuccess(() => {})
        } else {
          onError && onError(new Error(response.statusText))
        }
      }}
      onRemove={() => setQuestionArtifactId()}
      maxCount={1}
      defaultFileList={
        question.artifactId
          ? [
              {
                uid: question.artifactId,
                name: question.artifactName || '',
              },
            ]
          : []
      }
    />
  )
}

const useSetQuestionArtifactId = (questionId: string) => {
  const setQuestionArtifactId = useMutation(
    ({ storage }, artifactId?: string, artifactName?: string) => {
      const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
      const question = storage.get('questions').get(questionIndex) as FileQuestionType
      storage.get('questions').set(questionIndex, {
        ...question,
        artifactId: artifactId,
        artifactName: artifactName,
        status: artifactId ? 'needsReview' : 'unanswered',
      })
    },
    [],
  )

  return setQuestionArtifactId
}
