import React from 'react'
import { Link } from 'react-router-dom'

import { IconsToolBar } from '@/components/icons-tool-bar'

import { LinkTabs, LinkTabsProps } from './link-tabs'

import LemaIcon from '@/assets/lema.svg'

interface NavigationBarProps extends LinkTabsProps {}

export const NavigationBar: React.FC<NavigationBarProps> = ({ tabs }) => {
  return (
    <>
      <div className='bg-gray-50'>
        <div className='m-auto flex max-w-screen-3xl justify-between px-8 py-2'>
          <div className='flex items-center gap-16'>
            <Link to='/inventory'>
              <LemaIcon className='pb-1' />
            </Link>
            <LinkTabs tabs={tabs} />
          </div>
          <IconsToolBar />
        </div>
      </div>
    </>
  )
}
