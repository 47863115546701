import { TemplateQuestion } from '@/gen/questionnaire/v1/template_service_pb'

import { cn } from '@/lib/style-helpers'

import { EditableQuestion } from '@/pages/questionnaire-template/editable-question'

import { Separator } from '@/components/ui/separator'

type SubCategoryProps = {
  questionList: TemplateQuestion[]
}

export const SubCategory = ({ questionList }: SubCategoryProps) => {
  const [firstQuestion, ...remainingQuestions] = questionList

  return (
    <>
      <EditableQuestion
        leadingTogglerEnabled={remainingQuestions.length > 0}
        question={firstQuestion}
      />

      {remainingQuestions.length > 0 && (
        <div
          className={cn('flex gap-3 transition-all duration-300', {
            'gap-0': !firstQuestion.leadingQuestion,
          })}
        >
          <div
            className={cn(
              'flex w-0 scale-x-0 justify-center rounded bg-gray-50 pt-3 transition-all duration-150',
              {
                'w-8 scale-x-100': firstQuestion.leadingQuestion,
              },
            )}
          />

          <div className='flex w-full flex-col gap-6'>
            {remainingQuestions.map((question) => (
              <div key={question.id} className=''>
                <EditableQuestion question={question} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='mr-11 last:hidden'>
        <Separator />
      </div>
    </>
  )
}
