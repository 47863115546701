import { Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { useRef, useState } from 'react'

import {
  DocumentMetadata,
  UploadFiles,
  UploadFilesRef,
} from '@/pages/settings-page/artifacts-section/upload-files'

import { Button } from '@/components/ui/button'

type UseUploadDocumentsModalOptions = {
  metadata?: DocumentMetadata
  onSuccess?: () => void
  allowedFileTypes?: string[]
}

export const useUploadDocumentsModal = ({
  metadata,
  allowedFileTypes,
  onSuccess,
}: UseUploadDocumentsModalOptions = {}) => {
  const [isUploadEnabled, setIsUploadEnabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const uploadRef = useRef<UploadFilesRef>(null)

  const showModal = () => setIsOpen(true)

  const [isLoading, setIsLoading] = useState(false)
  const handleUpload = async () => {
    setIsLoading(true)
    try {
      await uploadRef.current?.handleUpload()
      onSuccess?.()
      setIsOpen(false)
    } finally {
      setIsLoading(false)
    }
  }

  const renderModal = () => (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={
        <div className='flex items-end justify-between'>
          <Button variant={'ghost'} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            style={{ marginTop: 16 }}
            disabled={!isUploadEnabled || isLoading}
          >
            {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
            Save & Close
          </Button>
        </div>
      }
    >
      <UploadFiles
        ref={uploadRef}
        setIsUploadEnabled={setIsUploadEnabled}
        metadata={metadata}
        allowedFileTypes={allowedFileTypes}
      />
    </Modal>
  )

  return { showModal, renderModal }
}
