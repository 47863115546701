import { Position, useReactFlow } from '@xyflow/react'
import { ChangeEvent, memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'

type LLMNodeProps = BaseNodeProps & {
  data: {
    llmPrompt: string
  }
  id: string
}

export const LLMNode: React.FC<LLMNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='LLM Node'
      inputHandles={[
        {
          id: `llm-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
      dataInputs={[
        {
          id: `llm-${id}-statement`,
          name: 'llmPrompt',
          type: 'text',
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            updateNodeData(id, { llmPrompt: event.target.value }),
          value: data.llmPrompt,
          placeholder: 'Enter statement',
        },
      ]}
      outputHandles={[
        {
          id: `llm-${id}-matched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Matched',
          borderColor: 'green',
        },
        {
          id: `llm-${id}-unmatched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Unmatched',
          borderColor: 'red',
        },
      ]}
    />
  )
})

LLMNode.displayName = 'LLMNode'
