import { QuestionType, TemplateQuestion } from '@/gen/questionnaire/v1/template_service_pb'

import { CheckboxQuestion } from './checkbox'
import { DateQuestion } from './date'
import { FileQuestion } from './file'
import { FreeTextQuestion } from './free-text'
import { RadioQuestion } from './radio'

export type BaseEditableQuestionProps = {
  question: TemplateQuestion
  leadingTogglerEnabled?: boolean
}

export const EditableQuestion = ({ question, ...props }: BaseEditableQuestionProps) => {
  switch (question.type) {
    case QuestionType.CHECKBOX:
      return <CheckboxQuestion question={question} {...props} />
    case QuestionType.RADIO:
      return <RadioQuestion question={question} {...props} />
    case QuestionType.FREE_TEXT:
      return <FreeTextQuestion question={question} {...props} />
    case QuestionType.DATE:
      return <DateQuestion question={question} {...props} />
    case QuestionType.FILE:
      return <FileQuestion question={question} {...props} />
    default:
      return null
  }
}
