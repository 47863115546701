import { useGetQuestionnaireMarkdown } from '@/api/get-questionnaire-markdown.hook'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { Soc2Section } from '@/pages/company-drawer/artifact/soc2-report-section'

import { Markdown } from '@/components/markdown'
import { TabItem } from '@/components/tabs/types'
import { VerticalTabs } from '@/components/tabs/vertical'

import { CompanyArtifactTable } from './company-artifact-table'
import { SubProcessorTable } from './sub-processors-table'

interface ArtifactSectionProps {
  companyId: string
  companyName: string
}

export const ArtifactSection = ({ companyId, companyName }: ArtifactSectionProps) => {
  const { isEnabled: soc2Enabled } = useFeatureFlagEnabled('soc2-report-assessment')
  const { isEnabled: subprocessorsEnabled } = useFeatureFlagEnabled('third-party-subprocessors')
  const { isEnabled: questionnaireMarkdownEnabled } = useFeatureFlagEnabled(
    'third-party-questionnaire-markdown',
  )
  const { data: questionnaireMarkdown } = useGetQuestionnaireMarkdown(companyId)

  const items: TabItem[] = [
    {
      label: 'All Artifacts',
      component: <CompanyArtifactTable companyId={companyId} companyName={companyName} />,
    },
    {
      label: 'SOC2 Type ||',
      itemDisabled: !soc2Enabled,
      component: soc2Enabled && <Soc2Section companyName={companyName} companyId={companyId} />,
    },
  ]

  if (questionnaireMarkdownEnabled) {
    items.push({
      label: 'Questionnaires',
      itemDisabled: !questionnaireMarkdown,
      component: <Markdown>{questionnaireMarkdown}</Markdown>,
    })
  }

  if (subprocessorsEnabled) {
    items.push({
      label: 'Subprocessors',
      component: <SubProcessorTable companyId={companyId} />,
    })
  }

  return <VerticalTabs labelAsTitle items={items} />
}
