import { Drawer } from 'antd'

import { useListInherentRiskCategories } from '@/api/company.hook'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { SMALL_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_HIGH } from '@/pages/drawers/z-index'

import { IrqCarousel } from './irq-carousel'

export const IrqDrawer = () => {
  const {
    urlParams: { irq: thirdPartyId },
    removeParam,
  } = useUrlParams()

  const closeDrawer = () => removeParam(UrlParam.IRQ)

  const {
    data: inherentRiskCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useListInherentRiskCategories(thirdPartyId || '')

  return (
    <Drawer
      zIndex={Z_INDEX_HIGH}
      width={SMALL_DRAWER_WIDTH}
      closeIcon={false}
      onClose={closeDrawer}
      open={!!thirdPartyId}
    >
      {thirdPartyId && !categoriesLoading && !categoriesError && (
        <IrqCarousel
          inherentRiskCategories={inherentRiskCategories}
          thirdPartyId={thirdPartyId}
          closeDrawer={closeDrawer}
        />
      )}
    </Drawer>
  )
}
