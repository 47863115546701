import { PreferenceSet } from '@knocklabs/client'
import { ChannelType } from '@knocklabs/types'
import { useEffect } from 'react'

import { useSetPreferences } from '@/api/notifications-knock.hook'

import { workflows } from '@/pages/settings-page/notification-section/workflow.const'

import { OptimisticSwitch } from '@/components/optimistic-backend-toggler'
import { useToast } from '@/components/ui/use-toast'

type KnockPreferenceSwitchProps = {
  workflowId: string
  channelType: ChannelType
  preferences?: PreferenceSet
  disabled?: boolean
}

export const KnockPreferenceSwitch = ({
  workflowId,
  preferences,
  channelType,
  disabled = false,
}: KnockPreferenceSwitchProps) => {
  const { mutateAsync, isError, error } = useSetPreferences()
  const { toast } = useToast()

  useEffect(() => {
    if (isError) {
      toast({
        status: 'error',
        title: 'Error',
        description: (error as Error)?.message || 'Error updating preferences',
      })
    }
  }, [isError, error, toast])

  const onPreferenceChange = async (
    workflowKey: string,
    channelType: ChannelType,
    setting: boolean,
  ) => {
    if (!preferences) {
      return
    }

    await mutateAsync({ workflowKey, channelType, setting })
  }

  return (
    <div className='flex items-center gap-x-2' key={`switch-${workflowId}-${channelType}`}>
      <OptimisticSwitch
        disabled={disabled}
        isError={isError}
        isActive={getWorkflowPreference(workflowId, preferences, channelType) || false}
        setIsActive={async (isActive: boolean) => {
          await onPreferenceChange(workflowId, channelType, isActive)
        }}
      />
    </div>
  )
}

const getWorkflowPreference = (
  workflowId: string,
  preferences: PreferenceSet | undefined,
  channelType: ChannelType,
) => {
  if (!preferences) {
    return false
  }

  if (preferences.workflows && preferences.workflows[workflowId] !== null) {
    const pref = preferences.workflows[workflowId]
    if (pref === undefined) {
      return false
    }
    if (typeof pref === 'boolean') {
      return pref
    }

    if (pref.channel_types && pref.channel_types[channelType] !== null) {
      return pref.channel_types[channelType]
    }
  }

  const workflowCategory = workflows.find((workflow) => workflow.id === workflowId)?.category
  if (preferences.categories && workflowCategory && preferences.categories[workflowCategory]) {
    const pref = preferences.categories[workflowCategory]
    if (pref === undefined) {
      return false
    }

    if (typeof pref === 'boolean') {
      return pref
    }

    if (pref.channel_types && pref.channel_types[channelType] !== null) {
      return pref.channel_types[channelType]
    }
  }

  if (preferences.channel_types && preferences.channel_types[channelType] !== null) {
    const pref = preferences.channel_types[channelType]
    return pref || false
  }

  return false
}
