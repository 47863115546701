import { Modal } from 'antd'
import { BookCheckIcon, CheckCircleIcon, XIcon } from 'lucide-react'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useAssessorInviteRecipient,
  useGetQuestionnaire,
  useMutateFinalizeQuestionnaire,
} from '@/api/questionnaire-assessor.hook'
import { QuestionnaireStatus } from '@/gen/questionnaire/v1/model_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { useStorage, useThreads } from '@/lib/liveblocks.config'

import { Header, HeaderProps } from '@/pages/questionnaire/header'
import { ListQuestion } from '@/pages/questionnaire/list-question'
import { QuestionnaireProvider } from '@/pages/questionnaire/questionnaire-provider'
import { SubCategoryRequiredContext } from '@/pages/questionnaire/sub-category-required-provider'
import { useInviteRecipientModal } from '@/pages/questionnaire/use-invite-recipient-modal.hook'

import { Loading } from '@/components/loading'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

export const AssessorQuestionnaire = () => {
  const trackFinalize = useTrackCallback('questionnaire.finalize')
  const { questionnaireId } = useParams<{ questionnaireId: string }>()
  const { questionnaire, isLoading } = useGetQuestionnaire({ questionnaireId: questionnaireId! })
  const { mutateAsync } = useAssessorInviteRecipient()
  const { renderModal: renderFinishQuestionnaireModal, openModal: openFinishQuestionnaireModal } =
    useFinalizeQuestionnaireModal(questionnaireId)
  const { renderModal: renderInviteModal, openModal: openInviteModal } = useInviteRecipientModal({
    mutateFunc: async (recipientEmails: string[]) =>
      await mutateAsync({
        recipients: recipientEmails,
        questionnaireId: questionnaireId,
      }),
  })

  if (isLoading || !questionnaire?.roomId) {
    return <Loading />
  }

  const role =
    questionnaire.status === QuestionnaireStatus.ONGOING ? 'assessor' : 'assessor-readonly'

  return (
    <QuestionnaireProvider roomId={questionnaire.roomId}>
      <AssessorHeader
        title={questionnaire.name}
        description={questionnaire.description}
        dueDate={questionnaire.dueDate?.toDate() || new Date()}
        activeStatusFilters={['needsReview', 'unanswered', 'rejected', 'postponed']}
        recipients={questionnaire.recipients}
        onRequestInviteRecipient={() => openInviteModal()}
        onSubmit={() => {
          openFinishQuestionnaireModal()
          trackFinalize({
            questionnaireId: questionnaire.id,
            recipients: questionnaire.recipients,
            title: questionnaire.name,
            description: questionnaire.description,
            dueDate: questionnaire.dueDate?.toDate(),
          })
        }}
        questionnaireStatus={questionnaire.status}
      />
      <ListQuestion participantRole={role} />
      {renderInviteModal()}
      {renderFinishQuestionnaireModal()}
    </QuestionnaireProvider>
  )
}

type AssessorHeaderProps = Omit<HeaderProps, 'submit' | 'questionnaireStatus'> & {
  onSubmit?: () => void
  questionnaireStatus: QuestionnaireStatus
}

const AssessorHeader = ({
  questionnaireStatus,
  onSubmit = () => {},
  ...props
}: AssessorHeaderProps) => {
  const { subCategoriesRequired } = useContext(SubCategoryRequiredContext)
  const remainingQuestions = useStorage(({ questions }) => {
    const requiredQuestions = subCategoriesRequired
      ? questions.filter((q) => q.isLeading || subCategoriesRequired[q.subCategory] === 'required')
      : questions

    return requiredQuestions.filter(
      (q) => q.status === 'unanswered' || q.status === 'needsReview' || q.status === 'rejected',
    )
  })
  const { threads: unresolvedThreads, isLoading } = useThreads({
    query: {
      metadata: {
        resolved: false,
      },
    },
  })
  const resolvedAllThreads = unresolvedThreads?.length === 0
  const reviewedAllQuestions = remainingQuestions?.length === 0
  const disabled =
    isLoading ||
    !resolvedAllThreads ||
    !reviewedAllQuestions ||
    questionnaireStatus !== QuestionnaireStatus.ONGOING

  return (
    <Header
      questionnaireStatus={questionnaireStatus}
      {...props}
      submit={
        <div className='flex flex-col gap-y-1'>
          <SubmitQuestionnaireButton onClick={onSubmit} disabled={disabled} />
          <ValidationStatus message='reviewed all questions' isValid={reviewedAllQuestions} />
          <ValidationStatus message='resolved all threads' isValid={resolvedAllThreads} />
        </div>
      }
    />
  )
}

const ValidationStatus = ({ isValid, message }: { isValid: boolean; message: string }) => {
  return (
    <span className='flex items-center gap-2'>
      {isValid ? (
        <CheckCircleIcon size={12} className='text-green-600' />
      ) : (
        <XIcon className='text-red-600' size={12} />
      )}
      {message}
    </span>
  )
}

type SubmitQuestionnaireButtonProps = {
  onClick?: () => void
  disabled?: boolean
}

const SubmitQuestionnaireButton = ({ disabled, onClick }: SubmitQuestionnaireButtonProps) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      <BookCheckIcon size={16} strokeWidth={2.5} className='mr-2' />
      <span className='text-nowrap'>Finalize & Summarize</span>
    </Button>
  )
}

const useFinalizeQuestionnaireModal = (questionnaireId?: string) => {
  const { mutateAsync } = useMutateFinalizeQuestionnaire()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { toast } = useToast()

  const openModal = () => {
    if (!questionnaireId) return
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const renderModal = () => (
    <Modal
      confirmLoading={isLoading}
      title={'Finish Review'}
      okText={'Confirm'}
      onOk={async () => {
        setIsLoading(true)
        try {
          await mutateAsync({ id: questionnaireId })
        } catch (e) {
          console.error('Error finalizing questionnaire:', e)
          toast({
            status: 'error',
            title: 'Error finishing questionnaire',
            description: (e as { message: string }).message || '',
          })
          setIsLoading(false)
          return
        }
        setOpen(false)
        toast({
          status: 'success',
          title: 'Review Finished Successfully!',
        })
        setIsLoading(false)
      }}
      open={open}
      onCancel={() => setOpen(false)}
    >
      <p className='flex flex-col gap-y-2'>
        <span>By clicking confirm, you will finalize the questionnaire review 🎉</span>
        <ol className='flex list-inside list-decimal flex-col gap-y-1'>
          <li>All answers will be available in the third-party tray for future reference.</li>
          <li>
            Any rejected answer will be added as a finding so you can track third-party progress in
            fixing the issues.
          </li>
        </ol>
      </p>
    </Modal>
  )

  return { openModal, closeModal, renderModal }
}
