import { formatISO, parseISO } from 'date-fns'
import { CalendarIcon, InfoIcon } from 'lucide-react'

import { formatDate } from '@/lib/date'
import { type DateQuestionType, isAssessor, useMutation } from '@/lib/liveblocks.config'

import { DatePicker } from '@/components/date/picker'
import { TextWithIcon } from '@/components/text-with-icon'

import { QuestionContainer, QuestionContainerProps } from './container'

interface DateQuestionProps extends QuestionContainerProps {
  question: DateQuestionType
}

export const DateQuestion = (props: DateQuestionProps) => {
  const { question, participantRole } = props
  const setQuestionDate = useSetQuestionDate(question.id)
  const date = question.dateAnswer ? parseISO(question.dateAnswer) : undefined

  return (
    <QuestionContainer {...props}>
      {!isAssessor(participantRole) && (
        <DatePicker
          initialDate={date}
          onDateChange={(newDate) => setQuestionDate(formatISO(newDate))}
        />
      )}
      {isAssessor(participantRole) && <AssessorDateLabel date={date} />}
    </QuestionContainer>
  )
}

const AssessorDateLabel = ({ date }: { date?: Date }) => {
  if (!date) {
    return <TextWithIcon icon={<InfoIcon size={14} />} text='No date selected' />
  }
  return <TextWithIcon icon={<CalendarIcon size={14} />} text={formatDate(date)} />
}

const useSetQuestionDate = (questionId: string) => {
  const setQuestionDate = useMutation(({ storage }, dateAnswer: string) => {
    const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex) as DateQuestionType
    storage.get('questions').set(questionIndex, {
      ...question,
      dateAnswer,
      status: dateAnswer !== '' ? 'needsReview' : 'unanswered',
    })
  }, [])

  return setQuestionDate
}
