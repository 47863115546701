import { Modal } from 'antd'
import { RcFile } from 'antd/es/upload'
import { Loader2Icon } from 'lucide-react'
import { useState } from 'react'

import { useUploadQuestionnaireTemplate } from '@/api/upload-questionnaire-template.hook'

import { useReportErrorsCallback } from '@/lib/error-reporting'
import { rcFileToUint8Array } from '@/lib/rc-file-to-unit-8-array'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { Upload } from '@/components/upload/upload'

export const useImportTemplateModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const reportErrors = useReportErrorsCallback()
  const { toast } = useToast()
  const [fileList, setFileContent] = useState<RcFile[] | null>(null)
  const { mutateAsync } = useUploadQuestionnaireTemplate()
  const [questionnaireTemplateName, setQuestionnaireTemplateName] = useState<string>('')
  const [questionnaireTemplateDescription, setQuestionnaireTemplateDescription] = useState<string>()

  const showModal = () => setIsOpen(true)

  const handelCloseModal = () => {
    setIsLoading(false)
    setQuestionnaireTemplateName('')
    setQuestionnaireTemplateDescription('')
    setFileContent(null)
    setIsOpen(false)
  }

  const isImportEnabled = fileList?.length === 1 && questionnaireTemplateName

  const handelSubmit = async () => {
    if (isLoading) return
    if (!isImportEnabled) {
      toast({
        title: 'Error',
        description: 'Please provide a template name and upload a file',
        status: 'error',
      })
      return
    }
    setIsLoading(true)
    try {
      const fileContent = await rcFileToUint8Array(fileList[0])
      await mutateAsync({
        template: fileContent,
        name: questionnaireTemplateName,
        description: questionnaireTemplateDescription,
      })
      toast({
        title: 'Template imported successfully',
        status: 'success',
      })
      handelCloseModal()
    } catch (error) {
      reportErrors(error as Error)
      toast({
        title: 'Error',
        description: 'Failed to upload questionnaire template',
        status: 'error',
      })
    }
    setIsLoading(false)
  }

  const renderModal = () => (
    <Modal
      footer={
        <div className='flex justify-end gap-3'>
          <Button variant={'outline'} onClick={handelCloseModal}>
            Cancel
          </Button>
          <Button disabled={isLoading || !isImportEnabled} onClick={handelSubmit}>
            {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />} Import
          </Button>
        </div>
      }
      width={670}
      open={isOpen}
      onCancel={handelCloseModal}
    >
      <div className='mx-auto max-w-xl'>
        <h1 className='mb-4 text-lg'>Import Questionnaire Template</h1>
        <h2>Template Name</h2>
        <Input
          className='mb-4'
          placeholder='Questionnaire Template Name'
          value={questionnaireTemplateName}
          onChange={(e) => setQuestionnaireTemplateName(e.target.value)}
        />

        <h2>Template Description (optional)</h2>
        <Textarea
          className='mb-4'
          placeholder='Questionnaire Template Description'
          value={questionnaireTemplateDescription}
          onChange={(e) => setQuestionnaireTemplateDescription(e.target.value)}
        />
        <Upload
          fileList={fileList || []}
          onRemove={() => setFileContent(null)}
          name='questionnaire-template'
          maxCount={1}
          customRequest={async ({ file, onSuccess, onError }) => {
            try {
              const fileContent = file as RcFile
              setFileContent([fileContent])
              onSuccess && onSuccess(() => {})
            } catch (error) {
              onError && onError(new Error('Failed to upload questionnaire template'))
            }
          }}
        />
      </div>
    </Modal>
  )

  return { showModal, renderModal }
}
