// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file webhook/v1/webhook_service.proto (package webhook.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message webhook.v1.GetWebhookURLRequest
 */
export class GetWebhookURLRequest extends Message<GetWebhookURLRequest> {
  /**
   * @generated from field: string identifier = 1;
   */
  identifier = "";

  /**
   * @generated from field: string source = 2;
   */
  source = "";

  constructor(data?: PartialMessage<GetWebhookURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "webhook.v1.GetWebhookURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWebhookURLRequest {
    return new GetWebhookURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWebhookURLRequest {
    return new GetWebhookURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWebhookURLRequest {
    return new GetWebhookURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetWebhookURLRequest | PlainMessage<GetWebhookURLRequest> | undefined, b: GetWebhookURLRequest | PlainMessage<GetWebhookURLRequest> | undefined): boolean {
    return proto3.util.equals(GetWebhookURLRequest, a, b);
  }
}

/**
 * @generated from message webhook.v1.GetWebhookURLResponse
 */
export class GetWebhookURLResponse extends Message<GetWebhookURLResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetWebhookURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "webhook.v1.GetWebhookURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWebhookURLResponse {
    return new GetWebhookURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWebhookURLResponse {
    return new GetWebhookURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWebhookURLResponse {
    return new GetWebhookURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetWebhookURLResponse | PlainMessage<GetWebhookURLResponse> | undefined, b: GetWebhookURLResponse | PlainMessage<GetWebhookURLResponse> | undefined): boolean {
    return proto3.util.equals(GetWebhookURLResponse, a, b);
  }
}

/**
 * @generated from message webhook.v1.GetVendrWebhookURLResponse
 */
export class GetVendrWebhookURLResponse extends Message<GetVendrWebhookURLResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetVendrWebhookURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "webhook.v1.GetVendrWebhookURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVendrWebhookURLResponse {
    return new GetVendrWebhookURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVendrWebhookURLResponse {
    return new GetVendrWebhookURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVendrWebhookURLResponse {
    return new GetVendrWebhookURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVendrWebhookURLResponse | PlainMessage<GetVendrWebhookURLResponse> | undefined, b: GetVendrWebhookURLResponse | PlainMessage<GetVendrWebhookURLResponse> | undefined): boolean {
    return proto3.util.equals(GetVendrWebhookURLResponse, a, b);
  }
}

/**
 * @generated from message webhook.v1.GetVendrWebhookURLRequest
 */
export class GetVendrWebhookURLRequest extends Message<GetVendrWebhookURLRequest> {
  /**
   * @generated from field: string identifier = 1;
   */
  identifier = "";

  /**
   * @generated from field: string secret = 2;
   */
  secret = "";

  constructor(data?: PartialMessage<GetVendrWebhookURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "webhook.v1.GetVendrWebhookURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVendrWebhookURLRequest {
    return new GetVendrWebhookURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVendrWebhookURLRequest {
    return new GetVendrWebhookURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVendrWebhookURLRequest {
    return new GetVendrWebhookURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVendrWebhookURLRequest | PlainMessage<GetVendrWebhookURLRequest> | undefined, b: GetVendrWebhookURLRequest | PlainMessage<GetVendrWebhookURLRequest> | undefined): boolean {
    return proto3.util.equals(GetVendrWebhookURLRequest, a, b);
  }
}

