import { Table as AntDTable, TableProps as AntDTableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { useContext, useMemo } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterProviderContext } from '@/components/table/filter/context'
import { sortIcon } from '@/components/table/sort-icon'
import { generateAntColumn } from '@/components/table/table-generator'
import { ColumnType } from '@/components/table/table.type'

export type NestedTableProps<T> = {
  columns: ColumnType<T>[]
  dataSource: T[]
} & Omit<AntDTableProps<T>, 'columns' | 'dataSource'>

export const NestedTable = <T extends AnyObject>({
  columns,
  dataSource,
  ...props
}: NestedTableProps<T>) => {
  const { filteredState, searchState } = useContext(FilterProviderContext)

  const antColumns = useMemo(
    () => columns.map((col) => generateAntColumn(col, filteredState, searchState, sortIcon)),
    [columns, filteredState, searchState],
  )

  const dataSourceWithKey = useMemo(
    () =>
      dataSource.map((row, index) => ({
        key: row.id ? `${row.id}` : `${index}`,
        ...row,
      })),
    [dataSource],
  )

  return (
    <div
      className={cn('flex w-full flex-col gap-2', {
        'on-row-active': !!props.onRow,
      })}
    >
      <AntDTable
        {...props}
        pagination={false}
        dataSource={dataSourceWithKey}
        columns={antColumns}
      />
    </div>
  )
}
