import { Drawer } from 'antd'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { LARGE_DRAWER_WIDTH, MEDIUM_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_HIGH } from '@/pages/drawers/z-index'
import { FindingDetails } from '@/pages/finding-details/finding-details'

export const FindingDrawer = () => {
  const {
    urlParams: { thirdPartyId, findingId },
    removeParam,
  } = useUrlParams()
  return (
    <Drawer
      zIndex={Z_INDEX_HIGH}
      width={thirdPartyId ? MEDIUM_DRAWER_WIDTH : LARGE_DRAWER_WIDTH}
      closeIcon={false}
      onClose={() => removeParam(UrlParam.FINDING_ID)}
      open={!!findingId}
    >
      {findingId && <FindingDetails findingId={findingId} />}
    </Drawer>
  )
}
