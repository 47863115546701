// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file questionnaire/v1/questionnaire_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetParticipantsRequest, GetParticipantsResponse, GetRoomRequest, GetRoomResponse, GetUploadLinkRequest, GetUploadLinkResponse, GetUserTokenRequest, GetUserTokenResponse, InviteRecipientRequest, InviteRecipientResponse } from "./questionnaire_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service questionnaire.v1.QuestionnaireService
 */
export const QuestionnaireService = {
  typeName: "questionnaire.v1.QuestionnaireService",
  methods: {
    /**
     * the recipient authenticates so he can fill in the questionnaire
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetUserToken
     */
    getUserToken: {
      name: "GetUserToken",
      I: GetUserTokenRequest,
      O: GetUserTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetRoom
     */
    getRoom: {
      name: "GetRoom",
      I: GetRoomRequest,
      O: GetRoomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * the reciepent fills in the questionnaire
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetUploadLink
     */
    getUploadLink: {
      name: "GetUploadLink",
      I: GetUploadLinkRequest,
      O: GetUploadLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * recipients can invite others to fill the questionnaire with/for them
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.InviteRecipient
     */
    inviteRecipient: {
      name: "InviteRecipient",
      I: InviteRecipientRequest,
      O: InviteRecipientResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetParticipants
     */
    getParticipants: {
      name: "GetParticipants",
      I: GetParticipantsRequest,
      O: GetParticipantsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

