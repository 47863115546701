import { ClipboardPenIcon, PlusIcon } from 'lucide-react'

import { useNewQuestionnaireModal } from '@/pages/questionnaire-management/new-questionnaire-modal/use-new-questionnaire-modal.hook'
import { QuestionnaireSection } from '@/pages/questionnaire-management/questionnaires-section'
import { TemplatesSection } from '@/pages/questionnaire-management/templates-section'

import { InventoryHeader } from '@/components/inventory-header'
import { VerticalTabs } from '@/components/tabs/vertical'
import { Button } from '@/components/ui/button'

export const QuestionnaireManagementPage = () => {
  const { showModal, renderModal } = useNewQuestionnaireModal()
  return (
    <>
      <div className='bg-gray-50'>
        <InventoryHeader
          title='Questionnaires'
          icon={<ClipboardPenIcon className='h-5.5 w-5.5' />}
          actionElement={
            <Button
              onClick={showModal}
              className='flex items-center gap-2 px-4 py-2'
              variant={'outline'}
            >
              <PlusIcon size={14} />
              <span>Start New Questionnaire</span>
            </Button>
          }
        />
      </div>
      <div className='mx-8 mt-10 min-h-screen pb-10'>
        <VerticalTabs
          items={[
            {
              label: 'Questionnaires',
              component: <QuestionnaireSection />,
            },
            {
              label: 'Templates',
              component: <TemplatesSection />,
            },
          ]}
        />
      </div>
      {renderModal()}
    </>
  )
}
