import { useEffect, useState } from 'react'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { useFindingsCountBySeverityTimeseries } from '@/api/statistics.hook'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'
import { CountBySeverity } from '@/gen/inventory/v1/statistics_service_pb'

import { colors } from '@/const/colors'
import { riskLevelLabel } from '@/const/label'

import { riskLevelToColor } from '@/lib/color'
import { formatDate } from '@/lib/date'
import { calculateGrowthPercentage } from '@/lib/math-utils'
import { RISK_LEVELS } from '@/lib/proto-types'

import { EntityCountAndGrowth } from '@/pages/dashboard/entity-count-and-growth'

import { Loading } from '@/components/loading'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { Sentiment } from '@/components/variance-percentage/types'

export const OpenAndOngoingFindingsOverTimeChart = () => {
  const { data, isLoading } = useFindingsCountBySeverityTimeseries()

  const [growthPercentage, setGrowthPercentage] = useState<null | GrowthPercentageData[]>(null)

  useEffect(() => {
    const firstValues = data?.timeseries[0].values
    const lastValues = data?.timeseries[data.timeseries.length - 1].values

    if (firstValues && lastValues) {
      setGrowthPercentage(calculateGrowthPercentageBySeverity(firstValues, lastValues))
    }
  }, [data])

  if (isLoading) return <Loading />

  return (
    <Card className='flex py-5'>
      <div className='w-2/3'>
        <CardHeader className='mx-9 my-6'>
          <CardTitle>Open and Ongoing Findings over Time</CardTitle>
          <CardDescription>
            Showing critical and high severity findings for the last 30 days
          </CardDescription>
        </CardHeader>
        <CardContent className='flex'>
          <ChartContainer
            className='h-60 w-full'
            config={RISK_LEVELS.reduce(
              (acc, { no }) => ({
                ...acc,
                [riskLevelLabel[no]]: {
                  label: riskLevelLabel[no],
                  color: riskLevelToColor[no],
                },
              }),
              {},
            )}
          >
            <AreaChart
              accessibilityLayer
              data={data?.timeseries.map((item) => ({
                timestamp: item.timestamp && formatDate(item.timestamp?.toDate()),
                ...item.values.reduce(
                  (acc, { severity, total }) => ({
                    ...acc,
                    [riskLevelLabel[severity]]: total,
                  }),
                  {},
                ),
              }))}
              margin={{
                left: 12,
                right: 12,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey='timestamp' tickLine={false} axisLine={false} minTickGap={50} />
              <YAxis tickLine={false} axisLine={false} />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='dot' />} />
              <ChartLegend content={<ChartLegendContent />} />

              <defs>
                <linearGradient id='fillHigh' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor={colors.orange[300]} stopOpacity={0.7} />
                  <stop offset='95%' stopColor={colors.orange[300]} stopOpacity={0} />
                </linearGradient>
              </defs>

              <defs>
                <linearGradient id='fillCritical' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor={colors.red[300]} stopOpacity={0.7} />
                  <stop offset='95%' stopColor={colors.red[300]} stopOpacity={0} />
                </linearGradient>
              </defs>

              <Area
                dataKey={riskLevelLabel[RiskLevel.CRITICAL]}
                type='monotone'
                fill='url(#fillCritical)'
                stroke={colors.red[400]}
                strokeWidth={2}
              />
              <Area
                dataKey={riskLevelLabel[RiskLevel.HIGH]}
                type='monotone'
                fill='url(#fillHigh)'
                stroke={colors.orange[400]}
                strokeWidth={2}
              />
            </AreaChart>
          </ChartContainer>
        </CardContent>
      </div>
      <div className='w-1/3'>
        <CardHeader className='mx-9 my-6'>
          <CardTitle>Current Open and Ongoing Findings</CardTitle>
          <CardDescription>Versus 30 days ago</CardDescription>
        </CardHeader>
        <div className='mx-8 flex flex-col gap-4'>
          {growthPercentage?.map(({ severity, currentTotal, growthPercentage }, index) => {
            return (
              <EntityCountAndGrowth
                growthSentiment={Sentiment.NEGATIVE}
                key={index}
                count={currentTotal}
                entityName='findings'
                growthPercentage={growthPercentage}
                severity={severity}
              />
            )
          })}
        </div>
      </div>
    </Card>
  )
}

type GrowthPercentageData = { severity: RiskLevel; currentTotal: number; growthPercentage: number }

function calculateGrowthPercentageBySeverity(
  firstValues: CountBySeverity[],
  lastValues: CountBySeverity[],
): GrowthPercentageData[] {
  const growthPercentageArray: GrowthPercentageData[] = []

  firstValues.forEach((firstValue) => {
    const severityType = firstValue.severity
    const firstTotal = firstValue.total
    const lastTotal = lastValues.find((item) => item.severity === severityType)?.total

    if (lastTotal !== undefined && firstTotal !== 0) {
      const growth = calculateGrowthPercentage(lastTotal, firstTotal) // Use 'a' function to calculate percentage
      growthPercentageArray.push({
        severity: severityType,
        growthPercentage: growth,
        currentTotal: lastTotal,
      })
    } else {
      growthPercentageArray.push({
        severity: severityType,
        currentTotal: lastTotal || 0,
        growthPercentage: 0, // Avoid division by zero
      })
    }
  })

  return growthPercentageArray
}
