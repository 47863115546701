import { ChevronDownIcon, SlidersHorizontalIcon } from 'lucide-react'
import { useContext } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterProviderContext } from '@/components/table/filter/context'
import { Button } from '@/components/ui/button'

export const ShowFilterTagsToggler = () => {
  const { filteredState, showFilterTags, setShowFilterTags } = useContext(FilterProviderContext)

  const totalActiveFilters = Object.values(filteredState).reduce(
    (sum, { value }) => (value?.length || 0) + sum,
    0,
  )

  const filtersActive = totalActiveFilters > 0

  return (
    <Button
      disabled={!filtersActive}
      onClick={() => filtersActive && setShowFilterTags(!showFilterTags)}
      className='flex items-center gap-1 whitespace-nowrap rounded-sm text-base font-light text-gray-400'
      variant='ghost'
    >
      <SlidersHorizontalIcon className='w-3.5' />
      {filtersActive ? (
        <>
          {showFilterTags ? 'Hide' : 'Show'} Filters
          <ChevronDownIcon className={cn({ 'rotate-180': showFilterTags }, 'w-3.5 duration-200')} />
        </>
      ) : (
        'No Filters'
      )}
    </Button>
  )
}
