import { AnyObject } from 'antd/es/_util/type'
import _ from 'lodash'

import { FilteredState } from '@/components/table/filter/filter.hook'
import { filterMatch, getColumnKey, searchMatch } from '@/components/table/table-generator'
import { ArrayElementType, ColumnType } from '@/components/table/table.type'

export const isNestedRowDisplayed = <T extends AnyObject>(
  row: T,
  searchState: string,
  filteredState: FilteredState,
  nestedCols: ColumnType<T>[],
) => {
  return nestedCols.every((col) => {
    const isSearching = col.search && searchState.length > 0
    const key = getColumnKey(col)
    const accessor = col.accessor
    if (isSearching && !searchMatch(accessor?.(row).searchValue, searchState)) return false
    if (!filteredState[key] || !accessor) return true
    return filteredState[key].value?.some((filterValue) => {
      return filterMatch(accessor?.(row).filterValue, filterValue)
    })
  })
}

export const createGroups = <T extends AnyObject, K extends keyof T>(
  dataSource: T[],
  groupByKey: K,
): _.Dictionary<T[]> => {
  if (dataSource.length === 0) return {}

  const isGroupByKeyArray = _.isArray(dataSource[0][groupByKey])

  if (isGroupByKeyArray) {
    return createGroupsFromArrayKey(dataSource, groupByKey)
  } else {
    return _.groupBy(dataSource, groupByKey)
  }
}

const createGroupsFromArrayKey = <T extends AnyObject, K extends keyof T>(
  dataSource: T[],
  groupByKey: K,
): _.Dictionary<T[]> => {
  return dataSource.reduce(
    (acc, row) => {
      const keys = row[groupByKey] as ArrayElementType<T[K]>[]

      keys.forEach((key) => {
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(row)
      })

      return acc
    },
    {} as _.Dictionary<T[]>,
  )
}
