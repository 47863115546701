import { OptionLabel } from '@/pages/questionnaire-template/editable-question/option-label'

import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

import { QuestionContainer, QuestionContainerProps } from './container'

type RadioQuestionProps = QuestionContainerProps

export const RadioQuestion = (props: RadioQuestionProps) => {
  const { question } = props

  return (
    <QuestionContainer {...props}>
      <RadioGroup disabled={true} className='flex flex-col gap-0'>
        {question.options.map((option) => (
          <div key={option.id} className='flex w-full items-center gap-2.5'>
            <RadioGroupItem className='border-gray-400 text-gray-700' value={option.id} />
            <OptionLabel questionId={question.id} option={option} />
          </div>
        ))}
      </RadioGroup>
    </QuestionContainer>
  )
}
