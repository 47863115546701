// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file companycatalog/v1/company_catalog.proto (package companycatalog.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { CompanyStatus } from "../../inventory/v1/company_status_pb.js";
import { RiskLevel } from "../../inventory/v1/risk_pb.js";

/**
 * @generated from enum companycatalog.v1.CompanyLookupStrategy
 */
export enum CompanyLookupStrategy {
  /**
   * @generated from enum value: COMPANY_LOOKUP_STRATEGY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPANY_LOOKUP_STRATEGY_TAKE_FIRST = 1;
   */
  TAKE_FIRST = 1,

  /**
   * @generated from enum value: COMPANY_LOOKUP_STRATEGY_DROP_CONFLICTS = 2;
   */
  DROP_CONFLICTS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CompanyLookupStrategy)
proto3.util.setEnumType(CompanyLookupStrategy, "companycatalog.v1.CompanyLookupStrategy", [
  { no: 0, name: "COMPANY_LOOKUP_STRATEGY_UNSPECIFIED" },
  { no: 1, name: "COMPANY_LOOKUP_STRATEGY_TAKE_FIRST" },
  { no: 2, name: "COMPANY_LOOKUP_STRATEGY_DROP_CONFLICTS" },
]);

/**
 * @generated from enum companycatalog.v1.Platform
 */
export enum Platform {
  /**
   * @generated from enum value: PLATFORM_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PLATFORM_GWS = 1;
   */
  GWS = 1,

  /**
   * @generated from enum value: PLATFORM_MSGRAPH = 2;
   */
  MSGRAPH = 2,

  /**
   * created by internal team
   *
   * @generated from enum value: PLATFORM_MANUAL = 3;
   */
  MANUAL = 3,

  /**
   * @generated from enum value: PLATFORM_WIZ = 4;
   */
  WIZ = 4,

  /**
   * @generated from enum value: PLATFORM_OKTA = 5;
   */
  OKTA = 5,

  /**
   * @generated from enum value: PLATFORM_NETSKOPE = 6;
   */
  NETSKOPE = 6,

  /**
   * @generated from enum value: PLATFORM_ZIPHQ = 7;
   */
  ZIPHQ = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(Platform)
proto3.util.setEnumType(Platform, "companycatalog.v1.Platform", [
  { no: 0, name: "PLATFORM_UNSPECIFIED" },
  { no: 1, name: "PLATFORM_GWS" },
  { no: 2, name: "PLATFORM_MSGRAPH" },
  { no: 3, name: "PLATFORM_MANUAL" },
  { no: 4, name: "PLATFORM_WIZ" },
  { no: 5, name: "PLATFORM_OKTA" },
  { no: 6, name: "PLATFORM_NETSKOPE" },
  { no: 7, name: "PLATFORM_ZIPHQ" },
]);

/**
 * @generated from enum companycatalog.v1.OrgCompanySource
 */
export enum OrgCompanySource {
  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_MANUAL = 2;
   */
  MANUAL = 2,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_GWS = 3;
   */
  GWS = 3,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_MSGRAPH = 4;
   */
  MSGRAPH = 4,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_ZIPHQ = 5;
   */
  ZIPHQ = 5,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_LOGICMANAGER = 6;
   */
  LOGICMANAGER = 6,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_ARTIFACTS = 7;
   */
  ARTIFACTS = 7,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_WIZ = 8;
   */
  WIZ = 8,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_OKTA = 9;
   */
  OKTA = 9,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_NETSKOPE = 10;
   */
  NETSKOPE = 10,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_WEBHOOK = 11;
   */
  WEBHOOK = 11,

  /**
   * @generated from enum value: ORG_COMPANY_SOURCE_VENDR_WEBHOOK = 12;
   */
  VENDR_WEBHOOK = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(OrgCompanySource)
proto3.util.setEnumType(OrgCompanySource, "companycatalog.v1.OrgCompanySource", [
  { no: 0, name: "ORG_COMPANY_SOURCE_UNSPECIFIED" },
  { no: 1, name: "ORG_COMPANY_SOURCE_USER" },
  { no: 2, name: "ORG_COMPANY_SOURCE_MANUAL" },
  { no: 3, name: "ORG_COMPANY_SOURCE_GWS" },
  { no: 4, name: "ORG_COMPANY_SOURCE_MSGRAPH" },
  { no: 5, name: "ORG_COMPANY_SOURCE_ZIPHQ" },
  { no: 6, name: "ORG_COMPANY_SOURCE_LOGICMANAGER" },
  { no: 7, name: "ORG_COMPANY_SOURCE_ARTIFACTS" },
  { no: 8, name: "ORG_COMPANY_SOURCE_WIZ" },
  { no: 9, name: "ORG_COMPANY_SOURCE_OKTA" },
  { no: 10, name: "ORG_COMPANY_SOURCE_NETSKOPE" },
  { no: 11, name: "ORG_COMPANY_SOURCE_WEBHOOK" },
  { no: 12, name: "ORG_COMPANY_SOURCE_VENDR_WEBHOOK" },
]);

/**
 * @generated from message companycatalog.v1.LookupCompanyRequest
 */
export class LookupCompanyRequest extends Message<LookupCompanyRequest> {
  /**
   * @generated from field: repeated companycatalog.v1.CompanyPredicate predicates = 1;
   */
  predicates: CompanyPredicate[] = [];

  /**
   * rpc to lookup multiple ids for the same company and return the best match
   * to be used when there is more than one piece of information about the company and you are not sure which one is the correct one
   *
   * @generated from field: companycatalog.v1.CompanyLookupStrategy strategy = 2;
   */
  strategy = CompanyLookupStrategy.UNSPECIFIED;

  constructor(data?: PartialMessage<LookupCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "predicates", kind: "message", T: CompanyPredicate, repeated: true },
    { no: 2, name: "strategy", kind: "enum", T: proto3.getEnumType(CompanyLookupStrategy) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupCompanyRequest {
    return new LookupCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupCompanyRequest {
    return new LookupCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupCompanyRequest {
    return new LookupCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookupCompanyRequest | PlainMessage<LookupCompanyRequest> | undefined, b: LookupCompanyRequest | PlainMessage<LookupCompanyRequest> | undefined): boolean {
    return proto3.util.equals(LookupCompanyRequest, a, b);
  }
}

/**
 * may return NotFound error
 *
 * @generated from message companycatalog.v1.LookupCompanyResponse
 */
export class LookupCompanyResponse extends Message<LookupCompanyResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<LookupCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupCompanyResponse {
    return new LookupCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupCompanyResponse {
    return new LookupCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupCompanyResponse {
    return new LookupCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LookupCompanyResponse | PlainMessage<LookupCompanyResponse> | undefined, b: LookupCompanyResponse | PlainMessage<LookupCompanyResponse> | undefined): boolean {
    return proto3.util.equals(LookupCompanyResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.LookupOrgCompanyRequest
 */
export class LookupOrgCompanyRequest extends Message<LookupOrgCompanyRequest> {
  /**
   * @generated from field: repeated companycatalog.v1.CompanyPredicate predicates = 1;
   */
  predicates: CompanyPredicate[] = [];

  /**
   * @generated from field: companycatalog.v1.CompanyLookupStrategy strategy = 2;
   */
  strategy = CompanyLookupStrategy.UNSPECIFIED;

  constructor(data?: PartialMessage<LookupOrgCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupOrgCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "predicates", kind: "message", T: CompanyPredicate, repeated: true },
    { no: 2, name: "strategy", kind: "enum", T: proto3.getEnumType(CompanyLookupStrategy) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupOrgCompanyRequest {
    return new LookupOrgCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupOrgCompanyRequest {
    return new LookupOrgCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupOrgCompanyRequest {
    return new LookupOrgCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookupOrgCompanyRequest | PlainMessage<LookupOrgCompanyRequest> | undefined, b: LookupOrgCompanyRequest | PlainMessage<LookupOrgCompanyRequest> | undefined): boolean {
    return proto3.util.equals(LookupOrgCompanyRequest, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.LookupOrgCompanyResponse
 */
export class LookupOrgCompanyResponse extends Message<LookupOrgCompanyResponse> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: string org_company_id = 2;
   */
  orgCompanyId = "";

  constructor(data?: PartialMessage<LookupOrgCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupOrgCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupOrgCompanyResponse {
    return new LookupOrgCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupOrgCompanyResponse {
    return new LookupOrgCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupOrgCompanyResponse {
    return new LookupOrgCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LookupOrgCompanyResponse | PlainMessage<LookupOrgCompanyResponse> | undefined, b: LookupOrgCompanyResponse | PlainMessage<LookupOrgCompanyResponse> | undefined): boolean {
    return proto3.util.equals(LookupOrgCompanyResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.LookupSolutionRequest
 */
export class LookupSolutionRequest extends Message<LookupSolutionRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: companycatalog.v1.Platform platform = 2;
   */
  platform = Platform.UNSPECIFIED;

  /**
   * @generated from field: string platform_app_id = 3;
   */
  platformAppId = "";

  constructor(data?: PartialMessage<LookupSolutionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupSolutionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "platform", kind: "enum", T: proto3.getEnumType(Platform) },
    { no: 3, name: "platform_app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupSolutionRequest {
    return new LookupSolutionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupSolutionRequest {
    return new LookupSolutionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupSolutionRequest {
    return new LookupSolutionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookupSolutionRequest | PlainMessage<LookupSolutionRequest> | undefined, b: LookupSolutionRequest | PlainMessage<LookupSolutionRequest> | undefined): boolean {
    return proto3.util.equals(LookupSolutionRequest, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.LookupSolutionResponse
 */
export class LookupSolutionResponse extends Message<LookupSolutionResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<LookupSolutionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.LookupSolutionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupSolutionResponse {
    return new LookupSolutionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupSolutionResponse {
    return new LookupSolutionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupSolutionResponse {
    return new LookupSolutionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LookupSolutionResponse | PlainMessage<LookupSolutionResponse> | undefined, b: LookupSolutionResponse | PlainMessage<LookupSolutionResponse> | undefined): boolean {
    return proto3.util.equals(LookupSolutionResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.CreateUnverifiedOrgCompanyRequest
 */
export class CreateUnverifiedOrgCompanyRequest extends Message<CreateUnverifiedOrgCompanyRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: optional string website = 2;
   */
  website?: string;

  /**
   * @generated from field: inventory.v1.CompanyStatus status = 3;
   */
  status = CompanyStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel inherent_risk = 4;
   */
  inherentRisk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: companycatalog.v1.OrgCompanySource source = 5;
   */
  source = OrgCompanySource.UNSPECIFIED;

  /**
   * @generated from field: optional string external_id = 6;
   */
  externalId?: string;

  constructor(data?: PartialMessage<CreateUnverifiedOrgCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.CreateUnverifiedOrgCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
    { no: 4, name: "inherent_risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(OrgCompanySource) },
    { no: 6, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUnverifiedOrgCompanyRequest {
    return new CreateUnverifiedOrgCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUnverifiedOrgCompanyRequest {
    return new CreateUnverifiedOrgCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUnverifiedOrgCompanyRequest {
    return new CreateUnverifiedOrgCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUnverifiedOrgCompanyRequest | PlainMessage<CreateUnverifiedOrgCompanyRequest> | undefined, b: CreateUnverifiedOrgCompanyRequest | PlainMessage<CreateUnverifiedOrgCompanyRequest> | undefined): boolean {
    return proto3.util.equals(CreateUnverifiedOrgCompanyRequest, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.CreateUnverifiedOrgCompanyResponse
 */
export class CreateUnverifiedOrgCompanyResponse extends Message<CreateUnverifiedOrgCompanyResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CreateUnverifiedOrgCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.CreateUnverifiedOrgCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUnverifiedOrgCompanyResponse {
    return new CreateUnverifiedOrgCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUnverifiedOrgCompanyResponse {
    return new CreateUnverifiedOrgCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUnverifiedOrgCompanyResponse {
    return new CreateUnverifiedOrgCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUnverifiedOrgCompanyResponse | PlainMessage<CreateUnverifiedOrgCompanyResponse> | undefined, b: CreateUnverifiedOrgCompanyResponse | PlainMessage<CreateUnverifiedOrgCompanyResponse> | undefined): boolean {
    return proto3.util.equals(CreateUnverifiedOrgCompanyResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.UpsertOrgCompanyRequest
 */
export class UpsertOrgCompanyRequest extends Message<UpsertOrgCompanyRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * name and website will be taken into account only if the org_company did not exist before
   * in that case, name and website will be set on org_company in case the linked company has a different name or website
   *
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional string website = 3;
   */
  website?: string;

  /**
   * status and risk will be set only if the org_company did not exist before
   *
   * @generated from field: inventory.v1.CompanyStatus status = 4;
   */
  status = CompanyStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel inherent_risk = 5;
   */
  inherentRisk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: companycatalog.v1.OrgCompanySource source = 6;
   */
  source = OrgCompanySource.UNSPECIFIED;

  /**
   * @generated from field: optional string external_id = 7;
   */
  externalId?: string;

  constructor(data?: PartialMessage<UpsertOrgCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.UpsertOrgCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
    { no: 5, name: "inherent_risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 6, name: "source", kind: "enum", T: proto3.getEnumType(OrgCompanySource) },
    { no: 7, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertOrgCompanyRequest {
    return new UpsertOrgCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertOrgCompanyRequest {
    return new UpsertOrgCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertOrgCompanyRequest {
    return new UpsertOrgCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertOrgCompanyRequest | PlainMessage<UpsertOrgCompanyRequest> | undefined, b: UpsertOrgCompanyRequest | PlainMessage<UpsertOrgCompanyRequest> | undefined): boolean {
    return proto3.util.equals(UpsertOrgCompanyRequest, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.UpsertOrgCompanyResponse
 */
export class UpsertOrgCompanyResponse extends Message<UpsertOrgCompanyResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<UpsertOrgCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.UpsertOrgCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertOrgCompanyResponse {
    return new UpsertOrgCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertOrgCompanyResponse {
    return new UpsertOrgCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertOrgCompanyResponse {
    return new UpsertOrgCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertOrgCompanyResponse | PlainMessage<UpsertOrgCompanyResponse> | undefined, b: UpsertOrgCompanyResponse | PlainMessage<UpsertOrgCompanyResponse> | undefined): boolean {
    return proto3.util.equals(UpsertOrgCompanyResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.AutocompleteCompanyRequest
 */
export class AutocompleteCompanyRequest extends Message<AutocompleteCompanyRequest> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  constructor(data?: PartialMessage<AutocompleteCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.AutocompleteCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCompanyRequest {
    return new AutocompleteCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCompanyRequest {
    return new AutocompleteCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCompanyRequest {
    return new AutocompleteCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCompanyRequest | PlainMessage<AutocompleteCompanyRequest> | undefined, b: AutocompleteCompanyRequest | PlainMessage<AutocompleteCompanyRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteCompanyRequest, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.AutocompleteCompanyResponse
 */
export class AutocompleteCompanyResponse extends Message<AutocompleteCompanyResponse> {
  /**
   * @generated from field: repeated companycatalog.v1.AutocompleteItem companies = 1;
   */
  companies: AutocompleteItem[] = [];

  constructor(data?: PartialMessage<AutocompleteCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.AutocompleteCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "companies", kind: "message", T: AutocompleteItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCompanyResponse {
    return new AutocompleteCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCompanyResponse {
    return new AutocompleteCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCompanyResponse {
    return new AutocompleteCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCompanyResponse | PlainMessage<AutocompleteCompanyResponse> | undefined, b: AutocompleteCompanyResponse | PlainMessage<AutocompleteCompanyResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteCompanyResponse, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.AutocompleteItem
 */
export class AutocompleteItem extends Message<AutocompleteItem> {
  /**
   * @generated from oneof companycatalog.v1.AutocompleteItem.company_identification
   */
  companyIdentification: {
    /**
     * @generated from field: string id = 1;
     */
    value: string;
    case: "id";
  } | {
    /**
     * @generated from field: string pdl_id = 2;
     */
    value: string;
    case: "pdlId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string domain = 4;
   */
  domain = "";

  /**
   * @generated from field: string industry = 5;
   */
  industry = "";

  /**
   * @generated from field: string company_size = 6;
   */
  companySize = "";

  /**
   * true if the company is already added to the org
   *
   * @generated from field: bool is_added = 7;
   */
  isAdded = false;

  /**
   * 0-1, 1 is exact match
   *
   * @generated from field: double similarity_score = 8;
   */
  similarityScore = 0;

  constructor(data?: PartialMessage<AutocompleteItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.AutocompleteItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "company_identification" },
    { no: 2, name: "pdl_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "company_identification" },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "industry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "company_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_added", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "similarity_score", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteItem {
    return new AutocompleteItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteItem {
    return new AutocompleteItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteItem {
    return new AutocompleteItem().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteItem | PlainMessage<AutocompleteItem> | undefined, b: AutocompleteItem | PlainMessage<AutocompleteItem> | undefined): boolean {
    return proto3.util.equals(AutocompleteItem, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.CompanyPredicate
 */
export class CompanyPredicate extends Message<CompanyPredicate> {
  /**
   * @generated from oneof companycatalog.v1.CompanyPredicate.predicate
   */
  predicate: {
    /**
     * @generated from field: string id = 1;
     */
    value: string;
    case: "id";
  } | {
    /**
     * @generated from field: string name = 2;
     */
    value: string;
    case: "name";
  } | {
    /**
     * @generated from field: string website = 3;
     */
    value: string;
    case: "website";
  } | {
    /**
     * @generated from field: string pdl_id = 4;
     */
    value: string;
    case: "pdlId";
  } | {
    /**
     * @generated from field: companycatalog.v1.ExternalId external_id = 5;
     */
    value: ExternalId;
    case: "externalId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CompanyPredicate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.CompanyPredicate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "predicate" },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "predicate" },
    { no: 3, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "predicate" },
    { no: 4, name: "pdl_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "predicate" },
    { no: 5, name: "external_id", kind: "message", T: ExternalId, oneof: "predicate" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyPredicate {
    return new CompanyPredicate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyPredicate {
    return new CompanyPredicate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyPredicate {
    return new CompanyPredicate().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyPredicate | PlainMessage<CompanyPredicate> | undefined, b: CompanyPredicate | PlainMessage<CompanyPredicate> | undefined): boolean {
    return proto3.util.equals(CompanyPredicate, a, b);
  }
}

/**
 * @generated from message companycatalog.v1.ExternalId
 */
export class ExternalId extends Message<ExternalId> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: companycatalog.v1.OrgCompanySource source = 2;
   */
  source = OrgCompanySource.UNSPECIFIED;

  constructor(data?: PartialMessage<ExternalId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "companycatalog.v1.ExternalId";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "enum", T: proto3.getEnumType(OrgCompanySource) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalId {
    return new ExternalId().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalId {
    return new ExternalId().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalId {
    return new ExternalId().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalId | PlainMessage<ExternalId> | undefined, b: ExternalId | PlainMessage<ExternalId> | undefined): boolean {
    return proto3.util.equals(ExternalId, a, b);
  }
}

