import { Drawer } from 'antd'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { Control } from '@/pages/control/control'
import { LARGE_DRAWER_WIDTH, MEDIUM_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_HIGH } from '@/pages/drawers/z-index'

export const ControlDrawer = () => {
  const {
    urlParams: { thirdPartyId, thirdPartyControlId, controlId },
    replaceParams,
  } = useUrlParams()
  return (
    <Drawer
      zIndex={Z_INDEX_HIGH}
      width={thirdPartyId ? MEDIUM_DRAWER_WIDTH : LARGE_DRAWER_WIDTH}
      closeIcon={false}
      onClose={() =>
        replaceParams({ remove: [UrlParam.CONTROL_ID, UrlParam.THIRD_PARTY_CONTROL_ID] })
      }
      open={!!controlId}
    >
      {controlId && thirdPartyControlId && (
        <Control controlId={controlId} companyId={thirdPartyControlId} />
      )}
    </Drawer>
  )
}
