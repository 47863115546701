import { Spin } from 'antd'
import { CheckIcon, UnplugIcon } from 'lucide-react'
import { useEffect } from 'react'

import { useRevokeSlackToken, useUserSlackAuthTest } from '@/api/notifications-slack.hook'

import { cn } from '@/lib/style-helpers'

import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

type ConnectSlackButtonProps = {
  slackState: string
  knockChannelIdSlack: string
  slackClientId: string
  slackScope: string
  redirectUri: string
}

export const ConnectUserSlackButton = ({
  slackState,
  knockChannelIdSlack,
  slackClientId,
  slackScope,
  redirectUri,
}: ConnectSlackButtonProps) => {
  const { toast } = useToast()
  const { isLoading, isError, error, data } = useUserSlackAuthTest(knockChannelIdSlack)
  useEffect(() => {
    if (isError) {
      toast({
        status: 'error',
        title: 'Error validating slack connection',
      })
      console.error('Error validating slack connection', error)
    }
  }, [isError, error, toast])

  const params: Record<string, string> = {
    client_id: slackClientId,
    scope: slackScope,
    redirect_uri: redirectUri,
    state: slackState,
    user_scope: '',
  }

  const onClick = () => {
    window.open(
      `https://slack.com/oauth/v2/authorize?${new URLSearchParams(params).toString()}`,
      '_blank',
      'popup,width=600,height=600',
    )
  }

  return (
    <div className='flex items-center justify-between gap-4 rounded border border-gray-200 p-4'>
      <img src='/icons/slack.svg' alt='Slack' width={28} height={28} />
      <span className='mr-auto'>Receive notifications as private messages from our SlackBot</span>
      <Spin spinning={isLoading} size={'small'} />
      {data && data.connected ? (
        <div className='flex items-center gap-1'>
          <DisconnectSlackButton knockChannelIdSlack={knockChannelIdSlack} />
          <div className='flex h-10 items-center gap-x-2 rounded border border-green-100 px-4 py-2 text-green-500'>
            <CheckIcon size={12} /> Connected
          </div>
        </div>
      ) : (
        <Button onClick={onClick} disabled={isLoading}>
          Connect
        </Button>
      )}
    </div>
  )
}

const DisconnectSlackButton = ({ knockChannelIdSlack }: { knockChannelIdSlack: string }) => {
  const { mutateAsync: revokeSlack, isLoading: revokeLoading } =
    useRevokeSlackToken(knockChannelIdSlack)
  return (
    <div
      className={cn(
        'flex h-10 items-center p-2 hover:text-red-500',
        !revokeLoading && 'cursor-pointer',
      )}
      onClick={async () => !revokeLoading && (await revokeSlack())}
    >
      <Tooltip
        trigger={
          revokeLoading ? (
            <Spin spinning={revokeLoading} size={'small'} />
          ) : (
            <UnplugIcon size={16} />
          )
        }
      >
        <span>Disconnect from Slack</span>
      </Tooltip>
    </div>
  )
}
