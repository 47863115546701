import { Skeleton } from '@/components/ui/skeleton'

export const Loading = () => {
  return (
    <div className='flex w-full grid-cols-2 flex-col gap-4 p-10'>
      {[...Array(5)].map((_, index) => (
        <div key={index} className='flex items-center space-x-4'>
          <Skeleton className='size-12 rounded-full' />
          <div className='w-full space-y-2'>
            <Skeleton className='h-4 w-full' />
            <Skeleton className='h-4 w-7/12' />
          </div>
        </div>
      ))}
    </div>
  )
}
