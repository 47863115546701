// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/questionnaire_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Questionnaire, QuestionnaireLink } from "./model_pb.js";

/**
 * @generated from message questionnaire.v1.GetUserTokenRequest
 */
export class GetUserTokenRequest extends Message<GetUserTokenRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetUserTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUserTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserTokenRequest | PlainMessage<GetUserTokenRequest> | undefined, b: GetUserTokenRequest | PlainMessage<GetUserTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetUserTokenRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUserTokenResponse
 */
export class GetUserTokenResponse extends Message<GetUserTokenResponse> {
  /**
   * @generated from field: string user_token = 1;
   */
  userToken = "";

  constructor(data?: PartialMessage<GetUserTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUserTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserTokenResponse | PlainMessage<GetUserTokenResponse> | undefined, b: GetUserTokenResponse | PlainMessage<GetUserTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetUserTokenResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetRoomRequest
 */
export class GetRoomRequest extends Message<GetRoomRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetRoomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetRoomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomRequest {
    return new GetRoomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomRequest | PlainMessage<GetRoomRequest> | undefined, b: GetRoomRequest | PlainMessage<GetRoomRequest> | undefined): boolean {
    return proto3.util.equals(GetRoomRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetRoomResponse
 */
export class GetRoomResponse extends Message<GetRoomResponse> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  /**
   * @generated from field: questionnaire.v1.Questionnaire questionnaire = 2;
   */
  questionnaire?: Questionnaire;

  /**
   * @generated from field: string current_user_name = 3;
   */
  currentUserName = "";

  constructor(data?: PartialMessage<GetRoomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetRoomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "questionnaire", kind: "message", T: Questionnaire },
    { no: 3, name: "current_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomResponse {
    return new GetRoomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomResponse | PlainMessage<GetRoomResponse> | undefined, b: GetRoomResponse | PlainMessage<GetRoomResponse> | undefined): boolean {
    return proto3.util.equals(GetRoomResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUploadLinkRequest
 */
export class GetUploadLinkRequest extends Message<GetUploadLinkRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetUploadLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUploadLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUploadLinkRequest | PlainMessage<GetUploadLinkRequest> | undefined, b: GetUploadLinkRequest | PlainMessage<GetUploadLinkRequest> | undefined): boolean {
    return proto3.util.equals(GetUploadLinkRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUploadLinkResponse
 */
export class GetUploadLinkResponse extends Message<GetUploadLinkResponse> {
  /**
   * @generated from field: string upload_link = 1;
   */
  uploadLink = "";

  /**
   * @generated from field: string artifact_id = 2;
   */
  artifactId = "";

  constructor(data?: PartialMessage<GetUploadLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUploadLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUploadLinkResponse | PlainMessage<GetUploadLinkResponse> | undefined, b: GetUploadLinkResponse | PlainMessage<GetUploadLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetUploadLinkResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.InviteRecipientRequest
 */
export class InviteRecipientRequest extends Message<InviteRecipientRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  /**
   * @generated from field: repeated string recipient_emails = 2;
   */
  recipientEmails: string[] = [];

  constructor(data?: PartialMessage<InviteRecipientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.InviteRecipientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recipient_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InviteRecipientRequest | PlainMessage<InviteRecipientRequest> | undefined, b: InviteRecipientRequest | PlainMessage<InviteRecipientRequest> | undefined): boolean {
    return proto3.util.equals(InviteRecipientRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.InviteRecipientResponse
 */
export class InviteRecipientResponse extends Message<InviteRecipientResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireLink links = 1;
   */
  links: QuestionnaireLink[] = [];

  constructor(data?: PartialMessage<InviteRecipientResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.InviteRecipientResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "links", kind: "message", T: QuestionnaireLink, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InviteRecipientResponse | PlainMessage<InviteRecipientResponse> | undefined, b: InviteRecipientResponse | PlainMessage<InviteRecipientResponse> | undefined): boolean {
    return proto3.util.equals(InviteRecipientResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetParticipantsRequest
 */
export class GetParticipantsRequest extends Message<GetParticipantsRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetParticipantsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetParticipantsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetParticipantsRequest | PlainMessage<GetParticipantsRequest> | undefined, b: GetParticipantsRequest | PlainMessage<GetParticipantsRequest> | undefined): boolean {
    return proto3.util.equals(GetParticipantsRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetParticipantsResponse
 */
export class GetParticipantsResponse extends Message<GetParticipantsResponse> {
  /**
   * @generated from field: repeated string recipients = 1;
   */
  recipients: string[] = [];

  /**
   * @generated from field: repeated string assessors = 2;
   */
  assessors: string[] = [];

  constructor(data?: PartialMessage<GetParticipantsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetParticipantsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "assessors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetParticipantsResponse | PlainMessage<GetParticipantsResponse> | undefined, b: GetParticipantsResponse | PlainMessage<GetParticipantsResponse> | undefined): boolean {
    return proto3.util.equals(GetParticipantsResponse, a, b);
  }
}

