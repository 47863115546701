// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/implication.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.Implication
 */
export enum Implication {
  /**
   * @generated from enum value: IMPLICATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IMPLICATION_COMPLIANCE = 1;
   */
  COMPLIANCE = 1,

  /**
   * @generated from enum value: IMPLICATION_BREACH_IMPACT = 2;
   */
  BREACH_IMPACT = 2,

  /**
   * @generated from enum value: IMPLICATION_BREACH_LIKELIHOOD = 3;
   */
  BREACH_LIKELIHOOD = 3,

  /**
   * @generated from enum value: IMPLICATION_BUSINESS_CONTINUITY = 4;
   */
  BUSINESS_CONTINUITY = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Implication)
proto3.util.setEnumType(Implication, "inventory.v1.Implication", [
  { no: 0, name: "IMPLICATION_UNSPECIFIED" },
  { no: 1, name: "IMPLICATION_COMPLIANCE" },
  { no: 2, name: "IMPLICATION_BREACH_IMPACT" },
  { no: 3, name: "IMPLICATION_BREACH_LIKELIHOOD" },
  { no: 4, name: "IMPLICATION_BUSINESS_CONTINUITY" },
]);

