import { useUser } from '@descope/react-sdk'
import { BellIcon, FileTextIcon, GoalIcon, KeyIcon, PlugIcon, UserIcon } from 'lucide-react'

import { useOrgId } from '@/lib/auth/orgid.hook'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { ControlSection } from '@/pages/settings-page/controls-section/controls-section'
import { NotificationSection } from '@/pages/settings-page/notification-section/notification-section'
import { SsoSection } from '@/pages/settings-page/sso-section/sso-section'
import { UserSection } from '@/pages/settings-page/user-section/user-section'

import { TabItem } from '@/components/tabs/types'

import { ArtifactsSection } from './artifacts-section/artifacts-section'
import { IntegrationSection } from './integration-section/integration-section'

export const useSettingsItems = () => {
  const { isEnabled: notificationsEnabled } = useFeatureFlagEnabled('notification-settings')
  const { isVisible: ssoConfigVisible, isDisabled: ssoConfigDisabled } = useIsSsoEnabled()

  const settingsItems: TabItem[] = [
    {
      index: 'integrations',
      label: 'Integrations',
      labelIcon: <PlugIcon size={14} />,
      component: <IntegrationSection />,
    },
    {
      index: 'usersAndAccess',
      label: 'Users & Access',
      labelIcon: <UserIcon size={14} />,
      component: <UserSection />,
    },
    {
      index: 'artifacts',
      label: 'Artifacts',
      labelIcon: <FileTextIcon size={14} />,
      component: <ArtifactsSection />,
    },
    {
      index: 'controls',
      label: 'Controls',
      labelIcon: <GoalIcon size={14} />,
      component: <ControlSection />,
    },
  ]

  if (notificationsEnabled) {
    settingsItems.push({
      index: 'notifications',
      label: 'Notifications',
      labelIcon: <BellIcon size={14} />,
      component: <NotificationSection />,
    })
  }

  if (ssoConfigVisible) {
    settingsItems.push({
      index: 'sso',
      label: 'SSO',
      labelIcon: <KeyIcon size={14} />,
      component: <SsoSection />,
      itemDisabled: ssoConfigDisabled,
    })
  }

  return settingsItems
}

const useIsSsoEnabled = () => {
  const { isEnabled: featureFlagEnabled } = useFeatureFlagEnabled('sso-settings')
  const { user, isUserLoading } = useUser()
  const { orgId } = useOrgId()
  if (isUserLoading || !user) {
    return {
      isVisible: false,
      isDisabled: true,
    }
  }

  const userRoles = user.userTenants?.find((tenant) => tenant.tenantId === orgId)?.roleNames
  return {
    isVisible: featureFlagEnabled,
    isDisabled: !userRoles?.includes('Tenant Admin'),
  }
}
