// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file webhook/v1/webhook_service.proto (package webhook.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetVendrWebhookURLRequest, GetVendrWebhookURLResponse, GetWebhookURLRequest, GetWebhookURLResponse } from "./webhook_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "webhook.v1.WebhookService";

/**
 * @generated from service webhook.v1.WebhookService
 */
export const WebhookService = {
  typeName: "webhook.v1.WebhookService",
  methods: {
    /**
     * @generated from rpc webhook.v1.WebhookService.GetWebhookURL
     */
    getWebhookURL: {
      name: "GetWebhookURL",
      I: GetWebhookURLRequest,
      O: GetWebhookURLResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc webhook.v1.WebhookService.GetVendrWebhookURL
     */
    getVendrWebhookURL: {
      name: "GetVendrWebhookURL",
      I: GetVendrWebhookURLRequest,
      O: GetVendrWebhookURLResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: WebhookService,});

/**
 * @generated from rpc webhook.v1.WebhookService.GetWebhookURL
 */
export const getWebhookURL: UnaryFunctionsWithHooks<GetWebhookURLRequest, GetWebhookURLResponse> = {   ...$queryService.getWebhookURL,  ...createUnaryHooks($queryService.getWebhookURL)};

/**
 * @generated from rpc webhook.v1.WebhookService.GetVendrWebhookURL
 */
export const getVendrWebhookURL: UnaryFunctionsWithHooks<GetVendrWebhookURLRequest, GetVendrWebhookURLResponse> = {   ...$queryService.getVendrWebhookURL,  ...createUnaryHooks($queryService.getVendrWebhookURL)};
