import { Code, ConnectError } from '@connectrpc/connect'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { UrlParam } from '@/lib/url-param.hook'

import { useToast } from '@/components/ui/use-toast'

export const useUploadToast = (
  isSuccess: boolean,
  isError: boolean,
  error: ConnectError | null,
) => {
  const navigate = useNavigate()
  const { toast } = useToast()
  useEffect(() => {
    if (isSuccess) {
      toast({
        status: 'success',
        title: 'Integration Added Successfully',
      })
      setTimeout(() => navigate(`/settings?${UrlParam.SETTING_SECTION}=integrations`), 1000)
    } else if (isError && error && error.code == Code.InvalidArgument) {
      toast({
        status: 'error',
        title: error.message,
      })
    } else if (isError) {
      toast({
        status: 'error',
        title: 'An error has occurred',
      })
    }
  }, [isSuccess, isError, error, toast, navigate])
}
