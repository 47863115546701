import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'
import { MinusIcon } from 'lucide-react'
import { useMemo } from 'react'

import { InherentRiskGroup } from '@/gen/inventory/v1/company_service_pb'

import { InherentRiskItemCell } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-item-cell'

import { HeadlessTable } from '@/components/charts/headless-table'

type InherentRiskProfileTableProps = {
  inherentRiskGroups: PlainMessage<InherentRiskGroup>[]
}

export const InherentRiskProfileTable = ({ inherentRiskGroups }: InherentRiskProfileTableProps) => {
  const categoryToSubCategoriesMap = useMemo(
    () => getCategoryToSubcategoriesMap(inherentRiskGroups),
    [inherentRiskGroups],
  )

  const longestIndicatorsLength = useMemo(
    () =>
      inherentRiskGroups.reduce(
        (acc, { inherentRiskItems }) => Math.max(acc, inherentRiskItems?.length || 1),
        0,
      ),
    [inherentRiskGroups],
  )

  const emptyTableCellMatrix = useMemo(
    () =>
      _.times(longestIndicatorsLength, () => _.fill(Array(inherentRiskGroups?.length || 0), null)),
    [longestIndicatorsLength, inherentRiskGroups],
  )

  return (
    <HeadlessTable
      disableHover
      rows={[
        {
          cells: Array.from(categoryToSubCategoriesMap).map(([category, subcategories]) => {
            return {
              colSpan: subcategories?.length || 1,
              element: <CategoryHeaderCell category={category} subcategories={subcategories} />,
            }
          }),
        },
        ...emptyTableCellMatrix.map((emptyCellRow, rowIndex) => ({
          cells: emptyCellRow.map((_, colIndex) => {
            const group = inherentRiskGroups?.[colIndex]
            const inherentRiskItems = group?.inherentRiskItems

            return {
              element:
                rowIndex === 0 && inherentRiskItems?.length === 0 ? (
                  <EmptyInherentRiskGroup />
                ) : (
                  <InherentRiskItemCell
                    categoryEnum={group.categoryEnum}
                    inherentRiskItem={inherentRiskItems?.[rowIndex]}
                  />
                ),
            }
          }),
        })),
      ]}
    />
  )
}

const getCategoryToSubcategoriesMap = (inherentRiskGroups: PlainMessage<InherentRiskGroup>[]) => {
  const categoryMap = new Map<string, string[]>()

  inherentRiskGroups.forEach(({ category, subcategory }) => {
    if (categoryMap.has(category)) {
      const currentSubcategories = categoryMap.get(category)
      if (subcategory && !currentSubcategories?.includes(subcategory)) {
        currentSubcategories?.push(subcategory)
      }
    } else {
      categoryMap.set(category, subcategory ? [subcategory] : [])
    }
  })

  return categoryMap
}

type CategoryHeaderCellProps = {
  category: string
  subcategories?: string[]
}

const CategoryHeaderCell = ({ category, subcategories }: CategoryHeaderCellProps) => {
  return (
    <div className='mb-2 flex min-h-12 items-center justify-center rounded bg-gray-50 p-2 text-center'>
      <div className='w-full'>
        <span className='font-bold'>{category.toUpperCase()}</span>
        <div className='flex justify-around text-xs text-gray-400'>
          {subcategories?.map((subcategory, index) => <span key={index}>{subcategory}</span>)}
        </div>
      </div>
    </div>
  )
}

const EmptyInherentRiskGroup = () => (
  <div className='my-0.5 flex h-12 max-w-44 items-center rounded px-3 opacity-80'>
    <div className='mx-auto'>
      <MinusIcon className='text-gray-200' />
    </div>
  </div>
)
