import { Empty } from 'antd'
import { ArrowUpFromLineIcon } from 'lucide-react'

import { useListUserSourcedArtifact } from '@/api/artifact.hook'
import { Artifact } from '@/gen/inventory/v1/artifact_service_pb'

import { formatDate } from '@/lib/date'

import { useUploadDocumentsModal } from '@/pages/settings-page/artifacts-section/use-upload-files-modal'

import { ArtifactHyperlink } from '@/components/artifact-hyperlink'
import { Hyperlink } from '@/components/hyperlink'
import { Loading } from '@/components/loading'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

const SOC2_REPORT_SUBCATEGORY = 'REPORT_SOC2_TYPE_2'
const DOCUMENT_CATEGORY_REPORT = 'DOCUMENT_CATEGORY_REPORT'

type EmptySoc2ReportProps = {
  companyId: string
  companyName: string
}

export const EmptySoc2Activities = ({ companyId, companyName }: EmptySoc2ReportProps) => {
  const { data, isLoading } = useListUserSourcedArtifact(companyId, SOC2_REPORT_SUBCATEGORY)

  const { renderModal, showModal } = useUploadDocumentsModal({
    metadata: {
      company: { id: companyId, name: companyName },
      categoryMetadata: {
        category: DOCUMENT_CATEGORY_REPORT,
        subcategory: SOC2_REPORT_SUBCATEGORY,
      },
    },

    allowedFileTypes: ['application/pdf'],
  })

  if (isLoading) {
    return <Loading />
  }

  if (data.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <div className='flex flex-col items-center gap-3'>
            <h3 className='text-gray-700'>No SOC2 Type II found for this third-party.</h3>
            <Button onClick={showModal}>
              <TextWithIcon
                text={'Upload a SOC2 Report'}
                icon={<ArrowUpFromLineIcon size={14} />}
              />
            </Button>
            <Hyperlink color='purple' href='/integrations/marketplace'>
              Integration marketplace
            </Hyperlink>
            {renderModal()}
          </div>
        }
      />
    )
  }

  return <Soc2DocumentTable data={data} />
}

type Soc2DocumentTableProps = {
  data: Artifact[]
}

const Soc2DocumentTable = ({ data }: Soc2DocumentTableProps) => {
  const columns: ColumnType<Artifact>[] = [
    {
      title: 'Soc2 type II',
      width: '70%',
      ellipsis: true,
      render: ({ id, name }) => <ArtifactHyperlink artifactId={id}>{name}</ArtifactHyperlink>,
    },

    {
      title: 'Date Added',
      dataIndex: 'dateAdded',
      render: (dateAdded: Artifact['dateAdded']) =>
        dateAdded && <span className='text-gray-400'>{formatDate(dateAdded.toDate())}</span>,
    },
  ]

  return <Table showFilterBar={false} columns={columns} dataSource={data} />
}
