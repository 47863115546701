import { useQuery } from '@tanstack/react-query'

import { listUsers } from '@/gen/customer/management/v1/service-CustomerManagementService_connectquery'

export const useListUsers = () => {
  const queryState = useQuery(listUsers.useQuery())
  return {
    ...queryState,
    data: queryState.data?.users || [],
  }
}
