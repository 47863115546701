import { ColumnType as AntColumnType } from 'antd/es/table'

import { Filter } from '@/components/table/filter/bar'
import { FilteredState } from '@/components/table/filter/filter.hook'
import {
  ColumnAccessor,
  ColumnType,
  GroupColumnType,
  GroupTableRow,
} from '@/components/table/table.type'

export const getColumnKey = <T, K extends keyof T>(
  col: ColumnType<T> | GroupColumnType<T, K>,
): React.Key => {
  if (col.filterTitle) {
    return col.filterTitle
  }
  if (col.title && typeof col.title === 'string') {
    return col.title
  }
  if (col.dataIndex) {
    return col.dataIndex
  }
  if (col.key) {
    return col.key
  }
  return ''
}

function generateAccessor<T>(column: ColumnType<T>): ColumnAccessor<T> | undefined {
  if (column.accessor) {
    return column.accessor
  }

  if (column.dataIndex) {
    const dataIndex = column.dataIndex
    return (row: T) => {
      const value: string = row[dataIndex]?.toString() || ''
      return { filterValue: value, searchValue: value, sortValue: value }
    }
  }

  return undefined
}

// Function to generate sorter function based on accessor type
export function generateSorter<T>(accessor: ColumnAccessor<T>) {
  return (a: T, b: T) => {
    const aValue = accessor(a).sortValue
    const bValue = accessor(b).sortValue

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue
    } else if (aValue instanceof Date && bValue instanceof Date) {
      return bValue.getTime() - aValue.getTime()
    } else {
      return String(aValue).localeCompare(String(bValue))
    }
  }
}

// filterMatch is a generic implementation of an onFilter AntTable function.
export const filterMatch = (
  rowValue: string | string[] | undefined,
  filterValue: string | boolean | number,
) => {
  // when filterValue is set to boolean,
  // it means that all values should or should not be included, depending on the value.
  if (typeof filterValue === 'boolean') {
    return filterValue
  }

  // treat numbers as strings, we are comparing exact matches anyways so this is fine
  if (typeof filterValue === 'number') {
    filterValue = filterValue.toString()
  }

  if (Array.isArray(rowValue)) {
    return rowValue.includes(filterValue)
  } else if (rowValue) {
    return rowValue === filterValue
  }
  return false
}

export const searchMatch = (rowValue: string | string[] | undefined, searchValue: string) => {
  if (Array.isArray(rowValue)) {
    return rowValue.some((value) => value.toLowerCase().includes(searchValue.toLowerCase()))
  } else if (typeof rowValue === 'string') {
    return rowValue.toLowerCase().includes(searchValue.toLowerCase())
  }
  return false
}

type AntColumnTypeWithKey<T> = {
  key: React.Key
} & Omit<AntColumnType<T>, 'key'>

export function generateAntColumn<T>(
  col: ColumnType<T>,
  filteredState: FilteredState,
  searchState: string,
  sortIcon: React.ReactNode,
): AntColumnTypeWithKey<T> {
  const accessor = generateAccessor(col)
  const key = getColumnKey(col)
  let filteredValues: string[] | null = filteredState[key]?.value || []

  // filteredValues are used by AntDTable to indicate that a filter has changed and recomputing is needed.
  // We need to update this value whenever filters or search change state to make sure onFilter is triggered.
  if (col.search && searchState.length > 0) {
    filteredValues = filteredValues.concat(searchState)
  }
  if (filteredValues.length === 0) {
    filteredValues = null
  }

  const isSearching = col.search && searchState.length > 0

  return {
    ...col,
    key,
    sorter: col.sort !== false && accessor ? generateSorter(accessor) : undefined,
    sortIcon: () => sortIcon,
    filteredValue: filteredValues,
    onFilter: (value, record) =>
      filterMatch(accessor?.(record).filterValue, value) ||
      (isSearching && searchMatch(accessor?.(record).searchValue, searchState)),
  } as AntColumnTypeWithKey<T>
}

export function generateAntGroupColumn<T, K extends keyof T>(
  col: GroupColumnType<T, K>,
  sortIcon: React.ReactNode,
  groups: _.Dictionary<T[]>,
) {
  const colKey = getColumnKey<T, K>(col)
  const render = col.render

  return {
    ...col,
    sorter: col.sort !== false && col.accessor ? generateSorter(col.accessor) : undefined,
    render: render && ((_value, { key }, index) => render(key, index, groups[key as string])),
    key: colKey,
    sortIcon: () => sortIcon,
  } as AntColumnTypeWithKey<GroupTableRow<T, K>>
}

export function generateFilter<T>(col: ColumnType<T>, dataSource: readonly T[]): Filter {
  const accessor = generateAccessor(col)
  const options = dataSource
    // flatten out any cases where one row represents mutliple values
    .flatMap((row) => accessor?.(row).filterValue)
    // add default selected filters to the options list
    .concat(col.defaultSelectedFilter || [])
    // remove duplicates
    .filter((value, index, self) => self.indexOf(value) === index)
    // remove undefined, null, and empty strings,
    // hint to typescript that the list will not contain them any more.
    // https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript
    .filter((option): option is string => option !== undefined)

  return {
    label: col.filterTitle || col.title,
    key: getColumnKey(col),
    options: options,
    defaultSelected: col.defaultSelectedFilter,
    optionRender: col.optionRender,
    radioState: col.filterRadioState,
  }
}
