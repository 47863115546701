import { DataTypeSource, PermissionCategory } from '@/gen/inventory/v1/company_service_pb'
import { Implication } from '@/gen/inventory/v1/implication_pb'

export const dataTypeSourceDescription: Record<DataTypeSource, string> = {
  [DataTypeSource.DOCUMENTED_ONLY]:
    'This datatype was mentioned in a document, but no actual access/sharing has been detected',
  [DataTypeSource.DOCUMENTED_AND_ACCESSED]:
    'Both documentation and actual access/sharing exist for this datatype',
  [DataTypeSource.UNDOCUMENTED_ACCESS]:
    'This datatype was not mentioned in a document, but access or sharing with the third-party has been detected',
  [DataTypeSource.UNSPECIFIED]: 'Unspecified',
}

export const implicationDescription: Record<Implication, string> = {
  [Implication.BREACH_IMPACT]: 'Increases third-party’s violation impact',
  [Implication.BREACH_LIKELIHOOD]: 'Increases third-party’s violation likelihood',
  [Implication.COMPLIANCE]: 'Potentially deviates from compliance controls',
  [Implication.BUSINESS_CONTINUITY]: 'Lacks safeguard against operational interruptions',
  [Implication.UNSPECIFIED]: ' ',
}

export const permissionDescription: Record<PermissionCategory, string> = {
  [PermissionCategory.UNSPECIFIED]: '',
  [PermissionCategory.DATA_ACCESS]:
    'Third-party can view and interact with specific information within your systems and databases',
  [PermissionCategory.DATA_MODIFICATION]:
    'Third-party can make changes to specific information within your systems or databases',
  [PermissionCategory.OPERATIONAL_ENVIRONMENT]:
    'Third-party can change the settings, configurations, or infrastructure of your operational environment',
  [PermissionCategory.IDENTITY_AND_ACCESS_MANAGEMENT]:
    "Third-party can manage user access securely within an organization's systems and applications",
  [PermissionCategory.CONFIGURATION_PERMISSIONS]:
    'Third-party can manage the configuration settings, changes, and versioning of systems, applications, or other IT resources within your environment',
  [PermissionCategory.EMPLOYEE_DATA_ACCESS]:
    "Third-party has access to employee-related information within an organization's systems.",
  [PermissionCategory.EMPLOYEE_DATA_MODIFICATION]:
    "Third-party may update and modify employee information within an organization's systems.",
  [PermissionCategory.CLOUD_ENVIRONMENT]:
    'Third-party can manage, configure, or interact with cloud-based services, applications, and infrastructure within your environment.',
}
