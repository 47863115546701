import { LinkIcon, UsersIcon } from 'lucide-react'

import { AutocompleteItem } from '@/gen/companycatalog/v1/company_catalog_pb'

import { TextWithIcon } from '@/components/text-with-icon'

type CompanyProfileInfoProps = {
  companyInfo: AutocompleteItem | null
}

export const CompanyProfileInfo = ({ companyInfo }: CompanyProfileInfoProps) => {
  if (!companyInfo) {
    return null
  }

  return (
    <div className='mt-2 rounded border px-5 py-3'>
      <p className='text-xs font-light text-gray-400'>Profile found for {companyInfo.name}</p>
      <span>{companyInfo.industry}</span>
      <div className='mt-6 flex gap-24'>
        <div className='text-gray-400'>
          <TextWithIcon
            className='text-xs font-bold uppercase'
            icon={<UsersIcon size={10} />}
            text='Size'
          />
          {companyInfo.companySize}
        </div>

        <div className='text-gray-400'>
          <TextWithIcon
            className='text-xs font-bold uppercase'
            icon={<LinkIcon size={10} />}
            text='website'
          />
          <a
            data-dd-action-name='third-party.domain.click'
            href={`https://${companyInfo.domain}`}
            target='_blank'
            rel='noreferrer'
          >
            {companyInfo.domain}
          </a>
        </div>
      </div>
    </div>
  )
}
