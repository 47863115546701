// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file logicmanager/v1/logicmanager_service.proto (package logicmanager.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCredentialsRequest, AddCredentialsResponse } from "./logicmanager_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "logicmanager.v1.LogicManagerService";

/**
 * @generated from service logicmanager.v1.LogicManagerService
 */
export const LogicManagerService = {
  typeName: "logicmanager.v1.LogicManagerService",
  methods: {
    /**
     * @generated from rpc logicmanager.v1.LogicManagerService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: LogicManagerService,});

/**
 * @generated from rpc logicmanager.v1.LogicManagerService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
