import { Duration, PlainMessage } from '@bufbuild/protobuf'
import { ConfigProvider, Timeline } from 'antd'
import { CircleIcon } from 'lucide-react'

import { PersonProfile as ProtoPersonProfile } from '@/gen/inventory/v1/company_service_pb'

import { protoDurationToString } from '@/lib/date'

import { Hyperlink } from '@/components/hyperlink'

type PersonResumeCardProps = {
  person: PlainMessage<ProtoPersonProfile>
  // Limit the number of work experiences to show
  workExperiencesLimit?: number
}

export const PersonResumeCard = ({ person, workExperiencesLimit }: PersonResumeCardProps) => {
  const linkedinUrl = `https://${person.linkedinUrl}`
  const jobs = person.workExperience.slice(0, workExperiencesLimit)
  const hiddenJobCount = person.workExperience.length - jobs.length
  type JobAtCompany = {
    companyName: string
    titles: {
      title: string
      duration: PlainMessage<Duration> | undefined
    }[]
  }

  const jobsGroupedByCompany = jobs.reduce((acc: JobAtCompany[], job) => {
    const lastCompany = acc[acc.length - 1]
    if (!lastCompany || lastCompany.companyName !== job.companyName) {
      acc.push({ companyName: job.companyName, titles: [] })
    }
    acc[acc.length - 1].titles.push({ title: job.jobTitle, duration: job.duration })
    return acc
  }, [])

  return (
    <div className='p-2 text-gray-700'>
      <ConfigProvider theme={{ components: { Timeline: { itemPaddingBottom: 0 } } }}>
        <Hyperlink className='block' href={linkedinUrl}>
          <div className='flex justify-between'>
            <span className='text-lg capitalize'>{person.name}</span>
            <img src='/icons/linkedin.svg' alt='Linkedin' width={16} height={16} />
          </div>
          <div className='text-pretty capitalize'>
            {person.jobTitle} • {protoDurationToString(person.jobDuration)}
          </div>
        </Hyperlink>

        <div className='my-4 flex flex-col gap-y-2'>
          {jobsGroupedByCompany.map((job, idx) => (
            <div key={idx}>
              <div className='mb-4 font-semibold capitalize' key={`company-${idx}`}>
                {job.companyName}
              </div>
              <Timeline
                key={`timeline-${idx}`}
                items={job.titles.map((jobTitle) => ({
                  children: (
                    <span className='text-base font-light capitalize'>
                      {jobTitle.title}
                      {' • '}
                      <span className='text-sm'>{protoDurationToString(jobTitle.duration)}</span>
                    </span>
                  ),
                  dot: <CircleIcon size={2} />,
                }))}
              />
            </div>
          ))}
        </div>

        {hiddenJobCount > 0 && (
          <div className='text-sm'>
            +{hiddenJobCount} more titles.{' '}
            <span className='text-gray-500'>View their full experience on</span>{' '}
            <Hyperlink href={linkedinUrl}>Linkedin</Hyperlink>
          </div>
        )}
      </ConfigProvider>
    </div>
  )
}
