// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/artifact_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.DocumentStatus
 */
export enum DocumentStatus {
  /**
   * @generated from enum value: DOCUMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_STATUS_ANALYZING = 1;
   */
  ANALYZING = 1,

  /**
   * @generated from enum value: DOCUMENT_STATUS_COMPLETE = 2;
   */
  COMPLETE = 2,

  /**
   * @generated from enum value: DOCUMENT_STATUS_PENDING = 3;
   */
  PENDING = 3,

  /**
   * @generated from enum value: DOCUMENT_STATUS_CLASSIFYING = 4;
   */
  CLASSIFYING = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentStatus)
proto3.util.setEnumType(DocumentStatus, "inventory.v1.DocumentStatus", [
  { no: 0, name: "DOCUMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_STATUS_ANALYZING" },
  { no: 2, name: "DOCUMENT_STATUS_COMPLETE" },
  { no: 3, name: "DOCUMENT_STATUS_PENDING" },
  { no: 4, name: "DOCUMENT_STATUS_CLASSIFYING" },
]);

/**
 * @generated from enum inventory.v1.DocumentError
 */
export enum DocumentError {
  /**
   * @generated from enum value: DOCUMENT_ERROR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_ERROR_UNKNOWN_ERROR = 1;
   */
  UNKNOWN_ERROR = 1,

  /**
   * @generated from enum value: DOCUMENT_ERROR_UNSUPPORTED_TYPE = 2;
   */
  UNSUPPORTED_TYPE = 2,

  /**
   * @generated from enum value: DOCUMENT_ERROR_ENCRYPTED = 3;
   */
  ENCRYPTED = 3,

  /**
   * @generated from enum value: DOCUMENT_ERROR_EMPTY = 4;
   */
  EMPTY = 4,

  /**
   * @generated from enum value: DOCUMENT_ERROR_UNKNOWN_CATEGORY = 5;
   */
  UNKNOWN_CATEGORY = 5,

  /**
   * @generated from enum value: DOCUMENT_ERROR_UNKNOWN_COMPANY = 6;
   */
  UNKNOWN_COMPANY = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentError)
proto3.util.setEnumType(DocumentError, "inventory.v1.DocumentError", [
  { no: 0, name: "DOCUMENT_ERROR_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_ERROR_UNKNOWN_ERROR" },
  { no: 2, name: "DOCUMENT_ERROR_UNSUPPORTED_TYPE" },
  { no: 3, name: "DOCUMENT_ERROR_ENCRYPTED" },
  { no: 4, name: "DOCUMENT_ERROR_EMPTY" },
  { no: 5, name: "DOCUMENT_ERROR_UNKNOWN_CATEGORY" },
  { no: 6, name: "DOCUMENT_ERROR_UNKNOWN_COMPANY" },
]);

/**
 * @generated from enum inventory.v1.DocumentSource
 */
export enum DocumentSource {
  /**
   * @generated from enum value: DOCUMENT_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_SOURCE_MANUAL_UPLOAD = 1;
   */
  MANUAL_UPLOAD = 1,

  /**
   * @generated from enum value: DOCUMENT_SOURCE_INTEGRATION = 2;
   */
  INTEGRATION = 2,

  /**
   * used to hint that this doucment was fetched from the world wide web (not from the web-ui)
   *
   * @generated from enum value: DOCUMENT_SOURCE_WEB = 3;
   */
  WEB = 3,

  /**
   * used to hint that the company was provided by the user and not inferred
   *
   * @generated from enum value: DOCUMENT_SOURCE_MANUAL_UPLOAD_WITH_COMPANY = 4;
   */
  MANUAL_UPLOAD_WITH_COMPANY = 4,

  /**
   * the document was collected using the Lema Questionnaire module
   *
   * @generated from enum value: DOCUMENT_SOURCE_LEMA_QUESTIONNAIRE = 5;
   */
  LEMA_QUESTIONNAIRE = 5,

  /**
   * used to hint that the company and category were provided by the user and not inferred
   *
   * @generated from enum value: DOCUMENT_SOURCE_MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY = 6;
   */
  MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentSource)
proto3.util.setEnumType(DocumentSource, "inventory.v1.DocumentSource", [
  { no: 0, name: "DOCUMENT_SOURCE_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_SOURCE_MANUAL_UPLOAD" },
  { no: 2, name: "DOCUMENT_SOURCE_INTEGRATION" },
  { no: 3, name: "DOCUMENT_SOURCE_WEB" },
  { no: 4, name: "DOCUMENT_SOURCE_MANUAL_UPLOAD_WITH_COMPANY" },
  { no: 5, name: "DOCUMENT_SOURCE_LEMA_QUESTIONNAIRE" },
  { no: 6, name: "DOCUMENT_SOURCE_MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY" },
]);

/**
 * @generated from enum inventory.v1.SOC2Scope
 */
export enum SOC2Scope {
  /**
   * @generated from enum value: SOC2_SCOPE_UNSPECIFIED = 0;
   */
  SOC2_SCOPE_UNSPECIFIED = 0,

  /**
   * CC
   *
   * @generated from enum value: SOC2_SCOPE_SECURITY = 1;
   */
  SOC2_SCOPE_SECURITY = 1,

  /**
   * A
   *
   * @generated from enum value: SOC2_SCOPE_AVAILABILITY = 2;
   */
  SOC2_SCOPE_AVAILABILITY = 2,

  /**
   * C
   *
   * @generated from enum value: SOC2_SCOPE_CONFIDENTIALITY = 3;
   */
  SOC2_SCOPE_CONFIDENTIALITY = 3,

  /**
   * P
   *
   * @generated from enum value: SOC2_SCOPE_PRIVACY = 4;
   */
  SOC2_SCOPE_PRIVACY = 4,

  /**
   * PI
   *
   * @generated from enum value: SOC2_SCOPE_PROCESSING = 5;
   */
  SOC2_SCOPE_PROCESSING = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SOC2Scope)
proto3.util.setEnumType(SOC2Scope, "inventory.v1.SOC2Scope", [
  { no: 0, name: "SOC2_SCOPE_UNSPECIFIED" },
  { no: 1, name: "SOC2_SCOPE_SECURITY" },
  { no: 2, name: "SOC2_SCOPE_AVAILABILITY" },
  { no: 3, name: "SOC2_SCOPE_CONFIDENTIALITY" },
  { no: 4, name: "SOC2_SCOPE_PRIVACY" },
  { no: 5, name: "SOC2_SCOPE_PROCESSING" },
]);

/**
 * @generated from enum inventory.v1.SOC2ActivityImplementor
 */
export enum SOC2ActivityImplementor {
  /**
   * @generated from enum value: SOC2_ACTIVITY_IMPLEMENTOR_UNSPECIFIED = 0;
   */
  SOC2_ACTIVITY_IMPLEMENTOR_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SOC2_ACTIVITY_IMPLEMENTOR_COMPANY = 1;
   */
  SOC2_ACTIVITY_IMPLEMENTOR_COMPANY = 1,

  /**
   * @generated from enum value: SOC2_ACTIVITY_IMPLEMENTOR_SUBSERVICE = 2;
   */
  SOC2_ACTIVITY_IMPLEMENTOR_SUBSERVICE = 2,

  /**
   * @generated from enum value: SOC2_ACTIVITY_IMPLEMENTOR_USER = 3;
   */
  SOC2_ACTIVITY_IMPLEMENTOR_USER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SOC2ActivityImplementor)
proto3.util.setEnumType(SOC2ActivityImplementor, "inventory.v1.SOC2ActivityImplementor", [
  { no: 0, name: "SOC2_ACTIVITY_IMPLEMENTOR_UNSPECIFIED" },
  { no: 1, name: "SOC2_ACTIVITY_IMPLEMENTOR_COMPANY" },
  { no: 2, name: "SOC2_ACTIVITY_IMPLEMENTOR_SUBSERVICE" },
  { no: 3, name: "SOC2_ACTIVITY_IMPLEMENTOR_USER" },
]);

/**
 * @generated from message inventory.v1.GetQuestionnaireMarkdownRequest
 */
export class GetQuestionnaireMarkdownRequest extends Message<GetQuestionnaireMarkdownRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetQuestionnaireMarkdownRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetQuestionnaireMarkdownRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuestionnaireMarkdownRequest {
    return new GetQuestionnaireMarkdownRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuestionnaireMarkdownRequest {
    return new GetQuestionnaireMarkdownRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuestionnaireMarkdownRequest {
    return new GetQuestionnaireMarkdownRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuestionnaireMarkdownRequest | PlainMessage<GetQuestionnaireMarkdownRequest> | undefined, b: GetQuestionnaireMarkdownRequest | PlainMessage<GetQuestionnaireMarkdownRequest> | undefined): boolean {
    return proto3.util.equals(GetQuestionnaireMarkdownRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetQuestionnaireMarkdownResponse
 */
export class GetQuestionnaireMarkdownResponse extends Message<GetQuestionnaireMarkdownResponse> {
  /**
   * @generated from field: string questionnaire_markdown = 1;
   */
  questionnaireMarkdown = "";

  constructor(data?: PartialMessage<GetQuestionnaireMarkdownResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetQuestionnaireMarkdownResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaire_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuestionnaireMarkdownResponse {
    return new GetQuestionnaireMarkdownResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuestionnaireMarkdownResponse {
    return new GetQuestionnaireMarkdownResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuestionnaireMarkdownResponse {
    return new GetQuestionnaireMarkdownResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuestionnaireMarkdownResponse | PlainMessage<GetQuestionnaireMarkdownResponse> | undefined, b: GetQuestionnaireMarkdownResponse | PlainMessage<GetQuestionnaireMarkdownResponse> | undefined): boolean {
    return proto3.util.equals(GetQuestionnaireMarkdownResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompanyArtifactRequest
 */
export class ListCompanyArtifactRequest extends Message<ListCompanyArtifactRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListCompanyArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompanyArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompanyArtifactRequest {
    return new ListCompanyArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompanyArtifactRequest {
    return new ListCompanyArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompanyArtifactRequest {
    return new ListCompanyArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompanyArtifactRequest | PlainMessage<ListCompanyArtifactRequest> | undefined, b: ListCompanyArtifactRequest | PlainMessage<ListCompanyArtifactRequest> | undefined): boolean {
    return proto3.util.equals(ListCompanyArtifactRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompanyArtifactResponse
 */
export class ListCompanyArtifactResponse extends Message<ListCompanyArtifactResponse> {
  /**
   * @generated from field: repeated inventory.v1.Artifact artifacts = 1;
   */
  artifacts: Artifact[] = [];

  constructor(data?: PartialMessage<ListCompanyArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompanyArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifacts", kind: "message", T: Artifact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompanyArtifactResponse {
    return new ListCompanyArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompanyArtifactResponse {
    return new ListCompanyArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompanyArtifactResponse {
    return new ListCompanyArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompanyArtifactResponse | PlainMessage<ListCompanyArtifactResponse> | undefined, b: ListCompanyArtifactResponse | PlainMessage<ListCompanyArtifactResponse> | undefined): boolean {
    return proto3.util.equals(ListCompanyArtifactResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListUserSourcedArtifactResponse
 */
export class ListUserSourcedArtifactResponse extends Message<ListUserSourcedArtifactResponse> {
  /**
   * @generated from field: repeated inventory.v1.Artifact artifacts = 1;
   */
  artifacts: Artifact[] = [];

  constructor(data?: PartialMessage<ListUserSourcedArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListUserSourcedArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifacts", kind: "message", T: Artifact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserSourcedArtifactResponse {
    return new ListUserSourcedArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserSourcedArtifactResponse {
    return new ListUserSourcedArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserSourcedArtifactResponse {
    return new ListUserSourcedArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserSourcedArtifactResponse | PlainMessage<ListUserSourcedArtifactResponse> | undefined, b: ListUserSourcedArtifactResponse | PlainMessage<ListUserSourcedArtifactResponse> | undefined): boolean {
    return proto3.util.equals(ListUserSourcedArtifactResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListUserSourcedArtifactRequest
 */
export class ListUserSourcedArtifactRequest extends Message<ListUserSourcedArtifactRequest> {
  /**
   * @generated from field: optional string company_id = 1;
   */
  companyId?: string;

  /**
   * @generated from field: optional string artifact_subcategory = 2;
   */
  artifactSubcategory?: string;

  constructor(data?: PartialMessage<ListUserSourcedArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListUserSourcedArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "artifact_subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserSourcedArtifactRequest {
    return new ListUserSourcedArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserSourcedArtifactRequest {
    return new ListUserSourcedArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserSourcedArtifactRequest {
    return new ListUserSourcedArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserSourcedArtifactRequest | PlainMessage<ListUserSourcedArtifactRequest> | undefined, b: ListUserSourcedArtifactRequest | PlainMessage<ListUserSourcedArtifactRequest> | undefined): boolean {
    return proto3.util.equals(ListUserSourcedArtifactRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListSubprocessorsRequest
 */
export class ListSubprocessorsRequest extends Message<ListSubprocessorsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListSubprocessorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListSubprocessorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubprocessorsRequest {
    return new ListSubprocessorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubprocessorsRequest {
    return new ListSubprocessorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubprocessorsRequest {
    return new ListSubprocessorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubprocessorsRequest | PlainMessage<ListSubprocessorsRequest> | undefined, b: ListSubprocessorsRequest | PlainMessage<ListSubprocessorsRequest> | undefined): boolean {
    return proto3.util.equals(ListSubprocessorsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListSubprocessorsResponse
 */
export class ListSubprocessorsResponse extends Message<ListSubprocessorsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Subprocessor subprocessors = 1;
   */
  subprocessors: Subprocessor[] = [];

  constructor(data?: PartialMessage<ListSubprocessorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListSubprocessorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subprocessors", kind: "message", T: Subprocessor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubprocessorsResponse {
    return new ListSubprocessorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubprocessorsResponse {
    return new ListSubprocessorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubprocessorsResponse {
    return new ListSubprocessorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubprocessorsResponse | PlainMessage<ListSubprocessorsResponse> | undefined, b: ListSubprocessorsResponse | PlainMessage<ListSubprocessorsResponse> | undefined): boolean {
    return proto3.util.equals(ListSubprocessorsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetSoc2ActivitiesRequest
 */
export class GetSoc2ActivitiesRequest extends Message<GetSoc2ActivitiesRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: optional string solutoin_id = 2;
   */
  solutoinId?: string;

  constructor(data?: PartialMessage<GetSoc2ActivitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetSoc2ActivitiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "solutoin_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSoc2ActivitiesRequest {
    return new GetSoc2ActivitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSoc2ActivitiesRequest {
    return new GetSoc2ActivitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSoc2ActivitiesRequest {
    return new GetSoc2ActivitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSoc2ActivitiesRequest | PlainMessage<GetSoc2ActivitiesRequest> | undefined, b: GetSoc2ActivitiesRequest | PlainMessage<GetSoc2ActivitiesRequest> | undefined): boolean {
    return proto3.util.equals(GetSoc2ActivitiesRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetSoc2ActivitiesResponse
 */
export class GetSoc2ActivitiesResponse extends Message<GetSoc2ActivitiesResponse> {
  /**
   * @generated from field: repeated inventory.v1.SOC2Activity activities = 1;
   */
  activities: SOC2Activity[] = [];

  constructor(data?: PartialMessage<GetSoc2ActivitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetSoc2ActivitiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "activities", kind: "message", T: SOC2Activity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSoc2ActivitiesResponse {
    return new GetSoc2ActivitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSoc2ActivitiesResponse {
    return new GetSoc2ActivitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSoc2ActivitiesResponse {
    return new GetSoc2ActivitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSoc2ActivitiesResponse | PlainMessage<GetSoc2ActivitiesResponse> | undefined, b: GetSoc2ActivitiesResponse | PlainMessage<GetSoc2ActivitiesResponse> | undefined): boolean {
    return proto3.util.equals(GetSoc2ActivitiesResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Artifact
 */
export class Artifact extends Message<Artifact> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp date_added = 2;
   */
  dateAdded?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp date_updated = 3;
   */
  dateUpdated?: Timestamp;

  /**
   * @generated from field: optional string name = 4;
   */
  name?: string;

  /**
   * @generated from field: inventory.v1.DocumentSource source = 5;
   */
  source = DocumentSource.UNSPECIFIED;

  /**
   * @generated from field: optional string artifact_subcategory = 6;
   */
  artifactSubcategory?: string;

  /**
   * @generated from field: string artifact_category = 7;
   */
  artifactCategory = "";

  /**
   * @generated from oneof inventory.v1.Artifact.artifact
   */
  artifact: {
    /**
     * @generated from field: string artifact_id = 8;
     */
    value: string;
    case: "artifactId";
  } | {
    /**
     * @generated from field: string artifact_link = 9;
     */
    value: string;
    case: "artifactLink";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: inventory.v1.DocumentStatus status = 10;
   */
  status = DocumentStatus.UNSPECIFIED;

  /**
   * @generated from field: optional string company_id = 11;
   */
  companyId?: string;

  /**
   * @generated from field: optional inventory.v1.DocumentError error = 12;
   */
  error?: DocumentError;

  /**
   * @generated from field: optional google.protobuf.Timestamp expiration_date = 13;
   */
  expirationDate?: Timestamp;

  constructor(data?: PartialMessage<Artifact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Artifact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date_added", kind: "message", T: Timestamp },
    { no: 3, name: "date_updated", kind: "message", T: Timestamp },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(DocumentSource) },
    { no: 6, name: "artifact_subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "artifact_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "artifact" },
    { no: 9, name: "artifact_link", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "artifact" },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(DocumentStatus) },
    { no: 11, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "error", kind: "enum", T: proto3.getEnumType(DocumentError), opt: true },
    { no: 13, name: "expiration_date", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Artifact {
    return new Artifact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Artifact {
    return new Artifact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Artifact {
    return new Artifact().fromJsonString(jsonString, options);
  }

  static equals(a: Artifact | PlainMessage<Artifact> | undefined, b: Artifact | PlainMessage<Artifact> | undefined): boolean {
    return proto3.util.equals(Artifact, a, b);
  }
}

/**
 * @generated from message inventory.v1.Subprocessor
 */
export class Subprocessor extends Message<Subprocessor> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated inventory.v1.Country countries = 4;
   */
  countries: Country[] = [];

  /**
   * @generated from field: optional string source = 5;
   */
  source?: string;

  /**
   * @generated from field: optional string source_link = 6;
   */
  sourceLink?: string;

  constructor(data?: PartialMessage<Subprocessor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Subprocessor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "countries", kind: "message", T: Country, repeated: true },
    { no: 5, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "source_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Subprocessor {
    return new Subprocessor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Subprocessor {
    return new Subprocessor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Subprocessor {
    return new Subprocessor().fromJsonString(jsonString, options);
  }

  static equals(a: Subprocessor | PlainMessage<Subprocessor> | undefined, b: Subprocessor | PlainMessage<Subprocessor> | undefined): boolean {
    return proto3.util.equals(Subprocessor, a, b);
  }
}

/**
 * @generated from message inventory.v1.Country
 */
export class Country extends Message<Country> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Country {
    return new Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJsonString(jsonString, options);
  }

  static equals(a: Country | PlainMessage<Country> | undefined, b: Country | PlainMessage<Country> | undefined): boolean {
    return proto3.util.equals(Country, a, b);
  }
}

/**
 * This is the standardized control, as appears in every SOC2 report
 *
 * @generated from message inventory.v1.SOC2Control
 */
export class SOC2Control extends Message<SOC2Control> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: inventory.v1.SOC2Scope scope = 3;
   */
  scope = SOC2Scope.SOC2_SCOPE_UNSPECIFIED;

  constructor(data?: PartialMessage<SOC2Control>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SOC2Control";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scope", kind: "enum", T: proto3.getEnumType(SOC2Scope) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SOC2Control {
    return new SOC2Control().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SOC2Control {
    return new SOC2Control().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SOC2Control {
    return new SOC2Control().fromJsonString(jsonString, options);
  }

  static equals(a: SOC2Control | PlainMessage<SOC2Control> | undefined, b: SOC2Control | PlainMessage<SOC2Control> | undefined): boolean {
    return proto3.util.equals(SOC2Control, a, b);
  }
}

/**
 * This is a specific activity reported in the SCO2 report.enum
 * It can a test, check or complementary user delegation, and its fields will be filled accordingly.
 *
 * @generated from message inventory.v1.SOC2Activity
 */
export class SOC2Activity extends Message<SOC2Activity> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string details = 2;
   */
  details = "";

  /**
   * @generated from field: optional string test = 3;
   */
  test?: string;

  /**
   * @generated from field: optional string result = 4;
   */
  result?: string;

  /**
   * @generated from field: optional string management_response = 5;
   */
  managementResponse?: string;

  /**
   * @generated from field: inventory.v1.SOC2ActivityImplementor implementor = 7;
   */
  implementor = SOC2ActivityImplementor.SOC2_ACTIVITY_IMPLEMENTOR_UNSPECIFIED;

  /**
   * @generated from field: repeated inventory.v1.SOC2Control controls = 8;
   */
  controls: SOC2Control[] = [];

  constructor(data?: PartialMessage<SOC2Activity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SOC2Activity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "test", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "management_response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "implementor", kind: "enum", T: proto3.getEnumType(SOC2ActivityImplementor) },
    { no: 8, name: "controls", kind: "message", T: SOC2Control, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SOC2Activity {
    return new SOC2Activity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SOC2Activity {
    return new SOC2Activity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SOC2Activity {
    return new SOC2Activity().fromJsonString(jsonString, options);
  }

  static equals(a: SOC2Activity | PlainMessage<SOC2Activity> | undefined, b: SOC2Activity | PlainMessage<SOC2Activity> | undefined): boolean {
    return proto3.util.equals(SOC2Activity, a, b);
  }
}

