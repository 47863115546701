export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const isAbsoluteUrl = (url: string) => {
  const absoluteUrlRegex = /^(?:[a-z]+:)?\/\//i
  return absoluteUrlRegex.test(url)
}

export const isQuestionnaireMagicLink = (link: string) => {
  const magicLinkRegex = /^\/q\/.+/
  return magicLinkRegex.test(link)
}
