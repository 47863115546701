import { DataTypeSource } from '@/gen/inventory/v1/company_service_pb'

import { DataTypeBadgeProps } from '@/components/badge/data-type'

export const dataTypeSourceToVariant: Record<DataTypeSource, DataTypeBadgeProps['variant']> = {
  [DataTypeSource.DOCUMENTED_AND_ACCESSED]: 'regular',
  [DataTypeSource.DOCUMENTED_ONLY]: 'faded',
  [DataTypeSource.UNDOCUMENTED_ACCESS]: 'alert',
  [DataTypeSource.UNSPECIFIED]: 'regular',
}

export const dataTypeSourceToLabel: Record<DataTypeSource, string> = {
  [DataTypeSource.UNSPECIFIED]: '',
  [DataTypeSource.DOCUMENTED_ONLY]: 'Documented Only',
  [DataTypeSource.DOCUMENTED_AND_ACCESSED]: 'Documented & Accessed',
  [DataTypeSource.UNDOCUMENTED_ACCESS]: 'Undocumented Exchanged',
}
