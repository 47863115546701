import { Descope } from '@descope/react-sdk'

import { useOrgId } from '@/lib/auth/orgid.hook'
import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useToast } from '@/components/ui/use-toast'

export const SsoSection = () => {
  const reportErrors = useReportErrorsCallback()
  const { toast } = useToast()
  const { orgId } = useOrgId()

  if (!orgId) {
    return null
  }

  return (
    <div>
      <div className='mb-5 flex justify-between'>
        <h2 className='mb-4 text-3xl font-bold'>SSO Configuration</h2>
      </div>
      <div className='max-w-3xl'>
        <div className='min-h-96 rounded border p-4'>
          <Descope
            flowId='sso-config'
            tenant={orgId}
            form={{
              nonClosable: 'true',
            }}
            theme='light'
            onError={(err) => {
              reportErrors(new Error(`Descope error ${err}`))
              toast({
                title: 'Error',
                description: 'An error occurred. Please try again later.',
                status: 'error',
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}
