import { FloatButton } from 'antd'
import { ChevronUp } from 'lucide-react'

import { useListCompanies } from '@/api/company.hook'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { UnknownError } from '@/pages/error-pages/unknown-error'
import { InventoryTable } from '@/pages/third-party-inventory/inventory-table'

import { Loading } from '@/components/loading'

import { InventoryPageHeader } from './inventory-page-header'

export const ThirdPartyInventoryPage = () => {
  const { updateParam } = useUrlParams()
  const { data: thirdParties, error, isInitialLoading } = useListCompanies()

  if (isInitialLoading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
    return <UnknownError />
  }

  return (
    <>
      <div className='bg-gray-50'>
        <InventoryPageHeader />
      </div>
      <div className='m-auto max-w-screen-3xl'>
        {/* Minimum height for the table container to prevent it from jumping when the user applies filters.*/}
        <div className='mx-8 mt-10 min-h-screen pb-10'>
          <InventoryTable
            inventory={thirdParties}
            onClick={(thirdPartyId: string) => {
              updateParam(UrlParam.THIRD_PARTY_ID, thirdPartyId)
            }}
          />
          <FloatButton.BackTop
            shape='square'
            type='primary'
            icon={<ChevronUp size={20} className='mx-auto' />}
          />
        </div>
      </div>
    </>
  )
}
