import { Timestamp } from '@bufbuild/protobuf'
import { Spin as AntdSpin } from 'antd'
import { FlagIcon, MessageCircleQuestionIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useGetQuestionnaire, useListQuestionnaire } from '@/api/questionnaire-assessor.hook'
import { Questionnaire, QuestionnaireStatus } from '@/gen/questionnaire/v1/model_pb'

import { questionnaireStatusLabel } from '@/const/label'
import { questionStatusLabel } from '@/const/label'

import { questionStatusToClassNameColor } from '@/lib/color'
import { formatDate, timeSinceFormatted } from '@/lib/date'
import { useReportErrorsCallback } from '@/lib/error-reporting'
import {
  QuestionStatusString,
  isQuestionWithOptions,
  useStorage,
  useThreads,
} from '@/lib/liveblocks.config'
import { cn } from '@/lib/style-helpers'

import { QuestionnaireProvider } from '@/pages/questionnaire/questionnaire-provider'
import { useQuestionnaireStatusSummary } from '@/pages/questionnaire/use-get-questionnaire-status-summary.hook'

import { Loading } from '@/components/loading'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

export const QuestionnaireSection = () => {
  return (
    <div>
      <QuestionnaireTable />
    </div>
  )
}

const QuestionnaireTable = () => {
  const navigate = useNavigate()
  const { data: questionnaires, isLoading, error } = useListQuestionnaire()
  const reportErr = useReportErrorsCallback()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    reportErr(error)
    return null
  }

  const columns: ColumnType<Questionnaire>[] = [
    {
      title: 'Name',
      search: true,
      accessor: ({ name, description }) => ({
        searchValue: `${name} ${description}`,
      }),
      render: ({ name, description }) => (
        <>
          <div>{name}</div>
          {description && <div className='text-sm text-gray-400'>{description}</div>}
        </>
      ),
    },
    {
      title: 'Status',
      filter: true,
      accessor: ({ status }) => ({
        sortValue: status,
        filterValue: questionnaireStatusLabel[status],
      }),
      render: ({ status }: { status: QuestionnaireStatus }) => questionnaireStatusLabel[status],
    },
    {
      title: 'Questions',
      dataIndex: 'id',
      key: 'questions',
      render: (id: string) => <QuestionnaireQuestionsStatus questionnaireId={id} />,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      defaultSortOrder: 'descend',
      // make sure that is sorted by due date where uncompleted are above completed
      accessor: ({ dueDate, status }) => ({
        sortValue:
          status === QuestionnaireStatus.COMPLETED
            ? dueDate!.toDate().getTime() / new Date().getTime()
            : new Date().getTime() - dueDate!.toDate().getTime(),
      }),
      render: (dueDate: Timestamp, { status }) =>
        status === QuestionnaireStatus.COMPLETED ? (
          <div className='flex items-center gap-2'>
            <span className='text-sm text-gray-500'>{formatDate(dueDate?.toDate())}</span>
          </div>
        ) : (
          <div className='flex items-center gap-2 text-nowrap'>
            {timeSinceFormatted(dueDate?.toDate())}{' '}
            <span className='text-xs text-gray-500'>{formatDate(dueDate?.toDate())}</span>
          </div>
        ),
    },
    {
      title: 'Needs Your Attention',
      dataIndex: 'id',
      render: (id: string) => <NeedsAttentionIndicators questionnaireId={id} />,
    },
  ]
  const onRow = ({ id }: Questionnaire) => {
    return { onClick: () => navigate(id) }
  }
  return <Table onRow={onRow} columns={columns} dataSource={questionnaires.questionnaires} />
}

type NeedsAttentionIndicatorsProps = {
  questionnaireId: string
}

const NeedsAttentionIndicators = ({ questionnaireId }: NeedsAttentionIndicatorsProps) => {
  const { questionnaire, isLoading } = useGetQuestionnaire({ questionnaireId: questionnaireId! })

  if (isLoading) {
    return <Spin />
  }

  return (
    <QuestionnaireProvider loadingNode={<Spin />} roomId={questionnaire?.roomId}>
      <div className='flex gap-1'>
        <FlagAnswersIndicator />
        <WaitingCommentsIndicator />
      </div>
    </QuestionnaireProvider>
  )
}

// We need to wrap the WaitingCommentsIndicator in a QuestionnaireProvider to use the useThreads hook
const WaitingCommentsIndicator = () => {
  const { threads: waitingThreads, isLoading } = useThreads({
    query: {
      metadata: {
        lastRoleToReply: 'recipient',
        resolved: false,
      },
    },
  })

  if (isLoading) {
    return <Spin />
  }

  if (waitingThreads.length === 0) {
    return null
  }

  return (
    <TextWithIcon
      className='rounded border border-yellow-500 bg-yellow-400 px-2.5 py-0.5 text-base text-white'
      text={waitingThreads.length}
      icon={<MessageCircleQuestionIcon size={14} />}
    />
  )
}

// We need to wrap the FlagAnswersIndicator in a QuestionnaireProvider to use the useStorage hook
const FlagAnswersIndicator = () => {
  const numberOfFlagAnswers = useStorage(({ questions }) =>
    questions.reduce((acc, q) => {
      if (
        (q.status === 'needsReview' || q.status === 'unanswered') &&
        isQuestionWithOptions(q) &&
        q.options.some((o) => o.isSelect && o.isFlag)
      ) {
        return acc + 1
      }

      return acc
    }, 0),
  )

  if (numberOfFlagAnswers === null) {
    return <Spin />
  }

  if (numberOfFlagAnswers === 0) {
    return null
  }

  return (
    <TextWithIcon
      className='rounded border border-red-600 bg-red-400 px-2.5 py-0.5 text-base text-white'
      text={numberOfFlagAnswers}
      icon={<FlagIcon size={14} />}
    />
  )
}

type QuestionnaireQuestionsStatusProps = {
  questionnaireId: string
}

const QuestionnaireQuestionsStatus = ({ questionnaireId }: QuestionnaireQuestionsStatusProps) => {
  const { questionnaire, isLoading } = useGetQuestionnaire({ questionnaireId: questionnaireId! })

  if (isLoading) {
    return <Spin />
  }

  return (
    <QuestionnaireProvider loadingNode={<Spin />} roomId={questionnaire?.roomId}>
      <InternalQuestionnaireStatus />
    </QuestionnaireProvider>
  )
}

const InternalQuestionnaireStatus = () => {
  const questionnaireStatusSummary = useQuestionnaireStatusSummary()

  if (!questionnaireStatusSummary) {
    return <Spin />
  }

  return (
    <div className='grid grid-cols-[min-content,min-content,auto] items-center gap-x-1 gap-y-0.5 text-sm'>
      {questionnaireStatusSummary
        .filter(({ count }) => count > 0)
        .map(({ status, count }, index) => (
          <StatusCount key={index} status={status} count={count} />
        ))}
    </div>
  )
}

type StatusCountProps = {
  status: QuestionStatusString
  count: number
}

const StatusCount = ({ count, status }: StatusCountProps) => {
  return (
    <>
      <div className={cn('h-1.5 w-1.5 rounded-full', questionStatusToClassNameColor[status])} />
      <div>{count}</div>
      <div>{questionStatusLabel[status]}</div>
    </>
  )
}

const Spin = () => <AntdSpin size='small' className='px-2' />
