import { FlagIcon } from 'lucide-react'

import { Option, ParticipantRole, isAssessor } from '@/lib/liveblocks.config'
import { cn } from '@/lib/style-helpers'

type OptionLabelProps = {
  option: Option
  participantRole: ParticipantRole
}

export const OptionLabel = ({ option, participantRole }: OptionLabelProps) => {
  return (
    <div className='flex items-center gap-2'>
      <span>{option.optionLabel}</span>
      {isAssessor(participantRole) && option.isFlag && (
        <FlagIcon
          size={26}
          className={cn('rounded-full p-1.5 text-gray-500', {
            'bg-red-400 text-white': option.isSelect,
          })}
        />
      )}
    </div>
  )
}
