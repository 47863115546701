import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useGetVendrWebhookUrl } from '@/api/credentials/vendr.hook'

import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

import { WebhookURLModal } from './webhook-url-modal'

type VendrIntegrationProps = BaseIntegrationProps

type FormValues = { secret: string }

export const VendrIntegration = ({ integration }: VendrIntegrationProps) => {
  const {
    mutate: getVendrWebhookUrl,
    isLoading,
    data: vendrWebhookUrlResponse,
  } = useGetVendrWebhookUrl()

  return (
    <div className='flex w-full justify-around pt-8'>
      {vendrWebhookUrlResponse && <WebhookURLModal url={vendrWebhookUrlResponse.url} />}
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Sending webhook events from Vendr to Lema AI']}
        />
        <DashedContainer>
          <Form
            onFinish={({ secret }: FormValues) => {
              getVendrWebhookUrl({
                secret,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Integration Settings</h2>
            <Form.Item<FormValues>
              name='secret'
              rules={[{ required: false, message: 'Webhook Secret is optional' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Secret (Optional)'
                type='password'
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Generate Webhook URL
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
