import _ from 'lodash'
import { createContext } from 'react'

import { isQuestionWithOptions, useStorage } from '@/lib/liveblocks.config'

export const NOT_DETERMINED = 'notDetermined'
export const REQUIRED = 'required'
export const NOT_REQUIRED = 'notRequired'

export type RequiredStatus = typeof NOT_DETERMINED | typeof REQUIRED | typeof NOT_REQUIRED

type SubCategoryRequiredContext = {
  subCategoriesRequired: Record<string, RequiredStatus> | null
}

export const SubCategoryRequiredContext = createContext<SubCategoryRequiredContext>({
  subCategoriesRequired: null,
})

type SubCategoryRequiredProviderProps = React.PropsWithChildren

export const SubCategoryRequiredProvider = ({ children }: SubCategoryRequiredProviderProps) => {
  const subCategoriesRequired = useStorage(({ questions }): Record<string, RequiredStatus> => {
    return Object.entries(_.groupBy(questions, 'subCategory')).reduce(
      (acc, [subCategory, questions]) => {
        const leadingQuestions = questions.filter((q) => q.isLeading)

        if (leadingQuestions.length === 0) return { ...acc, [subCategory]: REQUIRED }

        if (leadingQuestions.some((q) => q.status === 'unanswered')) {
          return { ...acc, [subCategory]: NOT_DETERMINED }
        } else if (
          !leadingQuestions.some(
            (q) => isQuestionWithOptions(q) && q.options.some((o) => o.isSelect && o.isFlag),
          )
        ) {
          return {
            ...acc,
            [subCategory]: NOT_REQUIRED,
          }
        } else {
          return { ...acc, [subCategory]: REQUIRED }
        }
      },
      {},
    )
  })

  return (
    <SubCategoryRequiredContext.Provider value={{ subCategoriesRequired }}>
      {children}
    </SubCategoryRequiredContext.Provider>
  )
}
