import { AutoComplete } from 'antd'
import { useContext, useEffect, useState } from 'react'

import { useListCompanies } from '@/api/company.hook'

import { NewQuestionnaireContext } from '@/pages/questionnaire-management/new-questionnaire-modal/context'
import { StepProps } from '@/pages/questionnaire-management/new-questionnaire-modal/use-new-questionnaire-modal.hook'
import { InputRecipient } from '@/pages/questionnaire/input-recipient'

import { DatePicker } from '@/components/date/picker'
import { Loading } from '@/components/loading'
import { Label } from '@/components/ui/label'

export type RecipientsStepProps = StepProps

export const RecipientsStep = ({ setIsNextEnabled }: StepProps) => {
  const {
    dueDate: date,
    setDueDate: setDate,
    selectedThirdPartyId,
    setSelectedThirdPartyId,
    recipients,
    setRecipients,
  } = useContext(NewQuestionnaireContext)
  const [companyInput, setCompanyInput] = useState('')

  const { data: companies, isLoading } = useListCompanies()

  useEffect(() => {
    setIsNextEnabled(!!selectedThirdPartyId && recipients.length > 0)
  }, [recipients.length, selectedThirdPartyId, setIsNextEnabled])

  if (isLoading) return <Loading />

  return (
    <>
      <h2 className='mb-2 text-xl'>Invite Questionnaire Recipient(s)</h2>

      <Label className='text-bold mb-2'>Third-Party Name</Label>
      <AutoComplete
        filterOption={(inputValue, option) =>
          option?.label?.toLowerCase().includes(inputValue.toLowerCase()) || false
        }
        className='mb-4 h-10 w-full'
        placeholder='Third-Party Name'
        value={companyInput}
        onChange={(input) => setCompanyInput(input)}
        options={companies.map((company) => ({ value: company.id, label: company.profile?.name }))}
        optionRender={({ label, value }) => {
          return <div key={value}>{label}</div>
        }}
        onSelect={(_, { value, label }) => {
          setSelectedThirdPartyId(value)
          label && setCompanyInput(label)
        }}
      />

      <Label className='text-bold mb-2'>Recipients</Label>
      <InputRecipient onRecipientChange={setRecipients} />
      <div className='mb-4 text-sm text-gray-500'>
        Recipients will receive a link to the questionnaire invite via email.
      </div>

      <Label className='text-bold mb-2'>Due Date</Label>
      <div>
        <DatePicker
          className={'z-max'}
          buttonClassName='w-full h-10'
          initialDate={date}
          onDateChange={(newDate) => {
            setDate(newDate)
          }}
        />
      </div>
    </>
  )
}
