import { VariantProps, cva } from 'class-variance-authority'
import { ChevronDownIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { TextWithIcon } from '@/components/text-with-icon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const commonTriggerStyles =
  'border border-solid whitespace-nowrap rounded py-2 px-4 flex gap-1.5 items-center border-gray-200'
const commonDisabledStyles = 'cursor-not-allowed opacity-50'
const commonTransitionStyles = 'transition duration-300 ease-in-out'

const dropdownVariants = cva('', {
  variants: {
    triggerAppearance: {
      headless: '',
      default: `${commonTriggerStyles} text-gray-400`,
      secondary: `${commonTriggerStyles} text-gray-700`,
    },
    triggerDisabled: {
      true: commonDisabledStyles,
      false: '',
    },
  },
  defaultVariants: {
    triggerAppearance: 'default',
    triggerDisabled: false,
  },
  compoundVariants: [
    {
      triggerAppearance: 'default',
      triggerDisabled: false,
      className: `${commonTransitionStyles} hover:bg-gray-700 hover:text-white`,
    },
    {
      triggerAppearance: 'secondary',
      triggerDisabled: false,
      className: `${commonTransitionStyles} hover:bg-gray-200`,
    },
  ],
})

type menuItem = {
  content: string | ReactNode
  icon?: ReactNode
  onClick?: () => void
  itemDisabled?: boolean
  hide?: boolean
}

export type DropdownProps = {
  triggerContent: ReactNode
  menuItems: menuItem[]
} & VariantProps<typeof dropdownVariants>

export const Dropdown = ({
  triggerContent,
  menuItems,
  triggerAppearance,
  triggerDisabled,
}: DropdownProps) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        disabled={!!triggerDisabled}
        className={dropdownVariants({ triggerAppearance, triggerDisabled })}
      >
        {triggerContent}
        {triggerAppearance !== 'headless' && <ChevronDownIcon className='w-4' />}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={triggerAppearance === 'headless' ? 'end' : 'center'}
        className='z-max flex flex-col gap-1 rounded bg-gray-700 text-white'
      >
        {menuItems.map(
          ({ content, onClick, icon, itemDisabled, hide = false }, index) =>
            !hide && (
              <DropdownMenuItem
                onClick={!itemDisabled ? onClick : undefined}
                className='cursor-pointer'
                key={index}
                disabled={itemDisabled}
              >
                <TextWithIcon text={content} icon={icon} />
              </DropdownMenuItem>
            ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
