import _ from 'lodash'

import { useUpdateCompany } from '@/api/company.hook'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { riskLevelClassNameColor } from '@/lib/color'
import { RISK_LEVELS } from '@/lib/proto-types'
import { cn } from '@/lib/style-helpers'

import { SelectWithComment } from '@/components/select/select-with-comment'
import { MenuItem } from '@/components/select/type'
import { TextWithIcon } from '@/components/text-with-icon'
import { Tooltip } from '@/components/ui/tooltip'

import Critical from '@/assets/tier-critical.svg'
import High from '@/assets/tier-high.svg'
import Low from '@/assets/tier-low.svg'
import Medium from '@/assets/tier-medium.svg'

export type RiskFactorIconProps = {
  riskLevel: RiskLevel
  showText?: boolean
  showTooltip?: boolean
}

export const CompanyRiskIcon = ({
  riskLevel,
  showTooltip = false,
  showText = false,
}: RiskFactorIconProps) => {
  const Icon = riskLevelToIcon[riskLevel]
  if (!Icon) return null

  return (
    <Tooltip
      tooltipDisabled={!showTooltip}
      trigger={
        showText ? (
          <TextWithIcon
            className={riskLevelClassNameColor[riskLevel]}
            icon={<Icon className='size-5' />}
            text={riskLevelLabel[riskLevel]}
          />
        ) : (
          <Icon className={cn('h-5 min-h-5 w-5 min-w-5', riskLevelClassNameColor[riskLevel])} />
        )
      }
    >
      <div className='capitalize'>Inherent Risk: {riskLevelLabel[riskLevel]}</div>
    </Tooltip>
  )
}

const riskLevelToIcon: Record<RiskLevel, typeof Critical | null> = {
  [RiskLevel.CRITICAL]: Critical,
  [RiskLevel.HIGH]: High,
  [RiskLevel.MEDIUM]: Medium,
  [RiskLevel.LOW]: Low,
  [RiskLevel.UNSPECIFIED]: null,
}

export type SelectCompanyRiskProps = {
  riskLevel: RiskLevel
  thirdPartyId: string
  showText?: boolean
  companyName: string
}

export const SelectCompanyRisk = ({
  thirdPartyId,
  riskLevel,
  showText = false,
  companyName,
}: SelectCompanyRiskProps) => {
  const trackRiskUpdate = useTrackCallback('third-party.risk.update')
  const { mutate: updateCompany } = useUpdateCompany(thirdPartyId)
  const updateCompanyRisk = (newRiskLevel: RiskLevel, comment: string) => {
    trackRiskUpdate({
      company: companyName,
      riskLevel: riskLevelLabel[newRiskLevel],
      previousRiskLevel: riskLevelLabel[riskLevel],
      id: thirdPartyId,
      comment,
    })

    updateCompany({
      companyRiskLevel: newRiskLevel,
      id: thirdPartyId,
      comment: comment || undefined,
    })
  }

  return (
    <SelectWithComment
      value={riskLevel}
      triggerAppearance='headless'
      title='Inspect Or Adjust Third-Party Inherent Risk'
      onSave={updateCompanyRisk}
      selectedValueRenderer={(value) => (
        <CompanyRiskIcon showTooltip showText={showText} riskLevel={value} />
      )}
      menuItems={RISK_LEVELS.map(
        ({ no }): MenuItem<RiskLevel> => ({
          type: 'menuSelectItem',
          value: no,
          label: <CompanyRiskIcon showText riskLevel={no} />,
        }),
      )}
    />
  )
}
