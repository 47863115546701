import { Drawer } from 'antd'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { CompanyDrawer } from '@/pages/company-drawer/company-drawer'
import { LARGE_DRAWER_WIDTH } from '@/pages/drawers/size'
import { Z_INDEX_LOW } from '@/pages/drawers/z-index'

export const ThirdPartyDrawer = () => {
  const {
    urlParams: { thirdPartyId },
    removeParam,
  } = useUrlParams()
  return (
    <Drawer
      zIndex={Z_INDEX_LOW}
      width={LARGE_DRAWER_WIDTH}
      closeIcon={false}
      onClose={() => removeParam(UrlParam.THIRD_PARTY_ID)}
      open={!!thirdPartyId}
    >
      {thirdPartyId && <CompanyDrawer companyId={thirdPartyId} />}
    </Drawer>
  )
}
