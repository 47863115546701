import { HelpCircleIcon } from 'lucide-react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { DataTypeSource } from '@/gen/inventory/v1/company_service_pb'

import { dataTypeSourceDescription } from '@/const/description'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { DataTypeBadge } from '@/components/badge/data-type'
import { Separator } from '@/components/ui/separator'
import { Tooltip } from '@/components/ui/tooltip'

import { dataTypeSourceToLabel, dataTypeSourceToVariant } from './const'

export const DataTypeExplanation = () => {
  const { isEnabled: dataTypeDocumentedVsExchanged } = useFeatureFlagEnabled(
    'data-type-documented-vs-exchanged',
  )
  return (
    dataTypeDocumentedVsExchanged && (
      <Tooltip
        className='px-11 py-7'
        side='right'
        trigger={<HelpCircleIcon className='text-gray-400' size={13} />}
      >
        <div className='flex w-64 flex-col gap-5.5 text-center'>
          <h1 className='text-xs font-bold'>SOURCE OF DATA TYPE SIGHTING:</h1>
          {[
            DataTypeSource.DOCUMENTED_ONLY,
            DataTypeSource.DOCUMENTED_AND_ACCESSED,
            DataTypeSource.UNDOCUMENTED_ACCESS,
          ].map((source) => (
            <Fragment key={source}>
              <div className='flex flex-col items-center gap-3'>
                <DataTypeBadge variant={dataTypeSourceToVariant[source]}>
                  {dataTypeSourceToLabel[source]}
                </DataTypeBadge>

                <div className='whitespace-pre-wrap text-base font-normal'>
                  {dataTypeSourceDescription[source]}
                </div>
              </div>
              <Separator className='bg-gray-50' />
            </Fragment>
          ))}

          <div className='whitespace-pre-wrap text-gray-400'>
            For real-time data monitoring, add a{' '}
            <Link to='/integrations/marketplace' className='text-purple-400 hover:text-purple-600'>
              data integration
            </Link>
            , or contact us for assistance.
          </div>
        </div>
      </Tooltip>
    )
  )
}
