import { Position, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'

import { CategoryMetadata } from '@/gen/artifacts/analyzer/v1/analyzer_service_pb'

import { BaseNode, BaseNodeProps } from './base-node'
import { SelectValue } from './types'

type SubcategoryNodeProps = BaseNodeProps & {
  data: {
    selectedCategories: string[]
    options: CategoryMetadata[]
  }
  id: string
}

export const SubcategoryNode: React.FC<SubcategoryNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Subcategory Node'
      inputHandles={[
        {
          id: `subcat-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
      dataInputs={[
        {
          id: `subcat-${id}-selectedCategories`,
          name: 'selectedCategories',
          type: 'select',
          options: data.options?.map((category) => ({
            label: `${category.category} - ${category.subcategory}`,
            value: category.id,
          })),
          onChange: (value: SelectValue) => updateNodeData(id, { selectedCategories: value }),
          value: data.selectedCategories,
          placeholder: 'Select categories',
          mode: 'multiple',
        },
      ]}
      outputHandles={[
        {
          id: `subcat-${id}-matched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Matched',
          borderColor: 'green',
        },
        {
          id: `subcat-${id}-unmatched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Unmatched',
          borderColor: 'red',
        },
      ]}
    />
  )
})

SubcategoryNode.displayName = 'SubcategoryNode'
