import { ArrowRightIcon } from 'lucide-react'

import { useGetUserById } from '@/api/get-user-by-id.hook'
import { FindingChange } from '@/gen/inventory/v1/finding_service_pb'

import { findingStatusLabel, riskLevelLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'

import { CommentCard } from '@/pages/finding-details/comment-card'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'

type FindingChangeCardProps = {
  change: FindingChange
}

export const FindingChangeCard = ({ change }: FindingChangeCardProps) => {
  const { data: user } = useGetUserById(change.userId)
  const { prevRiskLevel, newRiskLevel, prevStatus, newStatus, comment } = change

  let statement = ''
  let prevBadge: React.ReactNode = null
  let newBadge: React.ReactNode = null

  if (prevRiskLevel !== newRiskLevel) {
    statement = `${user?.name} Changed Finding Severity to '${riskLevelLabel[newRiskLevel]}'`
    prevBadge = <FindingSeverityBadge level={prevRiskLevel} />
    newBadge = <FindingSeverityBadge level={newRiskLevel} />
  }

  if (prevStatus !== newStatus) {
    statement = `${user?.name} Changed Finding Work Status to '${findingStatusLabel[newStatus]}'`
    prevBadge = <FindingStatusBadge status={prevStatus} />
    newBadge = <FindingStatusBadge status={newStatus} />
  }

  return (
    <div>
      <div
        className={cn('flex flex-col gap-3 rounded-t border-x border-t p-5', {
          'rounded border': !comment,
        })}
      >
        <span className='font-semibold'>{statement}</span>
        <div className='flex flex-wrap items-center gap-3'>
          {prevBadge}
          <ArrowRightIcon size={14} />
          {newBadge}
        </div>
      </div>
      {comment && <CommentCard variant={'roundedNoneTopBorder'} comment={comment} />}
    </div>
  )
}
