import { useMutation, useQueryClient } from '@tanstack/react-query'

import { listQuestionnaire } from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'
import { createQuestionnaire } from '@/gen/questionnaire/v1/template_service-TemplateService_connectquery'

export const useCreateQuestionnaire = () => {
  const queryClient = useQueryClient()
  const { queryKey: listQuestionnaireQueryKey } = listQuestionnaire.useQuery()

  return useMutation(createQuestionnaire.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQuestionnaireQueryKey)
    },
  })
}
