import { DownloadIcon, Loader2Icon } from 'lucide-react'
import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Button, ButtonProps } from '@/components/ui/button'

import LemaLogo from '@/assets/lema.svg'

export const ExportContent = React.forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    return (
      <div className='invisible absolute'>
        <div ref={ref} className='absolute w-full'>
          <div className='bg-gray-50'>
            <div className='p-2'>
              <LemaLogo />
            </div>
          </div>
          {children}
          <div className='fixed bottom-3 w-full text-center'>
            Copyright © Lema Labs, inc. All Rights Reserved
          </div>
        </div>
      </div>
    )
  },
)

ExportContent.displayName = 'ExportContent'

type ExportButtonProps = {
  getExportContent: () => { exportContent: ReactNode; renderContent: () => void; isLoaded: boolean }
  documentTitle?: string
} & ButtonProps

export const ExportButton = ({ getExportContent, documentTitle, ...props }: ExportButtonProps) => {
  const [isBeforeGetContent, setIsBeforeGetContent] = useState(false)
  const [contentReady, setContentReady] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const { exportContent, renderContent, isLoaded } = getExportContent()

  const reactToPrintFn = useReactToPrint({
    content: () => contentRef.current,
    documentTitle,
    onAfterPrint: () => setIsBeforeGetContent(false),
  })

  useEffect(() => {
    if (isBeforeGetContent) {
      renderContent()
    }

    if (isBeforeGetContent && isLoaded) {
      setContentReady(true)
      reactToPrintFn()
    }
  }, [isBeforeGetContent, isLoaded, renderContent, reactToPrintFn])

  const handlePrint = () => {
    setIsBeforeGetContent(true)
  }

  return (
    <>
      <Button
        onClick={handlePrint}
        disabled={isBeforeGetContent}
        variant='outline'
        className='my-4 flex gap-2'
        {...props}
      >
        {isBeforeGetContent ? (
          <Loader2Icon className='mr-2 size-4 animate-spin text-gray-700' />
        ) : (
          <DownloadIcon strokeWidth={1.5} className='mr-2 size-4 text-gray-700' />
        )}
        Export
      </Button>

      {/* We need to reader the content of the component for the browser to export it */}
      {isBeforeGetContent && contentReady && (
        <ExportContent ref={contentRef}>{exportContent}</ExportContent>
      )}
    </>
  )
}
