// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file ziphq/v1/ziphq_service.proto (package ziphq.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCredentialsRequest, AddCredentialsResponse } from "./ziphq_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "ziphq.v1.ZiphqCollectorService";

/**
 * @generated from service ziphq.v1.ZiphqCollectorService
 */
export const ZiphqCollectorService = {
  typeName: "ziphq.v1.ZiphqCollectorService",
  methods: {
    /**
     * @generated from rpc ziphq.v1.ZiphqCollectorService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: ZiphqCollectorService,});

/**
 * @generated from rpc ziphq.v1.ZiphqCollectorService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
