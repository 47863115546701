export type Workflow = {
  id: string
  label: string
  category: string
}

export const workflows: Workflow[] = [
  {
    id: 'company-inherent-risk-updated',
    label: 'Inherent Risk Updated',
    category: 'third-party',
  },
  {
    id: 'company-status-updated',
    label: 'Status Updated',
    category: 'third-party',
  },
  {
    id: 'finding-detected',
    label: 'Finding Detected',
    category: 'finding',
  },
  {
    id: 'finding-new-comment',
    label: 'New Comment',
    category: 'finding',
  },
  {
    id: 'finding-severity-updated',
    label: 'Severity Updated',
    category: 'finding',
  },
  {
    id: 'finding-work-status-updated',
    label: 'Status Updated',
    category: 'finding',
  },
]
