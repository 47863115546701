import { UserStatus } from '@/gen/customer/management/v1/service_pb'

import { userStatusLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'

import { UserStatusIcon } from '@/components/icons/user-status'
import { Badge } from '@/components/ui/badge'

interface UserStatusBadgeProps {
  status: UserStatus
}

export const UserStatusBadge = ({ status }: UserStatusBadgeProps) => (
  <Badge
    variant='outline'
    className={cn(
      'flex w-fit items-center gap-1 whitespace-nowrap border-none p-0 text-md font-normal text-gray-400',
      {
        'text-green-400': status === UserStatus.ENABLED,
        'text-red-400': status === UserStatus.DISABLED,
      },
    )}
  >
    <UserStatusIcon status={status} />
    {userStatusLabel[status]}
  </Badge>
)
