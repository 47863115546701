import { cva } from 'class-variance-authority'

export const selectVariants = cva(
  'flex items-center gap-0.5 whitespace-nowrap rounded duration-200 ease-in-out',
  {
    variants: {
      triggerAppearance: {
        default: 'h-10 border px-4 hover:bg-gray-200',
        full: 'h-10 w-full border px-4 hover:bg-gray-200',
        headless: 'group',
      },
    },
    defaultVariants: {
      triggerAppearance: 'default',
    },
  },
)
