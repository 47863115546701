import { RumInitConfiguration } from '@datadog/browser-rum'

export const datadogOptions = (buildMode: string) => {
  const opts: RumInitConfiguration = {
    service: 'web',
    applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: import.meta.env.VITE_DD_SITE,
    env: import.meta.env.VITE_DD_ENV,
    version: import.meta.env.VITE_BUILD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    enableExperimentalFeatures: ['feature_flags'],
    allowedTracingUrls: [
      /https:\/\/(?!auth(?:eu)?\.lema\.ai).*lema\.ai(?!\/analytics)(?!\/questionnaire-uploads)/,
    ],
  }

  if (buildMode === 'staging' || buildMode === 'production' || buildMode === 'production_us') {
    opts.proxy = (options) => `/v2.analytics${options.path}?${options.parameters}`
  }

  if (buildMode === 'development') {
    opts.sessionReplaySampleRate = 0
    opts.sessionSampleRate = 0
  }
  return opts
}
