import { SettingsIcon } from 'lucide-react'

import { UrlParam } from '@/lib/url-param.hook'

import { useSettingsItems } from '@/pages/settings-page/use-settings-items.hook'

import { VerticalTabs } from '@/components/tabs/vertical'

export const SettingsPage = () => {
  const settingsItems = useSettingsItems()
  return (
    <div className='m-auto max-w-screen-3xl px-8 py-15'>
      <div>
        <h2 className='absolute flex items-center gap-1 text-3xl font-bold'>
          <SettingsIcon />
          <span>Settings</span>
        </h2>

        <VerticalTabs
          className='mt-14 w-48'
          urlParamName={UrlParam.SETTING_SECTION}
          items={settingsItems}
        />
      </div>
    </div>
  )
}
