import { Badge } from 'antd'
import { CheckIcon, ClockIcon, LightbulbIcon, XIcon } from 'lucide-react'
import { PropsWithChildren } from 'react'

import { requiredStatusLabel } from '@/const/label'

import { QuestionType, isAssessor } from '@/lib/liveblocks.config'
import { cn } from '@/lib/style-helpers'

import { useGetQuestionRequired } from '@/pages/questionnaire/question/use-get-question-required.hook'
import { useUpdateQuestionStatus } from '@/pages/questionnaire/question/use-update-question-status.hook'

import { Alert, AlertTitle } from '@/components/ui/alert'

import { BaseQuestionProps } from './index'
import { QuestionThread } from './thread'

import '@liveblocks/react-comments/styles.css'

export type QuestionContainerProps = BaseQuestionProps & PropsWithChildren

export const QuestionContainer = ({
  question,
  children,
  section,
  participantRole,
}: QuestionContainerProps) => {
  return (
    <div className='flex items-center gap-2'>
      <div className='w-3/5'>
        <DisabledQuestionProvider question={question}>
          <div className='rounded border p-2'>
            {isAssessor(participantRole) && (
              <ActionMenu question={question} disabled={participantRole == 'assessor-readonly'} />
            )}
            <div className={cn('px-6 pb-6', { 'pt-6': participantRole === 'recipient' })}>
              <h3 className='mb-4 text-lg'>
                {section} {question.question}
              </h3>
              {question.note && (
                <Alert variant={'note'} className='my-3 pl-3'>
                  <AlertTitle className='flex items-start gap-2'>
                    <LightbulbIcon size={14} /> {question.note}
                  </AlertTitle>
                </Alert>
              )}
              {children}
            </div>
          </div>
        </DisabledQuestionProvider>
      </div>

      <div className='w-2/5'>
        <QuestionThread
          participantRole={participantRole}
          questionId={question.id}
          readOnly={participantRole == 'assessor-readonly'}
        />
      </div>
    </div>
  )
}

type DisabledTooltipProviderProps = {
  question: QuestionType
} & React.PropsWithChildren

const DisabledQuestionProvider = ({ children, question }: DisabledTooltipProviderProps) => {
  const requiredStatus = useGetQuestionRequired(question)

  if (requiredStatus === 'required') {
    return children
  }

  return (
    <Badge.Ribbon text={requiredStatusLabel[requiredStatus]}>
      <div className='cursor-not-allowed opacity-40'>{children}</div>
    </Badge.Ribbon>
  )
}

type ActionMenuProps = {
  question: QuestionType
  disabled?: boolean
}

const ActionMenu = ({ question, disabled = false }: ActionMenuProps) => {
  const updateQuestionStatus = useUpdateQuestionStatus(question.id)
  return (
    <div className='flex justify-end gap-2'>
      <ClockIcon
        onClick={() =>
          !disabled &&
          updateQuestionStatus(question.status === 'postponed' ? 'needsReview' : 'postponed')
        }
        className={cn(
          'cursor-pointer rounded border bg-gray-200 bg-opacity-20 p-1 text-orange-400 duration-200 ease-in-out',
          {
            'border-orange-300 bg-orange-300': question.status === 'postponed',
            'cursor-not-allowed opacity-50': disabled,
            'hover:bg-opacity-80': !disabled,
          },
        )}
      />
      <XIcon
        onClick={() =>
          !disabled &&
          updateQuestionStatus(question.status === 'rejected' ? 'needsReview' : 'rejected')
        }
        className={cn(
          'cursor-pointer rounded border bg-gray-200 bg-opacity-20 p-1 text-red-400 duration-200 ease-in-out',
          {
            'border-red-300 bg-red-300': question.status === 'rejected',
            'cursor-not-allowed opacity-50': disabled,
            'hover:bg-opacity-80': !disabled,
          },
        )}
      />
      <CheckIcon
        onClick={() =>
          !disabled &&
          updateQuestionStatus(question.status === 'accepted' ? 'needsReview' : 'accepted')
        }
        className={cn(
          'cursor-pointer rounded border bg-gray-200 bg-opacity-20 p-1 text-green-400 duration-200 ease-in-out',
          {
            'border-green-300 bg-green-300': question.status === 'accepted',
            'cursor-not-allowed opacity-50': disabled,
            'hover:bg-opacity-80': !disabled,
          },
        )}
      />
    </div>
  )
}
