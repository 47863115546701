import { Checkbox } from '@/components/ui/checkbox'

import { QuestionContainer, QuestionContainerProps } from './container'
import { OptionLabel } from './option-label'

type CheckboxQuestionProps = QuestionContainerProps

export const CheckboxQuestion = (props: CheckboxQuestionProps) => {
  const { question } = props

  return (
    <QuestionContainer {...props}>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col'>
          {question.options.map((option) => (
            <div key={option.id} className='flex w-full items-center gap-2.5'>
              <Checkbox className='border-gray-400' disabled={true} />
              <OptionLabel questionId={question.id} option={option} />
            </div>
          ))}
        </div>
      </div>
    </QuestionContainer>
  )
}
