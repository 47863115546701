import { useMemo } from 'react'

import { useCompaniesBySeverity } from '@/api/statistics.hook'

import { sortRiskLevel } from '@/const/priority'

import { getTimestampDaysAgo } from '@/lib/date'
import { calculateGrowthPercentage } from '@/lib/math-utils'

import { EntityCountAndGrowth } from '@/pages/dashboard/entity-count-and-growth'
import { WidgetActiveStatusesInfo } from '@/pages/dashboard/widget-active-statuses-info'

import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Sentiment } from '@/components/variance-percentage/types'

export const ThirdPartiesPerInherentRisk = () => {
  const monthAgo = useMemo(() => getTimestampDaysAgo(30), [])
  const today = useMemo(() => getTimestampDaysAgo(0), [])
  const { data: companiesMonthAgo, isLoading: isMonthAgoLoading } = useCompaniesBySeverity(monthAgo)
  const { data: companiesToday, isLoading: isTodayLoading } = useCompaniesBySeverity(today)

  if (isMonthAgoLoading || isTodayLoading) return <Loading />

  return (
    <Card className='w-full'>
      <CardHeader className='mx-9 my-6'>
        <CardTitle>
          <TextWithIcon
            iconPosition='end'
            text={'Third-Parties per Inherent Risk'}
            icon={<WidgetActiveStatusesInfo />}
          />
        </CardTitle>
        <CardDescription>Current versus 30 days ago</CardDescription>
      </CardHeader>
      <CardContent className='mb-9 flex flex-col gap-4 px-13'>
        {companiesToday?.values
          .sort(({ severity: a }, { severity: b }) => sortRiskLevel(a, b))
          .map(({ severity, total }) => (
            <EntityCountAndGrowth
              key={severity}
              count={total}
              entityName='third-parties'
              severity={severity}
              growthSentiment={Sentiment.NEGATIVE}
              growthPercentage={calculateGrowthPercentage(
                total,
                companiesMonthAgo?.values.find((v) => v.severity === severity)?.total || 0,
              )}
            />
          ))}
      </CardContent>
    </Card>
  )
}
