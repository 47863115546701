import { Input } from 'antd'

import { QuestionContainer, QuestionContainerProps } from './container'

type FreeTextQuestionProps = QuestionContainerProps

export const FreeTextQuestion = (props: FreeTextQuestionProps) => {
  return (
    <QuestionContainer {...props}>
      <Input readOnly={true} placeholder='Enter answer here...' className='rounded border p-2' />
    </QuestionContainer>
  )
}
