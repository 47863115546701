import tailwindConfig from '@root/tailwind.config'
import { VictoryThemeDefinition } from 'victory'

const { gray } = tailwindConfig.theme.colors

export const victoryBarChartTheme: VictoryThemeDefinition = {
  dependentAxis: {
    style: {
      grid: {
        pointerEvents: 'painted',
        stroke: gray[300],
        strokeDasharray: '2,5',
      },
      tickLabels: {
        padding: 10,
        fill: gray[300],
        fontSize: 12,
        fontFamily: "'Manrope', sans-serif",
      },
      axis: { strokeWidth: 1, stroke: gray[100] },
    },
  },
  axis: {
    style: {
      grid: { stroke: 'none' },
      axis: { strokeWidth: 1, stroke: gray[100] },
      tickLabels: {
        padding: 10,
        fill: gray[300],
        fontSize: 12,
        fontFamily: "'Manrope', sans-serif",
      },
    },
  },
}

export const victoryPolarChartTheme: VictoryThemeDefinition = {
  polarAxis: {
    style: {
      axisLabel: { fill: gray[700], fontFamily: "'Manrope', sans-serif" },
      grid: { stroke: gray[100] },
      axis: { stroke: gray[200], strokeDasharray: '2' },
    },
  },
}
