import { cva } from 'class-variance-authority'

import { Badge, BadgeProps } from '@/components/ui/badge'

export type DataTypeBadgeProps = {
  variant: 'alert' | 'regular' | 'faded'
} & Pick<BadgeProps, 'onClick' | 'children'>

const badgeVariants = cva('text-md whitespace-nowrap font-normal', {
  variants: {
    variant: {
      regular: 'text-gray-700',
      alert: 'border-red-200 text-red-200',
      faded: 'border-dashed border-gray-300 text-gray-400',
    },
  },
  defaultVariants: {
    variant: 'regular',
  },
})

export const DataTypeBadge = ({ children, variant, ...props }: DataTypeBadgeProps) => {
  return (
    <Badge {...props} variant={'outline'} className={badgeVariants({ variant })}>
      {children}
    </Badge>
  )
}
