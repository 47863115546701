import { PlainMessage } from '@bufbuild/protobuf'
import { LucideIcon, PlugIcon, WandIcon } from 'lucide-react'

import {
  InherentRiskCategoryEnum,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { inherentRiskSourceLabel, irpiTooltipPrefix } from '@/const/label'

import { riskLevelToColor } from '@/lib/color'

import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { RiskLevelIcon } from '@/components/icons/risk-severity'
import { Tooltip } from '@/components/ui/tooltip'

type InherentRiskItemCellProps = {
  inherentRiskItem?: PlainMessage<InherentRiskItem>
  categoryEnum: InherentRiskCategoryEnum
}

export const IrpiTooltip = ({
  children,
  trigger,
}: {
  children?: React.ReactNode
  trigger: React.ReactNode
}) => {
  return (
    <Tooltip className='px-6 py-4' trigger={trigger} triggerClassName='w-full'>
      <div className='flex flex-col gap-1 text-center'>
        <span className='text-sm'>{children}</span>
      </div>
    </Tooltip>
  )
}

const sourceToIcon: Record<InherentRiskSource, LucideIcon | null> = {
  [InherentRiskSource.INTEGRATION]: PlugIcon,
  [InherentRiskSource.MICROSOFT_ENTRA_ID]: PlugIcon,
  [InherentRiskSource.GOOGLE_WORKSPACE]: PlugIcon,
  [InherentRiskSource.ZIP]: PlugIcon,
  [InherentRiskSource.WIZ]: PlugIcon,
  [InherentRiskSource.WEBHOOK]: PlugIcon,
  [InherentRiskSource.NETSKOPE]: PlugIcon,
  [InherentRiskSource.OKTA]: PlugIcon,
  [InherentRiskSource.PROJECTED]: WandIcon,
  [InherentRiskSource.IRQ]: null,
  [InherentRiskSource.UNSPECIFIED]: null,
}

export const InherentRiskItemCell = ({
  inherentRiskItem,
  categoryEnum,
}: InherentRiskItemCellProps) => {
  const riskColor = riskLevelToColor[inherentRiskItem?.severity || RiskLevel.UNSPECIFIED]
  const source = inherentRiskItem?.source || InherentRiskSource.UNSPECIFIED
  const SourceIcon = sourceToIcon[inherentRiskItem?.source || InherentRiskSource.UNSPECIFIED]
  const severity = inherentRiskItem?.severity || RiskLevel.UNSPECIFIED
  return (
    inherentRiskItem && (
      <IrpiTooltip
        trigger={
          <div
            style={{ borderColor: riskColor }}
            className='my-2 flex h-12 w-full max-w-44 items-center rounded border text-center font-medium xl:min-w-28'
          >
            <div
              style={{ backgroundColor: riskColor }}
              className='flex h-full min-w-5.5 items-center justify-center text-white'
            >
              <RiskLevelIcon variant='inherit' level={severity} />
            </div>
            {source !== InherentRiskSource.UNSPECIFIED && source !== InherentRiskSource.IRQ && (
              <div
                style={{ color: riskColor }}
                className='absolute -mt-12 ml-7 inline-block p-0.5 text-xs'
              >
                <div
                  style={{ color: riskColor }}
                  className='flex h-4 w-min items-center gap-1.5 bg-white'
                >
                  {SourceIcon && <SourceIcon className='inline-block h-4 w-3' />}
                  <span className='hidden 2xl:block'>{inherentRiskSourceLabel[source]}</span>
                </div>
              </div>
            )}
            <div className='mx-auto line-clamp-2 overflow-hidden text-ellipsis px-1 capitalize'>
              {inherentRiskItem.displayName}
            </div>
          </div>
        }
      >
        <span>{irpiTooltipPrefix[categoryEnum]}</span>
        <br />
        <span className='font-semibold capitalize'>{inherentRiskItem.displayName}</span>

        <div className='mt-4 flex flex-col items-center self-center text-center'>
          Severity level:
          <CompanyRiskIcon showText={true} riskLevel={severity} />
        </div>
      </IrpiTooltip>
    )
  )
}
