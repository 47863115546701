import { cva } from 'class-variance-authority'

import { useUpdateFinding } from '@/api/update-finding.hook'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'

import { RiskLevelIcon } from '@/components/icons/risk-severity'
import { SelectWithComment } from '@/components/select/select-with-comment'
import { Badge } from '@/components/ui/badge'

type Variant = 'bold' | 'light' | 'borderless'
interface FindingSeverityBadgeProps {
  level: RiskLevel
  variant?: Variant
}
const riskSeverityVariants = cva(
  'text-md flex w-fit items-center gap-1 rounded px-1.5 font-medium',
  {
    variants: {
      riskLevel: {
        [RiskLevel.CRITICAL]: 'border-red-100 bg-red-50 text-red-500',
        [RiskLevel.HIGH]: 'border-orange-100 bg-orange-50 text-orange-500',
        [RiskLevel.MEDIUM]: 'border-yellow-100 bg-yellow-50 text-yellow-500',
        [RiskLevel.LOW]: 'border-gray-200 bg-white text-gray-400',
        [RiskLevel.UNSPECIFIED]: '',
      },
      border: {
        normal: '',
        borderless: 'border-none bg-transparent',
      },
      weight: {
        normal: '',
        light: 'bg-transparent opacity-75',
      },
    },
    defaultVariants: {
      border: 'normal',
      weight: 'normal',
    },
  },
)

export const FindingSeverityBadge = ({ level, variant = 'bold' }: FindingSeverityBadgeProps) => {
  const label = riskLevelLabel[level]

  return (
    <Badge
      variant='outline'
      className={riskSeverityVariants({
        border: variant === 'borderless' ? 'borderless' : 'normal',
        weight: variant === 'light' ? 'light' : 'normal',
        riskLevel: level,
      })}
    >
      <RiskLevelIcon variant='inherit' level={level} />
      {label}
    </Badge>
  )
}

type SelectFindingSeverityBadgeProps = {
  severity: RiskLevel
  findingId: string
  suggestedEnabled: boolean
  extraTrackingProps?: EventProperties
  disableAction?: boolean
}

export const SelectFindingSeverityBadge = ({
  severity,
  findingId,
  suggestedEnabled,
  extraTrackingProps,
  disableAction = false,
}: SelectFindingSeverityBadgeProps) => {
  const { mutate: updateFinding } = useUpdateFinding(findingId)
  const trackUpdate = useTrackCallback('finding.severity.update')

  if (disableAction) {
    return <FindingSeverityBadge level={severity} />
  }

  return (
    <SelectWithComment
      triggerAppearance='headless'
      value={severity}
      title='Customize the Severity for This Item'
      onSave={(value, comment) => {
        trackUpdate({
          findingId,
          severity: riskLevelLabel[value],
          previousSeverity: riskLevelLabel[severity],
          comment,
          ...extraTrackingProps,
        })
        updateFinding({ id: findingId, level: value, comment: comment || undefined })
      }}
      menuItems={[
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.CRITICAL} />,
          value: RiskLevel.CRITICAL,
          isSuggested: suggestedEnabled,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.HIGH} />,
          value: RiskLevel.HIGH,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.MEDIUM} />,
          value: RiskLevel.MEDIUM,
        },
        {
          type: 'menuSelectItem',
          label: <FindingSeverityBadge level={RiskLevel.LOW} />,
          value: RiskLevel.LOW,
        },
      ]}
    />
  )
}
