import { Spin } from 'antd'
import { ClipboardEditIcon } from 'lucide-react'
import { useParams } from 'react-router-dom'

import { useGetQuestionnaireTemplate, useUpdateTemplate } from '@/api/questionnaire-template.hook'

import { formatDate } from '@/lib/date'

import { ListEditableTemplateQuestion } from '@/pages/questionnaire-template/list-editable-template-question'

import { EditableText } from '@/components/editable-text'
import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'

export const QuestionnaireTemplate = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { data, isLoading, isFetching } = useGetQuestionnaireTemplate({
    templateId: templateId || '',
  })
  const { toast } = useToast()
  const { mutateAsync } = useUpdateTemplate()

  if (isLoading) {
    return <Loading />
  }

  const lastUpdatedTime = data?.updatedAt?.toDate()

  return (
    <div className='w-full'>
      <div className='w-full p-11'>
        <TextWithIcon
          className='mb-4 w-full'
          icon={<ClipboardEditIcon />}
          text={
            <EditableText
              className='min-h-11 w-full max-w-5xl text-2xl font-semibold'
              text={data?.name || ''}
              onSave={async (input) => {
                if (!input) {
                  toast({ status: 'error', title: "Template name can't be left empty" })
                  return
                }
                try {
                  await mutateAsync({
                    templateId,
                    name: input,
                  })
                } catch (err) {
                  toast({
                    status: 'error',
                    title: "Couldn't rename template",
                  })
                }
              }}
            />
          }
        />
        <div className='flex items-center justify-between gap-5'>
          <div className='flex max-w-5xl grow items-center gap-10 py-4'>
            <div className='font-bold'>DESCRIPTION</div>
            <EditableText
              className='min-h-11 w-full text-base'
              text={data?.description || ''}
              onSave={async (input) => {
                try {
                  await mutateAsync({
                    templateId,
                    description: input,
                  })
                } catch (err) {
                  toast({
                    status: 'error',
                    title: "Couldn't update description",
                  })
                }
              }}
            />
          </div>
          {lastUpdatedTime && (
            <div className='flex gap-2'>
              <div>Last Saved:</div>
              <div className='flex min-w-56 items-center gap-2 text-gray-400'>
                {formatDate(lastUpdatedTime, 'longDateWithSeconds')}
                {isFetching && <Spin size='small' />}
              </div>
            </div>
          )}
        </div>
        <Separator />
      </div>
      <ListEditableTemplateQuestion />
    </div>
  )
}
