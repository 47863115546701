import { Input } from 'antd'

import {
  CheckboxQuestionType,
  ParticipantRole,
  RadioQuestionType,
  isAssessor,
  useMutation,
} from '@/lib/liveblocks.config'

import { useGetQuestionRequired } from '@/pages/questionnaire/question/use-get-question-required.hook'

type ExplanationProps = {
  question: CheckboxQuestionType | RadioQuestionType
  participantRole: ParticipantRole
}

export const Explanation = ({ question, participantRole }: ExplanationProps) => {
  const setQuestionExplanation = useSetQuestionExplanation(question.id)
  const required_status = useGetQuestionRequired(question)
  return (
    <Input
      disabled={required_status !== 'required'}
      readOnly={isAssessor(participantRole)}
      placeholder='Enter explanation here...'
      value={question.explanation}
      onChange={(e) => setQuestionExplanation(e.target.value)}
      className='mt-5 p-2'
    />
  )
}

const useSetQuestionExplanation = (questionId: string) => {
  const setQuestionExplanation = useMutation(({ storage }, explanation: string) => {
    const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex) as
      | CheckboxQuestionType
      | RadioQuestionType
    storage.get('questions').set(questionIndex, {
      ...question,
      explanation,
    })
  }, [])

  return setQuestionExplanation
}
