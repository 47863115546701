import { ControlStatus } from '@/gen/inventory/v1/control_pb'

import { controlStatusLabel } from '@/const/label'

import { badgeSentimentVariant } from '@/lib/variants'

import { ControlStatusIcon } from '@/components/icons/control-status'
import { Badge } from '@/components/ui/badge'
import { Sentiment } from '@/components/variance-percentage/types'

type ControlStatusBadgeProps = {
  status: ControlStatus
}

export const ControlStatusBadge = ({ status }: ControlStatusBadgeProps) => (
  <Badge
    variant='outline'
    className={badgeSentimentVariant({
      sentiment: controlStatusToSentiment[status],
    })}
  >
    <ControlStatusIcon status={status} />
    {controlStatusLabel[status]}
  </Badge>
)

const controlStatusToSentiment: Record<ControlStatus, Sentiment> = {
  [ControlStatus.FINDING]: Sentiment.NEGATIVE,
  [ControlStatus.VALIDATED]: Sentiment.POSITIVE,
  [ControlStatus.UNSPECIFIED]: Sentiment.NEUTRAL,
}
