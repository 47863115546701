import { type CheckboxQuestionType, isAssessor, useMutation } from '@/lib/liveblocks.config'

import { Explanation } from '@/pages/questionnaire/question/explanation'
import { OptionLabel } from '@/pages/questionnaire/question/option-label'
import { useGetQuestionRequired } from '@/pages/questionnaire/question/use-get-question-required.hook'

import { Checkbox } from '@/components/ui/checkbox'

import { QuestionContainer, QuestionContainerProps } from './container'

interface CheckboxQuestionProps extends QuestionContainerProps {
  question: CheckboxQuestionType
}

export const CheckboxQuestion = (props: CheckboxQuestionProps) => {
  const { question, participantRole } = props
  const requiredStatus = useGetQuestionRequired(question)

  const toggleOption = useToggleOption(question.id)
  return (
    <QuestionContainer {...props}>
      <div className='flex flex-col gap-2'>
        {question.options.map((option) => (
          <div key={option.id}>
            <div className='flex w-fit items-center gap-2.5 self-center'>
              <Checkbox
                disabled={requiredStatus !== 'required' || isAssessor(participantRole)}
                onCheckedChange={() => toggleOption(option.id)}
                checked={option.isSelect}
              />
              <OptionLabel option={option} participantRole={participantRole} />
            </div>
          </div>
        ))}
      </div>
      {question.options.some(({ isFlag, isSelect }) => isFlag && isSelect) && (
        <Explanation question={question} participantRole={participantRole} />
      )}
    </QuestionContainer>
  )
}

const useToggleOption = (questionId: string) => {
  const toggleOption = useMutation(({ storage }, optionId: string) => {
    const questionIndex = storage.get('questions').findIndex((q) => q.id === questionId)
    const question = storage.get('questions').get(questionIndex) as CheckboxQuestionType
    const options = question.options.map((option) =>
      option.id === optionId ? { ...option, isSelect: !option.isSelect } : option,
    )
    storage.get('questions').set(questionIndex, {
      ...question,
      options,
      status: options.some(({ isSelect }) => isSelect) ? 'needsReview' : 'unanswered',
    })
  }, [])

  return toggleOption
}
