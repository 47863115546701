// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file gws/v1/gws_service.proto (package gws.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AddCredentialsRequest, AddCredentialsResponse } from "./gws_service_pb.js";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "gws.v1.GWSCollectorService";

/**
 * @generated from service gws.v1.GWSCollectorService
 */
export const GWSCollectorService = {
  typeName: "gws.v1.GWSCollectorService",
  methods: {
    /**
     * @generated from rpc gws.v1.GWSCollectorService.Collect
     */
    collect: {
      name: "Collect",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gws.v1.GWSCollectorService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: GWSCollectorService,});

/**
 * @generated from rpc gws.v1.GWSCollectorService.Collect
 */
export const collect: UnaryFunctionsWithHooks<Empty, Empty> = {   ...$queryService.collect,  ...createUnaryHooks($queryService.collect)};

/**
 * @generated from rpc gws.v1.GWSCollectorService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
