import { ClipboardEditIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { QuestionnaireStatus } from '@/gen/questionnaire/v1/model_pb'

import { questionnaireStatusLabel } from '@/const/label'

import { formatDate, timeSinceFormatted } from '@/lib/date'
import { useStorage } from '@/lib/liveblocks.config'
import { calculatePercentage } from '@/lib/math-utils'

import {
  QuestionnaireFilter,
  QuestionnaireFilterProps,
} from '@/pages/questionnaire/questionnaire-filter'

import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'
import { Progress } from '@/components/ui/progress'

export type HeaderProps = QuestionnaireFilterProps & {
  recipients: string[]
  onRequestInviteRecipient: () => void
  title: string
  description: string
  dueDate: Date
  submit?: ReactNode
  questionnaireStatus?: QuestionnaireStatus
}

export const Header = ({
  title,
  description,
  dueDate,
  recipients,
  onRequestInviteRecipient,
  submit,
  questionnaireStatus = QuestionnaireStatus.ONGOING,
  ...props
}: HeaderProps) => {
  const questions = useStorage(({ questions }) => questions)

  const totalQuestions = questions?.length || 0
  const answeredQuestions =
    questions?.filter((q) => q.status && q.status !== 'unanswered').length || 0

  return (
    <div className='mb-6 bg-gray-50 px-14 py-8'>
      <TextWithIcon
        className='text-3xl font-bold'
        text={title}
        icon={<ClipboardEditIcon size={22} strokeWidth={2.5} />}
      />
      <div className='mb-4 grid w-full grid-cols-[min-content,auto] items-start gap-4 py-6'>
        {description !== '' && <div className='font-bold'>DESCRIPTION</div>}
        {description !== '' && <div>{description}</div>}
        <div className='font-bold'>RECIPIENTS</div>
        <div className='flex max-w-3xl flex-wrap items-center gap-1'>
          {recipients.map((r, i) => (
            <UserBadge name={r} key={i} />
          ))}
          {questionnaireStatus !== QuestionnaireStatus.COMPLETED && (
            <a
              className='cursor-pointer px-2 text-purple-400 hover:text-purple-600'
              onClick={onRequestInviteRecipient}
            >
              + add
            </a>
          )}
        </div>
        <div className='font-bold'>DUE DATE</div>
        <div>
          {formatDate(dueDate)}{' '}
          <span className='text-sm text-gray-500'>({timeSinceFormatted(dueDate)})</span>
        </div>
      </div>
      <div className='flex items-start gap-6'>
        <div className='flex w-full flex-row gap-6'>
          <div>
            <span className='block whitespace-nowrap font-semibold'>
              {questionnaireStatusLabel[questionnaireStatus]}
            </span>
            <span className='block text-nowrap text-sm'>
              answered {answeredQuestions}/{totalQuestions}
            </span>
          </div>
          <Progress
            value={calculatePercentage(answeredQuestions, totalQuestions)}
            className='h-2 self-center bg-gray-200'
          />
        </div>
        {submit}
      </div>

      <QuestionnaireFilter {...props} />
    </div>
  )
}

const UserBadge = ({ name }: { name: string }) => (
  <Badge variant={'outline'} className='cursor-default pl-1 pr-2.5'>
    <div className='mr-2 size-4 rounded-full bg-purple-200'></div>
    {name}
  </Badge>
)
