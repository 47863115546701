import { createContext } from 'react'

type NewQuestionnaireContext = {
  selectedThirdPartyId: string | null
  setSelectedThirdPartyId: (id: string) => void
  dueDate: Date
  setDueDate: (date: Date) => void
  selectedTemplateId: string | null
  setSelectedTemplateId: (id: string) => void
  selectedSections: string[]
  setSelectedSections: (sections: string[]) => void
  recipients: string[]
  setRecipients: (recipients: string[]) => void
}

export const NewQuestionnaireContext = createContext<NewQuestionnaireContext>({
  selectedThirdPartyId: null,
  setSelectedThirdPartyId: () => {},
  dueDate: new Date(),
  setDueDate: () => {},
  selectedTemplateId: null,
  setSelectedTemplateId: () => {},
  selectedSections: [],
  setSelectedSections: () => {},
  recipients: [],
  setRecipients: () => {},
})
