import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useGetWebhookUrl } from '@/api/credentials/webhook.hook.ts'

import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

import { WebhookURLModal } from './webhook-url-modal'

type WebhookIntegrationProps = BaseIntegrationProps

type FormValues = { identifier: string }

export const WebhookIntegration = ({ integration }: WebhookIntegrationProps) => {
  const { mutate: getWebhookUrl, isLoading, data: webhookUrlResponse } = useGetWebhookUrl()

  return (
    <div className='flex w-full justify-around pt-8'>
      {webhookUrlResponse && <WebhookURLModal url={webhookUrlResponse.url} />}
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Sending webhook events to Lema AI']}
        />
        <DashedContainer>
          <Form
            onFinish={({ identifier }: FormValues) => {
              getWebhookUrl({
                identifier,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Integration Settings</h2>
            <Form.Item<FormValues>
              name='identifier'
              rules={[{ required: false, message: 'Webhook Identifier is optional' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Webhook Identifier (Optional)'
                type='text'
              />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Generate Webhook URL
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
