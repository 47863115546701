// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file notifications/v1/notifications_service.proto (package notifications.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListSlackChannelsRequest, ListSlackChannelsResponse } from "./notifications_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "notifications.v1.NotificationsService";

/**
 * @generated from service notifications.v1.NotificationsService
 */
export const NotificationsService = {
  typeName: "notifications.v1.NotificationsService",
  methods: {
    /**
     * @generated from rpc notifications.v1.NotificationsService.ListSlackChannels
     */
    listSlackChannels: {
      name: "ListSlackChannels",
      I: ListSlackChannelsRequest,
      O: ListSlackChannelsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

const $queryService = createQueryService({  service: NotificationsService,});

/**
 * @generated from rpc notifications.v1.NotificationsService.ListSlackChannels
 */
export const listSlackChannels: UnaryFunctionsWithHooks<ListSlackChannelsRequest, ListSlackChannelsResponse> = {   ...$queryService.listSlackChannels,  ...createUnaryHooks($queryService.listSlackChannels)};
