import _ from 'lodash'
import { PropsWithChildren, useMemo } from 'react'

import { useGetCompany, useGetCompanyActivityLog } from '@/api/company.hook'
import { CompanyActivityLogItem } from '@/gen/inventory/v1/company_service_pb'

import { formatDate, formatMonth } from '@/lib/date'
import { cn } from '@/lib/style-helpers'

import { ActivityRow } from '@/pages/company-drawer/activity-log-drawer/activity-row'
import { CompanyNotFound } from '@/pages/error-pages/company-not-found'

import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'

import ClockEditIcon from '@/assets/clock-edit.svg'

type ActivityLogProps = {
  thirdPartyId: string
}

export const ActivityLog = ({ thirdPartyId }: ActivityLogProps) => {
  const { data: companyData, isLoading: isCompanyLoading } = useGetCompany(thirdPartyId)
  const { data: activityLogData, isLoading: activityLogLoading } =
    useGetCompanyActivityLog(thirdPartyId)

  const groupedByMonth = useMemo(
    () => _.groupBy(activityLogData, ({ time }) => time && formatMonth(time.toDate())),
    [activityLogData],
  )

  if (activityLogLoading || isCompanyLoading) return <Loading />

  if (!activityLogData || !companyData) return <CompanyNotFound />

  return (
    <div className='mx-6 flex h-screen max-w-full flex-col truncate pt-11'>
      <TextWithIcon
        className='flex-none gap-3.5 text-3xl font-bold'
        icon={<ClockEditIcon />}
        text='Activity Log'
      />
      <div className='flex-1 overflow-y-scroll pb-4 pr-4'>
        {_.flatMap(groupedByMonth, (items, month) => (
          <ActivityGroup
            companyName={companyData.company?.profile?.name || 'This Company'}
            items={items}
            month={month}
          />
        ))}
      </div>
    </div>
  )
}

type ActivityGroupProps = {
  month: string
  companyName: string
  items: CompanyActivityLogItem[]
} & PropsWithChildren

const ActivityGroup = ({ month, items, companyName }: ActivityGroupProps) => {
  const currentMonth = formatMonth(new Date())

  return (
    <>
      <div
        className={cn('my-4 text-sm font-bold uppercase text-gray-400', {
          invisible: month === currentMonth,
        })}
      >
        {month}
      </div>

      {items.map((item) => (
        <ActivityRow
          companyName={companyName}
          formattedDate={formatDate(item.time?.toDate() || new Date())}
          activityLogItem={item}
          key={item.id}
          comment={item.comment}
          userId={item.userId}
        />
      ))}
    </>
  )
}
