import { Modal, Slider } from 'antd'
import _ from 'lodash'
import { CircleAlert } from 'lucide-react'
import { useMemo, useState } from 'react'

import { useControlSettings, useUpdateControlSettings } from '@/api/control'
import { InherentRiskSetting } from '@/gen/inventory/v1/control_service_pb'

import { InherentRiskSettingPriority } from '@/const/priority'

import { RISK_LEVELS } from '@/lib/proto-types'

import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'
import { Alert, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'

const { confirm } = Modal

const sliderValueToTooltip: Record<number, string> = {
  [InherentRiskSettingPriority[InherentRiskSetting.DISABLED]]: 'No third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.CRITICAL_AND_ABOVE]]:
    'Critical third-parties only',
  [InherentRiskSettingPriority[InherentRiskSetting.HIGH_AND_ABOVE]]:
    'Critical & High third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.MEDIUM_AND_ABOVE]]:
    'Critical, High, and Medium third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]]: 'All third-parties',
}

const sliderValueToInherentRiskSetting: Record<number, InherentRiskSetting> = {
  [InherentRiskSettingPriority[InherentRiskSetting.DISABLED]]: InherentRiskSetting.DISABLED,
  [InherentRiskSettingPriority[InherentRiskSetting.CRITICAL_AND_ABOVE]]:
    InherentRiskSetting.CRITICAL_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.HIGH_AND_ABOVE]]:
    InherentRiskSetting.HIGH_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.MEDIUM_AND_ABOVE]]:
    InherentRiskSetting.MEDIUM_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]]:
    InherentRiskSetting.LOW_AND_ABOVE,
}

export const ControlSection = () => {
  const { data, isLoading } = useControlSettings()
  const { mutateAsync } = useUpdateControlSettings()
  const [newControlSettingByControlId, setNewControlSettingByControlId] = useState<
    Record<string, InherentRiskSetting>
  >({})
  const { toast } = useToast()
  const controlSettingByCategory = useMemo(() => _.groupBy(data, 'categoryName'), [data])
  const backendControlSettingByControlId = useMemo(
    () =>
      data.reduce(
        (acc, { controlId, inherentRiskSetting }) => {
          acc[controlId] = inherentRiskSetting
          return acc
        },
        {} as Record<string, InherentRiskSetting>,
      ),
    [data],
  )

  if (isLoading || !data) {
    return <Loading />
  }

  const handleChangeSlider = (controlId: string, value: number) => {
    setNewControlSettingByControlId((prev) => {
      const inherentRiskSetting = sliderValueToInherentRiskSetting[value]

      if (inherentRiskSetting === backendControlSettingByControlId[controlId]) {
        const { [controlId]: _, ...rest } = prev
        return rest
      }

      return {
        ...prev,
        [controlId]: inherentRiskSetting,
      }
    })
  }

  const showConfirm = () => {
    confirm({
      centered: true,
      title: 'Confirm Changes?',
      content:
        'All findings related to the controls you have deactivated will be hidden, including open and ongoing findings.',
      onOk: async () => {
        await mutateAsync(
          {
            controlSettings: _.entries(newControlSettingByControlId).map(
              ([controlId, inherentRiskSetting]) => {
                return {
                  controlId,
                  inherentRiskSetting,
                }
              },
            ),
          },
          {
            onSuccess: () => {
              toast({
                title: 'Control settings updated successfully',
                status: 'success',
              })
              setNewControlSettingByControlId({})
            },
            onError: () => {
              toast({
                title: 'Failed to update control settings',
                status: 'error',
              })
            },
          },
        )
      },
    })
  }

  return (
    <div className='max-w-3xl'>
      <h2 className='mb-3 self-start text-3xl font-bold'>Controls</h2>
      <span className='text-sm'>
        Configure which control categories are enforced across your third-parties, and specify
        controls by third-party inherent risk.
      </span>
      <Alert className='my-4 border-none'>
        <AlertTitle className='flex gap-1'>
          <TextWithIcon
            className='gap-2 font-semibold text-gray-700'
            icon={<CircleAlert size={14} />}
            text='Inactive Controls:'
          />
          Inactive controls do not generate findings
        </AlertTitle>
      </Alert>
      <div className='flex flex-col'>
        <div className='sticky top-0 z-10 flex w-full flex-col bg-white pt-3'>
          <div className='flex w-96 justify-between self-end pr-3'>
            <div>None</div>
            {RISK_LEVELS.map(({ no }) => {
              return <CompanyRiskIcon showTooltip key={no} riskLevel={no} />
            })}
          </div>
          <Separator className='mt-3' />
        </div>

        <div className='mt-7 flex flex-col gap-9'>
          {_.entries(controlSettingByCategory).map(([categoryName, controls]) => (
            <div key={categoryName}>
              <h3 className='mb-4 font-semibold'>{categoryName}</h3>
              <div className='flex w-full gap-7'>
                <Separator orientation='vertical' className='w-0.5 rounded' />
                <div className='flex w-full flex-col gap-4'>
                  {controls.map(({ controlId, controlTitle }) => (
                    <div className='flex w-full items-center justify-between' key={controlId}>
                      <div>{controlTitle}</div>
                      <div className='w-96 min-w-96 px-3'>
                        <Slider
                          onChange={(value) => handleChangeSlider(controlId, value)}
                          value={
                            sliderValueToInherentRiskSetting[
                              _.isNumber(newControlSettingByControlId[controlId])
                                ? newControlSettingByControlId[controlId]
                                : backendControlSettingByControlId[controlId]
                            ]
                          }
                          tooltip={{
                            overlayStyle: { maxWidth: '300px' },
                            formatter: (value) => _.isNumber(value) && sliderValueToTooltip[value],
                          }}
                          dots
                          max={InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]}
                          min={InherentRiskSettingPriority[InherentRiskSetting.DISABLED]}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          disabled={_.isEmpty(newControlSettingByControlId)}
          onClick={showConfirm}
          className='sticky bottom-5 -mr-28 self-end'
        >
          Save
        </Button>
      </div>
    </div>
  )
}
