// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/risk.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.RiskLevel
 */
export enum RiskLevel {
  /**
   * @generated from enum value: RISK_LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RISK_LEVEL_LOW = 1;
   */
  LOW = 1,

  /**
   * @generated from enum value: RISK_LEVEL_MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: RISK_LEVEL_HIGH = 3;
   */
  HIGH = 3,

  /**
   * @generated from enum value: RISK_LEVEL_CRITICAL = 4;
   */
  CRITICAL = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(RiskLevel)
proto3.util.setEnumType(RiskLevel, "inventory.v1.RiskLevel", [
  { no: 0, name: "RISK_LEVEL_UNSPECIFIED" },
  { no: 1, name: "RISK_LEVEL_LOW" },
  { no: 2, name: "RISK_LEVEL_MEDIUM" },
  { no: 3, name: "RISK_LEVEL_HIGH" },
  { no: 4, name: "RISK_LEVEL_CRITICAL" },
]);

