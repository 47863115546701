import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useSetOktaCredentials } from '@/api/credentials/okta.hook'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

type OktaIntegrationProps = BaseIntegrationProps

type FormValues = { baseUrl: string; apiToken: string }

export const OktaIntegration = ({ integration }: OktaIntegrationProps) => {
  const {
    mutate: setOktaCredentials,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSetOktaCredentials()
  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Access to create API keys in Okta portal']}
        />
        <DashedContainer>
          <Form
            onFinish={({ baseUrl, apiToken }: FormValues) => {
              setOktaCredentials({
                baseUrl,
                apiToken,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Base URL</h2>
            <Form.Item<FormValues>
              name='baseUrl'
              rules={[
                {
                  required: true,
                  message: 'Please input Base URL',
                },
              ]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Base URL' />
            </Form.Item>
            <h2 className='my-3 text-xl font-light'>API Token</h2>
            <Form.Item<FormValues>
              name='apiToken'
              rules={[
                {
                  required: true,
                  message: 'Please input Api Token',
                },
              ]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='API Token' />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
