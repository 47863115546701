// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file artifacts/analyzer/v1/analyzer_service.proto (package artifacts.analyzer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AnalyzeArtifactRequest, AnalyzeArtifactResponse, GetRecipeRequest, GetRecipeResponse, ListFactTypesRequest, ListFactTypesResponse, ListRecipesRequest, ListRecipesResponse, ListSubcategoriesRequest, ListSubcategoriesResponse } from "./analyzer_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "artifacts.analyzer.v1.AnalyzerService";

/**
 * @generated from service artifacts.analyzer.v1.AnalyzerService
 */
export const AnalyzerService = {
  typeName: "artifacts.analyzer.v1.AnalyzerService",
  methods: {
    /**
     * @generated from rpc artifacts.analyzer.v1.AnalyzerService.AnalyzeArtifact
     */
    analyzeArtifact: {
      name: "AnalyzeArtifact",
      I: AnalyzeArtifactRequest,
      O: AnalyzeArtifactResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListSubcategories
     */
    listSubcategories: {
      name: "ListSubcategories",
      I: ListSubcategoriesRequest,
      O: ListSubcategoriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListFactTypes
     */
    listFactTypes: {
      name: "ListFactTypes",
      I: ListFactTypesRequest,
      O: ListFactTypesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListRecipes
     */
    listRecipes: {
      name: "ListRecipes",
      I: ListRecipesRequest,
      O: ListRecipesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.analyzer.v1.AnalyzerService.GetRecipe
     */
    getRecipe: {
      name: "GetRecipe",
      I: GetRecipeRequest,
      O: GetRecipeResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: AnalyzerService,});

/**
 * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListSubcategories
 */
export const listSubcategories: UnaryFunctionsWithHooks<ListSubcategoriesRequest, ListSubcategoriesResponse> = {   ...$queryService.listSubcategories,  ...createUnaryHooks($queryService.listSubcategories)};

/**
 * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListFactTypes
 */
export const listFactTypes: UnaryFunctionsWithHooks<ListFactTypesRequest, ListFactTypesResponse> = {   ...$queryService.listFactTypes,  ...createUnaryHooks($queryService.listFactTypes)};

/**
 * @generated from rpc artifacts.analyzer.v1.AnalyzerService.ListRecipes
 */
export const listRecipes: UnaryFunctionsWithHooks<ListRecipesRequest, ListRecipesResponse> = {   ...$queryService.listRecipes,  ...createUnaryHooks($queryService.listRecipes)};

/**
 * @generated from rpc artifacts.analyzer.v1.AnalyzerService.GetRecipe
 */
export const getRecipe: UnaryFunctionsWithHooks<GetRecipeRequest, GetRecipeResponse> = {   ...$queryService.getRecipe,  ...createUnaryHooks($queryService.getRecipe)};
