import { Position, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'
import { SelectValue } from './types'

type CreateFactNodeProps = BaseNodeProps & {
  data: {
    selectedFactType: string[]
    options: string[]
  }
  id: string
}

export const CreateFactNode: React.FC<CreateFactNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Create Fact Node'
      inputHandles={[
        {
          id: `createfact-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
      dataInputs={[
        {
          id: `createfact-${id}-selectedFactType`,
          name: 'selectedFactType',
          type: 'select',
          mode: 'custom',
          options: data.options?.map((factType) => ({ label: factType, value: factType })),
          onChange: (value: SelectValue) => updateNodeData(id, { selectedFactType: value }),
          value: data.selectedFactType,
          placeholder: 'Select fact type',
        },
      ]}
    />
  )
})

CreateFactNode.displayName = 'CreateFactNode'
