import { useContext } from 'react'

import { QuestionStatusString, useStorage } from '@/lib/liveblocks.config'

import {
  REQUIRED,
  SubCategoryRequiredContext,
} from '@/pages/questionnaire/sub-category-required-provider'

export const useQuestionnaireStatusSummary = () => {
  const questions = useStorage(({ questions }) => questions)
  const { subCategoriesRequired } = useContext(SubCategoryRequiredContext)

  const reducedStatuses = questions?.reduce(
    (accumulator, current) => {
      if (
        !current.isLeading &&
        subCategoriesRequired &&
        subCategoriesRequired[current.subCategory] !== REQUIRED
      ) {
        return accumulator
      }

      const statusToSearch = current.status || 'unanswered' // In case status is not set, we consider it as unanswered
      const found = accumulator.find((item) => item.status === statusToSearch)
      if (found) {
        found.count++
      } else {
        reportError(`Unknown status: ${statusToSearch}`)
      }
      return accumulator
    },
    [
      { status: 'unanswered', count: 0 },
      { status: 'needsReview', count: 0 },
      { status: 'rejected', count: 0 },
      { status: 'postponed', count: 0 },
      { status: 'accepted', count: 0 },
    ] as { status: QuestionStatusString; count: number }[],
  )

  return reducedStatuses
}
