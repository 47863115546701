import { useSession, useUser } from '@descope/react-sdk'
import { KnockProvider, KnockSlackProvider } from '@knocklabs/react'
import { useCallback, useEffect } from 'react'

import { useUserSlackStateToken } from '@/api/notifications-slack.hook'

import { useOrgIdFromToken } from '@/lib/auth/orgid.hook'

import { UnknownError } from '@/pages/error-pages/unknown-error'
import { WorkflowPreferences } from '@/pages/settings-page/notification-section/knock-preferences'
import { OrgNotificationSettings } from '@/pages/settings-page/notification-section/knock-slack-org-section'
import { ConnectUserSlackButton } from '@/pages/settings-page/notification-section/knock-slack-user-connect-button'

import { Loading } from '@/components/loading'
import { HorizontalTabs } from '@/components/tabs/horizontal'
import { useToast } from '@/components/ui/use-toast'

const redirectUri = `${window.origin}/api/notifications.v1/slack/oauth`
const knockPublicKey = import.meta.env.VITE_KNOCK_PUBLIC_KEY
const knockChannelIdSlack = import.meta.env.VITE_KNOCK_CHANNEL_ID_SLACK
const slackClientId = import.meta.env.VITE_SLACK_CLIENT_ID
const slackRequestedScopes = import.meta.env.VITE_SLACK_REQUESTED_SCOPES

// code triggering this message is located at service/notifications/slack-oauth-success.html and service/notifications/slack-oauth-error.html
type oauthResultMessage = {
  source: 'slack-oauth'
  status: 'success' | 'error'
  message: string
}

const isOauthResultMessage = (data: unknown): data is oauthResultMessage => {
  if (typeof data !== 'object' || data === null) {
    return false
  }
  if ((data as oauthResultMessage).source !== 'slack-oauth') {
    return false
  }
  return true
}

export const NotificationSection = () => {
  const { user, isUserLoading } = useUser()
  const { isSessionLoading, sessionToken } = useSession()
  const orgId = useOrgIdFromToken(user, sessionToken)
  const { toast } = useToast()

  const { data, isLoading, error } = useUserSlackStateToken(redirectUri)

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== window.origin) {
        return
      }
      if (!event.data || !isOauthResultMessage(event.data)) {
        return
      }
      toast({ status: event.data.status, title: event.data.message })
    },
    [toast],
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  if (isUserLoading || isSessionLoading || isLoading || !orgId) {
    return <Loading />
  }

  if (!data?.token || error) {
    return <UnknownError />
  }

  return (
    <KnockProvider apiKey={knockPublicKey} userId={user.userId} userToken={data.knockToken}>
      <div>
        <div className='mb-5 flex justify-between'>
          <h2 className='mb-4 text-3xl font-bold'>Notifications</h2>
        </div>
        <div className='max-w-3xl'>
          <HorizontalTabs
            items={[
              {
                label: 'Organization',
                component: (
                  <KnockSlackProvider knockSlackChannelId={knockChannelIdSlack} tenant={orgId}>
                    <OrgNotificationSettings
                      orgId={orgId}
                      knockChannelIdSlack={knockChannelIdSlack}
                      slackClientId={slackClientId}
                    />
                  </KnockSlackProvider>
                ),
              },
              {
                label: 'Personal',
                component: (
                  <div>
                    <ConnectUserSlackButton
                      slackState={data.token}
                      knockChannelIdSlack={knockChannelIdSlack}
                      slackClientId={slackClientId}
                      slackScope={slackRequestedScopes}
                      redirectUri={redirectUri}
                    />
                    <WorkflowPreferences knockChannelIdSlack={knockChannelIdSlack} />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </KnockProvider>
  )
}
