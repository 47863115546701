// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file questionnaire/v1/template_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateQuestionnaireRequest, CreateQuestionnaireResponse, GetTemplateRequest, GetTemplateResponse, ImportRequest, ImportResponse, ListTemplateQuestionRequest, ListTemplateQuestionResponse, ListTemplatesRequest, ListTemplatesResponse, RenameSectionRequest, RenameSectionResponse, UpdateOptionRequest, UpdateOptionResponse, UpdateQuestionRequest, UpdateQuestionResponse, UpdateTemplateRequest, UpdateTemplateResponse } from "./template_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "questionnaire.v1.TemplateService";

/**
 * @generated from service questionnaire.v1.TemplateService
 */
export const TemplateService = {
  typeName: "questionnaire.v1.TemplateService",
  methods: {
    /**
     * @generated from rpc questionnaire.v1.TemplateService.Import
     */
    import: {
      name: "Import",
      I: ImportRequest,
      O: ImportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.ListTemplates
     */
    listTemplates: {
      name: "ListTemplates",
      I: ListTemplatesRequest,
      O: ListTemplatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.GetTemplate
     */
    getTemplate: {
      name: "GetTemplate",
      I: GetTemplateRequest,
      O: GetTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.CreateQuestionnaire
     */
    createQuestionnaire: {
      name: "CreateQuestionnaire",
      I: CreateQuestionnaireRequest,
      O: CreateQuestionnaireResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.ListTemplateQuestion
     */
    listTemplateQuestion: {
      name: "ListTemplateQuestion",
      I: ListTemplateQuestionRequest,
      O: ListTemplateQuestionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.UpdateTemplate
     */
    updateTemplate: {
      name: "UpdateTemplate",
      I: UpdateTemplateRequest,
      O: UpdateTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.RenameSection
     */
    renameSection: {
      name: "RenameSection",
      I: RenameSectionRequest,
      O: RenameSectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.UpdateQuestion
     */
    updateQuestion: {
      name: "UpdateQuestion",
      I: UpdateQuestionRequest,
      O: UpdateQuestionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.TemplateService.UpdateOption
     */
    updateOption: {
      name: "UpdateOption",
      I: UpdateOptionRequest,
      O: UpdateOptionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: TemplateService,});

/**
 * @generated from rpc questionnaire.v1.TemplateService.Import
 */
export const import$: UnaryFunctionsWithHooks<ImportRequest, ImportResponse> = {   ...$queryService.import,  ...createUnaryHooks($queryService.import)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.ListTemplates
 */
export const listTemplates: UnaryFunctionsWithHooks<ListTemplatesRequest, ListTemplatesResponse> = {   ...$queryService.listTemplates,  ...createUnaryHooks($queryService.listTemplates)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.GetTemplate
 */
export const getTemplate: UnaryFunctionsWithHooks<GetTemplateRequest, GetTemplateResponse> = {   ...$queryService.getTemplate,  ...createUnaryHooks($queryService.getTemplate)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.CreateQuestionnaire
 */
export const createQuestionnaire: UnaryFunctionsWithHooks<CreateQuestionnaireRequest, CreateQuestionnaireResponse> = {   ...$queryService.createQuestionnaire,  ...createUnaryHooks($queryService.createQuestionnaire)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.ListTemplateQuestion
 */
export const listTemplateQuestion: UnaryFunctionsWithHooks<ListTemplateQuestionRequest, ListTemplateQuestionResponse> = {   ...$queryService.listTemplateQuestion,  ...createUnaryHooks($queryService.listTemplateQuestion)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.UpdateTemplate
 */
export const updateTemplate: UnaryFunctionsWithHooks<UpdateTemplateRequest, UpdateTemplateResponse> = {   ...$queryService.updateTemplate,  ...createUnaryHooks($queryService.updateTemplate)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.RenameSection
 */
export const renameSection: UnaryFunctionsWithHooks<RenameSectionRequest, RenameSectionResponse> = {   ...$queryService.renameSection,  ...createUnaryHooks($queryService.renameSection)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.UpdateQuestion
 */
export const updateQuestion: UnaryFunctionsWithHooks<UpdateQuestionRequest, UpdateQuestionResponse> = {   ...$queryService.updateQuestion,  ...createUnaryHooks($queryService.updateQuestion)};

/**
 * @generated from rpc questionnaire.v1.TemplateService.UpdateOption
 */
export const updateOption: UnaryFunctionsWithHooks<UpdateOptionRequest, UpdateOptionResponse> = {   ...$queryService.updateOption,  ...createUnaryHooks($queryService.updateOption)};
