import { UserStatus } from '@/gen/customer/management/v1/service_pb'
import { DocumentSource, DocumentStatus, SOC2Scope } from '@/gen/inventory/v1/artifact_service_pb'
import {
  InherentRiskCategoryEnum,
  InherentRiskSource,
  PermissionCategory,
} from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { ControlStatus } from '@/gen/inventory/v1/control_pb'
import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'
import { Implication } from '@/gen/inventory/v1/implication_pb'
import { IntegrationCategory } from '@/gen/inventory/v1/integration_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'
import { QuestionnaireStatus } from '@/gen/questionnaire/v1/model_pb'

import { QuestionStatusString } from '@/lib/liveblocks.config'

import { RequiredStatus } from '@/pages/questionnaire/sub-category-required-provider'

export const requiredStatusLabel: Record<Exclude<RequiredStatus, 'required'>, string> = {
  notDetermined: 'Answer the above questions first',
  notRequired: "You don't need to answer this question",
}

export const categoryLabel: Record<IntegrationCategory, string> = {
  [IntegrationCategory.UNSPECIFIED]: '',
  [IntegrationCategory.API_GATEWAY]: 'API Gateway',
  [IntegrationCategory.SECURITY_STACK]: 'Security Stack',
  [IntegrationCategory.FILE_SHARING]: 'File Sharing',
  [IntegrationCategory.SASE]: 'SASE',
  [IntegrationCategory.PAYMENT]: 'Payment Gateway',
  [IntegrationCategory.CLOUD_INFRASTRUCTURE]: 'Cloud Infrastructure',
  [IntegrationCategory.GRC]: 'GRC',
  [IntegrationCategory.PROCUREMENT]: 'Procurement',
  [IntegrationCategory.IDP]: 'IDP',
}

export const riskLevelLabel: Record<RiskLevel, string> = {
  [RiskLevel.UNSPECIFIED]: '',
  [RiskLevel.CRITICAL]: 'Critical',
  [RiskLevel.HIGH]: 'High',
  [RiskLevel.MEDIUM]: 'Medium',
  [RiskLevel.LOW]: 'Low',
}

export const companyStatusLabel: Record<CompanyStatus, string> = {
  [CompanyStatus.UNSPECIFIED]: '',
  [CompanyStatus.ASSESSMENT_REQUIRED]: 'Assessment Required',
  [CompanyStatus.RE_ASSESSMENT_REQUIRED]: 'Re-assessment Required',
  [CompanyStatus.IN_ASSESSMENT]: 'In Assessment',
  [CompanyStatus.ASSESSED]: 'Assessed',
  [CompanyStatus.OFFBOARDED]: 'Offboarded',
  [CompanyStatus.ARCHIVED]: 'Archived',
  [CompanyStatus.REJECTED]: 'Rejected',
  [CompanyStatus.UNSANCTIONED]: 'Unsanctioned',
}

export const controlStatusLabel: Record<ControlStatus, string> = {
  [ControlStatus.UNSPECIFIED]: '',
  [ControlStatus.VALIDATED]: 'Validated',
  [ControlStatus.FINDING]: 'Finding',
}

/** get human readable label to be displayed in UI for a given permission category
 *  Note: having a default value for category is not necessary,
 * because without it passing PermissionCategory.UNSPECIFIED will return undefined instead of 'Other' */
export const getPermissionLabel = (category: PermissionCategory) => {
  return permissionLabel[category || PermissionCategory.UNSPECIFIED]
}

const permissionLabel: Record<PermissionCategory, string> = {
  [PermissionCategory.UNSPECIFIED]: 'Other',
  [PermissionCategory.DATA_ACCESS]: 'Data Access',
  [PermissionCategory.DATA_MODIFICATION]: 'Data Modification',
  [PermissionCategory.OPERATIONAL_ENVIRONMENT]: 'Operational Environment Modification',
  [PermissionCategory.IDENTITY_AND_ACCESS_MANAGEMENT]: 'Identity & Access Management',
  [PermissionCategory.CONFIGURATION_PERMISSIONS]: 'Configuration Permissions',
  [PermissionCategory.EMPLOYEE_DATA_ACCESS]: 'Employee Data Access',
  [PermissionCategory.EMPLOYEE_DATA_MODIFICATION]: 'Employee Data modification',
  [PermissionCategory.CLOUD_ENVIRONMENT]: 'Cloud Environment',
}

export const implicationLabel: Record<Implication, string> = {
  [Implication.BREACH_IMPACT]: 'Impact',
  [Implication.BREACH_LIKELIHOOD]: 'Likelihood',
  [Implication.COMPLIANCE]: 'Compliance',
  [Implication.BUSINESS_CONTINUITY]: 'Business Continuity',
  [Implication.UNSPECIFIED]: '',
}

export const findingStatusLabel: Record<FindingStatus, string> = {
  [FindingStatus.RESOLVED]: 'Resolved',
  [FindingStatus.ACCEPTED]: 'Accepted',
  [FindingStatus.ONGOING]: 'Ongoing',
  [FindingStatus.OPEN]: 'Open',
  [FindingStatus.UNSPECIFIED]: '',
}

export const documentStatusLabel: Record<DocumentStatus, string> = {
  [DocumentStatus.PENDING]: 'Pending',
  [DocumentStatus.CLASSIFYING]: 'Classifying',
  [DocumentStatus.ANALYZING]: 'Analyzing',
  [DocumentStatus.COMPLETE]: 'Complete',
  [DocumentStatus.UNSPECIFIED]: '',
}

export const userStatusLabel: Record<UserStatus, string> = {
  [UserStatus.ENABLED]: 'Enabled',
  [UserStatus.DISABLED]: 'Disabled',
  [UserStatus.INVITED]: 'Invited',
  [UserStatus.UNSPECIFIED]: '',
}

export const documentSourceLabel: Record<DocumentSource, string> = {
  [DocumentSource.UNSPECIFIED]: '-',
  [DocumentSource.MANUAL_UPLOAD]: 'Manual Upload',
  [DocumentSource.INTEGRATION]: 'Integration',
  [DocumentSource.WEB]: 'Web',
  [DocumentSource.MANUAL_UPLOAD_WITH_COMPANY]: 'Manual Upload',
  [DocumentSource.MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY]: 'Manual Upload',
  [DocumentSource.LEMA_QUESTIONNAIRE]: 'Lema',
}

export const inherentRiskSourceLabel: Record<InherentRiskSource, string> = {
  [InherentRiskSource.UNSPECIFIED]: '',
  [InherentRiskSource.PROJECTED]: 'Projected',
  [InherentRiskSource.IRQ]: 'IRQ',
  [InherentRiskSource.INTEGRATION]: 'Integration',
  [InherentRiskSource.MICROSOFT_ENTRA_ID]: 'Integration',
  [InherentRiskSource.GOOGLE_WORKSPACE]: 'Integration',
  [InherentRiskSource.ZIP]: 'Integration',
  [InherentRiskSource.WIZ]: 'Integration',
  [InherentRiskSource.NETSKOPE]: 'Integration',
  [InherentRiskSource.OKTA]: 'Integration',
  [InherentRiskSource.WEBHOOK]: 'Integration',
}

export const InherentRiskCategoryEnumName: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.ATTACK_SURFACE]: 'Attack Surface',
  [InherentRiskCategoryEnum.DATA]: 'Data',
  [InherentRiskCategoryEnum.LOB]: 'Operational Impact',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]: 'Operational Impact',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]: 'Customer Impact',
  [InherentRiskCategoryEnum.INTEG_METHOD]: 'Customer Impact',
  [InherentRiskCategoryEnum.SPEND]: 'Spend',
}

export const InherentRiskCategoryEnumTitle: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.ATTACK_SURFACE]: 'Attack Surface',
  [InherentRiskCategoryEnum.DATA]: 'Data',
  [InherentRiskCategoryEnum.LOB]: 'Operational Impact - Line of Business',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]: 'Operational Impact - Outage',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]: 'Customer Impact - Outage',
  [InherentRiskCategoryEnum.INTEG_METHOD]: 'Customer Impact - Integration Method',
  [InherentRiskCategoryEnum.SPEND]: 'Spend',
}

export const InherentRiskCategoryEnumDescription: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.DATA]: 'What types of data does the third-party process or store?',
  [InherentRiskCategoryEnum.ATTACK_SURFACE]:
    'What permissions does the third-party have to access your data?',
  [InherentRiskCategoryEnum.LOB]: 'Which lines of business will engage with this third-party?',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]:
    'What is the impact of the third-party outage on your operations?',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]:
    'What is the impact of the third-party outage on your business?',
  [InherentRiskCategoryEnum.INTEG_METHOD]:
    'What method is used to integrate with this third-party?',
  [InherentRiskCategoryEnum.SPEND]:
    'What is the estimated total spend with this third-party over the duration of this contract?',
}

export const irpiTooltipPrefix: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.DATA]: 'Third-party is processing and/or storing',
  [InherentRiskCategoryEnum.ATTACK_SURFACE]: 'Third-party has permissions to',
  [InherentRiskCategoryEnum.LOB]: 'Third-party is used by',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]: 'Third-party impacts operations:',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]: 'Third-party impacts business:',
  [InherentRiskCategoryEnum.INTEG_METHOD]: "Third-party's integration method is",
  [InherentRiskCategoryEnum.SPEND]: 'Third-party spend is roughly',
}

export const questionStatusLabel: Record<QuestionStatusString, string> = {
  unanswered: 'Unanswered',
  needsReview: 'Needs Review',
  rejected: 'Rejected',
  accepted: 'Accepted',
  postponed: 'Postponed',
}

export const soc2ScopeLabel: Record<SOC2Scope, string> = {
  [SOC2Scope.SOC2_SCOPE_UNSPECIFIED]: '',
  [SOC2Scope.SOC2_SCOPE_SECURITY]: 'Security',
  [SOC2Scope.SOC2_SCOPE_AVAILABILITY]: 'Availability',
  [SOC2Scope.SOC2_SCOPE_CONFIDENTIALITY]: 'Confidentiality',
  [SOC2Scope.SOC2_SCOPE_PRIVACY]: 'Privacy',
  [SOC2Scope.SOC2_SCOPE_PROCESSING]: 'Processing Integrity',
}

export const questionnaireStatusLabel: Record<QuestionnaireStatus, string> = {
  [QuestionnaireStatus.UNSPECIFIED]: '',
  [QuestionnaireStatus.ONGOING]: 'In Progress',
  [QuestionnaireStatus.COMPLETED]: 'Completed',
}

export const artifactSubcategoryLabel = {
  REPORT_SOC2_TYPE_2: 'SOC2 Type II',
} as const
