import { Badge } from '@/components/ui/badge'

export const CompanyUrgentBadge = () => (
  <Badge
    variant='outline'
    className='h-3.5 rounded border-red-50 bg-red-50 p-1 text-xs font-medium text-red-500'
  >
    Urgent
  </Badge>
)
