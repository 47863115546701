import Knock, { PreferenceSet, TENANT_OBJECT_COLLECTION } from '@knocklabs/client'
import { useKnockClient } from '@knocklabs/react'
import { ChannelType } from '@knocklabs/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const QueryKeyGetPreferences = 'getPreferences'
const QueryKeyGetTenantSlackChannels = 'getTenantSlackChannels'
const QueryKeyGetTenantAuthTest = 'getTenantAuthTest'

// https://docs.knock.app/managing-recipients/setting-channel-data#chat-app-channels
type ChannelDataSlackConnection = {
  channel_id: string
  access_token?: string
  user_id?: string
  incoming_webhook?: { url: string }
}

const updateTenantSlackChannel = async (
  knockClient: Knock,
  tenantId: string,
  knockChannelId: string,
  slackChannelId: string,
) => {
  const channelData = await knockClient.objects.getChannelData({
    collection: TENANT_OBJECT_COLLECTION,
    objectId: tenantId,
    channelId: knockChannelId,
  })

  await knockClient.objects.setChannelData({
    collection: TENANT_OBJECT_COLLECTION,
    objectId: tenantId,
    channelId: knockChannelId,
    data: {
      token: channelData.data.token,
      connections: [
        { channel_id: slackChannelId }, // override existing connections and set the new channel as the only connection
      ],
    },
  })
}

type SetTenantSlackChannelMutationProps = {
  tenantId: string
  knockChannelId: string
  slackChannelId: string
}

export const useSetTenantSlackChannel = () => {
  const knockClient = useKnockClient()
  return useMutation({
    mutationFn: async ({
      tenantId,
      knockChannelId,
      slackChannelId,
    }: SetTenantSlackChannelMutationProps) => {
      return await updateTenantSlackChannel(knockClient, tenantId, knockChannelId, slackChannelId)
    },
  })
}

export const useTenantSlackChannels = (tenantId: string, knockChannelId: string) => {
  const knockClient = useKnockClient()
  return useQuery([QueryKeyGetTenantSlackChannels, tenantId], async () => {
    const channelData = await knockClient.objects.getChannelData({
      collection: TENANT_OBJECT_COLLECTION,
      objectId: tenantId,
      channelId: knockChannelId,
    })

    const connections: ChannelDataSlackConnection[] = channelData.data.connections || []
    return connections.filter((c) => c.channel_id).map((connection) => connection.channel_id)
  })
}

const getPreferences = async (knockClient: Knock) => {
  return await knockClient.user.getPreferences()
}

export const useGetPreferences = () => {
  const knockClient = useKnockClient()
  return useQuery([QueryKeyGetPreferences], async () => await getPreferences(knockClient), {
    enabled: knockClient.isAuthenticated(),
  })
}

const setPreferences = async (
  knockClient: Knock,
  preferences: PreferenceSet,
): Promise<PreferenceSet> => {
  return await knockClient.user.setPreferences(preferences)
}

const updatePreferences = (
  preferences: PreferenceSet,
  workflowKey: string,
  channelType: ChannelType,
  setting: boolean,
) => {
  let existingChannelTypes = {}
  const workflowChannels = preferences?.workflows?.[workflowKey]
  if (workflowChannels && typeof workflowChannels !== 'boolean') {
    existingChannelTypes = workflowChannels.channel_types
  }

  return {
    ...preferences,
    workflows: {
      ...preferences?.workflows,
      [workflowKey]: {
        channel_types: {
          ...existingChannelTypes,
          [channelType]: setting,
        },
      },
    },
  }
}

type SetPreferencesMutationProps = {
  workflowKey: string
  channelType: ChannelType
  setting: boolean
}

type SetPreferenceMutationContext = {
  previousPreferences: PreferenceSet
}

export const useSetPreferences = () => {
  const knockClient = useKnockClient()
  const queryClient = useQueryClient()

  // The Knock API requires a complete preference set, but it's more convenient to expose an API that accepts only changes and doesn't require the caller to manage the entire preference state.
  // To address this, we merge the user's changes with the latest state from the server and send the combined result to the backend.
  return useMutation<
    PreferenceSet | undefined,
    Error,
    SetPreferencesMutationProps,
    SetPreferenceMutationContext
  >({
    mutationFn: async ({
      workflowKey,
      channelType,
      setting,
    }: SetPreferencesMutationProps): Promise<PreferenceSet | undefined> => {
      const prevState: PreferenceSet | undefined = queryClient.getQueryData([
        QueryKeyGetPreferences,
      ])
      if (!prevState) {
        return prevState
      }
      return setPreferences(
        knockClient,
        updatePreferences(prevState, workflowKey, channelType, setting),
      )
    },
    // onMutate is used to implement optimistic updates, see more:
    // https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
    onMutate: async (
      preferences: SetPreferencesMutationProps,
    ): Promise<SetPreferenceMutationContext> => {
      // cancel outgoing query to backend, instead update the local query state with the new (optimistic) preferences
      // then when all mutations are settled, invalidate the query to fetch the latest data
      await queryClient.cancelQueries({ queryKey: [QueryKeyGetPreferences] })
      const previousPreferences = queryClient.getQueryData([QueryKeyGetPreferences])

      // make optimistic value available to get preference query caller.
      // when mutation is settled, the query will be invalidated and refetched and
      // query data will be overridden with the latest data from the server.
      queryClient.setQueryData([QueryKeyGetPreferences], (old?: PreferenceSet) => {
        if (!old) {
          return old
        }
        return updatePreferences(
          old,
          preferences.workflowKey,
          preferences.channelType,
          preferences.setting,
        )
      })

      return { previousPreferences } as SetPreferenceMutationContext
    },
    onError: (
      err: Error,
      mutationProps: SetPreferencesMutationProps,
      context?: SetPreferenceMutationContext,
    ) => {
      console.error(
        'error updating preferences',
        err,
        'mutation properties',
        mutationProps,
        'context',
        context,
      )
      queryClient.setQueryData([QueryKeyGetPreferences], context?.previousPreferences)
    },
    // Always refetch after error or success to ensure the data is correct
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeyGetPreferences] })
    },
  })
}

type AuthTest = {
  connection: {
    ok: boolean
  }
}

export const useTenantAuthTest = (tenantId: string, knockChannelId: string) => {
  const knockClient = useKnockClient()
  return useQuery(
    [QueryKeyGetTenantAuthTest, tenantId, knockChannelId],
    async (): Promise<AuthTest> => {
      const res = await knockClient.slack.authCheck({ tenant: tenantId, knockChannelId })
      if (res instanceof AxiosError) {
        // knock returns 403 if the channel is not connected
        if (res.status === 403) {
          return {
            connection: {
              ok: false,
            },
          }
        }

        // rethrow other unexpected errors
        throw res
      }

      return res
    },
  )
}
