import tailwindConfig from '@root/tailwind.config'
import { VictoryThemeDefinition } from 'victory'

export const customVictoryTheme: VictoryThemeDefinition = {
  axis: {
    style: {
      grid: { stroke: 'none' },
      axis: { stroke: 'none' },
    },
  },
  area: {
    style: {
      data: {
        fill: tailwindConfig.theme.colors.gray[700],
      },
    },
  },
}
