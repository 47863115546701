import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

export const INACTIVE_COMPANY_STATUSES = [
  CompanyStatus.UNSPECIFIED,
  CompanyStatus.OFFBOARDED,
  CompanyStatus.ARCHIVED,
  CompanyStatus.REJECTED,
]

export const ACTIVE_COMPANY_STATUSES = [
  CompanyStatus.IN_ASSESSMENT,
  CompanyStatus.ASSESSMENT_REQUIRED,
  CompanyStatus.RE_ASSESSMENT_REQUIRED,
  CompanyStatus.ASSESSED,
  CompanyStatus.UNSANCTIONED,
]
