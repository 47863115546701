// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file artifacts/analyzer/v1/analyzer_service.proto (package artifacts.analyzer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { DocumentSource } from "../../../inventory/v1/artifact_service_pb.js";

/**
 * @generated from enum artifacts.analyzer.v1.LogType
 */
export enum LogType {
  /**
   * @generated from enum value: LOG_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOG_TYPE_KEEP_ALIVE = 1;
   */
  KEEP_ALIVE = 1,

  /**
   * @generated from enum value: LOG_TYPE_NODE_STATUS = 2;
   */
  NODE_STATUS = 2,

  /**
   * @generated from enum value: LOG_TYPE_NODE_LOG = 3;
   */
  NODE_LOG = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LogType)
proto3.util.setEnumType(LogType, "artifacts.analyzer.v1.LogType", [
  { no: 0, name: "LOG_TYPE_UNSPECIFIED" },
  { no: 1, name: "LOG_TYPE_KEEP_ALIVE" },
  { no: 2, name: "LOG_TYPE_NODE_STATUS" },
  { no: 3, name: "LOG_TYPE_NODE_LOG" },
]);

/**
 * @generated from enum artifacts.analyzer.v1.NodeStatus
 */
export enum NodeStatus {
  /**
   * @generated from enum value: NODE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NODE_STATUS_RUNNING = 1;
   */
  RUNNING = 1,

  /**
   * @generated from enum value: NODE_STATUS_COMPLETED = 2;
   */
  COMPLETED = 2,

  /**
   * @generated from enum value: NODE_STATUS_FAILED = 3;
   */
  FAILED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(NodeStatus)
proto3.util.setEnumType(NodeStatus, "artifacts.analyzer.v1.NodeStatus", [
  { no: 0, name: "NODE_STATUS_UNSPECIFIED" },
  { no: 1, name: "NODE_STATUS_RUNNING" },
  { no: 2, name: "NODE_STATUS_COMPLETED" },
  { no: 3, name: "NODE_STATUS_FAILED" },
]);

/**
 * @generated from enum artifacts.analyzer.v1.NodeType
 */
export enum NodeType {
  /**
   * @generated from enum value: NODE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NODE_TYPE_ROOT = 1;
   */
  ROOT = 1,

  /**
   * @generated from enum value: NODE_TYPE_SUBCATEGORY = 2;
   */
  SUBCATEGORY = 2,

  /**
   * @generated from enum value: NODE_TYPE_KEYWORDS = 3;
   */
  KEYWORDS = 3,

  /**
   * @generated from enum value: NODE_TYPE_DOCNAME_KEYWORDS = 4;
   */
  DOCNAME_KEYWORDS = 4,

  /**
   * @generated from enum value: NODE_TYPE_CREATE_FACT = 5;
   */
  CREATE_FACT = 5,

  /**
   * @generated from enum value: NODE_TYPE_LLM = 6;
   */
  LLM = 6,

  /**
   * @generated from enum value: NODE_TYPE_SOURCE = 7;
   */
  SOURCE = 7,

  /**
   * @generated from enum value: NODE_TYPE_NO_OP = 8;
   */
  NO_OP = 8,

  /**
   * @generated from enum value: NODE_TYPE_PENTEST = 9;
   */
  PENTEST = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(NodeType)
proto3.util.setEnumType(NodeType, "artifacts.analyzer.v1.NodeType", [
  { no: 0, name: "NODE_TYPE_UNSPECIFIED" },
  { no: 1, name: "NODE_TYPE_ROOT" },
  { no: 2, name: "NODE_TYPE_SUBCATEGORY" },
  { no: 3, name: "NODE_TYPE_KEYWORDS" },
  { no: 4, name: "NODE_TYPE_DOCNAME_KEYWORDS" },
  { no: 5, name: "NODE_TYPE_CREATE_FACT" },
  { no: 6, name: "NODE_TYPE_LLM" },
  { no: 7, name: "NODE_TYPE_SOURCE" },
  { no: 8, name: "NODE_TYPE_NO_OP" },
  { no: 9, name: "NODE_TYPE_PENTEST" },
]);

/**
 * @generated from message artifacts.analyzer.v1.ListRecipesRequest
 */
export class ListRecipesRequest extends Message<ListRecipesRequest> {
  constructor(data?: PartialMessage<ListRecipesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListRecipesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecipesRequest {
    return new ListRecipesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecipesRequest {
    return new ListRecipesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecipesRequest {
    return new ListRecipesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecipesRequest | PlainMessage<ListRecipesRequest> | undefined, b: ListRecipesRequest | PlainMessage<ListRecipesRequest> | undefined): boolean {
    return proto3.util.equals(ListRecipesRequest, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.ListRecipesResponse
 */
export class ListRecipesResponse extends Message<ListRecipesResponse> {
  /**
   * @generated from field: repeated string recipe_ids = 1;
   */
  recipeIds: string[] = [];

  constructor(data?: PartialMessage<ListRecipesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListRecipesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipe_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecipesResponse {
    return new ListRecipesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecipesResponse {
    return new ListRecipesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecipesResponse {
    return new ListRecipesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecipesResponse | PlainMessage<ListRecipesResponse> | undefined, b: ListRecipesResponse | PlainMessage<ListRecipesResponse> | undefined): boolean {
    return proto3.util.equals(ListRecipesResponse, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.GetRecipeRequest
 */
export class GetRecipeRequest extends Message<GetRecipeRequest> {
  /**
   * @generated from field: string recipe_id = 1;
   */
  recipeId = "";

  constructor(data?: PartialMessage<GetRecipeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.GetRecipeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipe_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRecipeRequest {
    return new GetRecipeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRecipeRequest {
    return new GetRecipeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRecipeRequest {
    return new GetRecipeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRecipeRequest | PlainMessage<GetRecipeRequest> | undefined, b: GetRecipeRequest | PlainMessage<GetRecipeRequest> | undefined): boolean {
    return proto3.util.equals(GetRecipeRequest, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.GetRecipeResponse
 */
export class GetRecipeResponse extends Message<GetRecipeResponse> {
  /**
   * @generated from field: artifacts.analyzer.v1.Recipe recipe = 1;
   */
  recipe?: Recipe;

  constructor(data?: PartialMessage<GetRecipeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.GetRecipeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipe", kind: "message", T: Recipe },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRecipeResponse {
    return new GetRecipeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRecipeResponse {
    return new GetRecipeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRecipeResponse {
    return new GetRecipeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRecipeResponse | PlainMessage<GetRecipeResponse> | undefined, b: GetRecipeResponse | PlainMessage<GetRecipeResponse> | undefined): boolean {
    return proto3.util.equals(GetRecipeResponse, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.AnalyzeArtifactRequest
 */
export class AnalyzeArtifactRequest extends Message<AnalyzeArtifactRequest> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = "";

  /**
   * @generated from field: bytes file_content = 2;
   */
  fileContent = new Uint8Array(0);

  /**
   * @generated from field: optional string company_id = 3;
   */
  companyId?: string;

  /**
   * @generated from field: inventory.v1.DocumentSource source = 4;
   */
  source = DocumentSource.UNSPECIFIED;

  /**
   * @generated from field: optional artifacts.analyzer.v1.CategoryMetadata category_metadata = 5;
   */
  categoryMetadata?: CategoryMetadata;

  /**
   * @generated from field: artifacts.analyzer.v1.Recipe recipe = 6;
   */
  recipe?: Recipe;

  constructor(data?: PartialMessage<AnalyzeArtifactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.AnalyzeArtifactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "source", kind: "enum", T: proto3.getEnumType(DocumentSource) },
    { no: 5, name: "category_metadata", kind: "message", T: CategoryMetadata, opt: true },
    { no: 6, name: "recipe", kind: "message", T: Recipe },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeArtifactRequest {
    return new AnalyzeArtifactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeArtifactRequest {
    return new AnalyzeArtifactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeArtifactRequest {
    return new AnalyzeArtifactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeArtifactRequest | PlainMessage<AnalyzeArtifactRequest> | undefined, b: AnalyzeArtifactRequest | PlainMessage<AnalyzeArtifactRequest> | undefined): boolean {
    return proto3.util.equals(AnalyzeArtifactRequest, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.AnalyzeArtifactResponse
 */
export class AnalyzeArtifactResponse extends Message<AnalyzeArtifactResponse> {
  /**
   * @generated from field: artifacts.analyzer.v1.Log log = 1;
   */
  log?: Log;

  constructor(data?: PartialMessage<AnalyzeArtifactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.AnalyzeArtifactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "log", kind: "message", T: Log },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeArtifactResponse {
    return new AnalyzeArtifactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeArtifactResponse {
    return new AnalyzeArtifactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeArtifactResponse {
    return new AnalyzeArtifactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeArtifactResponse | PlainMessage<AnalyzeArtifactResponse> | undefined, b: AnalyzeArtifactResponse | PlainMessage<AnalyzeArtifactResponse> | undefined): boolean {
    return proto3.util.equals(AnalyzeArtifactResponse, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.NodeStatusLog
 */
export class NodeStatusLog extends Message<NodeStatusLog> {
  /**
   * @generated from field: string node_id = 1;
   */
  nodeId = "";

  /**
   * @generated from field: artifacts.analyzer.v1.NodeStatus status = 2;
   */
  status = NodeStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<NodeStatusLog>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.NodeStatusLog";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "node_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(NodeStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NodeStatusLog {
    return new NodeStatusLog().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NodeStatusLog {
    return new NodeStatusLog().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NodeStatusLog {
    return new NodeStatusLog().fromJsonString(jsonString, options);
  }

  static equals(a: NodeStatusLog | PlainMessage<NodeStatusLog> | undefined, b: NodeStatusLog | PlainMessage<NodeStatusLog> | undefined): boolean {
    return proto3.util.equals(NodeStatusLog, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.NodeLog
 */
export class NodeLog extends Message<NodeLog> {
  /**
   * @generated from field: string node_id = 1;
   */
  nodeId = "";

  /**
   * @generated from field: string log = 2;
   */
  log = "";

  /**
   * @generated from field: string level = 3;
   */
  level = "";

  /**
   * @generated from field: map<string, string> args = 4;
   */
  args: { [key: string]: string } = {};

  constructor(data?: PartialMessage<NodeLog>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.NodeLog";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "node_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "log", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "args", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NodeLog {
    return new NodeLog().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NodeLog {
    return new NodeLog().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NodeLog {
    return new NodeLog().fromJsonString(jsonString, options);
  }

  static equals(a: NodeLog | PlainMessage<NodeLog> | undefined, b: NodeLog | PlainMessage<NodeLog> | undefined): boolean {
    return proto3.util.equals(NodeLog, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.Log
 */
export class Log extends Message<Log> {
  /**
   * @generated from field: artifacts.analyzer.v1.LogType type = 1;
   */
  type = LogType.UNSPECIFIED;

  /**
   * @generated from oneof artifacts.analyzer.v1.Log.log
   */
  log: {
    /**
     * @generated from field: artifacts.analyzer.v1.NodeStatusLog node_status_log = 2;
     */
    value: NodeStatusLog;
    case: "nodeStatusLog";
  } | {
    /**
     * @generated from field: artifacts.analyzer.v1.NodeLog node_log = 3;
     */
    value: NodeLog;
    case: "nodeLog";
  } | {
    /**
     * @generated from field: bool keep_alive_log = 4;
     */
    value: boolean;
    case: "keepAliveLog";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;

  constructor(data?: PartialMessage<Log>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.Log";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(LogType) },
    { no: 2, name: "node_status_log", kind: "message", T: NodeStatusLog, oneof: "log" },
    { no: 3, name: "node_log", kind: "message", T: NodeLog, oneof: "log" },
    { no: 4, name: "keep_alive_log", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "log" },
    { no: 5, name: "timestamp", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Log {
    return new Log().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Log {
    return new Log().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Log {
    return new Log().fromJsonString(jsonString, options);
  }

  static equals(a: Log | PlainMessage<Log> | undefined, b: Log | PlainMessage<Log> | undefined): boolean {
    return proto3.util.equals(Log, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.ListSubcategoriesRequest
 */
export class ListSubcategoriesRequest extends Message<ListSubcategoriesRequest> {
  constructor(data?: PartialMessage<ListSubcategoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListSubcategoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubcategoriesRequest {
    return new ListSubcategoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubcategoriesRequest {
    return new ListSubcategoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubcategoriesRequest {
    return new ListSubcategoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubcategoriesRequest | PlainMessage<ListSubcategoriesRequest> | undefined, b: ListSubcategoriesRequest | PlainMessage<ListSubcategoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListSubcategoriesRequest, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.ListSubcategoriesResponse
 */
export class ListSubcategoriesResponse extends Message<ListSubcategoriesResponse> {
  /**
   * @generated from field: repeated artifacts.analyzer.v1.CategoryMetadata categories = 1;
   */
  categories: CategoryMetadata[] = [];

  constructor(data?: PartialMessage<ListSubcategoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListSubcategoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: CategoryMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubcategoriesResponse {
    return new ListSubcategoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubcategoriesResponse {
    return new ListSubcategoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubcategoriesResponse {
    return new ListSubcategoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubcategoriesResponse | PlainMessage<ListSubcategoriesResponse> | undefined, b: ListSubcategoriesResponse | PlainMessage<ListSubcategoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListSubcategoriesResponse, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.ListFactTypesRequest
 */
export class ListFactTypesRequest extends Message<ListFactTypesRequest> {
  constructor(data?: PartialMessage<ListFactTypesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListFactTypesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFactTypesRequest {
    return new ListFactTypesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFactTypesRequest {
    return new ListFactTypesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFactTypesRequest {
    return new ListFactTypesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFactTypesRequest | PlainMessage<ListFactTypesRequest> | undefined, b: ListFactTypesRequest | PlainMessage<ListFactTypesRequest> | undefined): boolean {
    return proto3.util.equals(ListFactTypesRequest, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.ListFactTypesResponse
 */
export class ListFactTypesResponse extends Message<ListFactTypesResponse> {
  /**
   * @generated from field: repeated string fact_types = 1;
   */
  factTypes: string[] = [];

  constructor(data?: PartialMessage<ListFactTypesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.ListFactTypesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact_types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFactTypesResponse {
    return new ListFactTypesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFactTypesResponse {
    return new ListFactTypesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFactTypesResponse {
    return new ListFactTypesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFactTypesResponse | PlainMessage<ListFactTypesResponse> | undefined, b: ListFactTypesResponse | PlainMessage<ListFactTypesResponse> | undefined): boolean {
    return proto3.util.equals(ListFactTypesResponse, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.CategoryMetadata
 */
export class CategoryMetadata extends Message<CategoryMetadata> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category = 2;
   */
  category = "";

  /**
   * @generated from field: string subcategory = 3;
   */
  subcategory = "";

  constructor(data?: PartialMessage<CategoryMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.CategoryMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryMetadata {
    return new CategoryMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryMetadata | PlainMessage<CategoryMetadata> | undefined, b: CategoryMetadata | PlainMessage<CategoryMetadata> | undefined): boolean {
    return proto3.util.equals(CategoryMetadata, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.Recipe
 */
export class Recipe extends Message<Recipe> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated artifacts.analyzer.v1.Node nodes = 2;
   */
  nodes: Node[] = [];

  /**
   * @generated from field: repeated artifacts.analyzer.v1.Edge edges = 3;
   */
  edges: Edge[] = [];

  constructor(data?: PartialMessage<Recipe>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.Recipe";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nodes", kind: "message", T: Node, repeated: true },
    { no: 3, name: "edges", kind: "message", T: Edge, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Recipe {
    return new Recipe().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Recipe {
    return new Recipe().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Recipe {
    return new Recipe().fromJsonString(jsonString, options);
  }

  static equals(a: Recipe | PlainMessage<Recipe> | undefined, b: Recipe | PlainMessage<Recipe> | undefined): boolean {
    return proto3.util.equals(Recipe, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.Node
 */
export class Node extends Message<Node> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: artifacts.analyzer.v1.NodeData data = 2;
   */
  data?: NodeData;

  /**
   * @generated from field: artifacts.analyzer.v1.NodeType type = 3;
   */
  type = NodeType.UNSPECIFIED;

  /**
   * @generated from field: repeated artifacts.analyzer.v1.Target targets = 4;
   */
  targets: Target[] = [];

  constructor(data?: PartialMessage<Node>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.Node";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "message", T: NodeData },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(NodeType) },
    { no: 4, name: "targets", kind: "message", T: Target, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Node {
    return new Node().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Node {
    return new Node().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Node {
    return new Node().fromJsonString(jsonString, options);
  }

  static equals(a: Node | PlainMessage<Node> | undefined, b: Node | PlainMessage<Node> | undefined): boolean {
    return proto3.util.equals(Node, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.Target
 */
export class Target extends Message<Target> {
  /**
   * @generated from field: string node_id = 1;
   */
  nodeId = "";

  /**
   * @generated from field: string edge_id = 2;
   */
  edgeId = "";

  constructor(data?: PartialMessage<Target>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.Target";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "node_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "edge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Target {
    return new Target().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Target {
    return new Target().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Target {
    return new Target().fromJsonString(jsonString, options);
  }

  static equals(a: Target | PlainMessage<Target> | undefined, b: Target | PlainMessage<Target> | undefined): boolean {
    return proto3.util.equals(Target, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.NodeData
 */
export class NodeData extends Message<NodeData> {
  /**
   * @generated from field: optional string recipe_id = 1;
   */
  recipeId?: string;

  /**
   * @generated from field: repeated string selected_categories = 2;
   */
  selectedCategories: string[] = [];

  /**
   * @generated from field: repeated string keywords = 3;
   */
  keywords: string[] = [];

  /**
   * @generated from field: repeated inventory.v1.DocumentSource selected_sources = 4;
   */
  selectedSources: DocumentSource[] = [];

  /**
   * @generated from field: optional string fact_type = 5;
   */
  factType?: string;

  /**
   * @generated from field: optional string llm_prompt = 6;
   */
  llmPrompt?: string;

  constructor(data?: PartialMessage<NodeData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.NodeData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipe_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "selected_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "selected_sources", kind: "enum", T: proto3.getEnumType(DocumentSource), repeated: true },
    { no: 5, name: "fact_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "llm_prompt", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NodeData {
    return new NodeData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NodeData {
    return new NodeData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NodeData {
    return new NodeData().fromJsonString(jsonString, options);
  }

  static equals(a: NodeData | PlainMessage<NodeData> | undefined, b: NodeData | PlainMessage<NodeData> | undefined): boolean {
    return proto3.util.equals(NodeData, a, b);
  }
}

/**
 * @generated from message artifacts.analyzer.v1.Edge
 */
export class Edge extends Message<Edge> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string source = 2;
   */
  source = "";

  /**
   * @generated from field: string target = 3;
   */
  target = "";

  /**
   * @generated from field: string source_handle = 4;
   */
  sourceHandle = "";

  /**
   * @generated from field: string target_handle = 5;
   */
  targetHandle = "";

  constructor(data?: PartialMessage<Edge>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "artifacts.analyzer.v1.Edge";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "source_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "target_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Edge {
    return new Edge().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Edge {
    return new Edge().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Edge {
    return new Edge().fromJsonString(jsonString, options);
  }

  static equals(a: Edge | PlainMessage<Edge> | undefined, b: Edge | PlainMessage<Edge> | undefined): boolean {
    return proto3.util.equals(Edge, a, b);
  }
}

