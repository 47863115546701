import { GoalIcon } from 'lucide-react'

export const ControlFindingInactive = () => {
  return (
    <div className='flex h-full items-center justify-center'>
      <div className='flex flex-col items-center gap-2'>
        <h2 className='font-bold'>ERROR</h2>
        <h2 className='text-3xl font-bold'>Finding Inactive</h2>
        <GoalIcon size={56} className='my-4 text-gray-300' strokeWidth={1.5} />
        <div className='max-w-112 text-center text-lg'>
          This finding is hidden because it results from a control that is inactive for
          third-parties of this inherent risk.
        </div>
      </div>
    </div>
  )
}
