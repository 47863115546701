import { Integration } from '@/gen/inventory/v1/integration_service_pb'

import { ContactSupportLabel } from '@/lib/contact-support-label'
import { cn } from '@/lib/style-helpers'

import { FormCallout } from '@/pages/settings-page/integration-section/integration-form/external-documentation-callout'
import { FormHeader } from '@/pages/settings-page/integration-section/integration-form/form-header'

import { Button } from '@/components/ui/button'

type IntegrationHeaderProps = {
  integration: Integration
  requiredPermissions?: string[]
}

export const IntegrationHeader = ({ integration, requiredPermissions }: IntegrationHeaderProps) => {
  return (
    <>
      <FormHeader
        title={integration.name}
        iconURL={integration.iconUrl}
        description={integration.description}
        dataCollected={integration.dataCollected}
        category={integration.category}
      />

      <FormCallout title={`${integration.name} Integration Setup Guide with Lema`}>
        <>
          {requiredPermissions && (
            <>
              <h3 className='font-bold'>To complete this process, you will need:</h3>
              <ul className='list-inside list-disc'>
                {requiredPermissions.map((permission) => (
                  <li key={permission}>{permission}</li>
                ))}
              </ul>
            </>
          )}
          <Button
            className={cn('mb-6 rounded', { 'mt-6': requiredPermissions })}
            onClick={() => window.open(integration.documentationUrl)}
          >
            Open Guide
          </Button>
          <ContactSupportLabel />
        </>
      </FormCallout>
    </>
  )
}
