import _ from 'lodash'
import { ReactNode, useContext } from 'react'

import { useStorage } from '@/lib/liveblocks.config'

import { BaseQuestionProps, Question } from '@/pages/questionnaire/question'
import { QuestionnaireAnchor } from '@/pages/questionnaire/questionnaire-anchor'
import { QuestionnaireFilterContext } from '@/pages/questionnaire/questionnaire-filter-provider'

import { Loading } from '@/components/loading'

type ListQuestionProps = Pick<BaseQuestionProps, 'participantRole'>

export const ListQuestion = ({ participantRole }: ListQuestionProps) => {
  const { isQuestionMatchFilter } = useContext(QuestionnaireFilterContext)
  const questions = useStorage(({ questions }) => questions)
  const categoryToQuestions = _.groupBy(questions, 'category')

  if (questions === null) return <Loading />

  const categoryList = Object.entries(categoryToQuestions)
    .map(([category, questions], index) => {
      const categoryIndex = index + 1
      const questionList = questions
        ?.map((question, index) => {
          const questionIndex = index + 1
          const section = `${categoryIndex}.${questionIndex}`

          if (!isQuestionMatchFilter(question)) return null

          return (
            <Question
              participantRole={participantRole}
              key={section}
              question={question}
              section={section}
            />
          )
        })
        .filter((question) => question !== null)

      return {
        category,
        categoryIndex,
        questionList,
      }
    })
    .filter((category) => category.questionList.length > 0) as {
    category: string
    categoryIndex: number
    questionList: ReactNode[]
  }[]

  return (
    <div className='flex gap-24'>
      <QuestionnaireAnchor
        categories={categoryList.map(({ category, questionList }) => ({
          category,
          questionCount: questionList.length,
        }))}
      />
      <div className='w-full'>
        {categoryList.map(({ category, categoryIndex, questionList }) => (
          <div id={category} key={category} className='mb-12 flex flex-col gap-6'>
            <h2 className='text-xl font-bold'>
              {categoryIndex}. {category}
            </h2>
            {questionList}
          </div>
        ))}
      </div>
    </div>
  )
}
