import { PropsWithChildren } from 'react'

import { RoomProvider } from '@/lib/liveblocks.config'

import { QuestionnaireRoomNotFound } from '@/pages/error-pages/questionnaire-room-not-found'
import { QuestionnaireFilterProvider } from '@/pages/questionnaire/questionnaire-filter-provider'
import { SubCategoryRequiredProvider } from '@/pages/questionnaire/sub-category-required-provider'

type QuestionnaireProviderProps = {
  roomId?: string
  loadingNode?: React.ReactNode
} & PropsWithChildren

export const QuestionnaireProvider = ({
  roomId,
  children,
  loadingNode,
}: QuestionnaireProviderProps) => {
  if (!roomId) {
    return loadingNode || <QuestionnaireRoomNotFound />
  }

  return (
    <RoomProvider id={roomId} initialPresence={{}}>
      <SubCategoryRequiredProvider>
        <QuestionnaireFilterProvider>{children}</QuestionnaireFilterProvider>
      </SubCategoryRequiredProvider>
    </RoomProvider>
  )
}
