import { LEMA_SUPPORT_EMAIL } from '@/const/contact'

/** ContactSupportLabel is a label with a link to contact support */
export const ContactSupportLabel = () => (
  <span className='block text-xs text-gray-400'>
    Need help?{' '}
    <a href={`mailto:${LEMA_SUPPORT_EMAIL}`} className='text-purple-400 hover:text-purple-600'>
      Contact Us
    </a>{' '}
    for support
  </span>
)
