import { ClipboardIcon } from 'lucide-react'

import { useGetControl } from '@/api/control'
import { useGetFinding } from '@/api/get-risk-factor.hook'

import { formatDate } from '@/lib/date'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { ControlNotFound } from '@/pages/error-pages/control-not-found'

import { CompanySummaryBadge } from '@/components/badge/company-summary'
import { ControlStatusBadge } from '@/components/badge/control-status'
import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { SelectFindingStatusBadge } from '@/components/badge/finding-status'
import { Evidence } from '@/components/evidence'
import { ImplicationIndicatorList } from '@/components/indicator/implication'
import { Loading } from '@/components/loading'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { TextWithIcon } from '@/components/text-with-icon'
import { Separator } from '@/components/ui/separator'

import GlobeWithEmptySearch from '@/assets/globe-with-empty-search.svg'

type ControlProps = {
  controlId: string
  companyId: string
}

export const Control = ({ controlId, companyId }: ControlProps) => {
  const { data, isLoading } = useGetControl(controlId, companyId)

  if (isLoading) return <Loading />

  if (!data?.control) return <ControlNotFound />

  const { control, findingId, factMarkdown, additionalFactMarkdown, additionalFactCount } = data

  return (
    <div className='mt-5 px-8 pb-10'>
      {data.company && (
        <div className='mb-6 flex items-center'>
          <CompanySummaryBadge companySummary={data.company} />
        </div>
      )}

      <div className='flex flex-col gap-1'>
        <h3 className='text-xs font-bold uppercase tracking-wide'>{control?.categoryName}</h3>
        <h2 className='text-3xl font-bold'>{control?.title}</h2>
        <div>{control?.description}</div>
      </div>
      <div className='mt-6 w-80'>
        <StatisticsRow
          items={[
            {
              title: 'Validation Status',
              children: control?.status && <ControlStatusBadge status={control.status} />,
            },
            {
              title: 'Implications',
              children: <ImplicationIndicatorList activeImplications={data.implications} />,
            },
          ]}
        />
      </div>

      {findingId && (
        <>
          <Separator className='my-12' />
          <div className='flex flex-col gap-3'>
            <TextWithIcon
              className='font-medium'
              text='Finding'
              icon={<ClipboardIcon className='size-4' />}
            />
            <FindingCard findingId={findingId} />
          </div>
        </>
      )}

      {!findingId && !factMarkdown && (
        <>
          <Separator className='my-12' />
          <div className='flex flex-col items-center'>
            <GlobeWithEmptySearch />
            <div className='text-center'>
              Lema has not found any indication that this control is violated.
              <br />
              We are continuing to monitor it.
            </div>
          </div>
        </>
      )}

      {factMarkdown && (
        <>
          <Separator className='my-12' />
          <Evidence
            factMarkdown={factMarkdown}
            additionalFactMarkdown={additionalFactMarkdown}
            additionalFactCount={additionalFactCount}
          />
        </>
      )}
    </div>
  )
}

type FindingCardProps = {
  findingId: string
}

const FindingCard = ({ findingId }: FindingCardProps) => {
  const { data } = useGetFinding(findingId || '')
  const { replaceParams } = useUrlParams()

  if (!data?.finding) return null

  return (
    <div
      onClick={() =>
        replaceParams({
          remove: [UrlParam.CONTROL_ID, UrlParam.THIRD_PARTY_CONTROL_ID],
          update: [[UrlParam.FINDING_ID, findingId]],
        })
      }
      className='flex cursor-pointer items-center justify-between gap-9 rounded border border-red-100 p-6 outline outline-red-50 duration-150 ease-in-out hover:bg-gray-50'
    >
      <div className='truncate'>{data.finding.title}</div>
      <FindingSeverityBadge level={data.finding.riskLevel} />
      <SelectFindingStatusBadge
        findingId={data.finding.id}
        status={data.finding.status}
        suggestedWorkStatusEnabled={false}
      />
      <ImplicationIndicatorList activeImplications={data.finding.implications} />
      {data.finding.detectionTime && (
        <div className='text-nowrap text-md font-light'>
          {formatDate(data.finding.detectionTime?.toDate())}
        </div>
      )}
    </div>
  )
}
