import useDrivePicker from '@lema-ai/google-drive-picker-react'
import { useGoogleLogin } from '@react-oauth/google'
import { Form } from 'antd'
import { Rule } from 'antd/es/form'
import _ from 'lodash'
import { CheckIcon, Loader2Icon, TrashIcon } from 'lucide-react'

import { useSetGoogleDriveCredentials } from '@/api/credentials/google-drive.hook'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

type GoogleDriveIntegrationProps = BaseIntegrationProps

const googleClientId = import.meta.env.VITE_GOOGLE_DRIVE_WEB_CLIENT_ID
const googleDeveloperKey = import.meta.env.VITE_GOOGLE_DRIVE_DEVELOPER_KEY
const googleDriveScope = 'https://www.googleapis.com/auth/drive.readonly'

type Dir = {
  id: string
  name: string
}

type FormValues = {
  authCode: string
  dirs: Dir[]
}

const grantPermissions: Rule[] = [
  {
    required: true,
    validateTrigger: 'onSubmit',
    validator: async (_rule, value) => {
      if (!value) throw new Error('Please Grant Permissions')
    },
  },
]

export const GoogleDriveIntegration = ({ integration }: GoogleDriveIntegrationProps) => {
  const [form] = Form.useForm<FormValues>()
  const tryReportErr = useReportErrorsCallback()
  const grantGoogleDrivePermissions = useGoogleLogin({
    flow: 'auth-code',
    include_granted_scopes: false,
    overrideScope: true,
    scope: googleDriveScope,
    enable_serial_consent: false,
    onSuccess: (response) => form.setFieldsValue({ authCode: response.code }),
    onError: ({ error_description }) => {
      const description = error_description || "Couldn't grant permissions for Google Drive"
      tryReportErr(new Error(description))
      toast({ status: 'error', title: description })
    },
  })

  const {
    mutate: setGoogleDriveCredentials,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSetGoogleDriveCredentials()
  useUploadToast(isSuccess, isError, error)
  const { toast } = useToast()

  const [openPicker] = useDrivePicker()

  const onFinish = async ({ authCode, dirs }: FormValues) => {
    setGoogleDriveCredentials({
      authCode,
      dirs: dirs.map((dir) => dir.id),
      redirectUri: window.location.origin,
    })
  }

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader integration={integration} />
        <DashedContainer>
          <Form form={form} onFinish={onFinish}>
            <h2 className='mb-3 mt-6 text-xl font-light'>
              Step 1: Grant Google Permissions to Lema
            </h2>
            <p>
              Click ‘Grant Permissions’ and complete the authorization flow to allow Lema to access
              specific Google services or data.
            </p>

            <Form.Item<FormValues> className='my-6' name='authCode' rules={grantPermissions}>
              <div className='flex items-center gap-3'>
                <Button
                  type='button'
                  name='authCode'
                  variant='outline'
                  onClick={grantGoogleDrivePermissions}
                >
                  Grant Permissions
                </Button>
                {form.getFieldValue('authCode') && <CheckIcon className='text-green-500' />}
              </div>
            </Form.Item>

            <h2 className='mb-3 mt-6 text-xl font-light'>
              Step 2: Select which Folders Lema can Access
            </h2>
            <p>
              To select which folders Lema can access, click the button below and choose those
              folders in the popup window.
            </p>

            <Form.List name='dirs'>
              {(fields, { remove }) => {
                const currentDirs = form.getFieldValue('dirs') || []
                return (
                  <Form.Item
                    className='my-6'
                    name='dirs'
                    rules={[
                      {
                        required: true,
                        validateTrigger: 'onSubmit',
                        validator: async () => {
                          if (!(currentDirs && currentDirs.length !== 0))
                            throw new Error('Please Select Folders')
                        },
                      },
                    ]}
                  >
                    <div className='flex items-center gap-3'>
                      <Button
                        type='button'
                        variant={'outline'}
                        onClick={() =>
                          openPicker({
                            clientId: googleClientId,
                            developerKey: googleDeveloperKey,
                            viewId: 'FOLDERS',
                            supportDrives: true,
                            multiselect: true,
                            setIncludeFolders: true,
                            setEnableDrives: true,
                            setSelectFolderEnabled: true,
                            callbackFunction: ({ docs }) => {
                              if (docs && docs.length > 0) {
                                const uniqueDirs = _.uniqBy([...currentDirs, ...docs], 'id')
                                form.resetFields(['dirs'])
                                form.setFieldsValue({ dirs: uniqueDirs })
                              }
                            },
                          })
                        }
                      >
                        Select Accessible Folders
                      </Button>
                      {!!currentDirs.length && <CheckIcon className='text-green-500' />}
                    </div>

                    {!!currentDirs.length && (
                      <div className='m-2'>
                        <h3 className='mb-3 mt-4.5 font-semibold'>Selected Folders</h3>
                        <ul className='list-inside list-disc'>
                          {fields.map(({ name, key }) => (
                            <li
                              className='group rounded px-0.5 py-1 transition duration-300 ease-in-out hover:bg-gray-100'
                              key={key}
                            >
                              <span>{form.getFieldValue(['dirs', name, 'name'])}</span>
                              <TrashIcon
                                onClick={() => remove(name)}
                                strokeWidth={1.5}
                                className='float-right cursor-pointer text-gray-700 opacity-0 transition-opacity group-hover:opacity-100'
                                size={14}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Form.Item>
                )
              }}
            </Form.List>

            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
