import { HomeIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { Element } from 'react-scroll'

import { Company } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { companyStatusLabel, riskLevelLabel } from '@/const/label'
import { companyStatusPriority, riskLevelPriority } from '@/const/priority'

import { formatDate } from '@/lib/date'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { UrlParam } from '@/lib/url-param.hook'

import { EmptyInventoryTable } from '@/pages/third-party-inventory/empty-inventory-table'
import { isUrgentCompany } from '@/pages/third-party-inventory/urgent'

import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { CompanyUrgentBadge } from '@/components/badge/urgent'
import { DataTypeExplanation } from '@/components/data-type/data-type-explanation'
import { DataTypeTableCell } from '@/components/data-type/data-types-table-cell'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { RiskLevelSymbol } from '@/components/icons/risk-severity'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

export interface InventoryTableProps {
  inventory: Company[]
  onClick?: (thirdPartyId: string) => void
}

export const InventoryTable = ({ inventory, onClick }: InventoryTableProps) => {
  const { isEnabled: spendEnabled } = useFeatureFlagEnabled('spend')

  const onRow = ({ id }: Company) => ({
    onClick: () => onClick && id && onClick(id),
  })

  const columns: ColumnType<Company>[] = [
    {
      width: '23%',
      title: <TextWithIcon text='Third-Party' icon={<HomeIcon className='size-3' />} />,
      key: 'thirdParty',
      search: true,
      render: (company: Company) => {
        const { profile, solutionCount } = company
        return (
          <div>
            <div className='flex items-center gap-1.5'>
              <span className='max-w-sm truncate font-semibold'>{profile?.name}</span>
              {isUrgentCompany(company) && <CompanyUrgentBadge />}
            </div>
            <div className='text-xs'>
              <span className='block max-w-md truncate text-gray-600'>
                {profile?.shortDescription}
              </span>
              {solutionCount > 1 && pluralize('Solutions', solutionCount, true)}
            </div>
          </div>
        )
      },
      accessor: ({ profile, solutions }) => ({
        sortValue: profile?.name,
        searchValue: profile?.name + ' ' + profile?.shortDescription + ' ' + solutions.join(' '),
      }),
    },
    {
      title: 'Inherent Risk',
      defaultSortOrder: 'ascend',
      render: ({ risk }: Company) => <CompanyRiskIcon showTooltip riskLevel={risk} />,
      filter: true,
      accessor: ({ risk }) => ({
        filterValue: riskLevelLabel[risk],
        sortValue: riskLevelPriority[risk],
      }),
    },
    {
      key: 'Findings',
      title: <TextWithIcon text='Findings' icon={<RiskLevelSymbol />} />,
      render: ({ findingsByLevel }: Company) => (
        <FindingDistribution findingCountByLevel={findingsByLevel} />
      ),
    },
    {
      title: 'Status',
      filter: true,
      render: ({ status }: Company) => <CompanyVerificationBadge status={status} />,
      accessor: ({ status }) => {
        return {
          filterValue: companyStatusLabel[status],
          sortValue: companyStatusPriority[status],
        }
      },
    },
    {
      key: 'dataTypes',
      title: <TextWithIcon text='Datatypes' iconPosition='end' icon={<DataTypeExplanation />} />,
      filterTitle: 'Datatypes',
      filter: true,
      sort: false,
      render: ({ dataTypes }: Company) => <DataTypeTableCell dataTypes={dataTypes} />,
      accessor: ({ dataTypes }) => ({
        filterValue: dataTypes.map(({ dataType }) => dataType),
      }),
    },
    {
      title: 'Spend (USD)',
      hidden: !spendEnabled,
      render: ({ spend }: Company) => spend,
    },
    {
      title: 'Status Last Updated',
      render: ({ statusLastUpdate }: Company) => (
        <span className='text-gray-400'>
          {statusLastUpdate && formatDate(statusLastUpdate?.toDate())}
        </span>
      ),
      accessor: ({ statusLastUpdate }) => {
        return {
          sortValue: statusLastUpdate && statusLastUpdate.toDate(),
        }
      },
    },
    {
      title: 'Date Added',
      render: ({ createdAt }: Company) => (
        <span className='text-gray-400'>{createdAt && formatDate(createdAt?.toDate())}</span>
      ),
      accessor: ({ createdAt }) => {
        return {
          sortValue: createdAt && createdAt.toDate(),
        }
      },
    },
  ]

  return (
    <Element name='third-party-inventory-table'>
      <Table
        narrowFilterBar
        tableViews={{
          selectedViewUrlKey: UrlParam.VIEW,
          viewAccessors: {
            All: ({ status }) =>
              ![CompanyStatus.OFFBOARDED, CompanyStatus.ARCHIVED, CompanyStatus.REJECTED].includes(
                status,
              ),
            'In Assessment': ({ status }) => CompanyStatus.IN_ASSESSMENT === status,
            'Assessment Required': ({ status }) =>
              [CompanyStatus.RE_ASSESSMENT_REQUIRED, CompanyStatus.ASSESSMENT_REQUIRED].includes(
                status,
              ),
            Assessed: ({ status }) => status === CompanyStatus.ASSESSED,
            'Attention Required': isUrgentCompany,
            Unsanctioned: ({ status }) => status === CompanyStatus.UNSANCTIONED,
            Archived: ({ status }) =>
              [CompanyStatus.OFFBOARDED, CompanyStatus.ARCHIVED, CompanyStatus.REJECTED].includes(
                status,
              ),
          },
        }}
        locale={{
          emptyText: <EmptyInventoryTable />,
        }}
        stickyFilterBar
        columns={columns}
        dataSource={inventory}
        onRow={onRow}
      />
    </Element>
  )
}
