import { Anchor as AntDAnchor } from 'antd'

import './ant-anchor.less'

interface AnchorProps {
  items: { id: string; title: React.ReactNode }[]
}

/**
 * Anchor is used as table of contents navigation element, expected to be located at the left side of the screen showing what Section of the page the user is currently viewing.
 * It also allows navigating to the different sections using Web Anchors (the URL value after the hashtag.)
 * For navigation to work, make sure a React element with the matching `id` field is present in the page.
 */
export const Anchor = ({ items }: AnchorProps) => {
  return (
    <div className='size-0'>
      <AntDAnchor
        className='padding-block-2 no-scrollbar ml-4 w-fit overflow-y-auto'
        offsetTop={100}
        items={items.map(({ id, title }) => ({
          key: id,
          href: `#${id}`,
          title,
        }))}
      />
    </div>
  )
}
