import { Clock4Icon, MessageSquareIcon } from 'lucide-react'

type TimelineIconProps = {
  timelineCase?: 'comment' | 'change'
}

export const TimelineIcon = ({ timelineCase }: TimelineIconProps) => {
  switch (timelineCase) {
    case 'comment':
      return <MessageSquareIcon className='rounded bg-orange-50 p-1 text-orange-400' size={20} />
    case 'change':
      return <Clock4Icon className='rounded bg-gray-100 p-1 text-gray-400' size={20} />
    default:
      return <Clock4Icon className='rounded bg-gray-100 p-1 text-gray-400' size={20} />
  }
}
