import { Descope, useSession } from '@descope/react-sdk'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useToast } from '@/components/ui/use-toast'

export type LoginPageProps = {
  authenticatedPath: string
  descopeFlowId?: string
}

export const LoginPage = ({
  authenticatedPath,
  descopeFlowId = 'sign-up-or-in',
}: LoginPageProps) => {
  const { isAuthenticated } = useSession()
  const navigate = useNavigate()
  const location = useLocation()
  const toPath = location.state?.from ? getPathFromState(location.state.from) : authenticatedPath

  useEffect(() => {
    if (isAuthenticated) {
      navigate(toPath)
    }
  }, [authenticatedPath, isAuthenticated, navigate, toPath])

  const reportErrors = useReportErrorsCallback()
  const { toast } = useToast()
  return (
    <div className='mx-auto mt-16 max-w-lg'>
      {!isAuthenticated && (
        <Descope
          flowId={descopeFlowId}
          theme='light'
          onError={(err) => {
            reportErrors(new Error(`Descope error ${err}`))
            toast({
              title: 'Error',
              description: 'An error occurred. Please try again later.',
              status: 'error',
            })
          }}
        />
      )}
    </div>
  )
}

const getPathFromState = ({
  pathname,
  search,
  hash,
}: {
  pathname: string
  search: string
  hash: string
}) => {
  return `${pathname}${search}${hash}`
}
