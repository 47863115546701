// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file notifications/v1/notifications_service.proto (package notifications.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message notifications.v1.ListSlackChannelsRequest
 */
export class ListSlackChannelsRequest extends Message<ListSlackChannelsRequest> {
  constructor(data?: PartialMessage<ListSlackChannelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.ListSlackChannelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSlackChannelsRequest | PlainMessage<ListSlackChannelsRequest> | undefined, b: ListSlackChannelsRequest | PlainMessage<ListSlackChannelsRequest> | undefined): boolean {
    return proto3.util.equals(ListSlackChannelsRequest, a, b);
  }
}

/**
 * @generated from message notifications.v1.ListSlackChannelsResponse
 */
export class ListSlackChannelsResponse extends Message<ListSlackChannelsResponse> {
  /**
   * @generated from field: repeated notifications.v1.SlackChannel channels = 1;
   */
  channels: SlackChannel[] = [];

  constructor(data?: PartialMessage<ListSlackChannelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.ListSlackChannelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channels", kind: "message", T: SlackChannel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSlackChannelsResponse | PlainMessage<ListSlackChannelsResponse> | undefined, b: ListSlackChannelsResponse | PlainMessage<ListSlackChannelsResponse> | undefined): boolean {
    return proto3.util.equals(ListSlackChannelsResponse, a, b);
  }
}

/**
 * @generated from message notifications.v1.SlackChannel
 */
export class SlackChannel extends Message<SlackChannel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool is_private = 3;
   */
  isPrivate = false;

  constructor(data?: PartialMessage<SlackChannel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.SlackChannel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SlackChannel {
    return new SlackChannel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SlackChannel {
    return new SlackChannel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SlackChannel {
    return new SlackChannel().fromJsonString(jsonString, options);
  }

  static equals(a: SlackChannel | PlainMessage<SlackChannel> | undefined, b: SlackChannel | PlainMessage<SlackChannel> | undefined): boolean {
    return proto3.util.equals(SlackChannel, a, b);
  }
}

