import { useState } from 'react'

export interface SearchContext {
  searchState: string
  setSearchState: (searchState: string) => void
}

export const useSearch = (): SearchContext => {
  const [searchState, setSearchState] = useState<string>('')

  return {
    searchState,
    setSearchState,
  }
}
