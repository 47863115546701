import { Form, Input } from 'antd'
import { Rule } from 'antd/es/form'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { VariantProps, cva } from 'class-variance-authority'
import { Loader2Icon } from 'lucide-react'

import { isEmailValid } from '@/lib/string-validators'

import { Button } from '@/components/ui/button'
import { Upload } from '@/components/upload/upload'

type FormValues = {
  superAdminEmail: string
  file: Promise<string>
}

const variants = cva('', {
  variants: {
    triggerAlign: {
      start: 'self-start',
      end: 'self-end',
    },
  },
  defaultVariants: {
    triggerAlign: 'start',
  },
})

const superAdminValidation: Rule[] = [
  {
    required: true,
    message: 'Please input email address',
  },
  {
    required: true,
    validateTrigger: 'onSubmit',
    validator: async (_rule, value) => {
      if (value && !isEmailValid(value)) throw new Error('Please input a valid email address')
    },
  },
]

const credsFileValidation: Rule[] = [
  {
    validateTrigger: 'onSubmit',
    required: true,
    validator: async (_rule, value) => {
      const file = await value
      if (file?.error) throw file.error
      if (!file) throw new Error('Please upload a file')
    },
  },
]

type GoogleCredentialsFormProps = {
  disabled?: boolean
  setCredentials: (creds: { credsJsonBase64: string; subject: string }) => void
  isLoading: boolean
} & VariantProps<typeof variants>

export const GoogleCredentialsForm = ({
  triggerAlign,
  disabled = false,
  setCredentials,
  isLoading,
}: GoogleCredentialsFormProps) => {
  const onFinish = async ({ superAdminEmail, file }: FormValues) => {
    setCredentials({
      credsJsonBase64: await file,
      subject: superAdminEmail,
    })
  }

  return (
    <Form name='basic' onFinish={onFinish} autoComplete='off' disabled={disabled}>
      <div className='flex flex-col'>
        <h2 className='mb-3 mt-6 text-xl font-light'>Provide the Super-Admin Email Address</h2>
        <span>
          Provide the email address of the super admin account which was used to set up the
          domain-wide delegation for your Lema service account.
        </span>
        <Form.Item<FormValues> className='my-5' name='superAdminEmail' rules={superAdminValidation}>
          <Input
            className='h-10 rounded border-gray-100'
            placeholder='Domain-Wide Delegation Email Used'
          />
        </Form.Item>
        <h2 className='my-3 text-xl font-light'>Upload Your Service Account ‘credentials.json’:</h2>
        <span>Upload the JSON file that contains the service account key pair.</span>
        <span className='block font-semibold'>Example: “credentials.json”</span>
        <span>For instructions on how to get this file, follow the integration guide.</span>
        <br />
        <Form.Item<FormValues>
          name='file'
          valuePropName='file'
          // eslint-disable-next-line consistent-return
          getValueFromEvent={async ({ file }: UploadChangeParam<UploadFile>) => {
            if (file.status === 'uploading' && file.originFileObj) {
              try {
                const reader = file.originFileObj.stream().getReader()
                const { value } = await reader.read()
                const text = new TextDecoder().decode(value)
                JSON.parse(text) // make sure the file is a valid JSON
                const base64 = btoa(text)
                file.status = 'done'
                return base64
              } catch {
                file.error = new Error('Invalid JSON format')
                file.status = 'error'
                return { error: file.error }
              }
            }
          }}
          rules={credsFileValidation}
        >
          <Upload
            name='file'
            accept='application/json'
            maxCount={1}
            customRequest={() => {}} // We want to override the default behavior of antd upload
          />
        </Form.Item>
        <Form.Item className={variants({ triggerAlign })}>
          <Button disabled={disabled} type='submit'>
            {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
            Add Integration
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}
