import { useEffect, useMemo, useState } from 'react'
import { Subject, debounceTime } from 'rxjs'

type UseDelayedCallbackHook = <T>(
  cb: (input: T) => void,
  delay?: number,
) => [(input: T) => void, boolean]

export const useDelayedCallback: UseDelayedCallbackHook = <T>(
  cb: (input: T) => void,
  delay = 400,
) => {
  const [isHanging, setIsHanging] = useState(false)

  const inputSubject = useMemo(() => new Subject<T>(), [])

  useEffect(() => {
    const subscription = inputSubject.pipe(debounceTime(delay)).subscribe({
      next: (value) => {
        cb(value)
        setIsHanging(false)
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [cb, delay, inputSubject, setIsHanging])

  const sendInput = (input: T) => {
    setIsHanging(true)
    inputSubject.next(input)
  }

  return [sendInput, isHanging]
}
