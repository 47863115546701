import { CheckIcon, XIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { UserStatus } from '@/gen/customer/management/v1/service_pb'

interface UserStatusIconProps {
  status: UserStatus
}

export const UserStatusIcon = ({ status }: UserStatusIconProps) => userStatusIcon[status]

const userStatusIcon: Record<UserStatus, ReactNode> = {
  [UserStatus.ENABLED]: <CheckIcon strokeWidth={1.5} className='size-4' />,
  [UserStatus.DISABLED]: <XIcon strokeWidth={1.5} className='size-4' />,
  [UserStatus.INVITED]: null,
  [UserStatus.UNSPECIFIED]: null,
}
