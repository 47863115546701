import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  getIntegration,
  listAllIntegrations,
} from '@/gen/inventory/v1/integration_service-IntegrationService_connectquery'
import { getVendrWebhookURL } from '@/gen/webhook/v1/webhook_service-WebhookService_connectquery'

export const useGetVendrWebhookUrl = () => {
  const queryClient = useQueryClient()
  const opts = getVendrWebhookURL.useMutation()
  return useMutation({
    ...opts,
    onSuccess: () => {
      queryClient.invalidateQueries(getIntegration.getQueryKey())
      queryClient.invalidateQueries(listAllIntegrations.getQueryKey())
    },
  })
}
