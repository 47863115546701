import _ from 'lodash'

import { Asset } from '@/gen/inventory/v1/company_service_pb'

import { CountryFlagIcon } from '@/components/country-flag-icon'
import { GroupedTable } from '@/components/table/grouped-table/grouped-table'
import { ColumnType, GroupColumnType } from '@/components/table/table.type'
import { Badge } from '@/components/ui/badge'

const assetColumns: ColumnType<Asset>[] = [
  {
    title: 'Asset',
    dataIndex: 'asset',
    filterTitle: 'Country',
    filter: true,
    search: true,
    accessor: ({ countryName }) => ({
      searchValue: countryName,
      filterValue: countryName,
    }),
    render: (asset: string) => <span className='capitalize'>{asset}</span>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    accessor: ({ tags }) => ({
      filterValue: tags,
    }),
    filter: true,
    render: (tags: Asset['tags']) => (
      <div className='flex gap-1'>
        {tags
          .map(_.trim)
          .filter((tag) => tag.length > 0)
          .map((tag: string) => (
            <Badge variant={'outline'} className='whitespace-nowrap rounded text-md' key={tag}>
              {tag}
            </Badge>
          ))}
      </div>
    ),
  },
  {
    title: 'Details',
    dataIndex: 'details',
  },
]

const countryColumns: GroupColumnType<Asset, 'countryCode'>[] = [
  {
    title: 'Country',
    render: (countryCode, _index, nestedRows) => (
      <div className='flex items-center gap-2 capitalize'>
        <CountryFlagIcon countryCode={countryCode} />
        <span>{nestedRows[0].countryName}</span>
      </div>
    ),
  },
]

interface AssetTableProps {
  dataSource: Asset[]
}

export const AssetsByCountryTable = ({ dataSource }: AssetTableProps) => {
  return (
    <GroupedTable
      groupByKey='countryCode'
      size='middle'
      showHeader={false}
      nestedTableColumns={assetColumns}
      tableColumns={countryColumns}
      dataSource={dataSource}
    />
  )
}
