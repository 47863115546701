import { MessageCircleIcon } from 'lucide-react'

import { Finding, FindingStatus } from '@/gen/inventory/v1/finding_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { findingStatusLabel, riskLevelLabel } from '@/const/label'
import { findingStatusPriority, riskLevelPriority } from '@/const/priority'

import { formatDate } from '@/lib/date'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'
import { ImplicationIndicatorList } from '@/components/indicator/implication'
import { ProfileTeaser } from '@/components/profile-teaser'
import { Table, TableProps } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'

export type FindingTableProps = {
  dataSource: Finding[]
  onClick?: (id: string) => void
} & Pick<TableProps<Finding>, 'loading'>

export const FindingTable = ({ dataSource, onClick, ...props }: FindingTableProps) => {
  const { isEnabled: assigneeEnabled } = useFeatureFlagEnabled('finding-assignee')
  const { isEnabled: commentsEnabled } = useFeatureFlagEnabled('riskfactor-comments')
  const columns: ColumnType<Finding>[] = [
    {
      title: 'Finding',
      width: '30%',
      dataIndex: 'title',
      render: (title: string) => <div className='truncate'>{title}</div>,
      search: true,
    },
    {
      title: 'Severity',
      width: '10%',
      dataIndex: 'riskLevel',
      filter: true,
      defaultSortOrder: 'ascend',
      render: (riskLevel: RiskLevel) => <FindingSeverityBadge level={riskLevel} />,
      accessor: ({ riskLevel }: Finding) => ({
        filterValue: riskLevelLabel[riskLevel] || undefined,
        sortValue: riskLevelPriority[riskLevel],
      }),
    },
    {
      title: 'Implication',
      width: '12%',
      render: ({ implications }: Finding) => (
        <ImplicationIndicatorList activeImplications={implications} />
      ),
    },
    {
      title: 'Status',
      width: '10%',
      dataIndex: 'status',
      filter: true,
      accessor: ({ status }: Finding) => ({
        filterValue: findingStatusLabel[status],
        sortValue: findingStatusPriority[status],
      }),
      defaultSelectedFilter: [
        findingStatusLabel[FindingStatus.OPEN],
        findingStatusLabel[FindingStatus.ONGOING],
      ],
      render: (status: FindingStatus) => <FindingStatusBadge status={status} />,
    },
    {
      hidden: !assigneeEnabled,
      title: 'Assignee',
      width: '9%',
      ellipsis: true,
      render: (row: Finding) => <ProfileTeaser username={row.assignee} />,
    },
    {
      title: 'Date Detected',
      width: '10%',
      ellipsis: true,
      dataIndex: 'detectionTime',
      render: (time: Finding['detectionTime']) => (
        <>
          {time && (
            <div className='whitespace-nowrap text-gray-400'>{formatDate(time.toDate())}</div>
          )}
        </>
      ),
      accessor: ({ detectionTime }) => ({
        sortValue: detectionTime && detectionTime.toDate(),
      }),
    },
    {
      title: 'Comments',
      width: '9%',
      ellipsis: true,
      dataIndex: 'comments',
      hidden: !commentsEnabled,
      render: (comments: number) => (
        <div className='flex w-10 items-center gap-1'>
          <MessageCircleIcon strokeWidth={1} className='size-4' />
          <span className='text-gray-700'>{comments}</span>
        </div>
      ),
    },
  ]

  const onRow = ({ id }: Finding) => ({
    onClick: () => onClick && onClick(id),
  })

  return (
    <Table
      {...props}
      narrowFilterBar
      tableLayout='fixed'
      scroll={{ y: 300 }}
      columns={columns}
      onRow={onRow}
      dataSource={dataSource}
    />
  )
}
