import { useQuery } from '@tanstack/react-query'

import { getFindingTimeline } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

export const useGetFindingTimeline = (findingId?: string) => {
  const queryState = useQuery(getFindingTimeline.useQuery({ findingId }))
  return {
    ...queryState,
    data: queryState.data?.items || [],
  }
}
