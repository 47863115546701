import { Empty } from 'antd'
import { Link } from 'react-router-dom'
import { VictoryArea, VictoryAxis, VictoryChart } from 'victory'

import { DataTypeInfo, User } from '@/gen/inventory/v1/company_service_pb'

import { UsersTable } from '@/pages/company-drawer/users-table'

import { PieChart } from '@/components/charts/pie-chart'
import { customVictoryTheme } from '@/components/charts/theme'
import { DataTypes } from '@/components/data-type/data-type'
import { DataTypeExplanation } from '@/components/data-type/data-type-explanation'
import { useDatatypeClickCallback } from '@/components/data-type/datatype.hook'
import { TextWithIcon } from '@/components/text-with-icon'

type ThirdPartyUsageProps = {
  titleSuffix?: string
  numUsers?: number
  numUsersByOrganizationUnit?: Record<string, number>
  dataTypes?: DataTypeInfo[]
  users?: User[]
}

export const ThirdPartyUsage = ({
  titleSuffix,
  numUsers,
  numUsersByOrganizationUnit,
  dataTypes,
  users,
}: ThirdPartyUsageProps) => {
  const { action } = useDatatypeClickCallback()

  const usersOverTime: Array<number> = []

  return (
    <div>
      <h1 className='mb-6 text-xl font-bold text-gray-700'>Your Usage {titleSuffix}</h1>
      <div className='flex justify-between gap-3'>
        <div className='w-1/3 border p-6 px-8'>
          <h1 className='text-center font-semibold text-gray-700'>By Line of Business</h1>
          {numUsersByOrganizationUnit && Object.keys(numUsersByOrganizationUnit).length ? (
            <PieChart
              data={Object.entries(numUsersByOrganizationUnit || {}).map(([x, y]) => ({ x, y }))}
            />
          ) : (
            <Empty
              data-testid='usage-lob-empty'
              className='mx-auto my-4'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
        <div className='flex w-1/3 flex-col gap-3 border pt-6'>
          <h2 className='whitespace-nowrap text-center font-semibold text-gray-700'>Total Users</h2>
          {numUsers ? (
            <h3 className='text-center text-6xl font-semibold text-gray-700'>{numUsers}</h3>
          ) : (
            <Empty
              data-testid='usage-users-empty'
              className='mx-auto my-4'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          <div>
            <VictoryChart height={90} padding={{ bottom: 0 }} theme={customVictoryTheme}>
              <VictoryArea
                interpolation='natural'
                animate
                data={usersOverTime.map((users, index) => ({ x: index, y: users }))}
              />
              <VictoryAxis dependentAxis />
            </VictoryChart>
          </div>
        </div>
        <div className='flex w-1/3 flex-col gap-4 border p-6'>
          <h1 className='font-semibold text-gray-700'>
            <TextWithIcon
              className='mx-auto w-fit'
              text='Datatypes'
              iconPosition='end'
              icon={<DataTypeExplanation />}
            />
          </h1>
          <div className='flex size-full flex-wrap items-start gap-1'>
            {dataTypes && dataTypes.length ? (
              <DataTypes
                dataTypes={
                  dataTypes.map((dataType) => ({
                    dataType,
                    action,
                  })) || []
                }
              />
            ) : (
              <Empty
                data-testid='usage-datatypes-empty'
                className='mx-auto my-4'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </div>
          <span className='font-light text-gray-400'>
            <Link
              to='/integrations/marketplace'
              className='font-bold text-purple-400 hover:text-purple-600'
            >
              Click here
            </Link>{' '}
            to add more integrations
          </span>
        </div>
      </div>
      {users && users.length ? (
        <div className='mt-6'>
          <h1 className='mb-6 text-xl font-bold text-gray-700'>Users</h1>
          <UsersTable dataSource={users} />
        </div>
      ) : null}
    </div>
  )
}
