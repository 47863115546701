import { cva } from 'class-variance-authority'
import { ArrowDown, ArrowUp, Minus, Plus } from 'lucide-react'

import { cn } from '@/lib/style-helpers'

import { Badge } from '@/components/ui/badge'

import { Sentiment } from './types'

type size = 'small' | 'medium' | 'large'

const badgeVariants = cva('bg-transparent text-lg font-normal hover:bg-transparent', {
  variants: {
    variant: {
      naked: 'flex justify-end border-none p-0',
      badge: '',
    },
    sentiment: {
      [Sentiment.NEGATIVE]: 'border-red-500 text-red-500',
      [Sentiment.POSITIVE]: 'border-green-500 text-green-500',
      [Sentiment.NEUTRAL]: 'text-gray-700',
    },
    size: {
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg',
    },
  },
})

const sizeToIconSize: Record<size, number> = {
  small: 12,
  medium: 16,
  large: 20,
}

type VariancePercentageProps = {
  value: number
  variant?: 'naked' | 'badge'
  iconType?: 'arrow' | 'plusMinus'
  size?: size
  /*
   * The `growthSentiment` prop allows customization of the color based on the sentiment associated with the value,
   * for example -5% will normally have negative sentiment while 0% is neutral,
   * but sometimes that opposite is true and a decrease of -%5 has in our eyes a good sentiment.
   * `growthSentiment` allows the caller to override the default behavior.
   */
  growthSentiment?: Sentiment
} & React.HTMLAttributes<HTMLDivElement>

/**
 *`VariancePercentage` can be used to display a percentage change.
 * It shows the percentage change along with an icon in accordance with the percentage change.
 * it colored in accordance with the sentiment.
 */
export const VariancePercentage = ({
  value,
  growthSentiment = Sentiment.POSITIVE,
  iconType = 'arrow',
  variant = 'naked',
  size = 'medium',
  ...props
}: VariancePercentageProps) => {
  const getSentiment = () => {
    if (growthSentiment == Sentiment.POSITIVE) {
      return Math.sign(value) as Sentiment
    } else if (growthSentiment == Sentiment.NEGATIVE) {
      return -Math.sign(value) as Sentiment
    }
    return Sentiment.NEUTRAL
  }

  // avoid showing percentage in cases where it is infinity or not a number.
  const percentage = formatAbsolutePercentage(value)
  if (percentage === undefined) {
    return null
  }

  return (
    <Badge
      {...props}
      className={cn(badgeVariants({ variant, sentiment: getSentiment(), size }), props.className)}
    >
      {value !== 0 && (
        <SentimentIcon iconType={iconType} direction={value > 0 ? 'up' : 'down'} size={size} />
      )}
      {percentage}
    </Badge>
  )
}

type SentimentIconProps = {
  direction: 'up' | 'down'
} & Pick<VariancePercentageProps, 'iconType' | 'size'>

const SentimentIcon = ({ direction, iconType, size = 'medium' }: SentimentIconProps) => {
  if (iconType == 'arrow' && direction == 'up') {
    return <ArrowUp size={sizeToIconSize[size]} />
  } else if (iconType == 'arrow' && direction == 'down') {
    return <ArrowDown size={sizeToIconSize[size]} />
  } else if (iconType == 'plusMinus' && direction == 'up') {
    return <Plus size={sizeToIconSize[size]} />
  } else if (iconType == 'plusMinus' && direction == 'down') {
    return <Minus size={sizeToIconSize[size]} />
  }
  return null
}

const formatAbsolutePercentage = (value: number) => {
  if (!Number.isFinite(value) || isNaN(value) || value === null || value === undefined) {
    return undefined
  }
  return `${Math.abs(value)}%`
}
