import { useQuery } from '@tanstack/react-query'

import { listSlackChannels } from '@/gen/notifications/v1/notifications_service-NotificationsService_connectquery'

export const useListSlackChannels = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const queryState = useQuery(listSlackChannels.useQuery({ enabled }))
  return {
    ...queryState,
    data: queryState.data?.channels || [],
  }
}
