import { PlainMessage } from '@bufbuild/protobuf'
import { BarChart4Icon, FlagIcon, LinkIcon, UsersIcon } from 'lucide-react'

import {
  CompanyProfile as ProtoCompanyProfile,
  PersonProfile as ProtoPersonProfile,
} from '@/gen/inventory/v1/company_service_pb'

import { cn } from '@/lib/style-helpers'

import { PersonResumeCard } from '@/pages/company-drawer/person-resume-card'

import { Badge } from '@/components/ui/badge'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'

export type CompanyProfileProps = {
  info: PlainMessage<ProtoCompanyProfile>
  ciso?: PlainMessage<ProtoPersonProfile>
}

export const CompanyProfile = ({ info, ciso }: CompanyProfileProps) => {
  return (
    <div>
      <h3 className='py-4 text-xl'>Company Profile</h3>
      <div className='flex max-w-4xl flex-wrap'>
        <CompanyStatisticItem
          title='Founded'
          icon={<FlagIcon size={10} />}
          disabled={!info.foundingYear}
        >
          {info.foundingYear}
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Size'
          icon={<UsersIcon size={10} />}
          disabled={!info.companySize}
        >
          {info.companySize}
        </CompanyStatisticItem>
        <CompanyStatisticItem title='Industry' disabled={!info.industrySector}>
          <span className='capitalize'>{info.industrySector}</span>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Website'
          icon={<LinkIcon size={10} />}
          disabled={!info.website}
        >
          <Link href={`https://${info.website}`} ariaLabel="Company's website">
            {info.website}
          </Link>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Linkedin'
          icon={<LinkIcon size={10} />}
          disabled={!info.linkedinUrl}
        >
          <Link href={`https://${info.linkedinUrl}`} ariaLabel="Company's Linkedin">
            profile
          </Link>
        </CompanyStatisticItem>
        <CompanyStatisticItem
          title='Stocks'
          icon={<BarChart4Icon size={10} />}
          disabled={!info.ticker}
        >
          <Link
            href={`https://finance.yahoo.com/quote/${info.ticker}`}
            ariaLabel="Company's stock ticker"
          >
            {info.ticker}
          </Link>
        </CompanyStatisticItem>

        <CompanyStatisticItem title='CISO' disabled={!ciso}>
          <HoverCard openDelay={300}>
            <HoverCardTrigger className='truncate capitalize'>{ciso?.name}</HoverCardTrigger>
            <HoverCardContent className='min-w-96'>
              {ciso && <PersonResumeCard person={ciso} workExperiencesLimit={4} />}
            </HoverCardContent>
          </HoverCard>
        </CompanyStatisticItem>
      </div>
      <div className='mb-8 mt-4 max-w-4xl'>{info.paragraphDescription}</div>
      {info.tags && info.tags.length > 0 && (
        <div className='my-4 max-w-3xl'>
          <h4 className='mb-2 mt-4 text-xs font-bold uppercase tracking-wide'>Tags</h4>
          <div className='flex flex-wrap gap-1'>
            {info.tags.map((tag) => (
              <Badge key={tag} variant={'outline'} className='text-sm capitalize'>
                {tag}
              </Badge>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const Link = ({
  href,
  children,
  ariaLabel,
}: {
  ariaLabel: string
  href: string
  children: React.ReactNode
}) => (
  <a
    href={href}
    target='_blank'
    aria-label={ariaLabel}
    className='overflow-hidden text-ellipsis underline hover:underline'
    rel='noreferrer'
  >
    {children}
  </a>
)

const CompanyStatisticItem = ({
  title,
  icon,
  children,
  disabled,
}: {
  title: string
  icon?: React.ReactNode
  children?: React.ReactNode
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(
        'mr-2 flex w-24 flex-col items-start gap-1 rounded-sm border border-gray-200 p-2 last:mr-0',
        disabled && 'select-none opacity-60',
      )}
    >
      <div className='flex flex-row items-center'>
        {icon && <span className='mr-1'>{icon}</span>}
        <h3 className='text-xs font-bold uppercase tracking-wide'>{title}</h3>
      </div>
      <div className='inline-flex w-20 overflow-hidden'>
        {!disabled && children}
        {disabled && <>N/A</>}
      </div>
    </div>
  )
}
