import _ from 'lodash'
import GaugeComponent from 'react-gauge-component'
import { useNavigate } from 'react-router-dom'

import { useDaysCompaniesInAssessmentRequired } from '@/api/statistics.hook'

import { colors } from '@/const/colors'

import { UrlParam } from '@/lib/url-param.hook'

import { Loading } from '@/components/loading'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

const threshHold = 30

export const TimeCompanyInAssessmentRequired = () => {
  const { data, isLoading } = useDaysCompaniesInAssessmentRequired()
  const navigate = useNavigate()
  const max = data?.days ? Math.max(...data.days) : 0
  const avg = Math.round(_.mean(data?.days) || 0)
  const aboveThreshold = data?.days.filter((day) => day > threshHold).length || 0

  if (isLoading) return <Loading />

  return (
    <Card className='w-full'>
      <CardHeader className='mx-9 my-6'>
        <CardTitle>{'Time Third-Parties Stay in ‘Assessment Required’'}</CardTitle>
        <CardDescription>
          {'Includes ‘Assessment Required’ and ‘Re-Assessment Required’'}
        </CardDescription>
      </CardHeader>
      <CardContent
        className='cursor-pointer'
        onClick={() => {
          navigate(`/inventory?${UrlParam.VIEW}=Assessment Required`)
        }}
      >
        <div className='text-center text-gray-400'>{avg} days average</div>
        <div className='mx-auto max-w-112'>
          <GaugeComponent
            type='semicircle'
            value={avg}
            labels={{
              valueLabel: {
                hide: true,
              },
            }}
            pointer={{
              color: colors.gray[500],
              length: 0.7,
              width: 13,
            }}
            arc={{
              width: 0.4,
              padding: 0.005,
              cornerRadius: 1,
              subArcs: [
                {
                  limit: threshHold,
                  color: colors.green[500],
                  showTick: true,
                },
                {
                  limit: max < threshHold ? undefined : max,
                  color: colors.red[500],
                },
              ],
            }}
            maxValue={max < threshHold ? threshHold : max}
            minValue={0}
          />
        </div>
      </CardContent>
      <CardFooter className='flex-col gap-2 text-sm'>
        <div className='text-base text-gray-400'>Threshold: {threshHold} days</div>
        <div className='text-base'>
          {aboveThreshold} out of {data?.days.length} third-parties overdue (max {max} days)
        </div>
      </CardFooter>
    </Card>
  )
}
