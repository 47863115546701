import { ParticipantRole, QuestionType } from '@/lib/liveblocks.config'

import { CheckboxQuestion } from './checkbox'
import { DateQuestion } from './date'
import { FileQuestion } from './file'
import { FreeTextQuestion } from './free-text'
import { RadioQuestion } from './radio'

export type BaseQuestionProps = {
  question: QuestionType
  section: string
  participantRole: ParticipantRole
}

export const Question = ({ question, ...props }: BaseQuestionProps) => {
  switch (question.type) {
    case 'checkbox':
      return <CheckboxQuestion question={question} {...props} />
    case 'radio':
      return <RadioQuestion question={question} {...props} />
    case 'free-text':
      return <FreeTextQuestion question={question} {...props} />
    case 'date':
      return <DateQuestion question={question} {...props} />
    case 'file':
      return <FileQuestion question={question} {...props} />
    default:
      return null
  }
}
