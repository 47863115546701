import { PencilIcon, PlusIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

import { useOutsideClick } from '@/lib/outside-click.hook'
import { cn } from '@/lib/style-helpers'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Tooltip } from '@/components/ui/tooltip'

import '@liveblocks/react-comments/styles.css'

type EditableTextProps = {
  text: string
  onSave: (newText: string) => Promise<void>
  placeholder?: string
} & React.HTMLAttributes<HTMLDivElement>

export const EditableText = ({
  text,
  onSave,
  className,
  placeholder,
  ...props
}: EditableTextProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [newText, setNewText] = useState(text)

  useEffect(() => {
    setNewText(text)
  }, [text])

  const handleSave = async () => {
    if (newText === text) {
      setIsEditing(false)
      return
    }
    try {
      await onSave(newText)
    } finally {
      setIsEditing(false)
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, handleSave)

  return (
    <div className={cn(className, 'flex items-center')}>
      {isEditing ? (
        <div ref={ref} className='flex w-full items-center gap-2 text-sm'>
          <Input
            {...props}
            autoFocus
            placeholder={placeholder}
            className={className}
            value={newText}
            onChange={({ target }) => setNewText(target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSave()
              }
            }}
          />
          <Button size={'sm'} onClick={handleSave} variant={'outline'}>
            Save
          </Button>
          <Button size={'sm'} onClick={() => setIsEditing(false)} variant={'ghost'}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className={cn('flex items-center gap-2', className)}>
          <h3 {...props}>{text || placeholder}</h3>
          <Tooltip
            className='font-normal'
            trigger={
              <div onClick={() => setIsEditing(true)}>
                {text ? (
                  <PencilIcon className='min-w-5 cursor-pointer text-gray-300' size={14} />
                ) : (
                  <TextWithIcon text='add' icon={<PlusIcon size={14} />} />
                )}
              </div>
            }
          >
            {text ? 'Edit' : 'Add'}
          </Tooltip>
        </div>
      )}
    </div>
  )
}
