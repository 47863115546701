import {
  Avatar as ShadcnAvatar,
  AvatarFallback as ShadcnAvatarFallback,
} from '@/components/ui/avatar'
import { Tooltip } from '@/components/ui/tooltip'

export type ProfileTeaserProps = {
  username: string
  email?: string
}

/**
 * ProfileTeaser represents a user in the system and allows to preview extra infromation on hover.
 */
export const ProfileTeaser = ({ username, email }: ProfileTeaserProps) => (
  <Tooltip
    trigger={
      <ShadcnAvatar className='size-6 rounded-none border border-gray-200'>
        <ShadcnAvatarFallback className='m-auto text-center text-md'>
          {username[0] || ''}
        </ShadcnAvatarFallback>
      </ShadcnAvatar>
    }
  >
    {email || username}
  </Tooltip>
)
