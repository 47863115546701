import { Position, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'

export type KeywordsNodeProps = BaseNodeProps & {
  data: {
    keywords: string[]
  }
  id: string
}

export const KeywordsNode: React.FC<KeywordsNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Keywords Node'
      inputHandles={[
        {
          id: `keywords-${id}-input`,
          position: Position.Left,
          type: 'target',
          isConnectable: true,
        },
      ]}
      dataInputs={[
        {
          id: `keywords-${id}-keywords`,
          name: 'keywords',
          type: 'tags',
          onChange: (value: string | string[]) => updateNodeData(id, { keywords: value }),
          value: data.keywords,
          placeholder: 'Enter keywords',
          mode: 'tags',
        },
      ]}
      outputHandles={[
        {
          id: `keywords-${id}-matched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Matched',
          borderColor: 'green',
        },
        {
          id: `keywords-${id}-unmatched`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Unmatched',
          borderColor: 'red',
        },
      ]}
    />
  )
})

KeywordsNode.displayName = 'KeywordsNode'
