import { useQuery } from '@tanstack/react-query'

import { autocompleteCompany } from '@/gen/companycatalog/v1/company_catalog-CompanyManagementService_connectquery'

export const useAutocompleteCompany = (companyName: string) => {
  return useQuery({
    ...autocompleteCompany.useQuery({ search: companyName }),
    enabled: !!companyName,
    retry: false,
  })
}
