import { useState } from 'react'
import ReactCountryFlag, { ReactCountryFlagProps } from 'react-country-flag'

type CountryFlagIconProps = ReactCountryFlagProps

export const CountryFlagIcon = ({ countryCode, ...props }: CountryFlagIconProps) => {
  const [hasError, setHasError] = useState(false)

  const handleError = () => {
    setHasError(true)
  }

  if (hasError) {
    return null
  }

  return <ReactCountryFlag countryCode={countryCode} svg {...props} onError={handleError} />
}
