// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/model.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum questionnaire.v1.QuestionnaireStatus
 */
export enum QuestionnaireStatus {
  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_ONGOING = 1;
   */
  ONGOING = 1,

  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_COMPLETED = 2;
   */
  COMPLETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionnaireStatus)
proto3.util.setEnumType(QuestionnaireStatus, "questionnaire.v1.QuestionnaireStatus", [
  { no: 0, name: "QUESTIONNAIRE_STATUS_UNSPECIFIED" },
  { no: 1, name: "QUESTIONNAIRE_STATUS_ONGOING" },
  { no: 2, name: "QUESTIONNAIRE_STATUS_COMPLETED" },
]);

/**
 * @generated from message questionnaire.v1.QuestionnaireLink
 */
export class QuestionnaireLink extends Message<QuestionnaireLink> {
  /**
   * @generated from field: string recipient = 1;
   */
  recipient = "";

  /**
   * @generated from field: string magic_link = 2;
   */
  magicLink = "";

  constructor(data?: PartialMessage<QuestionnaireLink>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireLink";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipient", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireLink | PlainMessage<QuestionnaireLink> | undefined, b: QuestionnaireLink | PlainMessage<QuestionnaireLink> | undefined): boolean {
    return proto3.util.equals(QuestionnaireLink, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.Questionnaire
 */
export class Questionnaire extends Message<Questionnaire> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp due_date = 4;
   */
  dueDate?: Timestamp;

  /**
   * @generated from field: string room_id = 5;
   */
  roomId = "";

  /**
   * list of recipient user names which the questionnaire was sent to
   *
   * @generated from field: repeated string recipients = 6;
   */
  recipients: string[] = [];

  /**
   * @generated from field: questionnaire.v1.QuestionnaireStatus status = 7;
   */
  status = QuestionnaireStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<Questionnaire>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.Questionnaire";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "due_date", kind: "message", T: Timestamp },
    { no: 5, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(QuestionnaireStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Questionnaire {
    return new Questionnaire().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Questionnaire {
    return new Questionnaire().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Questionnaire {
    return new Questionnaire().fromJsonString(jsonString, options);
  }

  static equals(a: Questionnaire | PlainMessage<Questionnaire> | undefined, b: Questionnaire | PlainMessage<Questionnaire> | undefined): boolean {
    return proto3.util.equals(Questionnaire, a, b);
  }
}

