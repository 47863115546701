import { useSession } from '@descope/react-sdk'
import Knock from '@knocklabs/client'
import { useKnockClient } from '@knocklabs/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const buildMode = import.meta.env.MODE

export type TokenResponse = {
  token: string // token to be passed to slack oauth api, which we will validate when slack redirects back to us
  knockToken: string // used to authenticate the user against the knock api
}

const QueryKeyGenerateToken = 'generateToken'
const QueryKeyAuthTest = 'authTest'

type SlackError = string
const ErrorNotAuthed: SlackError = 'not_authed'

const fetchToken = async (
  addAuthorizationHeader: boolean,
  redirectUrl: string,
  sessionToken?: string,
): Promise<TokenResponse> => {
  const { data } = await axios.get(`/api/notifications.v1/slack/generate-state`, {
    params: { redirectUrl },
    headers: addAuthorizationHeader ? { Authorization: `Bearer ${sessionToken}` } : {},
  })
  return data
}

export const useUserSlackStateToken = (redirectUrl: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession()
  const addAuthorizationHeader = buildMode !== 'production' && buildMode !== 'production_us'
  const enabled =
    (addAuthorizationHeader && isAuthenticated && !isSessionLoading) || !addAuthorizationHeader

  return useQuery(
    [QueryKeyGenerateToken, redirectUrl],
    () =>
      fetchToken(
        buildMode !== 'production' && buildMode !== 'production_us',
        redirectUrl,
        sessionToken,
      ),
    {
      enabled,
    },
  )
}

type ChannelDataResponse = {
  channel_id: string
  data: {
    connections: Array<{ access_token: string; user_id?: string }>
  }
}
const getChannelAuthToken = (channelDataResponse: ChannelDataResponse) => {
  if (
    !channelDataResponse.data ||
    !channelDataResponse.data.connections ||
    channelDataResponse.data.connections.length === 0
  ) {
    return ''
  }

  const connection = channelDataResponse.data.connections[0]
  if (!connection.access_token) {
    console.warn('Knock-channel-data[0] has no access token', connection)
    return ''
  }

  return connection.access_token
}

type SlackAuthTestResponse = {
  url: string
  team: string
  user: string
  teamID: string
  userID: string
  enterpriseID?: string
  botID: string
  ok: boolean
}

type AuthTestResponse = (SlackAuthTestResponse & { connected: boolean }) | { connected: false }

const authTest = async (knockChannelId: string, knockClient: Knock): Promise<AuthTestResponse> => {
  const channelDataResponse: ChannelDataResponse | undefined = await knockClient.user
    .getChannelData({
      channelId: knockChannelId,
    })
    .catch((e) => {
      // 404 is expected when user never connected to slack
      if ((e as Error)?.message !== 'AxiosError: Request failed with status code 404') {
        console.error('Failed to get channel data', e, 'msg', (e as Error)?.message)
      }
      return undefined
    })
    .then((result) => {
      return result as ChannelDataResponse
    })

  if (!channelDataResponse) {
    return { connected: false }
  }

  const token = getChannelAuthToken(channelDataResponse as ChannelDataResponse)
  if (token.length === 0) {
    return { connected: false }
  }

  const body = `token=${token}`
  const { data } = await axios.post(`https://slack.com/api/auth.test`, body, {
    // this is required to prevent axios from setting the content type to application/json
    // and avoid the browser from sending a preflight request
    // (token parameter is no longer allowed in query of a GET request so we must use POST)
    // https://stackoverflow.com/questions/68349334/slack-api-cors-error-with-axios-in-vue-js
    transformRequest(data, headers) {
      delete headers['Content-Type']
      return data
    },
  })

  return {
    ...data,
    connected: data.ok,
  }
}

export const useUserSlackAuthTest = (knockChannelId: string) => {
  const knockClient = useKnockClient()
  return useQuery(
    [QueryKeyAuthTest, knockChannelId],
    async () => await authTest(knockChannelId, knockClient),
    { retry: 1 },
  )
}

const revokeToken = async (knockChannelId: string, knockClient: Knock) => {
  const channelDataResponse = await knockClient.user.getChannelData({
    channelId: knockChannelId,
  })

  const token = await getChannelAuthToken(channelDataResponse as ChannelDataResponse)
  if (token.length === 0) {
    return { ok: false, error: ErrorNotAuthed }
  }

  const body = `token=${token}`
  const { data: slackRevokeResponse } = await axios.post(
    `https://slack.com/api/auth.revoke`,
    body,
    {
      transformRequest(data, headers) {
        delete headers['Content-Type']
        return data
      },
    },
  )
  if (!slackRevokeResponse.ok) {
    console.error('Failed to revoke token', slackRevokeResponse)
    throw new Error('Failed to revoke token')
  }

  await knockClient.user.setChannelData({
    channelId: knockChannelId,
    channelData: {
      ...channelDataResponse.data,
      connections: [
        ...channelDataResponse.data.connections.filter((connection: { access_token?: string }) => {
          if (connection.access_token === token) {
            return false
          }
          return true
        }),
      ],
    },
  })

  return slackRevokeResponse
}

export const useRevokeSlackToken = (knockChannelId: string) => {
  const knockClient = useKnockClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => await revokeToken(knockChannelId, knockClient),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeyAuthTest] })
    },
  })
}
