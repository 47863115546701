import { AwardIcon, BombIcon, PercentIcon, RefreshCwIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { Implication } from '@/gen/inventory/v1/implication_pb'

interface ImplicationIconProps {
  implication: Implication
}

export const ImplicationIcon = ({ implication }: ImplicationIconProps) =>
  implicationIcon[implication]

const implicationIcon: Record<Implication, ReactNode> = {
  [Implication.BREACH_IMPACT]: <BombIcon strokeWidth={1.5} className='size-4' />,
  [Implication.BREACH_LIKELIHOOD]: <PercentIcon strokeWidth={1.5} className='size-4' />,
  [Implication.COMPLIANCE]: <AwardIcon strokeWidth={1.5} className='size-4' />,
  [Implication.BUSINESS_CONTINUITY]: <RefreshCwIcon strokeWidth={1.5} className='size-4' />,
  [Implication.UNSPECIFIED]: null,
}
