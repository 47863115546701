import { Code, ConnectError } from '@connectrpc/connect'
import { LightbulbIcon } from 'lucide-react'
import { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'

import { useUpdateTemplateQuestion } from '@/api/questionnaire-template.hook'
import { QuestionType } from '@/gen/questionnaire/v1/template_service_pb'

import { cn } from '@/lib/style-helpers'

import { EditableText } from '@/components/editable-text'
import { OptimisticSwitch } from '@/components/optimistic-backend-toggler'
import { Alert, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

import { BaseEditableQuestionProps } from './index'

import '@liveblocks/react-comments/styles.css'

export type QuestionContainerProps = BaseEditableQuestionProps & PropsWithChildren

export const QuestionContainer = ({
  question,
  children,
  leadingTogglerEnabled,
}: QuestionContainerProps) => {
  const { toast } = useToast()
  const { templateId } = useParams<{ templateId: string }>()
  const { mutateAsync, isError } = useUpdateTemplateQuestion()

  const isConditionalSupported =
    question.type === QuestionType.CHECKBOX || question.type === QuestionType.RADIO

  return (
    <div className='flex gap-4'>
      <div className='w-3/5 max-w-2xl rounded border p-6'>
        <EditableText
          className='min-h-10 py-0 text-lg font-semibold'
          text={question.question}
          onSave={async (input) => {
            if (!input) {
              toast({ status: 'error', title: "Question can't be left empty" })
              return
            }
            try {
              await mutateAsync({
                questionId: question.id,
                templateId,
                question: input,
              })
            } catch (err) {
              toast({
                status: 'error',
                title: "Couldn't update question",
              })
            }
          }}
        />
        <div className='flex w-full flex-col items-stretch'>
          <Tooltip
            trigger={
              <Alert
                variant={question.note ? 'note' : 'default'}
                className='my-3 mb-4 min-h-11 py-0 pl-3'
              >
                <AlertTitle className='mb-0 flex w-full items-center gap-2 text-base'>
                  {question.note && <LightbulbIcon size={14} />}
                  <EditableText
                    placeholder='Optional: provide clarification on the question'
                    className={cn(
                      'min-h-10 w-full border-none bg-transparent pl-0 text-base tracking-normal text-gray-700 outline-0 outline-transparent',
                      {
                        'text-gray-400': !question.note,
                      },
                    )}
                    text={question.note}
                    onSave={async (input) => {
                      try {
                        await mutateAsync({
                          questionId: question.id,
                          templateId,
                          note: input,
                        })
                      } catch (err) {
                        toast({
                          status: 'error',
                          title: "Couldn't update note",
                        })
                      }
                    }}
                  />
                  {question.note && (
                    <Button
                      onClick={async () => {
                        try {
                          await mutateAsync({
                            questionId: question.id,
                            templateId,
                            note: '',
                          })
                        } catch (err) {
                          toast({
                            status: 'error',
                            title: "Couldn't update note",
                          })
                        }
                      }}
                      variant={'ghost'}
                      className='h-8 hover:bg-yellow-50 hover:text-yellow-600'
                    >
                      Clear
                    </Button>
                  )}
                </AlertTitle>
              </Alert>
            }
          >
            Recipients will see any clarification you leave here as a hint.
          </Tooltip>
        </div>

        {children}
      </div>
      {leadingTogglerEnabled && isConditionalSupported && (
        <div className='flex gap-3'>
          <OptimisticSwitch
            variant='primary'
            isError={isError}
            isActive={question.leadingQuestion}
            setIsActive={async () => {
              try {
                await mutateAsync({
                  questionId: question.id,
                  templateId,
                  leadingQuestion: !question.leadingQuestion,
                })
              } catch (err) {
                if (err instanceof ConnectError) {
                  const message =
                    err.code === Code.InvalidArgument
                      ? err.rawMessage
                      : "Couldn't update leading question"

                  toast({
                    status: 'error',
                    title: message,
                  })
                }
              }
            }}
          />
          <div className='max-w-56 text-start'>
            <h3 className='mb-2.5 leading-none'>Make section conditional</h3>
            <span className='text-sm font-light text-gray-400'>
              If this section is conditional, the rest of the questions in this sub-section are
              disabled unless the recipient chooses a flagged answer for the first question.
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
