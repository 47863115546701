// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/assessor_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Questionnaire, QuestionnaireLink } from "./model_pb.js";

/**
 * @generated from message questionnaire.v1.GetTokenRequest
 */
export class GetTokenRequest extends Message<GetTokenRequest> {
  constructor(data?: PartialMessage<GetTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined, b: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetTokenRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetTokenResponse
 */
export class GetTokenResponse extends Message<GetTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined, b: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetTokenResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetQuestionnaireRequest
 */
export class GetQuestionnaireRequest extends Message<GetQuestionnaireRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetQuestionnaireRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetQuestionnaireRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuestionnaireRequest {
    return new GetQuestionnaireRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuestionnaireRequest {
    return new GetQuestionnaireRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuestionnaireRequest {
    return new GetQuestionnaireRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuestionnaireRequest | PlainMessage<GetQuestionnaireRequest> | undefined, b: GetQuestionnaireRequest | PlainMessage<GetQuestionnaireRequest> | undefined): boolean {
    return proto3.util.equals(GetQuestionnaireRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetQuestionnaireResponse
 */
export class GetQuestionnaireResponse extends Message<GetQuestionnaireResponse> {
  /**
   * @generated from field: questionnaire.v1.Questionnaire questionnaire = 1;
   */
  questionnaire?: Questionnaire;

  constructor(data?: PartialMessage<GetQuestionnaireResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetQuestionnaireResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaire", kind: "message", T: Questionnaire },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuestionnaireResponse {
    return new GetQuestionnaireResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuestionnaireResponse {
    return new GetQuestionnaireResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuestionnaireResponse {
    return new GetQuestionnaireResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuestionnaireResponse | PlainMessage<GetQuestionnaireResponse> | undefined, b: GetQuestionnaireResponse | PlainMessage<GetQuestionnaireResponse> | undefined): boolean {
    return proto3.util.equals(GetQuestionnaireResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListQuestionnaireRequest
 */
export class ListQuestionnaireRequest extends Message<ListQuestionnaireRequest> {
  constructor(data?: PartialMessage<ListQuestionnaireRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListQuestionnaireRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListQuestionnaireRequest {
    return new ListQuestionnaireRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListQuestionnaireRequest {
    return new ListQuestionnaireRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListQuestionnaireRequest {
    return new ListQuestionnaireRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListQuestionnaireRequest | PlainMessage<ListQuestionnaireRequest> | undefined, b: ListQuestionnaireRequest | PlainMessage<ListQuestionnaireRequest> | undefined): boolean {
    return proto3.util.equals(ListQuestionnaireRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListQuestionnaireResponse
 */
export class ListQuestionnaireResponse extends Message<ListQuestionnaireResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.Questionnaire questionnaires = 1;
   */
  questionnaires: Questionnaire[] = [];

  constructor(data?: PartialMessage<ListQuestionnaireResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListQuestionnaireResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaires", kind: "message", T: Questionnaire, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListQuestionnaireResponse {
    return new ListQuestionnaireResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListQuestionnaireResponse {
    return new ListQuestionnaireResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListQuestionnaireResponse {
    return new ListQuestionnaireResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListQuestionnaireResponse | PlainMessage<ListQuestionnaireResponse> | undefined, b: ListQuestionnaireResponse | PlainMessage<ListQuestionnaireResponse> | undefined): boolean {
    return proto3.util.equals(ListQuestionnaireResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.AssessorInviteRecipientRequest
 */
export class AssessorInviteRecipientRequest extends Message<AssessorInviteRecipientRequest> {
  /**
   * @generated from field: string questionnaire_id = 1;
   */
  questionnaireId = "";

  /**
   * @generated from field: repeated string recipients = 2;
   */
  recipients: string[] = [];

  constructor(data?: PartialMessage<AssessorInviteRecipientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.AssessorInviteRecipientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaire_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessorInviteRecipientRequest {
    return new AssessorInviteRecipientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessorInviteRecipientRequest {
    return new AssessorInviteRecipientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessorInviteRecipientRequest {
    return new AssessorInviteRecipientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessorInviteRecipientRequest | PlainMessage<AssessorInviteRecipientRequest> | undefined, b: AssessorInviteRecipientRequest | PlainMessage<AssessorInviteRecipientRequest> | undefined): boolean {
    return proto3.util.equals(AssessorInviteRecipientRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.AssessorInviteRecipientResponse
 */
export class AssessorInviteRecipientResponse extends Message<AssessorInviteRecipientResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireLink links = 2;
   */
  links: QuestionnaireLink[] = [];

  constructor(data?: PartialMessage<AssessorInviteRecipientResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.AssessorInviteRecipientResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "links", kind: "message", T: QuestionnaireLink, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessorInviteRecipientResponse {
    return new AssessorInviteRecipientResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessorInviteRecipientResponse {
    return new AssessorInviteRecipientResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessorInviteRecipientResponse {
    return new AssessorInviteRecipientResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessorInviteRecipientResponse | PlainMessage<AssessorInviteRecipientResponse> | undefined, b: AssessorInviteRecipientResponse | PlainMessage<AssessorInviteRecipientResponse> | undefined): boolean {
    return proto3.util.equals(AssessorInviteRecipientResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.AssessorGetParticipantsRequest
 */
export class AssessorGetParticipantsRequest extends Message<AssessorGetParticipantsRequest> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  constructor(data?: PartialMessage<AssessorGetParticipantsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.AssessorGetParticipantsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessorGetParticipantsRequest {
    return new AssessorGetParticipantsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessorGetParticipantsRequest {
    return new AssessorGetParticipantsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessorGetParticipantsRequest {
    return new AssessorGetParticipantsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessorGetParticipantsRequest | PlainMessage<AssessorGetParticipantsRequest> | undefined, b: AssessorGetParticipantsRequest | PlainMessage<AssessorGetParticipantsRequest> | undefined): boolean {
    return proto3.util.equals(AssessorGetParticipantsRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.AssessorGetParticipantsResponse
 */
export class AssessorGetParticipantsResponse extends Message<AssessorGetParticipantsResponse> {
  /**
   * @generated from field: repeated string recipients = 1;
   */
  recipients: string[] = [];

  /**
   * @generated from field: repeated string assessors = 2;
   */
  assessors: string[] = [];

  constructor(data?: PartialMessage<AssessorGetParticipantsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.AssessorGetParticipantsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "assessors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessorGetParticipantsResponse {
    return new AssessorGetParticipantsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessorGetParticipantsResponse {
    return new AssessorGetParticipantsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessorGetParticipantsResponse {
    return new AssessorGetParticipantsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessorGetParticipantsResponse | PlainMessage<AssessorGetParticipantsResponse> | undefined, b: AssessorGetParticipantsResponse | PlainMessage<AssessorGetParticipantsResponse> | undefined): boolean {
    return proto3.util.equals(AssessorGetParticipantsResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.FinalizeQuestionnaireRequest
 */
export class FinalizeQuestionnaireRequest extends Message<FinalizeQuestionnaireRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<FinalizeQuestionnaireRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.FinalizeQuestionnaireRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeQuestionnaireRequest {
    return new FinalizeQuestionnaireRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeQuestionnaireRequest {
    return new FinalizeQuestionnaireRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeQuestionnaireRequest {
    return new FinalizeQuestionnaireRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeQuestionnaireRequest | PlainMessage<FinalizeQuestionnaireRequest> | undefined, b: FinalizeQuestionnaireRequest | PlainMessage<FinalizeQuestionnaireRequest> | undefined): boolean {
    return proto3.util.equals(FinalizeQuestionnaireRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.FinalizeQuestionnaireResponse
 */
export class FinalizeQuestionnaireResponse extends Message<FinalizeQuestionnaireResponse> {
  constructor(data?: PartialMessage<FinalizeQuestionnaireResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.FinalizeQuestionnaireResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeQuestionnaireResponse {
    return new FinalizeQuestionnaireResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeQuestionnaireResponse {
    return new FinalizeQuestionnaireResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeQuestionnaireResponse {
    return new FinalizeQuestionnaireResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeQuestionnaireResponse | PlainMessage<FinalizeQuestionnaireResponse> | undefined, b: FinalizeQuestionnaireResponse | PlainMessage<FinalizeQuestionnaireResponse> | undefined): boolean {
    return proto3.util.equals(FinalizeQuestionnaireResponse, a, b);
  }
}

