import { useQuery } from '@tanstack/react-query'

import { getArtifactPreviewUrl } from '@/gen/artifacts/store/v1/store_service-OrgArtifactStoreService_connectquery'
import {
  listCompanyArtifact,
  listSubprocessors,
  listUserSourcedArtifact,
} from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'

import { artifactSubcategoryLabel } from '@/const/label'

export const useListUserSourcedArtifact = (
  companyId?: string,
  artifactSubcategory?: keyof typeof artifactSubcategoryLabel,
) => {
  const queryState = useQuery({
    ...listUserSourcedArtifact.useQuery({
      companyId,
      artifactSubcategory,
    }),
    refetchInterval: 15000,
  })

  return {
    ...queryState,
    data: queryState.data?.artifacts || [],
  }
}

export const useListCompanyArtifact = (companyId: string) => {
  const queryState = useQuery({
    ...listCompanyArtifact.useQuery({ companyId }),
    refetchInterval: 15000,
  })

  return {
    ...queryState,
    data: queryState.data?.artifacts || [],
  }
}

export const useListSubprocessor = (companyId: string) => {
  return useQuery(listSubprocessors.useQuery({ companyId: companyId }))
}

export const useGetArtifactPreviewUrl = (artifactsId: string) => {
  const staleTimeMilliseconds = 300000 // 5 minutes it's the expiration time of the preview URL

  const { queryKey, queryFn } = getArtifactPreviewUrl.useQuery({ id: artifactsId })

  const queryState = useQuery(queryKey, queryFn, {
    staleTime: staleTimeMilliseconds,
  })

  return {
    ...queryState,
    data: queryState.data?.url,
  }
}
