import { PlainMessage } from '@bufbuild/protobuf'

import { Permission } from '@/gen/inventory/v1/company_service_pb'

import { PermissionsTable } from '@/pages/company-drawer/permissions-table'

type PermissionsTabProps = {
  titleSuffix?: string
  permissions: PlainMessage<Permission>[]
  loading?: boolean
}

export const PermissionsTab = ({
  titleSuffix,
  permissions,
  loading = false,
}: PermissionsTabProps) => {
  return (
    <div>
      <div className='mb-6 text-xl font-bold'>Permissions {titleSuffix}</div>
      <PermissionsTable loading={loading} dataSource={permissions.filter(Boolean) ?? []} />
    </div>
  )
}
