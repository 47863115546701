import { IntegrationCategory } from '@/gen/inventory/v1/integration_service_pb'

import { categoryLabel } from '@/const/label'

import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'

type FormTitleProps = {
  title: string
  iconURL: string
  description: string
  category: IntegrationCategory
  dataCollected: string[]
}

export const FormHeader = ({
  title,
  iconURL,
  description,
  category,
  dataCollected,
}: FormTitleProps) => {
  return (
    <>
      <TextWithIcon
        className='my-4 text-3xl font-bold'
        text={title}
        icon={<img src={iconURL} alt={title} width={40} height={40} />}
      />
      <span>{description}</span>
      <Badge variant={'outline'} className='my-4 block w-fit rounded'>
        {categoryLabel[category]}
      </Badge>
      <h2 className='mb-3 font-semibold'>Data Collected from this Integration</h2>
      <ul className='list-inside list-disc'>
        {dataCollected.map((data, i) => (
          <li key={i}>{data}</li>
        ))}
      </ul>
    </>
  )
}
