import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { listFindings } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

export const useListFindings = ({
  companyId,
  solutionId,
  urgentOnly = false,
  withCompany = false,
}: {
  companyId?: string
  solutionId?: string
  withCompany?: boolean
  urgentOnly?: boolean
}) => {
  const { isEnabled } = useFeatureFlagEnabled('finding-show-even-if-invisible')
  const res = useQuery(listFindings.useQuery({ companyId, solutionId, withCompany, urgentOnly }))

  const findings = useMemo(() => {
    if (!res.data) {
      return []
    }
    if (isEnabled) {
      return res.data?.findings.map((finding) => ({
        ...finding,
        title: finding.visible ? finding.title : `* ${finding.title}`,
      }))
    }
    return res.data?.findings.filter((finding) => finding.visible)
  }, [res.data, isEnabled])

  return {
    ...res,
    data: {
      ...res.data,
      findings: findings || [],
    } as typeof res.data,
  }
}
