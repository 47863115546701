import { Position, useReactFlow } from '@xyflow/react'
import React, { memo } from 'react'

import { BaseNode, BaseNodeProps } from './base-node'

import '@xyflow/react/dist/style.css'

type RootNodeProps = BaseNodeProps & {
  data: {
    recipeId: string
  }
  id: string
}

export const RootNode: React.FC<RootNodeProps> = memo((props) => {
  const { updateNodeData } = useReactFlow()
  const { data, id } = props
  return (
    <BaseNode
      {...props}
      id={id}
      title='Root Node'
      type='root'
      dataInputs={[
        {
          id: `root-${id}-recipeId`,
          name: 'recipeId',
          type: 'text',
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            updateNodeData(id, { recipeId: event.target.value }),
          value: data.recipeId,
          placeholder: 'Recipe ID',
        },
      ]}
      outputHandles={[
        {
          id: `root-${id}-next-step`,
          position: Position.Right,
          type: 'source',
          isConnectable: true,
          label: 'Next Step',
        },
      ]}
    />
  )
})

RootNode.displayName = 'RootNode'
