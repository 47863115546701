import {
  CloudIcon,
  CpuIcon,
  DatabaseIcon,
  KeyIcon,
  LucideIcon,
  SlidersIcon,
  UnlockIcon,
  WrenchIcon,
} from 'lucide-react'

import { PermissionCategory } from '@/gen/inventory/v1/company_service_pb'

import { cn } from '@/lib/style-helpers'

import EmployeeModification from '@/assets/employee-modification.svg'
import Employee from '@/assets/employee.svg'

export const PermissionSymbol = (props: React.ComponentProps<typeof UnlockIcon>) => (
  <UnlockIcon {...props} className={cn('w-2.5', props.className)} />
)

type PermissionIconProps = {
  permission: PermissionCategory
} & React.ComponentProps<LucideIcon>

export const PermissionIcon = ({ permission, className }: PermissionIconProps) => {
  const Icon = permissionIcon[permission]
  return Icon ? <Icon className={cn('mr-2 h-4 w-4 last:mr-0', className)} /> : null
}

const permissionIcon: Record<
  PermissionCategory,
  | React.FunctionComponent<{
      className?: string | undefined
    }>
  | undefined
> = {
  [PermissionCategory.UNSPECIFIED]: undefined,
  [PermissionCategory.DATA_ACCESS]: DatabaseIcon,
  [PermissionCategory.DATA_MODIFICATION]: WrenchIcon,
  [PermissionCategory.OPERATIONAL_ENVIRONMENT]: CpuIcon,
  [PermissionCategory.CONFIGURATION_PERMISSIONS]: SlidersIcon,
  [PermissionCategory.IDENTITY_AND_ACCESS_MANAGEMENT]: KeyIcon,
  [PermissionCategory.EMPLOYEE_DATA_ACCESS]: Employee,
  [PermissionCategory.EMPLOYEE_DATA_MODIFICATION]: EmployeeModification,
  [PermissionCategory.CLOUD_ENVIRONMENT]: CloudIcon,
}
