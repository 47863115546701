import { Check, ChevronsUpDown } from 'lucide-react'
import * as React from 'react'

import { cn } from '@/lib/style-helpers'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

type ChannelSelectProps = {
  channels: { id: string; label: string | React.ReactNode; value: string }[]
  connectedChannelId?: string
  onSelected: (channelId: string) => void
  disabled?: boolean
}

export const ChannelSelect = ({
  channels,
  connectedChannelId: connectedChannelIdProp,
  onSelected,
  disabled = false,
}: ChannelSelectProps) => {
  const [open, setOpen] = React.useState(false)
  const [connectedChannelId, setConnectedChannelId] = React.useState(connectedChannelIdProp)
  const connectedChannel = channels.find((channel) => channel.id === connectedChannelId)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant='outline'
          role='combobox'
          disabled={disabled}
          aria-expanded={open}
          className='min-w-48 justify-between'
        >
          {connectedChannel ? connectedChannel.label : 'Select channel...'}
          <ChevronsUpDown className='ml-2 size-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-0' align='start'>
        <Command>
          <CommandInput placeholder='Search channel...' className='sticky top-0' />
          <CommandList className='max-h-96 overflow-y-auto'>
            <CommandEmpty>Invite the @Lema app to a channel first.</CommandEmpty>
            <CommandGroup>
              {channels.map((channel) => (
                <CommandItem
                  key={channel.id}
                  value={channel.value}
                  onSelect={() => {
                    setConnectedChannelId(channel.id)
                    setOpen(false)
                    onSelected(channel.id)
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 size-4',
                      connectedChannelId === channel.id ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {channel.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
