import { Dispatch, SetStateAction, createContext } from 'react'

import { NodeType } from './nodes/nodes'

type DndContext = {
  type: NodeType | null
  setType: Dispatch<SetStateAction<NodeType | null>>
}

export const DndContext = createContext<DndContext>({
  type: null,
  setType: () => {},
})
