import { cn } from '@/lib/style-helpers'
import { useUrlParams } from '@/lib/url-param.hook'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { TabsProps, getTabItemIndex } from './types'

type VerticalTabsProps = TabsProps & React.ComponentProps<typeof TabsList>

export const VerticalTabs = ({
  items,
  urlParamName,
  labelAsTitle,
  titleSuffix,
  ...props
}: VerticalTabsProps) => {
  const { updateParam, urlParams } = useUrlParams()
  const defaultItem = items.find(({ itemDisabled }) => !itemDisabled)
  const defaultIndex = defaultItem && getTabItemIndex(defaultItem)
  return (
    <Tabs
      value={(urlParamName && urlParams[urlParamName]) || undefined}
      defaultValue={defaultIndex}
    >
      {items.map((tabItem) => {
        const index = getTabItemIndex(tabItem)
        return (
          (labelAsTitle || titleSuffix) && (
            <TabsContent className='w-full' key={index} value={index}>
              <h2 className='mb-6 text-xl font-bold'>
                {labelAsTitle && tabItem.label}
                {titleSuffix}
              </h2>
            </TabsContent>
          )
        )
      })}
      <div className='flex items-start gap-12'>
        <TabsList
          {...props}
          className={cn('flex-col items-start gap-4 rounded-none border-r', props.className)}
        >
          {items.map((tabItem) => {
            const index = getTabItemIndex(tabItem)
            const { label, itemDisabled, labelIcon } = tabItem
            return (
              <TabsTrigger
                onClick={() => urlParamName && index && updateParam(urlParamName, index)}
                className={cn('w-full rounded-none border-r-2 border-transparent p-2', {
                  'ml-5': tabItem.indented,
                  'data-[state=active]:border-purple-600 data-[state=active]:text-purple-600':
                    !itemDisabled,
                })}
                key={index}
                disabled={itemDisabled}
                value={index}
              >
                {labelIcon && <span className='mr-2'>{labelIcon}</span>}
                {label}
              </TabsTrigger>
            )
          })}
        </TabsList>
        {items.map((tabItem) => {
          const index = getTabItemIndex(tabItem)
          return (
            <TabsContent className='w-full' key={index} value={index}>
              {tabItem.component}
            </TabsContent>
          )
        })}
      </div>
    </Tabs>
  )
}
