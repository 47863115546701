import { Home, PlusIcon } from 'lucide-react'

import { useAddThirdPartyModal } from '@/components/add-new-third-party/use-add-third-party-modal.hook'
import { InventoryHeader } from '@/components/inventory-header'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

export const InventoryPageHeader = () => {
  const { renderModal, showModal } = useAddThirdPartyModal()

  return (
    <InventoryHeader
      title='Third-Party Inventory'
      actionElement={
        <>
          <Button
            onClick={showModal}
            className='flex bg-transparent px-4 py-2 duration-200 ease-in-out'
            variant={'outline'}
          >
            <TextWithIcon
              className='flex gap-2'
              icon={<PlusIcon size={14} />}
              text='Add Third-Party'
            />
          </Button>
          {renderModal()}
        </>
      }
      icon={<Home className='h-5.5 w-5.5' />}
    />
  )
}
