import { createPromiseClient } from '@connectrpc/connect'
import { BaseMetadata, BaseUserMeta, JsonObject, LiveList, createClient } from '@liveblocks/client'
import { createRoomContext } from '@liveblocks/react'
import _ from 'lodash'

import { AssessorService } from '@/gen/questionnaire/v1/assessor_service_connect'
import { QuestionnaireService } from '@/gen/questionnaire/v1/questionnaire_service_connect'

import { sessionTokenInterceptor } from '@/lib/auth/session-token-interceptor'
import { getConnectTransport } from '@/lib/connect-transport'
import { isQuestionnaireMagicLink } from '@/lib/string-validators'

const buildMode = import.meta.env.MODE

const questionnaireAssessorClient = createPromiseClient(
  AssessorService,
  getConnectTransport({
    interceptors:
      buildMode === 'production' || buildMode === 'production_us' ? [] : [sessionTokenInterceptor],
  }),
)

const questionnaireRecipientClient = createPromiseClient(
  QuestionnaireService,
  getConnectTransport({}),
)

const client = createClient({
  authEndpoint: async () => {
    const magicLink = window.location.pathname
    let token: string
    if (isQuestionnaireMagicLink(magicLink)) {
      const { userToken } = await questionnaireRecipientClient.getUserToken({ magicLink })
      token = userToken
    } else {
      const { token: userToken } = await questionnaireAssessorClient.getToken({})
      token = userToken
    }
    return {
      token,
    }
  },

  // Used to display the userId as a name in the comment/thread UI.
  resolveUsers: async ({ userIds }) => {
    return userIds.map((userId) => ({
      name: userId,
    }))
  },
  resolveMentionSuggestions: async ({ text, roomId }) => {
    let users: string[]
    if (isQuestionnaireMagicLink(window.location.pathname)) {
      const { recipients, assessors } = await questionnaireRecipientClient.getParticipants({
        magicLink: window.location.pathname,
      })
      users = [...recipients, ...assessors]
    } else {
      const { recipients, assessors } = await questionnaireAssessorClient.assessorGetParticipants({
        roomId,
      })
      users = [...recipients, ...assessors]
    }
    return _.uniq(users.filter((user) => user.toLowerCase().includes(text.toLowerCase())))
  },
})

export type QuestionStatusString =
  | 'unanswered'
  | 'needsReview'
  | 'accepted'
  | 'rejected'
  | 'postponed'

type BaseQuestionType = {
  id: string
  type: 'radio' | 'checkbox' | 'free-text' | 'file' | 'date'
  question: string
  category: string
  subCategory: string
  isLeading: boolean
  note?: string
  status: QuestionStatusString
}

export type Option = {
  id: string
  optionLabel: string
  isFlag: boolean
  isSelect: boolean
}

export type RadioQuestionType = BaseQuestionType & {
  type: 'radio'
  options: Option[]
  explanation?: string
}

export type CheckboxQuestionType = BaseQuestionType & {
  type: 'checkbox'
  options: Option[]
  explanation?: string
}

export type DateQuestionType = BaseQuestionType & {
  type: 'date'
  dateAnswer?: string
}

export type FreeTextQuestionType = BaseQuestionType & {
  type: 'free-text'
  answer?: string
}

export type FileQuestionType = BaseQuestionType & {
  type: 'file'
  artifactId?: string
  artifactName?: string
}

export type QuestionType =
  | RadioQuestionType
  | CheckboxQuestionType
  | FreeTextQuestionType
  | FileQuestionType
  | DateQuestionType

type QuestionTypeWithOptions = Extract<QuestionType, { options: Option[] }>

export const isQuestionWithOptions = (
  question: QuestionType,
): question is QuestionTypeWithOptions => {
  return 'options' in question
}

type Storage = { questions: LiveList<QuestionType> }

type Presence = JsonObject

interface UserMeta extends BaseUserMeta {}

export type ParticipantRole = 'assessor' | 'assessor-readonly' | 'recipient'

export const isAssessor = (role: ParticipantRole): boolean => {
  return role === 'assessor' || role === 'assessor-readonly'
}
export interface ThreadMetadata extends BaseMetadata {
  questionId: string
  createdAt: number
  resolved: boolean
  lastRoleToReply: ParticipantRole
}

export const {
  RoomProvider,
  useMyPresence,
  useStorage,
  useOthers,
  useThreads,
  useMutation,
  useCreateThread,
  useCreateComment,
  useEditThreadMetadata,
  useRoomInfo,
} = createRoomContext<Presence, Storage, UserMeta, BaseUserMeta, ThreadMetadata>(client)
