import { createContext, useMemo, useState } from 'react'

import { QuestionStatusString, QuestionType, useThreads } from '@/lib/liveblocks.config'

type QuestionnaireFilterContext = {
  statuses?: QuestionStatusString[]
  setFilterUnresolvedThreads: (filter: boolean) => void
  filterUnresolvedThreads: boolean
  setStatuses: (statuses: QuestionStatusString[]) => void
  isQuestionMatchFilter: (question: QuestionType) => boolean
}

export const QuestionnaireFilterContext = createContext<QuestionnaireFilterContext>({
  statuses: [] as QuestionStatusString[],
  setStatuses: () => {},
  isQuestionMatchFilter: () => true,
  filterUnresolvedThreads: false,
  setFilterUnresolvedThreads: () => {},
})

type QuestionnaireFilterProviderProps = React.PropsWithChildren

export const QuestionnaireFilterProvider = ({ children }: QuestionnaireFilterProviderProps) => {
  const { threads: unresolvedThreads } = useThreads({
    query: {
      metadata: {
        resolved: false,
      },
    },
  })

  const unresolvedThreadsQuestionIds = useMemo(
    () => unresolvedThreads?.map(({ metadata: { questionId } }) => questionId),
    [unresolvedThreads],
  )

  const [filterUnresolvedThreads, setFilterUnresolvedThreads] = useState<boolean>(false)
  const [statuses, setStatuses] = useState<QuestionStatusString[]>()

  const isQuestionMatchFilter = (question: QuestionType) => {
    if (filterUnresolvedThreads && !unresolvedThreadsQuestionIds?.includes(question.id))
      return false
    const status = question.status || 'unanswered' // In case status is not set, we consider it as unanswered
    if (!statuses || statuses.length === 0) return true
    if (statuses.includes(status)) return true
    return false
  }

  return (
    <QuestionnaireFilterContext.Provider
      value={{
        statuses,
        setStatuses,
        isQuestionMatchFilter,
        filterUnresolvedThreads,
        setFilterUnresolvedThreads,
      }}
    >
      {children}
    </QuestionnaireFilterContext.Provider>
  )
}
