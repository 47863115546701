import {
  ClipboardCheckIcon,
  ClipboardIcon,
  ClipboardListIcon,
  ClipboardSignatureIcon,
} from 'lucide-react'
import { ReactElement } from 'react'

import { FindingStatus } from '@/gen/inventory/v1/finding_service_pb'

import { cn } from '@/lib/style-helpers'

const findingIcons: Record<FindingStatus, ReactElement> = {
  [FindingStatus.ONGOING]: <ClipboardSignatureIcon className='w-3.75 text-yellow-500' />,
  [FindingStatus.OPEN]: <ClipboardIcon className='w-3.75 text-red-500' />,
  [FindingStatus.RESOLVED]: <ClipboardCheckIcon className='w-3.75' />,
  [FindingStatus.ACCEPTED]: <ClipboardListIcon className='w-3.75 text-gray-400' />,
  [FindingStatus.UNSPECIFIED]: <></>,
}

export const FindingIcon = ({ status }: { status: FindingStatus }) => {
  return findingIcons[status]
}

export type FindingStatusSymbolProps = React.ComponentProps<typeof ClipboardSignatureIcon>

export const FindingStatusSymbol = (props: FindingStatusSymbolProps) => (
  <ClipboardIcon {...props} className={cn('w-2.5', props.className)} />
)
