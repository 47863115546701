import { cn } from '@/lib/style-helpers'

import { Loading } from '@/components/loading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { TabsProps, getTabItemIndex } from './types'

type HorizontalTabsProps = TabsProps & {
  disabled?: boolean
  isLoading?: boolean
  size?: 'small' | 'large'
}

export const HorizontalTabs = ({
  items,
  className,
  disabled,
  isLoading = false,
  size = 'large',
}: HorizontalTabsProps) => {
  const defaultItem = items.find(({ itemDisabled }) => !itemDisabled)
  const defaultIndex = defaultItem && getTabItemIndex(defaultItem)
  return (
    <Tabs defaultValue={defaultIndex} className={className}>
      <TabsList
        className={cn(
          'mb-6 flex h-fit w-full justify-start gap-4 rounded bg-gray-50 px-9 text-gray-500',
          {
            'py-2': size === 'large',
            'py-1': size === 'small',
          },
        )}
      >
        {items.map((tabItem) => {
          const index = getTabItemIndex(tabItem)
          const { label, itemDisabled } = tabItem
          return (
            <TabsTrigger
              key={index}
              disabled={disabled || itemDisabled}
              className={cn(
                'rounded text-base hover:bg-gray-100 data-[state=active]:bg-gray-700 data-[state=active]:text-white data-[state=active]:shadow-sm',
                {
                  'h-10 py-2': size === 'large',
                  'h-8': size === 'small',
                },
              )}
              value={index}
            >
              {label}
            </TabsTrigger>
          )
        })}
      </TabsList>
      {isLoading ? (
        <Loading />
      ) : (
        items.map((tabItem) => {
          const index = getTabItemIndex(tabItem)
          return (
            <TabsContent className='px-8' key={index} value={index}>
              {tabItem.component}
            </TabsContent>
          )
        })
      )}
    </Tabs>
  )
}
