// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file artifacts/store/v1/store_service.proto (package artifacts.store.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetArtifactPreviewUrlRequest, GetArtifactPreviewUrlResponse, UploadArtifactRequest, UploadArtifactResponse } from "./store_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "artifacts.store.v1.OrgArtifactStoreService";

/**
 * @generated from service artifacts.store.v1.OrgArtifactStoreService
 */
export const OrgArtifactStoreService = {
  typeName: "artifacts.store.v1.OrgArtifactStoreService",
  methods: {
    /**
     * @generated from rpc artifacts.store.v1.OrgArtifactStoreService.GetArtifactPreviewUrl
     */
    getArtifactPreviewUrl: {
      name: "GetArtifactPreviewUrl",
      I: GetArtifactPreviewUrlRequest,
      O: GetArtifactPreviewUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.store.v1.OrgArtifactStoreService.UploadArtifact
     */
    uploadArtifact: {
      name: "UploadArtifact",
      I: UploadArtifactRequest,
      O: UploadArtifactResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: OrgArtifactStoreService,});

/**
 * @generated from rpc artifacts.store.v1.OrgArtifactStoreService.GetArtifactPreviewUrl
 */
export const getArtifactPreviewUrl: UnaryFunctionsWithHooks<GetArtifactPreviewUrlRequest, GetArtifactPreviewUrlResponse> = {   ...$queryService.getArtifactPreviewUrl,  ...createUnaryHooks($queryService.getArtifactPreviewUrl)};

/**
 * @generated from rpc artifacts.store.v1.OrgArtifactStoreService.UploadArtifact
 */
export const uploadArtifact: UnaryFunctionsWithHooks<UploadArtifactRequest, UploadArtifactResponse> = {   ...$queryService.uploadArtifact,  ...createUnaryHooks($queryService.uploadArtifact)};
