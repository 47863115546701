import { useQuery } from '@tanstack/react-query'

import { getSoc2Activities } from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'

export const useGetSoc2Activities = (companyId: string) => {
  const queryState = useQuery(getSoc2Activities.useQuery({ companyId }))
  return {
    ...queryState,
    data: queryState.data?.activities || [],
  }
}
