import { PlainMessage } from '@bufbuild/protobuf'
import _ from 'lodash'
import { useMemo, useState } from 'react'

import {
  InherentRiskCategoryEnum,
  InherentRiskGroup,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { InherentRiskChart } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-chart'
import { InherentRiskProfile } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-profile'

import { Loading } from '@/components/loading'

type InherentRiskPanelProps = {
  companyId: string
  companyRisk: RiskLevel
  inherentRiskGroups?: PlainMessage<InherentRiskGroup>[]
  isLoading?: boolean
}

const defaultInherentRiskGroups: PlainMessage<InherentRiskGroup>[] = [
  {
    categoryEnum: InherentRiskCategoryEnum.DATA,
    category: 'Data',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.ATTACK_SURFACE,
    category: 'Attack Surface',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.SPEND,
    category: 'Spend',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT,
    category: 'Customer Impact',
    subcategory: 'Impact in case of outage',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.INTEG_METHOD,
    category: 'Customer Impact',
    subcategory: 'Integration Method',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.LOB,
    category: 'Operational Impact',
    subcategory: 'Lines of Business',
    inherentRiskItems: [] as InherentRiskItem[],
  },
  {
    categoryEnum: InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT,
    category: 'Operational Impact',
    subcategory: 'Impact in case of outage',
    inherentRiskItems: [] as InherentRiskItem[],
  },
]

export type InherentRiskPanelSource = 'irq' | 'lema'

export const InherentRiskPanel = ({
  companyId,
  companyRisk,
  inherentRiskGroups = defaultInherentRiskGroups,
  isLoading = false,
}: InherentRiskPanelProps) => {
  const irqItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: _.uniqBy(
          _.filter(group.inherentRiskItems, (item) => item.source === InherentRiskSource.IRQ),
          (item) => item.id,
        ),
      })
    })
  }, [inherentRiskGroups])

  const lemaItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      const items = _.filter(
        group.inherentRiskItems,
        (item) => item.source !== InherentRiskSource.IRQ,
      )
      const groupedItems: Partial<InherentRiskItem>[][] = _.map(
        _.groupBy(items, (item) => item.displayName),
      )
      const filteredItems = _.map(
        _.values(groupedItems),
        (items) => _.maxBy(items, (item) => item.source) || new InherentRiskItem(),
      )

      const uniqItems = _.uniqBy(filteredItems, (item) => item.id)

      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: uniqItems,
      })
    })
  }, [inherentRiskGroups])

  const irqItemsCount = useMemo(() => {
    return irqItems.reduce((acc, { inherentRiskItems }) => acc + inherentRiskItems.length, 0)
  }, [irqItems])

  const [selectedSource, setSelectedSource] = useState<InherentRiskPanelSource>(
    irqItemsCount > 0 ? 'irq' : 'lema',
  )

  const selectedInherentRiskGroups = useMemo(() => {
    if (selectedSource === 'irq') return irqItems
    return lemaItems
  }, [selectedSource, irqItems, lemaItems])

  const chart = useMemo(() => {
    const maxByCategory: Record<string, number> = {}

    for (const { category, inherentRiskItems } of selectedInherentRiskGroups) {
      const max = _.max(inherentRiskItems.map(({ severity }) => severity)) || 0

      // Note: it is possible for a category to have multiple sub categories
      // we need to take the max of all sub categories
      if (maxByCategory[category] === undefined || maxByCategory[category] < max) {
        maxByCategory[category] = max
      }
    }

    return Object.entries(maxByCategory).map(([x, y]) => ({ x, y }))
  }, [selectedInherentRiskGroups])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='flex justify-between py-6'>
      <div className='flex h-80 w-1/5 min-w-52 flex-col items-center justify-center'>
        <div className='mb-1.5 whitespace-nowrap text-xs font-bold'>THIRD-PARTY INHERENT RISK</div>
        <InherentRiskChart companyRisk={companyRisk} chart={chart} />
      </div>
      <div className='w-4/5'>
        <InherentRiskProfile
          companyId={companyId}
          inherentRiskGroups={selectedInherentRiskGroups}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          irqCount={irqItemsCount}
        />
      </div>
    </div>
  )
}
