import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  getIntegration,
  listAllIntegrations,
} from '@/gen/inventory/v1/integration_service-IntegrationService_connectquery'
import { addCredentials } from '@/gen/msgraph/v1/msgraph_service-MSGraphCollectorService_connectquery'

export const useSetMicrosoftCredentials = () => {
  const queryClient = useQueryClient()
  const opts = addCredentials.useMutation()
  return useMutation({
    ...opts,
    onSuccess: () => {
      queryClient.invalidateQueries(getIntegration.getQueryKey())
      queryClient.invalidateQueries(listAllIntegrations.getQueryKey())
    },
  })
}
