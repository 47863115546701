import { createPromiseClient } from '@connectrpc/connect'
import { useTransport } from '@connectrpc/connect-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'

import {
  CompanyCatalogService,
  CompanyManagementService,
} from '@/gen/companycatalog/v1/company_catalog_connect'
import {
  type AutocompleteItem,
  CompanyLookupStrategy,
  OrgCompanySource,
  type UpsertOrgCompanyRequest,
} from '@/gen/companycatalog/v1/company_catalog_pb'
import {
  getCompany,
  getCompanyActivityLog,
  getCompanyCISOProfile,
  getCompanyDrawerMetadata,
  getInherentRisk,
  listCompanies,
  listInherentRiskCategories,
  setIRQItem,
  updateCompany,
} from '@/gen/inventory/v1/company_service-CompanyInventoryService_connectquery'
import { listFindings } from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

import { companyStatusLabel, riskLevelLabel } from '@/const/label'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { useToast } from '@/components/ui/use-toast'

type CreateCompanyVariables = {
  isManual: boolean
  predicate?: AutocompleteItem['companyIdentification']
} & Pick<UpsertOrgCompanyRequest, 'name' | 'website' | 'status'>

type CreateCompanyData = {
  id: string
}

export const useCreateCompany = () => {
  const queryClient = useQueryClient()
  const { queryKey: listTpCompaniesQueryKey } = listCompanies.useQuery()
  const transport = useTransport()
  const catalogClient = createPromiseClient(CompanyCatalogService, transport)
  const managementClient = createPromiseClient(CompanyManagementService, transport)

  return useMutation({
    mutationFn: async ({
      predicate,
      status,
      name,
      website,
      isManual,
    }: CreateCompanyVariables): Promise<CreateCompanyData> => {
      // If user inputted the company manually, create an unverified company
      if (isManual) {
        const res = await managementClient.createUnverifiedOrgCompany({ name, website, status })
        return { id: res.id }
      }
      const company = await catalogClient.lookupCompany({
        predicates: [{ predicate: predicate }],
        strategy: CompanyLookupStrategy.TAKE_FIRST,
      })
      const res = await managementClient.upsertOrgCompany({
        source: OrgCompanySource.MANUAL,
        companyId: company.id,
        status,
        name,
        website,
      })
      return {
        id: res.id,
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listTpCompaniesQueryKey)
    },
  })
}

export const useGetCompany = (companyId?: string, enabled = true) => {
  return useQuery({ ...getCompany.useQuery({ id: companyId }), enabled })
}

export const useGetCompanyDrawerMetadata = (
  companyId?: string,
  solutionId?: string,
  enabled = true,
) => {
  const { isEnabled } = useFeatureFlagEnabled('third-party-usage-list-users')
  return useQuery({
    ...getCompanyDrawerMetadata.useQuery({ companyId, solutionId, withUsers: isEnabled }),
    enabled,
  })
}

export const useGetCompanyCISOProfile = (companyId: string, enabled = true) => {
  const response = useQuery({ ...getCompanyCISOProfile.useQuery({ companyId }), enabled })
  return {
    ...response,
    data: response.data?.cisoProfile,
  }
}

export const useListCompanies = (enabled = true) => {
  const queryState = useQuery(listCompanies.useQuery({ enabled }))

  return {
    ...queryState,
    data: (enabled && queryState.data?.companies) || [],
  }
}

export const useGetInherentRisk = (companyId: string, enabled = true) => {
  const queryState = useQuery({ ...getInherentRisk.useQuery({ companyId }), enabled })
  return {
    ...queryState,
    data: queryState.data?.inherentRiskGroups || [],
  }
}

export const useListInherentRiskCategories = (companyId: string) => {
  const queryState = useQuery(listInherentRiskCategories.useQuery({ companyId }))
  return {
    ...queryState,
    data: queryState.data?.categories || [],
  }
}

export const useGetCompanyActivityLog = (companyId: string) => {
  const queryState = useQuery(getCompanyActivityLog.useQuery({ companyId }))
  return {
    ...queryState,
    data: queryState.data?.items || [],
  }
}

export const useSetIRQItem = (companyId: string) => {
  const queryClient = useQueryClient()

  const opts = setIRQItem.useMutation()
  return useMutation({
    ...opts,
    onSuccess: () => {
      queryClient.invalidateQueries(listInherentRiskCategories.getQueryKey({ companyId }))
      queryClient.invalidateQueries(getInherentRisk.getQueryKey({ companyId }))
      queryClient.invalidateQueries(getCompany.getQueryKey({ id: companyId }))
    },
  })
}

export const useUpdateCompany = (companyId: string) => {
  const queryClient = useQueryClient()
  const { showCompanyToast } = useUpdateCompanyToast()

  const { queryKey: listFindingsQueryKey } = listFindings.useQuery({ urgentOnly: true })
  const { queryKey: listCompaniesQueryKey } = listCompanies.useQuery()
  const { queryKey: getCompanyQueryKey } = getCompany.useQuery({ id: companyId })

  return useMutation(updateCompany.useMutation().mutationFn, {
    onSuccess: (_, { companyRiskLevel, status }) => {
      queryClient.invalidateQueries(listFindingsQueryKey)
      queryClient.invalidateQueries(listCompaniesQueryKey)
      queryClient.invalidateQueries(getCompanyQueryKey)

      if (companyRiskLevel) {
        showCompanyToast('inherent risk', 'success', riskLevelLabel[companyRiskLevel])
      }

      if (status) {
        showCompanyToast('status', 'success', companyStatusLabel[status])
      }
    },
    onError: (_, { companyRiskLevel, status }) => {
      if (companyRiskLevel) {
        showCompanyToast('inherent risk', 'error', riskLevelLabel[companyRiskLevel])
      }

      if (status) {
        showCompanyToast('status', 'error', companyStatusLabel[status])
      }
    },
  })
}

const useUpdateCompanyToast = () => {
  const { toast } = useToast()

  const showCompanyToast = (
    entity: 'inherent risk' | 'status',
    status: 'success' | 'error',
    newValue: string,
  ) => {
    const entityLabel = _.startCase(entity)
    const title =
      status === 'success' ? `${entityLabel} Successfully Changed` : `${entityLabel} Update Failed`

    const description =
      status === 'success'
        ? `This third-party's ${entity} has been successfully set to "${newValue}".`
        : `Failed to update this third-party's ${entity}.`

    toast({ title, description, status: status })
  }

  return { showCompanyToast }
}
