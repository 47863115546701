import { Code, ConnectError } from '@connectrpc/connect'
import { FlagIcon } from 'lucide-react'
import { useParams } from 'react-router-dom'

import { useUpdateTemplateOption } from '@/api/questionnaire-template.hook'
import { TemplateOption } from '@/gen/questionnaire/v1/template_service_pb'

import { EditableText } from '@/components/editable-text'
import { OptimisticSwitch } from '@/components/optimistic-backend-toggler'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

type OptionLabelProps = {
  option: TemplateOption
  questionId: number
}

export const OptionLabel = ({ option, questionId }: OptionLabelProps) => {
  const { mutateAsync, isError } = useUpdateTemplateOption()
  const { toast } = useToast()
  const { templateId } = useParams<{ templateId: string }>()

  const handleToggleIsFlag = async () => {
    try {
      await mutateAsync({
        optionId: option.id,
        templateId,
        questionId,
        isFlag: !option.isFlag,
      })
    } catch (err) {
      if (err instanceof ConnectError) {
        const message =
          err.code === Code.InvalidArgument ? err.rawMessage : "Couldn't update option flag"
        toast({
          status: 'error',
          title: message,
        })
      }
    }
  }

  return (
    <div className='flex w-full items-center justify-between gap-6'>
      <EditableText
        className='min-h-10 w-full text-base'
        text={option.label}
        onSave={async (input) => {
          if (!input) {
            toast({ status: 'error', title: "Option label can't be left empty" })
            return
          }
          try {
            await mutateAsync({
              optionId: option.id,
              templateId,
              questionId,
              label: input,
            })
          } catch (err) {
            toast({
              status: 'error',
              title: "Couldn't update option label",
            })
          }
        }}
      />
      <Tooltip
        trigger={
          <OptimisticSwitch
            setIsActive={handleToggleIsFlag}
            isActive={option.isFlag}
            isError={isError}
            variant='primary'
            switchComponent={{
              unchecked: (
                <FlagIcon
                  size={26}
                  className='min-w-6.5 cursor-pointer rounded-full p-1.5 text-gray-400'
                />
              ),
              checked: (
                <FlagIcon
                  size={26}
                  className='min-w-6.5 cursor-pointer rounded-full bg-red-400 p-1.5 text-white'
                />
              ),
            }}
          />
        }
      >
        Flagged answers are marked for easy issue <br />
        management when you review responses.
      </Tooltip>
    </div>
  )
}
