import { Button, Carousel, Divider } from 'antd'
import { CarouselRef } from 'antd/es/carousel'
import _ from 'lodash'
import { ArrowLeftIcon, ArrowRightIcon, InfoIcon } from 'lucide-react'
import { useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useGetCompany, useGetInherentRisk } from '@/api/company.hook'
import {
  Company,
  InherentRiskCategory,
  InherentRiskGroup,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { InherentRiskCategoryEnumName } from '@/const/label'

import { InherentRiskChart } from '@/pages/company-drawer/inherent-risk-panel/inherent-risk-chart'

import { CompanyRiskIcon, SelectCompanyRisk } from '@/components/icons/company-risk'

import { IrqCarouselPage } from './irq-carousel-page'

import './antd-carousel.less'

type IrqCarouselProps = {
  thirdPartyId: string
  inherentRiskCategories: InherentRiskCategory[]
  closeDrawer: () => void
}

const getIrqStatus = (irqItems: InherentRiskGroup[], lemaItems: InherentRiskGroup[]) => {
  const suggestionSources = _.reduce(
    lemaItems,
    (acc, category) => {
      for (const item of category.inherentRiskItems) {
        if (item.id) {
          // In case of multiple LOB of same severity, they're concatenated with commas
          // To be able to show correct suggestions we need to split the ids
          item.id.split(',').forEach((id) => {
            acc.set(id, item.source)
          })
        }
      }
      return acc
    },
    new Map<string, InherentRiskSource>(),
  )

  const irqStatus = _.reduce(
    irqItems,
    (acc, { inherentRiskItems }) => {
      for (const item of inherentRiskItems) {
        if (item.id)
          item.id.split(',').forEach((id) => {
            acc.add(id)
          })
      }
      return acc
    },
    new Set<string>(),
  )

  {
    return { irqStatus, suggestionSources }
  }
}

const SlickButtonFix = (props: {
  children: React.ReactNode
  slideCount?: number
  currentSlide?: number
}) => {
  // Dropping unsupported and unused props passed by react-slick
  // https://github.com/akiran/react-slick/issues/1195
  const { children, currentSlide: _currentSlide, slideCount: _slideCount, ...others } = props

  return <span {...others}>{children}</span>
}

export const IrqCarousel = ({
  thirdPartyId,
  inherentRiskCategories,
  closeDrawer,
}: IrqCarouselProps) => {
  const [currPage, setCurrPage] = useState<number>(0)
  const [transactionId] = useState<string>(uuidv4())

  const onChange = (_: number, nextSlide: number) => {
    setCurrPage(nextSlide)
  }

  const {
    data: companyResponse,
    isLoading: companyLoading,
    error: tpCompanyError,
  } = useGetCompany(thirdPartyId)

  const company: Company =
    companyLoading || tpCompanyError || !companyResponse.company
      ? new Company()
      : companyResponse.company

  const companyRisk = !company ? RiskLevel.UNSPECIFIED : company?.risk || RiskLevel.UNSPECIFIED

  const {
    data: inherentRiskGroups,
    isLoading: inherentRiskLoading,
    error: inherentRiskError,
  } = useGetInherentRisk(thirdPartyId)

  const irqItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: _.filter(
          group.inherentRiskItems,
          (item) => item.source === InherentRiskSource.IRQ,
        ),
      })
    })
  }, [inherentRiskGroups])

  const lemaItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      const items = _.filter(
        group.inherentRiskItems,
        (item) => item.source !== InherentRiskSource.IRQ,
      )
      const groupedItems: InherentRiskItem[][] = _.map(_.groupBy(items, (item) => item.displayName))
      const filteredItems = _.map(
        _.values(groupedItems),
        (items) => _.maxBy(items, (item) => item.source) || new InherentRiskItem(),
      )

      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: filteredItems,
      })
    })
  }, [inherentRiskGroups])

  const { irqStatus, suggestionSources } = useMemo(
    () => getIrqStatus(irqItems || [], lemaItems || []),
    [irqItems, lemaItems],
  )

  const chart = useMemo(() => {
    const maxByCategory: Record<string, number> = {}

    if (!irqItems) return []
    for (const { category, inherentRiskItems } of irqItems) {
      const max = _.max(inherentRiskItems.map(({ severity }) => severity)) || 0

      // Note: it is possible for a category to have multiple sub categories
      // we need to take the max of all sub categories
      if (maxByCategory[category] === undefined || maxByCategory[category] < max) {
        maxByCategory[category] = max
      }
    }

    return Object.entries(maxByCategory).map(([x, y]) => ({ x, y }))
  }, [irqItems])

  const carouselRef = useRef<CarouselRef>(null)

  const groupedCategories = useMemo(() => {
    return _.groupBy(inherentRiskCategories, (category) => {
      return InherentRiskCategoryEnumName[category.categoryEnum]
    })
  }, [inherentRiskCategories])

  const catLen = useMemo(() => _.keys(groupedCategories).length, [groupedCategories])

  return (
    <div className='flex h-screen flex-col'>
      <div className='sticky'>
        {!inherentRiskLoading && !inherentRiskError && !companyLoading && !tpCompanyError && (
          <div className='mx-12 mt-4'>
            <div className='flex flex-col'>
              <div className='flex flex-row items-center'>
                <CompanyRiskIcon riskLevel={companyRisk} />
                <h2 className='ml-1 text-xl font-semibold text-gray-700'>
                  {company.profile?.name}
                </h2>
              </div>
              <span className='mr-2 text-xs capitalize'>{company.profile?.industrySector}</span>
            </div>
            <h2 className='mb-4 mt-6 text-3xl font-semibold text-gray-700'>
              Inherent Risk Questionnaire
            </h2>
            <div className='flex h-36 w-full border border-solid border-gray-100'>
              <div className='flex size-full flex-row items-center self-center px-32 py-2'>
                <div className='mr-10'>
                  <div className='mb-1.5 whitespace-nowrap text-xs font-bold'>
                    THIRD-PARTY INHERENT RISK
                  </div>
                  <div className='ml-5.5'>
                    <SelectCompanyRisk
                      companyName={companyResponse.company?.profile?.name || ''}
                      showText
                      thirdPartyId={thirdPartyId}
                      riskLevel={companyRisk}
                    />
                  </div>
                </div>
                <div className='h-32'>
                  <InherentRiskChart companyRisk={companyRisk} chart={chart} />
                </div>
              </div>
            </div>
          </div>
        )}
        <Divider />
        <div className='z-10 mx-12 text-wrap break-words bg-gray-50 p-2 text-xs font-light text-gray-700'>
          <span className='mr-1 font-bold'>
            <InfoIcon className='mr-1 inline size-3 items-center justify-center' />
            Sources:
          </span>
          <span className='text-gray-400'>{`Suggested profile indicators are derived from Lema's integrations, and projections based on recent behavior from third-party data.`}</span>
        </div>
      </div>
      <Carousel
        adaptiveHeight={true}
        arrows={true}
        prevArrow={<SlickButtonFix>{currPage > 0 && <ArrowLeftIcon />}</SlickButtonFix>}
        nextArrow={
          <SlickButtonFix>
            {currPage === catLen - 1 ? (
              <Button
                className='right-12'
                type='primary'
                onClick={() => {
                  carouselRef.current?.goTo(0, true)
                  closeDrawer()
                }}
              >
                Finish
              </Button>
            ) : (
              <ArrowRightIcon />
            )}
          </SlickButtonFix>
        }
        infinite={true}
        beforeChange={onChange}
        ref={carouselRef}
        dots={{ className: 'irq-dots' }}
      >
        {_.values(groupedCategories).map((categories: InherentRiskCategory[]) => (
          <IrqCarouselPage
            transactionId={transactionId}
            thirdPartyId={thirdPartyId}
            irqStatus={irqStatus}
            key={_.map(categories, 'categoryEnum').join('_')}
            inherentRiskCategories={categories}
            suggestions={suggestionSources}
          />
        ))}
      </Carousel>
    </div>
  )
}
