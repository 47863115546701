// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file okta/v1/okta_service.proto (package okta.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AddCredentialsRequest, AddCredentialsResponse } from "./okta_service_pb.js";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "okta.v1.OktaCollectorService";

/**
 * @generated from service okta.v1.OktaCollectorService
 */
export const OktaCollectorService = {
  typeName: "okta.v1.OktaCollectorService",
  methods: {
    /**
     * @generated from rpc okta.v1.OktaCollectorService.Collect
     */
    collect: {
      name: "Collect",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc okta.v1.OktaCollectorService.AddCredentials
     */
    addCredentials: {
      name: "AddCredentials",
      I: AddCredentialsRequest,
      O: AddCredentialsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: OktaCollectorService,});

/**
 * @generated from rpc okta.v1.OktaCollectorService.Collect
 */
export const collect: UnaryFunctionsWithHooks<Empty, Empty> = {   ...$queryService.collect,  ...createUnaryHooks($queryService.collect)};

/**
 * @generated from rpc okta.v1.OktaCollectorService.AddCredentials
 */
export const addCredentials: UnaryFunctionsWithHooks<AddCredentialsRequest, AddCredentialsResponse> = {   ...$queryService.addCredentials,  ...createUnaryHooks($queryService.addCredentials)};
