import { formatDate, timeSince } from '@/lib/date'

type LastChangedProps = {
  lastChanged: Date
  withTimeCount?: boolean
}

export const LastChanged = ({ lastChanged, withTimeCount = true }: LastChangedProps) => {
  const { value, unit } = timeSince(lastChanged)
  return (
    <span className='text-nowrap pl-2 text-xs text-gray-400'>
      since {formatDate(new Date(lastChanged))} {withTimeCount && `(${value} ${unit})`}
    </span>
  )
}
