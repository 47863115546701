import { Button } from 'antd'
import { MessageCircleIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useContext } from 'react'

import { questionStatusLabel } from '@/const/label'

import { questionStatusToClassNameColor } from '@/lib/color'
import { QuestionStatusString, useThreads } from '@/lib/liveblocks.config'
import { cn } from '@/lib/style-helpers'

import { QuestionnaireFilterContext } from '@/pages/questionnaire/questionnaire-filter-provider'
import { useQuestionnaireStatusSummary } from '@/pages/questionnaire/use-get-questionnaire-status-summary.hook'

import { TextWithIcon } from '@/components/text-with-icon'

export type QuestionnaireFilterProps = {
  activeStatusFilters: QuestionStatusString[]
}

export const QuestionnaireFilter = ({ activeStatusFilters }: QuestionnaireFilterProps) => {
  const { threads: unresolvedThreads } = useThreads({
    query: {
      metadata: {
        resolved: false,
      },
    },
  })

  const questionnaireStatusSummary = useQuestionnaireStatusSummary()

  const { setStatuses, statuses, filterUnresolvedThreads, setFilterUnresolvedThreads } = useContext(
    QuestionnaireFilterContext,
  )

  return (
    <div className='mt-6 flex items-center justify-between'>
      <div className='flex items-center gap-2'>
        <span>Only Show:</span>
        <div className='flex gap-3'>
          {questionnaireStatusSummary
            ?.filter(({ status }) => activeStatusFilters.includes(status))
            .map(({ count, status }) => (
              <Button
                disabled={count === 0 && !statuses?.includes(status)}
                className={cn(
                  'flex cursor-pointer items-center rounded border border-gray-300 p-1 pr-2.5',
                  statuses?.includes(status) && 'outline outline-2',
                )}
                onClick={() => setStatuses(statuses?.includes(status) ? [] : [status])}
                key={status}
              >
                <div
                  className={cn(
                    'mx-2 h-2 w-2 rounded-full',
                    questionStatusToClassNameColor[status],
                  )}
                />
                {questionStatusLabel[status]} ({count})
              </Button>
            ))}
        </div>
      </div>
      <div>
        <div
          className={cn(
            'flex cursor-pointer select-none items-center rounded border border-yellow-500 bg-yellow-50 p-1 px-2 pr-2.5 text-yellow-500 ring-0 ring-yellow-500 transition-all',
            {
              'ring-2': filterUnresolvedThreads,
            },
          )}
          onClick={() => setFilterUnresolvedThreads(!filterUnresolvedThreads)}
        >
          <TextWithIcon
            text={`Unresolved ${pluralize('Thread', unresolvedThreads?.length)} (${unresolvedThreads?.length})`}
            icon={<MessageCircleIcon size={14} />}
          />
        </div>
      </div>
    </div>
  )
}
