import { datadogRum } from '@datadog/browser-rum'
import { UserResponse } from '@descope/core-js-sdk'
import { PostHog, usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

export const useTrackUser = (user: UserResponse, orgId: string) => {
  const posthog = usePostHog()
  useEffect(() => {
    if (user) {
      analyticsTrackUser(posthog, user, orgId)
    }
  }, [posthog, user, orgId])
}

const analyticsTrackUser = (posthog: PostHog, user: UserResponse, orgId: string) => {
  posthog?.identify(user.userId, {
    email: user.email,
    name: user.name,
    roles: user.roleNames,
    org_ids: user.userTenants?.map((ut) => ut.tenantId),
    orgs: user.userTenants?.map((ut) => ut.tenantName),
  })

  // analyticsTrackUser is expected to be called each time user or org changes so clear before setting new values
  datadogRum.clearUser()
  datadogRum.setUser({
    id: user.userId,
    email: user.email,
    name: user.name,
    roles: user.roleNames,
    org_ids: user.userTenants?.map((ut) => ut.tenantId),
  })

  if (user.userTenants && user.userTenants.length >= 1) {
    const tenant = user.userTenants.find((ut) => ut.tenantId === orgId) || user.userTenants[0]
    posthog?.group('organization', tenant.tenantId, {
      id: tenant.tenantId,
      env: import.meta.env.MODE,
      name: tenant.tenantName,
      roles: tenant.roleNames,
    })

    datadogRum.setUserProperty('org_id', tenant.tenantId)
    datadogRum.setUserProperty('org_name', tenant.tenantName)
  }
}
