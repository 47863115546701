import { DataTypeInfo } from '@/gen/inventory/v1/company_service_pb'

import { DataTypes } from './data-type'
import { useDatatypeClickCallback } from './datatype.hook'

export const DataTypeTableCell = ({ dataTypes }: { dataTypes: DataTypeInfo[] }) => {
  const { action } = useDatatypeClickCallback()

  return (
    <div className='flex flex-wrap gap-1'>
      <DataTypes
        dataTypes={dataTypes.map((dataType) => ({
          dataType,
          action,
        }))}
      />
    </div>
  )
}
