import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useSetMicrosoftCredentials } from '@/api/credentials/microsoft.hook'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

type MicrosoftIntegrationProps = BaseIntegrationProps

type FormValues = { clientId: string; tenantId: string; secret: string }

export const MicrosoftIntegration = ({ integration }: MicrosoftIntegrationProps) => {
  const {
    mutate: setMicrosoftCredentials,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSetMicrosoftCredentials()
  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={[
            'Global Admin Role in either a Microsoft 365 or an Azure Active Directory user account',
          ]}
        />
        <DashedContainer>
          <Form
            onFinish={({ clientId, secret, tenantId }: FormValues) => {
              setMicrosoftCredentials({
                clientId,
                tenantId,
                secret,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>Application ID</h2>
            <Form.Item<FormValues>
              name='clientId'
              rules={[
                {
                  required: true,
                  message: 'Please input Application ID',
                },
              ]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Application ID' />
            </Form.Item>
            <h2 className='my-3 text-xl font-light'>Directory ID</h2>
            <Form.Item<FormValues>
              name='tenantId'
              rules={[
                {
                  required: true,
                  message: 'Please input Directory ID',
                },
              ]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Directory ID' />
            </Form.Item>
            <h2 className='my-3 text-xl font-light'>Secret Value</h2>
            <Form.Item<FormValues>
              name='secret'
              rules={[
                {
                  required: true,
                  message: 'Please input Secret Value',
                },
              ]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Secret Value' />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
