import { TextWithIcon } from '@/components/text-with-icon'

type InventoryHeaderProps = {
  title: string
  icon: React.ReactNode
  actionElement?: React.ReactNode
}

export const InventoryHeader = ({ title, icon, actionElement }: InventoryHeaderProps) => {
  return (
    <div className='m-auto flex max-w-screen-3xl flex-col gap-5 p-32 py-12'>
      <div className='flex w-full justify-between'>
        <TextWithIcon className='text-2xl font-bold text-gray-700' text={title} icon={icon} />
        {actionElement}
      </div>
    </div>
  )
}
