import { useQuery } from '@tanstack/react-query'

import {
  getIntegration,
  listAllIntegrations,
} from '@/gen/inventory/v1/integration_service-IntegrationService_connectquery'

export const useIntegrations = () => {
  const queryState = useQuery(listAllIntegrations.useQuery())
  return {
    ...queryState,
    data: queryState.data?.integrations || [],
  }
}

export const useIntegration = ({
  integrationId,
  enabled = true,
  retry = 3,
}: {
  integrationId: string
  enabled?: boolean
  retry?: number
}) => {
  const opts = getIntegration.useQuery({ integrationId })

  const queryState = useQuery({
    ...opts,
    enabled,
    retry,
  })
  return {
    ...queryState,
    data: queryState.data?.integration,
  }
}
