import { Form, Input } from 'antd'
import { Loader2Icon } from 'lucide-react'

import { useSetLogicmanagerCredentials } from '@/api/credentials/logicmanager.hook'

import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'
import { Button } from '@/components/ui/button'

type LogicmanagerIntegrationProps = BaseIntegrationProps

type FormValues = { apiKey: string; username: string; globalApiKey: string; domain: string }

export const LogicmanagerIntegration = ({ integration }: LogicmanagerIntegrationProps) => {
  const {
    mutate: setLogicmanagerCredentials,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSetLogicmanagerCredentials()
  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Access to create API keys in LogicManager portal']}
        />
        <DashedContainer>
          <Form
            onFinish={({ apiKey, username, globalApiKey, domain }: FormValues) => {
              setLogicmanagerCredentials({
                globalApiKey,
                apiKey,
                username,
                domain,
              })
            }}
          >
            <h2 className='my-3 text-xl font-light'>API Key</h2>
            <Form.Item<FormValues>
              name='apiKey'
              rules={[{ required: true, message: 'API Key is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='API Key'
                type='password'
              />
            </Form.Item>
            <Form.Item<FormValues>
              name='username'
              rules={[{ required: true, message: 'Username is required' }]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Username' type='email' />
            </Form.Item>
            <Form.Item<FormValues>
              name='globalApiKey'
              rules={[{ required: true, message: 'Global API Key is required' }]}
            >
              <Input
                className='h-10 rounded border-gray-100'
                placeholder='Global API Key'
                type='password'
              />
            </Form.Item>
            <Form.Item<FormValues>
              name='domain'
              rules={[{ required: true, message: 'Domain is required' }]}
            >
              <Input className='h-10 rounded border-gray-100' placeholder='Domain' type='text' />
            </Form.Item>
            <Form.Item>
              <Button disabled={isLoading} type='submit' className='float-right'>
                {isLoading && <Loader2Icon className='mr-2 size-4 animate-spin' />}
                Add Integration
              </Button>
            </Form.Item>
          </Form>
        </DashedContainer>
      </div>
    </div>
  )
}
