import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  addComment,
  getFindingTimeline,
} from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

export const useAddComment = (findingId: string) => {
  const queryClient = useQueryClient()
  const { queryKey: findingTimelineKey } = getFindingTimeline.useQuery({ findingId })

  return useMutation(addComment.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(findingTimelineKey)
    },
  })
}
