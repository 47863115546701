// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/integration_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetIntegrationRequest, GetIntegrationResponse, ListAllIntegrationsRequest, ListAllIntegrationsResponse } from "./integration_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.IntegrationService";

/**
 * @generated from service inventory.v1.IntegrationService
 */
export const IntegrationService = {
  typeName: "inventory.v1.IntegrationService",
  methods: {
    /**
     * @generated from rpc inventory.v1.IntegrationService.ListAllIntegrations
     */
    listAllIntegrations: {
      name: "ListAllIntegrations",
      I: ListAllIntegrationsRequest,
      O: ListAllIntegrationsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.IntegrationService.GetIntegration
     */
    getIntegration: {
      name: "GetIntegration",
      I: GetIntegrationRequest,
      O: GetIntegrationResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

const $queryService = createQueryService({  service: IntegrationService,});

/**
 * @generated from rpc inventory.v1.IntegrationService.ListAllIntegrations
 */
export const listAllIntegrations: UnaryFunctionsWithHooks<ListAllIntegrationsRequest, ListAllIntegrationsResponse> = {   ...$queryService.listAllIntegrations,  ...createUnaryHooks($queryService.listAllIntegrations)};

/**
 * @generated from rpc inventory.v1.IntegrationService.GetIntegration
 */
export const getIntegration: UnaryFunctionsWithHooks<GetIntegrationRequest, GetIntegrationResponse> = {   ...$queryService.getIntegration,  ...createUnaryHooks($queryService.getIntegration)};
