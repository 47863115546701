import { FindingCountStat } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { RISK_LEVELS } from '@/lib/proto-types'

import { RiskLevelIcon } from '@/components/icons/risk-severity'

type FindingDistributionProps = {
  findingCountByLevel: FindingCountStat[]
  hideNumbers?: boolean
}

export const FindingDistribution = ({
  findingCountByLevel,
  hideNumbers,
}: FindingDistributionProps) => {
  const countByLevel: {
    level: RiskLevel
    count: number
  }[] = []

  for (const { no } of RISK_LEVELS) {
    countByLevel.push({
      level: no,
      count: findingCountByLevel.find((item) => item.riskLevel === no)?.count || 0,
    })
  }

  return (
    <div className='flex flex-nowrap gap-4'>
      {countByLevel.map(({ level, count }) => (
        <div key={level} className='flex flex-nowrap items-center gap-1'>
          <RiskLevelIcon level={level} />
          <span>{hideNumbers ? '-' : count}</span>
        </div>
      ))}
    </div>
  )
}
