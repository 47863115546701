import { Link } from 'react-router-dom'

import { DataTypeInfo, DataTypeSource } from '@/gen/inventory/v1/company_service_pb'

import { sortDataTypeSource } from '@/const/priority'

import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { DataTypeBadge } from '@/components/badge/data-type'
import { dataTypeSourceToVariant } from '@/components/data-type/const'
import { Tooltip } from '@/components/ui/tooltip'

const dataTypeSourceToToolTipContent: Record<DataTypeSource, React.ReactNode> = {
  [DataTypeSource.UNSPECIFIED]: '',
  [DataTypeSource.UNDOCUMENTED_ACCESS]: (
    <div className='flex flex-col gap-1 px-3 py-1 text-center'>
      <h2 className='text-base text-red-400'>Undocumented Exchange</h2>
      <span className='text-sm'>Click to view this finding</span>
    </div>
  ),
  [DataTypeSource.DOCUMENTED_ONLY]: (
    <div className='flex flex-col gap-3 px-5 py-2 text-center text-base'>
      <h2>Documented Only</h2>
      <div className='text-sm font-medium'>
        For real-time data monitoring, click to <br />
        add a{' '}
        <Link
          to='/integrations/marketplace'
          className='text-purple-600 hover:text-purple-800'
          onClick={(e) => e.stopPropagation()}
        >
          data integration
        </Link>
        .
      </div>
      <span className='text-sm text-gray-400'>Contact us if you need assistance.</span>
    </div>
  ),
  [DataTypeSource.DOCUMENTED_AND_ACCESSED]: 'Documented & Exchanged',
}

interface DataTypeProps {
  dataType: DataTypeInfo
  action?: (event: React.MouseEvent, dataType: DataTypeInfo) => void
}

interface DataTypesProps {
  dataTypes: DataTypeProps[]
}

export const DataTypes = ({ dataTypes }: DataTypesProps) =>
  dataTypes
    .sort(({ dataType: a }, { dataType: b }) => {
      if (a.source === b.source) {
        return a.dataType.localeCompare(b.dataType)
      }
      return sortDataTypeSource(a.source, b.source)
    })
    .map((dataType, i) => <DataType key={i} {...dataType} />)

const DataType = ({ dataType, action }: DataTypeProps) => {
  const { isEnabled: dataTypeDocumentedVsExchanged } = useFeatureFlagEnabled(
    'data-type-documented-vs-exchanged',
  )

  const trigger = (
    <DataTypeBadge
      key={dataType.dataType}
      onClick={
        dataTypeDocumentedVsExchanged ? action && ((event) => action(event, dataType)) : undefined
      }
      variant={dataTypeDocumentedVsExchanged ? dataTypeSourceToVariant[dataType.source] : 'regular'}
    >
      {dataType.dataType}
    </DataTypeBadge>
  )

  return dataTypeDocumentedVsExchanged ? (
    <Tooltip trigger={trigger}>{dataTypeSourceToToolTipContent[dataType.source]}</Tooltip>
  ) : (
    trigger
  )
}
