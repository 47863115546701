// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file logicmanager/v1/logicmanager_service.proto (package logicmanager.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * LogicManager API needs two values for authentication:
 * 1. Global API Key
 * 2. Basic authentication
 *
 * The basic auth is constructed as a base64 string of `username:password`, `password` being the `api_key`
 * Which is generated per app, and `username` being the email of the person generating that API key.
 *
 * @generated from message logicmanager.v1.AddCredentialsRequest
 */
export class AddCredentialsRequest extends Message<AddCredentialsRequest> {
  /**
   * @generated from field: string global_api_key = 1;
   */
  globalApiKey = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string api_key = 3;
   */
  apiKey = "";

  /**
   * @generated from field: string domain = 4;
   */
  domain = "";

  constructor(data?: PartialMessage<AddCredentialsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "logicmanager.v1.AddCredentialsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "global_api_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "api_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCredentialsRequest {
    return new AddCredentialsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCredentialsRequest {
    return new AddCredentialsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCredentialsRequest {
    return new AddCredentialsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddCredentialsRequest | PlainMessage<AddCredentialsRequest> | undefined, b: AddCredentialsRequest | PlainMessage<AddCredentialsRequest> | undefined): boolean {
    return proto3.util.equals(AddCredentialsRequest, a, b);
  }
}

/**
 * @generated from message logicmanager.v1.AddCredentialsResponse
 */
export class AddCredentialsResponse extends Message<AddCredentialsResponse> {
  constructor(data?: PartialMessage<AddCredentialsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "logicmanager.v1.AddCredentialsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCredentialsResponse {
    return new AddCredentialsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCredentialsResponse {
    return new AddCredentialsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCredentialsResponse {
    return new AddCredentialsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddCredentialsResponse | PlainMessage<AddCredentialsResponse> | undefined, b: AddCredentialsResponse | PlainMessage<AddCredentialsResponse> | undefined): boolean {
    return proto3.util.equals(AddCredentialsResponse, a, b);
  }
}

