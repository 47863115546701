// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/company_summary.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { RiskLevel } from "./risk_pb.js";
import { CompanyStatus } from "./company_status_pb.js";

/**
 * @generated from message inventory.v1.CompanySummary
 */
export class CompanySummary extends Message<CompanySummary> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: string profile_name = 2;
   */
  profileName = "";

  /**
   * @generated from field: string profile_short_description = 3;
   */
  profileShortDescription = "";

  /**
   * @generated from field: inventory.v1.RiskLevel risk = 4;
   */
  risk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.CompanyStatus status = 5;
   */
  status = CompanyStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<CompanySummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanySummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanySummary {
    return new CompanySummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanySummary {
    return new CompanySummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanySummary {
    return new CompanySummary().fromJsonString(jsonString, options);
  }

  static equals(a: CompanySummary | PlainMessage<CompanySummary> | undefined, b: CompanySummary | PlainMessage<CompanySummary> | undefined): boolean {
    return proto3.util.equals(CompanySummary, a, b);
  }
}

