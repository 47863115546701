import { useListSubprocessor } from '@/api/artifact.hook'
import { Country, Subprocessor } from '@/gen/inventory/v1/artifact_service_pb'

import { countryCodeEmoji } from '@/lib/countryCodeEmoji'

import { CountryFlagIcon } from '@/components/country-flag-icon'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { Tooltip } from '@/components/ui/tooltip'

const columns: ColumnType<Subprocessor>[] = [
  {
    title: 'Name',
    width: '20%',
    dataIndex: 'name',
    search: true,
  },
  {
    title: 'Type',
    width: '20%',
    render: ({ type, description }) => {
      return type ? (
        <div className='truncate'>
          <div>{type}</div>
          {description && <div className='text-xs font-light'>{description}</div>}
        </div>
      ) : (
        '-'
      )
    },
    accessor: ({ type }) => ({ filterValue: type }),
    filter: true,
  },
  {
    title: 'Location',
    width: '20%',
    dataIndex: 'countries',
    render: (countries: Country[]) => (
      <div className='flex gap-2 text-xl'>
        {countries.map(({ name, code }) => (
          <Tooltip key={code} trigger={<CountryFlagIcon key={code} countryCode={code} />}>
            {name}
          </Tooltip>
        ))}
      </div>
    ),
    accessor: ({ countries }) => ({
      filterValue: countries
        .map(({ code, name }) => countryCodeEmoji(code) + ' ' + name)
        .filter(Boolean) as string[],
    }),
    filter: true,
  },
  {
    width: '40%',
    title: 'Source',
    render: ({ sourceLink, source }: Subprocessor) => {
      source = source || '-'
      return (
        <div className='truncate'>
          {sourceLink ? (
            <a target='_blank' href={sourceLink} rel='noreferrer'>
              {source}
            </a>
          ) : (
            source
          )}
        </div>
      )
    },
  },
]

interface SubProcessorTableProps {
  companyId: string
}

export const SubProcessorTable = ({ companyId }: SubProcessorTableProps) => {
  const { data, isLoading } = useListSubprocessor(companyId)
  return (
    <Table
      tableLayout='fixed'
      narrowFilterBar
      columns={columns}
      dataSource={data?.subprocessors}
      loading={isLoading}
    />
  )
}
