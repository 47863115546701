import { Empty } from 'antd'
import { PlusIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

export const EmptyInventoryTable = () => (
  <Empty
    className='my-44'
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <div className='flex flex-col'>
        <h2 className='font-semibold text-gray-700'>No Third-Parties Found.</h2>
        <span className='mb-4 text-gray-700'>
          Add integrations which allow Lema to detect
          <br /> third-parties, or add them manually.
        </span>
        <Link to='/integrations/marketplace'>
          <Button>
            <TextWithIcon text='Add Integration' icon={<PlusIcon size={14} />} />
          </Button>
        </Link>
      </div>
    }
  />
)
