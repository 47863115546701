import { useQuery } from '@tanstack/react-query'

import { getQuestionnaireMarkdown } from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'

export const useGetQuestionnaireMarkdown = (companyId: string) => {
  const queryState = useQuery(getQuestionnaireMarkdown.useQuery({ companyId }))

  return {
    ...queryState,
    data: queryState.data?.questionnaireMarkdown,
  }
}
