export interface CheckboxFilterContext {
  checkboxFilterState?: boolean
  setCheckboxFilterState?: (checkboxFilterState: boolean) => void
}

export const useCheckboxFilter = ({
  checkboxFilterState,
  setCheckboxFilterState,
}: CheckboxFilterContext): CheckboxFilterContext => {
  return {
    checkboxFilterState,
    setCheckboxFilterState,
  }
}
