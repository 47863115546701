import pluralize from 'pluralize'

import { useListFindings } from '@/api/list-findings.hook'

import { timeSinceFormatted } from '@/lib/date'
import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { EmptyUrgentFindings } from '@/pages/third-party-inventory/empty-urgent-findings'

import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'

export const UrgentFindingsChart = () => {
  const { data, isLoading } = useListFindings({ withCompany: true, urgentOnly: true })
  const { updateParam } = useUrlParams()

  if (isLoading || !data) {
    return <Loading />
  }
  const { findings, companySummary } = data

  return (
    <div className='rounded border'>
      <div className='mx-9 my-6 flex gap-4'>
        <h3 className={cn('font-bold', { 'text-gray-300': findings.length === 0 })}>
          Urgent {pluralize('Findings', findings.length)}
        </h3>
      </div>

      <div
        className={cn('h-44', {
          'overflow-y-scroll': findings.length > 0,
        })}
      >
        {findings.length ? (
          findings
            .sort((finding, prevFinding) => {
              if (!finding.detectionTime || !prevFinding.detectionTime) return 0
              return (
                prevFinding.detectionTime.toDate().getTime() -
                finding.detectionTime.toDate().getTime()
              )
            })
            .map((finding) => {
              const timeAgoString =
                finding.detectionTime && timeSinceFormatted(finding.detectionTime?.toDate())
              const { profileName, risk } = companySummary[finding.companyId]
              return (
                <div
                  onClick={() => updateParam(UrlParam.FINDING_ID, finding.id)}
                  key={finding.id}
                  className='flex cursor-pointer justify-between border-b px-9 py-3 hover:bg-gray-100'
                >
                  <div className='flex w-full'>
                    <TextWithIcon
                      className='w-1/3'
                      icon={<CompanyRiskIcon riskLevel={risk} />}
                      text={<div className='truncate font-semibold'>{profileName}</div>}
                    />
                    <TextWithIcon
                      text={finding.title}
                      icon={
                        <div className='w-24'>
                          <FindingSeverityBadge level={finding.riskLevel} />
                        </div>
                      }
                    />
                  </div>
                  {timeAgoString && (
                    <div className='text-nowrap font-light text-gray-600'>{timeAgoString}</div>
                  )}
                </div>
              )
            })
        ) : (
          <EmptyUrgentFindings />
        )}
      </div>
    </div>
  )
}
