import { VariantProps } from 'class-variance-authority'
import { ChevronDownIcon, XIcon } from 'lucide-react'
import { useState } from 'react'

import { cn } from '@/lib/style-helpers'

import { selectVariants } from '@/components/select/variants'
import {
  Select as SelectBase,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { Item } from './item'
import type { MenuItem } from './type'

type SelectProps<T extends number | string> = {
  title?: string
  value?: T
  placeholder?: string
  menuItems: MenuItem<T>[]
  onValueChange?: (value: T) => void
} & VariantProps<typeof selectVariants>

export const Select = <T extends number | string>({
  menuItems,
  title,
  value,
  onValueChange,
  triggerAppearance,
  placeholder,
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SelectBase
      onValueChange={(newValue) =>
        onValueChange &&
        // The internal select supports only strings but we want native support for numbers. This is why
        // we convert numbers to strings and here we need to convert strings back to numbers
        // in case the original input was numeric
        onValueChange((typeof value === 'number' ? Number(newValue) : newValue.toString()) as T)
      }
      open={isOpen}
      onOpenChange={setIsOpen}
      value={value && value.toString()}
    >
      <SelectTrigger className={selectVariants({ triggerAppearance })}>
        <div className={cn({ 'w-full': triggerAppearance === 'full' })}>
          <SelectValue placeholder={<div className='w-full text-left'>{placeholder}</div>} />
        </div>
        <ChevronDownIcon
          strokeWidth={1}
          className='size-6 rounded p-1 opacity-50 group-hover:bg-gray-200'
        />
      </SelectTrigger>
      <SelectContent className='z-max'>
        <SelectGroup>
          {title && (
            <>
              <SelectLabel className='flex items-center justify-between gap-4 py-3 font-normal text-gray-700'>
                {title}
                <XIcon
                  onClick={() => setIsOpen(false)}
                  className='cursor-pointer rounded p-0.5 text-gray-400 duration-200 ease-in-out hover:bg-gray-200'
                  size={18}
                />
              </SelectLabel>
              <SelectSeparator />
            </>
          )}

          <div className='max-h-112 overflow-y-auto'>
            {menuItems.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
        </SelectGroup>
      </SelectContent>
    </SelectBase>
  )
}
