import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  import$ as importTemplate,
  listTemplates,
} from '@/gen/questionnaire/v1/template_service-TemplateService_connectquery'

export const useUploadQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()
  const { queryKey: listTemplatesQueryKey } = listTemplates.useQuery()
  return useMutation(importTemplate.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listTemplatesQueryKey)
    },
  })
}
