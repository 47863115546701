import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  getControl,
  listControlSettings,
  listControls,
  updateControlSettings,
} from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'
import {
  getFinding,
  listFindings,
} from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

export const useUpdateControlSettings = () => {
  const queryClient = useQueryClient()

  return useMutation(updateControlSettings.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listControlSettings.getQueryKey())
      queryClient.invalidateQueries(listControls.getQueryKey())
      queryClient.invalidateQueries(getControl.getQueryKey())
      queryClient.invalidateQueries(getFinding.getQueryKey())
      queryClient.invalidateQueries(listFindings.getQueryKey())
    },
  })
}

export const useControlSettings = () => {
  const queryState = useQuery(listControlSettings.useQuery())

  return {
    ...queryState,
    data: queryState.data?.controlSettings || [],
  }
}

export const useListControls = (companyId: string, enabled = true) => {
  const queryState = useQuery({ ...listControls.useQuery({ companyId }), enabled })

  return {
    ...queryState,
    data: queryState.data?.controls || [],
  }
}

export const useGetControl = (controlId: string, companyId: string) => {
  const queryState = useQuery(getControl.useQuery({ controlId, companyId }))

  return {
    ...queryState,
    data: queryState.data,
  }
}
