import { InfoIcon } from 'lucide-react'

import { ACTIVE_COMPANY_STATUSES } from '@/const/company-statuses'

import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { Tooltip } from '@/components/ui/tooltip'

export const WidgetActiveStatusesInfo = () => (
  <Tooltip className='w-72 text-wrap p-3 text-base font-normal' trigger={<InfoIcon size={14} />}>
    This widget displays data only for third-parties with the following statuses:
    <div className='mt-5 flex flex-col gap-3'>
      {ACTIVE_COMPANY_STATUSES.map((status) => (
        <CompanyVerificationBadge status={status} key={status} />
      ))}
    </div>
  </Tooltip>
)
