import { PlusIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useListQuestionnaireTemplates } from '@/api/questionnaire-template.hook'
import {
  QuestionnaireTemplate,
  QuestionnaireTemplateSection,
} from '@/gen/questionnaire/v1/template_service_pb'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { useImportTemplateModal } from '@/pages/questionnaire-management/use-import-template-modal'

import { Loading } from '@/components/loading'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'

export const TemplatesSection = () => {
  const { showModal, renderModal } = useImportTemplateModal()
  return (
    <div>
      <div className='mb-5 flex justify-end'>
        <Button onClick={showModal} className='flex items-center gap-2 px-4 py-2'>
          <PlusIcon size={14} />
          <span>Import Template</span>
        </Button>
      </div>
      <TemplatesTable />
      {renderModal()}
    </div>
  )
}

const TemplatesTable = () => {
  const navigate = useNavigate()

  const { data: templates, isLoading, error } = useListQuestionnaireTemplates()
  const reportErr = useReportErrorsCallback()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    reportErr(error)
    return null
  }

  const columns: ColumnType<QuestionnaireTemplate>[] = [
    {
      title: 'Template',
      render: ({ name, description }) => (
        <>
          <div>{name}</div>
          {description && <div className='text-sm text-gray-400'>{description}</div>}
        </>
      ),
      accessor: ({ name, description }) => ({
        searchValue: `${name} ${description}`,
        sortValue: name,
      }),
    },
    {
      title: 'Questions',
      dataIndex: 'questionCount',
    },
    {
      title: 'Sections',
      dataIndex: 'sections',
      render: (sections: QuestionnaireTemplateSection[]) => (
        <div className='flex cursor-default flex-wrap gap-1'>
          {sections.map((s, i) => (
            <Badge className='bg-white text-sm' key={i} variant={'outline'}>
              {s.name}
            </Badge>
          ))}
        </div>
      ),
    },
  ]

  const onRow = ({ id }: QuestionnaireTemplate) => {
    return { onClick: () => navigate(`templates/${id}`) }
  }

  return <Table onRow={onRow} showFilterBar={false} columns={columns} dataSource={templates} />
}
