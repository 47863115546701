import { useSetGWSCredentials } from '@/api/credentials/gws.hook'

import { GoogleCredentialsForm } from '@/pages/settings-page/integration-section/integration-form/google-credentials-form'
import { useUploadToast } from '@/pages/settings-page/integration-section/integration-form/upload-toast.hook'
import { IntegrationHeader } from '@/pages/settings-page/integration-section/integration-header'
import { BaseIntegrationProps } from '@/pages/settings-page/integration-section/integration/type'

import { DashedContainer } from '@/components/dashed-container'

type GwsIntegrationProps = BaseIntegrationProps

export const GwsIntegration = ({ integration }: GwsIntegrationProps) => {
  const {
    mutate: setGWSCredentials,
    isSuccess,
    isError,
    isLoading: isSetIntegrationLoading,
    error,
  } = useSetGWSCredentials()

  useUploadToast(isSuccess, isError, error)

  return (
    <div className='flex w-full justify-around pt-8'>
      <div className='max-w-lg'>
        <IntegrationHeader
          integration={integration}
          requiredPermissions={['Super Admin Permissions']}
        />
        <DashedContainer>
          <p className='mt-2 font-semibold'>Integrate GWS independently</p>
          <GoogleCredentialsForm
            setCredentials={setGWSCredentials}
            isLoading={isSetIntegrationLoading}
          />
        </DashedContainer>
      </div>
    </div>
  )
}
