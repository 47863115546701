import { useGetUserById } from '@/api/get-user-by-id.hook'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Tooltip } from '@/components/ui/tooltip'

export const UserAvatar = ({ userId }: { userId: string }) => {
  const { data } = useGetUserById(userId)
  const userInitials = data?.name
    ?.split(' ')
    .map((n) => n[0])
    .join('')
  return (
    <Avatar className='size-6 bg-gray-200 text-xs'>
      <AvatarFallback>{<Tooltip trigger={userInitials}>{data?.name}</Tooltip>}</AvatarFallback>
    </Avatar>
  )
}
