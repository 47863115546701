import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { useListTemplateQuestion } from '@/api/questionnaire-template.hook'

import { Category } from '@/pages/questionnaire-template/categoty'
import { QuestionnaireAnchor } from '@/pages/questionnaire/questionnaire-anchor'

import { Loading } from '@/components/loading'

export const ListEditableTemplateQuestion = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { data: templateQuestions, isLoading } = useListTemplateQuestion(templateId)

  if (isLoading) {
    return <Loading />
  }

  const categoryQuestionsEntries = Object.entries(_.groupBy(templateQuestions, 'section'))

  return (
    <div className='flex gap-24'>
      <QuestionnaireAnchor
        categories={categoryQuestionsEntries.map(([category, questions]) => ({
          category: category,
          questionCount: questions.length,
        }))}
      />
      <div className='w-full'>
        {categoryQuestionsEntries.map(([category, questions]) => (
          <Category key={category} questions={questions} category={category} />
        ))}
      </div>
    </div>
  )
}
