import pluralize from 'pluralize'
import { useContext, useEffect } from 'react'

import { useListQuestionnaireTemplates } from '@/api/questionnaire-template.hook'

import { cn } from '@/lib/style-helpers'

import { NewQuestionnaireContext } from '@/pages/questionnaire-management/new-questionnaire-modal/context'
import { StepProps } from '@/pages/questionnaire-management/new-questionnaire-modal/use-new-questionnaire-modal.hook'

import { Loading } from '@/components/loading'

export type TemplateSelectionStepProps = StepProps
export const TemplateSelectionStep = ({ setIsNextEnabled }: StepProps) => {
  const { selectedTemplateId, setSelectedTemplateId } = useContext(NewQuestionnaireContext)
  const { data: templates, isLoading } = useListQuestionnaireTemplates()

  useEffect(() => {
    setIsNextEnabled(!!selectedTemplateId)
  }, [selectedTemplateId, setIsNextEnabled])

  if (isLoading) return <Loading />

  return (
    <div className='mt-4 flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <h2 className='text-xl'>Template</h2>
        <h3 className='text-lg font-semibold'>Choose the Questionnaire Template to Send</h3>
        <p>You can choose which sections of the template to send in the next step.</p>
      </div>
      <div className='flex flex-col gap-2'>
        {templates.map((template) => (
          <div
            key={template.id}
            className={cn(
              'flex cursor-pointer flex-col gap-0.5 rounded border p-4 shadow ring-0 transition-all',
              template.id == selectedTemplateId && 'ring-2 ring-purple-500',
            )}
            onClick={() => setSelectedTemplateId(template.id)}
          >
            <h4 className='font-semibold'>{template.name}</h4>
            <p>{template.description}</p>
            <p>
              {pluralize('Question', template.questionCount, true)} |{' '}
              {pluralize('Section', template.sections.length, true)}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
