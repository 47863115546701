import { PlainMessage } from '@bufbuild/protobuf'
import { Divider } from 'antd'
import _ from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { useGetCompany, useGetCompanyDrawerMetadata } from '@/api/company.hook'
import { ControlWithFinding, useFetchCompanyData } from '@/api/export-compnay.hook'
import {
  InherentRiskGroup,
  InherentRiskItem,
  InherentRiskSource,
} from '@/gen/inventory/v1/company_service_pb'
import { ControlStatus } from '@/gen/inventory/v1/control_pb'

import { riskLevelToBgClassName } from '@/lib/color'
import { formatDate } from '@/lib/date'
import { cn } from '@/lib/style-helpers'

import { CompanyArtifactTable } from '@/pages/company-drawer/artifact/company-artifact-table'
import { CompanyProfile } from '@/pages/company-drawer/company-profile'

import { ControlStatusBadge } from '@/components/badge/control-status'
import { FindingSeverityBadge } from '@/components/badge/finding-severity'
import { FindingStatusBadge } from '@/components/badge/finding-status'
import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { Evidence } from '@/components/evidence'
import { ExportButton } from '@/components/export-button'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { FindingStatusSymbol } from '@/components/icons/finding-status'
import { PermissionSymbol } from '@/components/icons/permission'
import { RiskLevelIcon, RiskLevelSymbol } from '@/components/icons/risk-severity'
import { PermissionIndicatorList } from '@/components/indicator/permission'
import { LastChanged } from '@/components/last-changed'
import { FindingDistribution } from '@/components/risk-factor-distribution'
import { StatisticsRow } from '@/components/stats-row/stats-row'
import { TextWithIcon } from '@/components/text-with-icon'
import { ButtonProps } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

type ExportCompanyProps = {
  companyId: string
} & ButtonProps

export const ExportCompany = ({ companyId, ...props }: ExportCompanyProps) => {
  const { data } = useGetCompany(companyId)
  const getExportContent = useExportCompanyContent(companyId)
  return (
    <ExportButton
      data-dd-action-name='third-party.export'
      documentTitle={data?.company?.profile?.name}
      getExportContent={getExportContent}
      {...props}
    />
  )
}

const useExportCompanyContent = (companyId: string) => {
  let content: ReactNode | null = null
  const [isEnabled, setIsEnabled] = useState(false)

  const {
    loading: companyLoading,
    data: { controlsData, cisoProfile, company, inherentRiskGroups },
  } = useFetchCompanyData(companyId, isEnabled)
  const { data: companyDrawerData, isLoading: companyDrawerLoading } =
    useGetCompanyDrawerMetadata(companyId)

  const loading = companyLoading || companyDrawerLoading

  const controlsByCategory = useMemo(() => {
    return _.groupBy(controlsData, (control) => control.control?.categoryName)
  }, [controlsData])

  const lemaItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      const items = _.filter(
        group.inherentRiskItems,
        (item) => item.source !== InherentRiskSource.IRQ,
      )
      const groupedItems: Partial<InherentRiskItem>[][] = _.map(
        _.groupBy(items, (item) => item.displayName),
      )
      const filteredItems = _.map(
        _.values(groupedItems),
        (items) => _.maxBy(items, (item) => item.source) || new InherentRiskItem(),
      )

      const uniqItems = _.uniqBy(filteredItems, (item) => item.id)

      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: uniqItems,
      })
    })
  }, [inherentRiskGroups])

  if (!loading && company && company.company?.profile) {
    const { profile, status, statusLastUpdate, risk, findingsByLevel } = company.company

    content = (
      <>
        <div className='mb-10 bg-gray-50 py-10'>
          <div className='mx-8'>
            <div className='grid w-fit grid-cols-[min-content,auto] items-center gap-x-20 gap-y-4.5'>
              <h2 className='text-nowrap text-3xl font-bold'>{profile.name}</h2>
              <div className='flex items-center gap-2'>
                <CompanyVerificationBadge className='pl-0' status={status} />
                {statusLastUpdate && (
                  <LastChanged withTimeCount={false} lastChanged={statusLastUpdate.toDate()} />
                )}
              </div>
              <span className='text-sm font-bold'>INHERENT RISK</span>
              <CompanyRiskIcon showText riskLevel={risk} />
              <span className='text-sm font-bold'>DESCRIPTION</span>
              <p>{profile.longDescription}</p>
              <TextWithIcon
                className='text-sm font-bold uppercase'
                text='Findings'
                icon={<FindingStatusSymbol />}
              />
              <FindingDistribution findingCountByLevel={findingsByLevel} />
              <TextWithIcon
                className='text-sm font-bold uppercase'
                text='Permissions'
                icon={<PermissionSymbol />}
              />
              <PermissionIndicatorList
                permissions={
                  companyDrawerData?.permissions.flatMap(({ category }) => category) ?? []
                }
              />
            </div>
          </div>
          <Separator className='my-12' />
          <div className='mx-8'>
            <CompanyProfile info={profile} ciso={cisoProfile} />
          </div>
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <Divider orientation='left'>
          <div className='text-2xl'>Inherent Risk Profile</div>
        </Divider>
        <div className='mx-8 flex items-center'>
          <InherentRiskExport inherentRiskGroups={lemaItems} />
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <Divider orientation='left'>
          <div className='text-2xl'>Third-Party Artifacts</div>
        </Divider>
        <div className='mx-8'>
          <CompanyArtifactTable isExport companyId={companyId} />
        </div>
        <div className='break-after-page' />
        <div className='my-10' />
        <Divider orientation='left'>
          <div className='text-2xl'>Third Party Controls & Findings</div>
        </Divider>
        <div className='mx-8'>
          {Object.entries(controlsByCategory).map(([category, controls]) => (
            <div key={category} className='mb-28 break-after-page last:break-after-avoid'>
              <div className='mt-4 rounded bg-gray-50 px-10 py-5 font-bold uppercase'>
                <span className='text-xs'>CONTROL CATEGORY</span>
                <h3 className='text-xl'>{category}</h3>
              </div>
              <div className='mt-6 flex flex-col gap-6'>
                {controls.map((controlWithFinding) => (
                  <div key={controlWithFinding.control?.id}>
                    <div className='mb-2 flex items-center space-x-1'>
                      <span className='shrink-0 text-lg font-bold'>
                        {controlWithFinding.control?.title}
                      </span>
                      <span className='shrink-0'>-</span>
                      <span className='truncate text-gray-700'>
                        {controlWithFinding.control?.description}
                      </span>
                    </div>

                    <div className='flex rounded border'>
                      <div className='flex w-fit flex-col gap-2 border-r p-4'>
                        <span className='text-nowrap'>Validation Status</span>
                        <ControlStatusBadge
                          status={controlWithFinding.control?.status || ControlStatus.VALIDATED}
                        />
                      </div>
                      <div className='w-full p-6'>
                        <ControlEvidence controlWithFinding={controlWithFinding} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  return () => ({
    exportContent: content,
    isLoaded: !loading,
    renderContent: () => setIsEnabled(true),
  })
}

const ControlEvidence = ({ controlWithFinding }: { controlWithFinding: ControlWithFinding }) => {
  const {
    title,
    status,
    riskLevel,
    detectionTime,
    id: findingId,
  } = controlWithFinding.finding || {}

  return (
    <div>
      {controlWithFinding.control?.status === ControlStatus.FINDING && (
        <div>
          <span className='text-xs'>Finding</span>
          <div className='mb-8 text-lg font-bold'>{title}</div>
          <StatisticsRow
            items={[
              {
                title: 'Severity',
                icon: <RiskLevelSymbol />,
                children: riskLevel && <FindingSeverityBadge level={riskLevel} />,
              },
              {
                title: 'Work Status',
                icon: <FindingStatusSymbol />,
                children: status && <FindingStatusBadge status={status} />,
              },
              {
                title: 'Detection Time',
                children: detectionTime && formatDate(detectionTime.toDate(), 'dateTime'),
              },
            ]}
          />
          <div className='mt-7'>
            <div className='my-3'>
              <span className='font-bold'>Description:</span>
              <span>{controlWithFinding.documentation?.description}</span>
            </div>
            <div className='my-3'>
              <span className='font-bold'>What is the Risk?</span>
              <span>{controlWithFinding.documentation?.risk}</span>
            </div>
          </div>
        </div>
      )}
      {!findingId && !controlWithFinding.factMarkdown && (
        <div className='flex h-full items-center leading-loose'>
          Lema has not found any indication that this control is violated. Lema is continuing to
          monitor it.
        </div>
      )}
      <Evidence factMarkdown={controlWithFinding.factMarkdown} defaultOpen />
    </div>
  )
}

export const InherentRiskExport = ({
  inherentRiskGroups,
}: {
  inherentRiskGroups: PlainMessage<InherentRiskGroup>[]
}) => {
  return (
    <div className='block'>
      <div className='grid grid-cols-2 gap-2'>
        {inherentRiskGroups.map((group) => (
          <div key={group.categoryEnum} className='rounded border'>
            <div
              key={group.categoryEnum}
              className='flex w-fit px-4 py-2 text-center text-lg font-medium'
            >
              {group.category}
              {group.subcategory && ` - ${group.subcategory}`}
            </div>
            <div className='flex flex-wrap gap-2 p-2'>
              {group.inherentRiskItems.map((item) => (
                <div
                  key={item.id}
                  className={cn(
                    'flex items-center rounded px-2 py-1',
                    riskLevelToBgClassName[item.severity],
                  )}
                >
                  <span className='mr-1'>
                    {item.severity && <RiskLevelIcon variant='inherit' level={item.severity} />}
                  </span>

                  {item.displayName}
                </div>
              ))}
              {group.inherentRiskItems.length === 0 && <div className='px-4 py-2'>No items</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
