import { ClipboardIcon, ShieldCheckIcon } from 'lucide-react'
import { ReactNode } from 'react'

import { ControlStatus } from '@/gen/inventory/v1/control_pb'

type ControlStatusIconProps = {
  status: ControlStatus
}

export const ControlStatusIcon = ({ status }: ControlStatusIconProps) => controlStatusIcon[status]

const controlStatusIcon: Record<ControlStatus, ReactNode> = {
  [ControlStatus.FINDING]: <ClipboardIcon strokeWidth={1.5} className='size-4 text-red-500' />,
  [ControlStatus.VALIDATED]: (
    <ShieldCheckIcon strokeWidth={1.5} className='size-4 text-green-500' />
  ),
  [ControlStatus.UNSPECIFIED]: null,
}
