import React, { createContext } from 'react'

import {
  CheckboxFilterContext,
  useCheckboxFilter,
} from '@/components/table/filter/checkbox-filter-hook'
import { FilterContext, useFilter } from '@/components/table/filter/filter.hook'
import { SearchContext, useSearch } from '@/components/table/filter/search.hook'

export type FilterProviderContext = FilterContext & SearchContext & CheckboxFilterContext

export const FilterProviderContext = createContext<FilterProviderContext>({
  searchState: '',
  checkboxFilterState: false,
  setSearchState: () => {},
  filteredState: {},
  setCheckboxFilterState: () => {},
  setSelected: () => {},
  toggleSelected: () => {},
  isSelected: () => false,
  clearFilter: () => {},
  clearFilters: () => {},
  setShowFilterTags: () => {},
  showFilterTags: false,
})

export type FilterProviderProps = {
  children: React.ReactNode
  checkboxFilterState?: boolean
  setCheckboxFilterState?: (checkboxFilterState: boolean) => void
}

// Create a provider component for the filter context
export const FilterProvider = ({
  children,
  setCheckboxFilterState,
  checkboxFilterState,
}: FilterProviderProps) => {
  const searchContext = useSearch()
  const filterContext = useFilter()
  const checkboxFilterContext = useCheckboxFilter({ checkboxFilterState, setCheckboxFilterState })

  return (
    <FilterProviderContext.Provider
      value={{ ...searchContext, ...filterContext, ...checkboxFilterContext }}
    >
      {children}
    </FilterProviderContext.Provider>
  )
}
