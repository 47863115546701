import { ArrowLeftIcon, PlusIcon } from 'lucide-react'

import { useGetCompany } from '@/api/company.hook'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { ArtifactsTable } from '@/pages/settings-page/artifacts-section/artifacts-table'
import { useUploadDocumentsModal } from '@/pages/settings-page/artifacts-section/use-upload-files-modal'

import { Hyperlink } from '@/components/hyperlink'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

export const ArtifactsSection = () => {
  const { renderModal, showModal } = useUploadDocumentsModal()
  const { urlParams } = useUrlParams()
  const { data } = useGetCompany(urlParams.thirdParty || '', !!urlParams.thirdParty)
  let companyBackToName = ''
  let companyBackToUrl = ''
  if (data?.company?.profile?.name && urlParams.thirdParty) {
    companyBackToName = data.company.profile.name
    companyBackToUrl = `/inventory?${UrlParam.THIRD_PARTY_ID}=${urlParams.thirdParty}`
  }
  return (
    <>
      <div className='mb-5 flex justify-between'>
        <div className='flex items-center gap-4'>
          <h2 className='self-start text-3xl font-bold'>Artifacts</h2>
          {companyBackToName && (
            <Hyperlink href={companyBackToUrl} color='purple'>
              <TextWithIcon
                icon={<ArrowLeftIcon size={14} />}
                text={`Back to ${companyBackToName}`}
              />
            </Hyperlink>
          )}
        </div>
        <Button onClick={showModal} className='flex items-center gap-2 px-4 py-2'>
          <PlusIcon size={14} />
          <span>Upload Artifacts</span>
        </Button>
      </div>
      <ArtifactsTable />
      {renderModal()}
    </>
  )
}
