import { ReactNode, useCallback } from 'react'

import { useGetFinding } from '@/api/get-risk-factor.hook'

import { FindingDetailsHeader } from '@/pages/finding-details/finding-header'

import { Evidence } from '@/components/evidence'
import { ExportButton } from '@/components/export-button'
import { Markdown } from '@/components/markdown'
import { Separator } from '@/components/ui/separator'

type ExportFindingProps = {
  findingId: string
}

export const ExportFinding = ({ findingId }: ExportFindingProps) => {
  const { data, isLoading, isError } = useGetFinding(findingId, true)

  const getExportContent = useCallback(() => {
    if (isLoading || !data || isError || !data?.finding || !data.company) {
      return {
        exportContent: null,
        renderContent: () => {},
        isLoaded: false,
      }
    }

    const exportContent: ReactNode = (
      <>
        <FindingDetailsHeader
          titleVariant={'showAll'}
          disableActions
          company={data.company}
          {...data.finding}
        />
        <div className='mx-8'>
          <div>
            <h1 className='font-semibold leading-10'>Description</h1>
            <Markdown>{data.documentation?.description}</Markdown>
          </div>
          <br />
          <div>
            <h1 className='font-semibold leading-10'>What is the Risk?</h1>
            <Markdown>{data.documentation?.risk}</Markdown>
          </div>
          {data.factMarkdown && (
            <>
              <Separator className='my-12' />
              <Evidence
                factMarkdown={data.factMarkdown}
                additionalFactMarkdown={data.additionalFactMarkdown}
                additionalFactCount={data.additionalFactCount}
              />
            </>
          )}
        </div>
      </>
    )

    return {
      exportContent,
      renderContent: () => {},
      isLoaded: true,
    }
  }, [data, isLoading, isError])

  if (isLoading || !data || isError || !data?.finding || !data.company) {
    return null
  }

  return (
    <ExportButton
      data-dd-action-name='finding.export'
      documentTitle={`${data.company.profileName} - ${data.finding.title}`}
      getExportContent={getExportContent}
    />
  )
}
