import { VictoryArea, VictoryChart, VictoryGroup, VictoryPolarAxis, VictoryScatter } from 'victory'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { victoryPolarChartTheme } from '@/const/theme'

import { riskLevelToColor } from '@/lib/color'

export type InherentRiskChartProps = {
  companyRisk: RiskLevel
  chart: {
    x: string
    y: number
  }[]
}

export const InherentRiskChart = ({ companyRisk, chart }: InherentRiskChartProps) => {
  return (
    <VictoryChart polar theme={victoryPolarChartTheme} domain={{ y: [0, 5] }}>
      {chart.map(({ x }, i) => {
        return (
          <VictoryPolarAxis
            dependentAxis
            key={i}
            label={x.replace(' ', '\n')}
            labelPlacement='vertical'
            style={{
              axisLabel: { padding: 15, fontSize: 20 },
              tickLabels: { fill: 'none' },
            }}
            axisValue={x}
          />
        )
      })}
      <VictoryGroup data={chart}>
        <VictoryArea
          style={{
            data: { fillOpacity: 0.1, strokeWidth: 1, fill: riskLevelToColor[companyRisk] },
          }}
        />
        <VictoryScatter
          style={{
            data: { fill: riskLevelToColor[companyRisk] },
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  )
}
