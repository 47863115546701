import { Collapse } from 'antd'
import _ from 'lodash'
import pluralize from 'pluralize'

import { useListCompanyArtifact } from '@/api/artifact.hook'
import { Artifact, DocumentSource } from '@/gen/inventory/v1/artifact_service_pb'

import { documentSourceLabel, documentStatusLabel } from '@/const/label'
import { documentStatusPriority } from '@/const/priority'

import { useTrackCallback } from '@/lib/analytics/events'
import { formatDate } from '@/lib/date'
import { cn } from '@/lib/style-helpers'

import { CompanyArtifactUploadButton } from '@/pages/company-drawer/company-artifact-upload-button'

import { ArtifactHyperlink } from '@/components/artifact-hyperlink'
import { ArtifactExpirationDate } from '@/components/badge/artifact-expiration-date'
import { DocumentStatusBadge } from '@/components/badge/file-analyze-status'
import { Hyperlink } from '@/components/hyperlink'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'

const MaxString = Array(100).fill('Z').join('')

// compareByExpirationAndDateAdded can be used to sort artifacts so that artifacts with expiry appear first, and are ordered by expiry date and then by date added
const compareByExpirationAndDateAdded = (a: Artifact, b: Artifact) => {
  if (a.expirationDate && b.expirationDate) {
    return b.expirationDate.toDate().getTime() - a.expirationDate.toDate().getTime()
  } else if (a.expirationDate) {
    return -1
  } else if (b.expirationDate) {
    return 1
  } else if (a.dateAdded && b.dateAdded) {
    return b.dateAdded.toDate().getTime() - a.dateAdded.toDate().getTime()
  } else {
    return b.id.localeCompare(a.id)
  }
}

type CompanyArtifactTableProps = {
  companyId: string
} & ({ isExport?: never; companyName: string } | { isExport: true; companyName?: never })

export const CompanyArtifactTable = ({
  companyId,
  isExport,
  companyName,
}: CompanyArtifactTableProps) => {
  const columns = useCompanyArtifactColumns(false, isExport)
  const { data, isLoading } = useListCompanyArtifact(companyId)

  const sortedData = data.sort(compareByExpirationAndDateAdded)

  const firstVersionItems = _.uniqBy(sortedData, (item) =>
    // Currently, we are not supporting versioning for web artifacts
    item.source === DocumentSource.WEB ? item.id : item.artifactSubcategory || item.id,
  )

  const groupedData = _.groupBy(
    sortedData.filter(
      (item) =>
        !firstVersionItems.some((unique) => unique.id === item.id) &&
        item.source !== DocumentSource.WEB,
    ),
    'artifactSubcategory',
  )

  return (
    <>
      <div className={cn('mb-5 flex justify-end', { '-mt-14': !isExport })}>
        {!isExport && (
          <CompanyArtifactUploadButton
            companyId={companyId}
            companyName={companyName}
            variant='outline'
          />
        )}
      </div>
      <Table
        rowKey={(record) => record.id}
        expandable={{
          showExpandColumn: false,
          expandedRowKeys: firstVersionItems
            .filter(({ artifactSubcategory }) => {
              if (!artifactSubcategory) return false
              return !!groupedData[artifactSubcategory]
            })
            .map((record) => record.id),
          expandedRowRender: ({ artifactSubcategory }) => {
            if (!artifactSubcategory) return null
            return (
              <ArtifactPreviousVersionsCollapse
                previousVersions={groupedData[artifactSubcategory]}
                subcategoryName={artifactSubcategory}
              />
            )
          },
        }}
        showFilterBar={!isExport}
        narrowFilterBar
        columns={columns}
        dataSource={firstVersionItems}
        loading={isLoading}
      />
    </>
  )
}

type ArtifactPreviousVersionsCollapseProps = {
  subcategoryName: string
  previousVersions: Artifact[]
}

const ArtifactPreviousVersionsCollapse = ({
  previousVersions,
  subcategoryName,
}: ArtifactPreviousVersionsCollapseProps) => {
  const columns = useCompanyArtifactColumns(true)
  return (
    <div className='-mx-2.5 -my-5'>
      <Collapse
        size='small'
        ghost
        items={[
          {
            label: (
              <span className='text-sm'>
                {previousVersions.length} previous {subcategoryName}{' '}
                {pluralize('version', previousVersions.length)}
              </span>
            ),
            children: (
              <div className='-m-2'>
                <Table
                  showHeader={false}
                  showBorder={false}
                  showFilterBar={false}
                  columns={columns}
                  dataSource={previousVersions}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}

const useCompanyArtifactColumns = (inactiveExpiryDate = false, isExport = false) => {
  const trackArtifactView = useTrackCallback('artifact.view')
  const columns: ColumnType<Artifact>[] = [
    {
      ellipsis: true,
      width: '25%',
      title: 'Artifact',
      filter: true,
      accessor: ({ artifactSubcategory }) => ({
        filterValue: artifactSubcategory,
        sortValue: artifactSubcategory,
      }),
      render: ({ artifactSubcategory, expirationDate }: Artifact) => {
        if (!artifactSubcategory) return '–'

        return (
          <TextWithIcon
            text={artifactSubcategory}
            icon={
              expirationDate && (
                <ArtifactExpirationDate
                  variant={inactiveExpiryDate ? 'inactive' : null}
                  expirationDate={expirationDate.toDate()}
                />
              )
            }
            iconPosition='end'
          />
        )
      },
    },
    {
      hidden: isExport,
      ellipsis: true,
      width: '15%',
      title: 'Source',
      dataIndex: 'source',
      render: (source: Artifact['source']) => documentSourceLabel[source],
      filter: true,
      accessor: ({ source }) => ({
        filterValue: documentSourceLabel[source],
      }),
    },
    {
      title: 'Link',
      ellipsis: true,
      width: '30%',
      render: ({ artifact, name, artifactSubcategory }: Artifact) => (
        <div className='truncate'>
          {artifact.case === 'artifactId' ? (
            // Do not include links in exports to prevent the accidental sharing of sensitive information with unintended recipients of the PDF.
            isExport ? (
              name || artifactSubcategory
            ) : (
              <ArtifactHyperlink artifactId={artifact.value}>
                {name || artifactSubcategory}
              </ArtifactHyperlink>
            )
          ) : (
            <Hyperlink
              onClick={() =>
                trackArtifactView({
                  artifactLink: artifact.value,
                })
              }
              href={artifact.value}
            >
              {name || artifactSubcategory}
            </Hyperlink>
          )}
        </div>
      ),
      search: true,
      accessor: ({ name, artifactSubcategory }) => ({
        sortValue: name === 'Unknown' ? MaxString : name,
        searchValue: `${name || ''} ${artifactSubcategory || ''}`,
      }),
    },
    {
      hidden: isExport,
      width: '15%',
      title: 'Analysis Status',
      render: ({ status, error, dateUpdated }: Artifact) => (
        <DocumentStatusBadge error={error} status={status} lastUpdated={dateUpdated} />
      ),
      filter: true,
      accessor: ({ status, error }) => {
        const maxStatus = _.max(Object.values(documentStatusPriority)) || 0
        return {
          filterValue: error ? 'Failed' : documentStatusLabel[status],
          sortValue: error ? maxStatus + 1 : documentStatusPriority[status],
        }
      },
    },
    {
      width: '15%',
      title: 'Date Added',
      dataIndex: 'dateAdded',
      render: (dateAdded: Artifact['dateUpdated'], { dateUpdated }) => (
        <span className='text-gray-400'>
          {dateAdded
            ? formatDate(dateAdded?.toDate())
            : dateUpdated && formatDate(dateUpdated?.toDate())}
        </span>
      ),
      accessor: ({ dateUpdated }) => ({
        sortValue: dateUpdated && dateUpdated.toDate(),
      }),
    },
  ]
  return columns
}
