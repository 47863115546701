import { useContext, useEffect } from 'react'

import { UploadFiles } from '@/pages/settings-page/artifacts-section/upload-files'

import { AddNewThirdPartyContext } from './context'
import { StepProps } from './use-add-third-party-modal.hook'

type UploadArtifactStepProps = StepProps

export const UploadArtifactStep = ({ setIsNextEnabled }: UploadArtifactStepProps) => {
  const { uploadRef, selectedCompany } = useContext(AddNewThirdPartyContext)
  useEffect(() => {
    setIsNextEnabled(true) // This Step is optional, so we always enable the next button
  }, [setIsNextEnabled])

  return (
    <div>
      <h2 className='my-4 text-xl font-light'>
        Upload Documents for {selectedCompany?.name} (Optional)
      </h2>
      <h3 className='leading-10'>{"Upload and classify this third party's documents."}</h3>
      <UploadFiles ref={uploadRef} hideTitle />
    </div>
  )
}
