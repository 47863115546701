import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelClassNameColor } from '@/lib/color'
import { cn } from '@/lib/style-helpers'

import Critical from '@/assets/critical.svg'
import High from '@/assets/high.svg'
import Low from '@/assets/low.svg'
import Medium from '@/assets/medium.svg'

export type RiskFactorIconProps = {
  level: RiskLevel
  variant?: 'color' | 'gray' | 'inherit'
}

export const RiskLevelIcon = ({ level, variant = 'color' }: RiskFactorIconProps) => {
  const Icon = riskLevelToIcon[level]
  if (!Icon) return null

  const colorClass = variant === 'color' ? riskLevelClassNameColor[level] : 'text-gray-500'
  return <Icon className={cn('w-3.75', variant !== 'inherit' && colorClass)} />
}

export type RiskLevelSymbolProps = React.ComponentProps<typeof Critical>

// RiskLevelSymbol is used to hint the user that the button or any ui component is releated to risk level.
export const RiskLevelSymbol = (props: RiskLevelSymbolProps) => (
  <Critical className={cn('w-2.5', props.className)} {...props} />
)

const riskLevelToIcon: Record<RiskLevel, typeof Critical | null> = {
  [RiskLevel.CRITICAL]: Critical,
  [RiskLevel.HIGH]: High,
  [RiskLevel.MEDIUM]: Medium,
  [RiskLevel.LOW]: Low,
  [RiskLevel.UNSPECIFIED]: null,
}
