import { usePostHog } from 'posthog-js/react'
import { useCallback } from 'react'

export const useReportErrorsCallback = () => {
  const posthog = usePostHog()

  return useCallback(
    (...errors: (Error | null)[]) => {
      errors.forEach((error) => {
        if (!error) {
          return
        }
        posthog.capture('error', {
          message: error.message,
          stack: error.stack,
        })
        console.error('error', error.message, error.stack)
      })
    },
    [posthog],
  )
}
