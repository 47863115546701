import { Timestamp } from '@bufbuild/protobuf'
import { useQuery } from '@tanstack/react-query'

import {
  daysCompaniesInAssessmentRequired,
  getCountCompaniesBySeverity,
  getFindingsCountBySeverityTimeseries,
  listCompaniesWithMostFindings,
} from '@/gen/inventory/v1/statistics_service-StatisticsService_connectquery'

export const useDaysCompaniesInAssessmentRequired = () => {
  return useQuery(daysCompaniesInAssessmentRequired.useQuery())
}

export const useFindingsCountBySeverityTimeseries = () => {
  return useQuery(getFindingsCountBySeverityTimeseries.useQuery({}))
}

export const useCompaniesWithMostFindings = () => {
  return useQuery(listCompaniesWithMostFindings.useQuery())
}

export const useCompaniesBySeverity = (date: Timestamp) => {
  return useQuery(
    getCountCompaniesBySeverity.useQuery({
      date,
    }),
  )
}
